<html>
   <head>
      <meta content="text/html; charset=UTF-8" http-equiv="content-type">
     
   </head>
   <body class="c2 c4" style="padding: 30px !important;">
      <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
         <span aria-hidden="true">
            <span class="material-icons">
            close
            </span>
         </span>
       </button>
      <h3 style="font-size: 22px !important;color: #354052 !important;" class="pt-0">Our Privacy Policy&nbsp; November 3, 2022</h3>
     
      <h4 class="c0 c2" style=" border-bottom: 3px solid #FF9A00; width: 164px">About Our Privacy Policy</h4>
      <p class="c0 c2"><span class="c7">ShipCarte Inc. (hereinafter &ldquo;ShipCarte&quot;, &quot;we&quot;, &quot;us&quot;, &quot;our&quot;) respects and protects your privacy online. To ensure this, we have prepared this Privacy Policy to set out the manner in which we collect, use, disclose, and otherwise manage the personal information of our customers and/or platform users. This Privacy Policy applies to our privacy practices on our website located at www.shipcarte.com (the &quot;Website&quot;) as well as other interactions with our customers.</span></p>
      <p class="c0 c2"><span class="c9" style=" border-bottom: 3px solid #FF9A00; width: 264px">Collection & Use of Personal Information</span></p>
      <p class="c0 c2"><span class="c7">We collect personal information about every shipment that is facilitated by ShipCarte to provide customers with the necessary transportation and information services. This is to make shipping convenient and provide accurate billing for the services that we provide. The personal information that is collected in relation to shipments include:</span></p>
      <ul class="c14 lst-kix_list_1-0 start">
         <li class="c0 c13 c2 li-bullet-0"><span class="c5">Contact Information</span></li>
         <!-- <li class="c0 c13 c2 li-bullet-0"><span class="c5">Proof of Identity</span></li> -->
         <!-- <li class="c0 c13 c2 li-bullet-0"><span class="c5">Financial information for billing etc.</span></li> -->
      </ul>
      <ul class="c14 lst-kix_list_1-0 start">     
         <li class="c0 c13 c2 li-bullet-0"><span class="c5">Proof of Identity</span></li>
      </ul>
      <ul class="c14 lst-kix_list_1-0 start">
         <li class="c0 c13 c2 li-bullet-0"><span class="c5">Financial information for billing etc.</span></li>
      </ul>
      <p class="c2 c16"><span class="c12 c2">With information collected, we provide our customers with products and services that are presented in a timely manner, including but not limited to the pick-up and delivery of shipments,&nbsp; logistics services, and timely communication with our customers about the status of their shipments , billing our customers for the products and services we provide them, the authenticity of their identities along with detailed invoicing, information for the management of established relationships pertaining to goods and services offered. The collection of information is also used to assist law enforcement agencies, collect debts that are owed to ShipCarte and to protect ShipCartes interests, includes but not limited to, enforcing ShipCarte&rsquo;s Terms and Conditions of Service, and generally meet the requirements that are imposed by the law.</span></p>
      <p class="c0 c2 c6"><span class="c3"></span></p>
      <p class="c0 c2"><span class="c9" style=" border-bottom: 3px solid #FF9A00; width: 117px">Customer Service</span></p>
      <p class="c0 c2"><span class="c7">In the event you contact us regarding a question, comment, or complaint, you will be asked for information that may verify your identity. This could include but not be limited to name, address, phone number etc. This will be updated to provide adequate solutions. This information will also be retained to constantly provide improved customer and user experience.</span></p>
      <p class="c0 c2 c6"><span class="c3"></span></p>
     
      <p class="c0 c2"><span class="c9" style=" border-bottom: 3px solid #FF9A00; width: 224px">Disclosure of Personal Information</span></p>
      <p class="c0 c2"><span class="c7">ShipCarte will not disclose any personal information relating to any customer unless it is to aid us in being providing our particular goods and services. </span><span class="c12 c2">Such parties may include other transportation companies or local pick-up and delivery agents involved in the pick-up, transportation and delivery of shipments, customs brokers and information services/technology service providers. The service providers that we use are prohibited to use your personal information other than for the sole purpose of delivering your goods. In the event of a proposed or actual or indirect sell, merger or other transfer of assets or shares of ShipCarte Inc, or a division thereof, ShipCarte reserves the right to disclose customer information, provided the 3rd party adheres to principles set out in this privacy statement.</span></p>
      <p class="c0 c2"><span class="c2 c12">We also reserve the right to cooperate completely with local, provincial, state and federal officials in the case of an investigation pertaining to the violation of any personal information, search warrants, or any form of unlawful activities. In the event that a debt is owed by you, we will disclose personal information as needed with enforcement agencies.</span></p>
      <p class="c0 c2"><span class="c12 c2">&nbsp;Except as stated above, we will not sell, share, transfer, license, trade or rent personal information received from them to others, without our customers consent.</span></p>
      <p class="c0 c6"><span class="c3"></span></p>
      <p class="c0"><span class="c9" style=" border-bottom: 3px solid #FF9A00; width: 57px">Consent</span></p>
      <p class="c0 c6"><span class="c5"></span></p>
      <p class="c0"><span class="c7">The consent we obtain from the individual will depend largely on the actual sensitivity of the personal information. Due to our code of conduct and services, we will not require the customer to provide consent any more than which is needed to fulfill the duty and requirements of the service and products provided. In the case that the customer is obtaining information pertaining to a third party, we rely on the customer to ensure that consent is obtained for the collection, use and disclosure of personal information.</span></p>
      <p class="c0 c6"><span class="c5"></span></p>
      <p class="c0"><span class="c7">In the event you want to withdraw your Consent to ShipCarte&rsquo;s use of and collection of personal information, you may do so by emailing clientcare@shipcarte.</span></p>
      <p class="c0 c6"><span class="c5"></span></p>
     
      <p class="c0"><span class="c9" style=" border-bottom: 3px solid #FF9A00; width: 52px">Cookies</span></p>
      <p class="c0 c6"><span class="c5"></span></p>
      <p class="c0"><span class="c7">A cookie is a small file that is created when you visit a website. Cookies can often track how the user is using the website and store information. They allow you to gain full access and mobility of a site that uses this technology. We use &ldquo;session&rdquo; cookies so that once the user is finished using the website the information, the originator does not have cookies stored anymore. Web browsers are additionally set up to notify you when the &ldquo;cookies&rdquo; feature is to take place, prompting a notification pop up to accept the cookies. If you decide not to use the cookies feature, you may not have access to the full site.</span></p>
      <p class="c0 c6"><span class="c5"></span></p>
      <p class="c0"><span class="c9" style=" border-bottom: 3px solid #FF9A00; width: 106px">Third Party Sites</span></p>
      <p class="c0 c6"><span class="c5"></span></p>
      <p class="c0"><span class="c7">Our website offers links to other websites, and in those circumstances, information can be collected, stored and or used by the generation of cookies, when they are linked to the website. We do not hold accountability for how third parties collect, use or disclose our personal customers information.&nbsp;</span></p>
      <p class="c0 c6"><span class="c5"></span></p>
      <p class="c0"><span class="c9"  style=" border-bottom: 3px solid #FF9A00; width: 225px">Changes to this Privacy Statement</span></p>
      <p class="c0 c6"><span class="c5"></span></p>
      <p class="c0"><span class="c7">Shipcarte reserves the right to modify/change this privacy statement at any given time. This information will be updated constantly to reflect our personal information practises as laid out in this privacy statement</span></p>
      <p class="c6 c11"><span class="c5"></span></p>
      <p class="c0"><span class="c9"  style=" border-bottom: 3px solid #FF9A00; width: 93px">Contacting Us</span></p>
      <p class="c0"><span class="c7">Anyone that has any questions regarding our compliance with our privacy policy, please contact </span><span class="c17">support@shipcarte.com</span><span class="c7">. If our response does not meet your needs and/or you wish to register a complaint regarding your privacy concerns, you may wish to contact the Privacy Commissioner of Ontario:</span></p>
      <p class="c0"><span class="c7">&nbsp;</span></p>
      <p class="c0"><span class="c7">Office of the Information and Privacy Commissioner of Ontario</span></p>
      <p class="c0"><span class="c7">2 Bloor Street East, Suite 1400</span></p>
      <p class="c0"><span class="c7">Toronto, Ontario M4W 1A8</span></p>
      <p class="c1"><span class="c8"></span></p>
      <div class="d-block">
         <button type="button" class="btn orange px-4 float-right text-white" #closeBtn data-dismiss="modal"
           (click)="agreeModal()">Agree</button>
         <button type="button" style="pointer-events: all; cursor: pointer;font-weight:600 !important;margin-right: 1pc;border: 1px solid #ff9a00;width: 86px;
                  height: 36px;color: #ff9a00;display: flex;align-items: center;justify-content: center"
            class="btn btn-outline-warning later-btn  px-4 float-right " (click)="closeModal()">
            Decline
         </button>
       </div>
   </body>
</html>