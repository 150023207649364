
    <div style="top: 20px;padding: 40px;">
        <img class="d-block m-auto" src="assets/img/emailDoc.svg"  width="13%">
        <form [formGroup]="sendEmail" name="sendEmail" (ngSubmit)="onSubmit(sendEmail.value)" class="mb-0">
            <div class="form-group" style="margin-bottom: 1rem !important;">
              <label for="exampleInputEmail1">Email <span class="text-danger1">*</span></label>
              <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" formControlName="email"
              required >

              <div class="password-label">
                <label class="mb-0 col-12 p-0 text-danger1 align-items-center" [ngClass]="sendEmail.controls['email'].hasError('required') || sendEmail.controls['email'].hasError('pattern') ? 'd-flex' : 'd-none'">
                    <i style="float: left;" class="material-icons">{{ sendEmail.controls['email'].hasError('required') || sendEmail.controls['email'].hasError('pattern')  ? 'clear' : 'done' }}</i>
                    <small class="error-align">{{sendEmail.controls['email'].hasError('pattern') ? 'Valid ':''}}Email is required</small>
                </label>
              </div>
             
            </div>
            <div class="form-group" style="margin-bottom: 1rem !important;">
                <label for="exampleInputEmail1">BCC Email <i>(optional)</i></label>
                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email address" formControlName="bccEmail">
            </div>
            <div class="text-right">
              <button type="button" class="btn faint-grey col-4" style="box-shadow: none; border-radius: 0;" (click)="closeModal()">Cancel</button>
              &nbsp;&nbsp;
              <button type="submit" class="btn orange text-white col-4"  style="box-shadow: none; border-radius: 0;">Send</button>
            </div>
        </form>
        <!-- <br> -->
    </div> 
    <!-- <hr> -->
  

