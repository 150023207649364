<!-- <div class="container">
    <div class="row">
        <div class="col-1">
            <app-leftsidebar></app-leftsidebar>
        </div> -->

        <div class="new-col credit-application">
            <form class="mt-3 payment_form" [formGroup]="creditApplicationForm" (ngSubmit)="onSubmit()">
                <div class="row mb-0 mt-5 pt-3">
                    <div class=" ml-5">
                        <h3 class="mb-0">Credit Application Form</h3>
                        <div class="grey-devider"></div>
                        <div>
                            <section class="">
                                <div class="container p-0">
                                    <div class="row mb-5">
                                        <mat-tab-group animationDuration="000ms" [(selectedIndex)]="activeTabIndex" (selectedTabChange)="tabClick($event)">
                                            <mat-tab label="Business Structure"  class="active mat-tab-label-active"> 
                                                <div class="card col-8 p-0">
                                                    <div class="card-header p-3">
                                                        <h4 class="mb-0 p-1">Business Structure</h4>
                                                    </div> 
                                                    <div class="card-body p-2">
                                                            <div class="col form-group">
                                                                <label>Legal Business Name*</label>
                                                                <input type="text" class="form-control" name="legalBusinessName" formControlName="legalBusinessName" [ngClass]="{ 'is-invalid': submitted && f.legalBusinessName.errors }" required  placeholder="Enter Legal Bussiness Name">
                                                                <div *ngIf="submitted && f.legalBusinessName.errors" class="invalid-feedback">
                                                                    <div class="red_alert_msg" *ngIf="f.legalBusinessName.errors.required">Business name must be entered</div>
                                                                </div>
                                                                <!--<div class="red_alert_msg" *ngIf="f.legalBusinessName.errors.invalid">Business name must be entered</div>-->
    
                                                            </div>
                                                            <div class="col row p-0 m-0">
                                                                <div class="col form-group mb-0">
                                                                    <label>First Name*</label>
                                                                    <input type="text" class="form-control" name="firstName" formControlName="firstName" [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" required placeholder="First Name">
                                                                    <div *ngIf="creditApplicationForm.get('firstName').invalid && (creditApplicationForm.get('firstName').dirty || creditApplicationForm.get('firstName').touched)" [ngClass]="'error'">
                                                                        <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('firstName').errors.required">First name must be entered</div> -->
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('firstName').errors.pattern">First name must be characters</div>
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('firstName').errors.minlength">First Name min length is 2 characters</div>
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('firstName').errors.maxlength">First Name max length is 32 characters</div>
                                                                    </div>
                                                                    <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.firstName.errors.required">First name must be entered</div>
                                                                    </div>
                                                                    <!-- <div class="red_alert_msg" *ngIf="f.firstName.errors.invalid">First name must be entered</div> -->
    
                                                                </div>
                                                                <div class="col form-group mb-0">
                                                                    <label>Last Name*</label>
                                                                    <input type="text" class="form-control" formControlName="lastName" placeholder="Last Name" [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }">
                                                                    <div *ngIf="creditApplicationForm.get('lastName').invalid && (creditApplicationForm.get('lastName').dirty || creditApplicationForm.get('lastName').touched)" [ngClass]="'error'">
                                                                        <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('lastName').errors.required">Last name must be entered</div> -->
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('lastName').errors.pattern">Last name must be characters</div>
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('lastName').errors.minlength">Last Name min length is 2 characters</div>
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('lastName').errors.maxlength">Last Name max length is 32 characters</div>
                                                                    </div>
                                                                    <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.lastName.errors.required">Last name must be entered</div>
                                                                        <!-- <div class="red_alert_msg" *ngIf="f.lastName.errors.minlength">Last Name min length is 2 characters</div>
                                                                        <div class="red_alert_msg"*ngIf="f.lastName.errors.maxlength">Last Name max length is 8 characters</div>
                                                                        <div class="red_alert_msg" *ngIf="f.lastName.errors.pattern">Last name must be characters</div> -->
                                                                    </div>
                                                                    <!-- <div class="red_alert_msg" *ngIf="f.firstName.errors.invalid">Last name must be entered</div> -->
    
                                                                </div>
                                                            </div>
                                                            <div class="col row p-0 m-0">
                                                                <div class="col form-group mb-0">
                                                                    <label>Address*</label>
                                                                    <input type="text" formControlName="address1" class="form-control" placeholder="Address" [ngClass]="{ 'is-invalid': submitted && f.address1.errors }">
                                                                    <div *ngIf="submitted && f.address1.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.address1.errors.required">Address must be entered</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col form-group mb-0">
                                                                    <label>Address 2*</label>
                                                                    <input type="text" formControlName="address2" class="form-control" placeholder="Address 2" [ngClass]="{ 'is-invalid': submitted && f.address2.errors }">
                                                                    <div *ngIf="submitted && f.address2.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.address2.errors.required">Address must be entered</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col row p-0 m-0">
                                                                <div class="col form-group mb-0">
                                                                    <label>City*</label>
                                                                    <input type="text" formControlName="city" class="form-control" placeholder="City" [ngClass]="{ 'is-invalid': submitted && f.city.errors }">
                                                                    <div *ngIf="creditApplicationForm.get('city').invalid && (creditApplicationForm.get('city').dirty || creditApplicationForm.get('city').touched)" [ngClass]="'error'">
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('city').errors.pattern">City only contain character.</div>
                                                                    </div>
                                                                    <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.city.errors.required">City must be entered</div>
                                                                    </div>
                                                                </div>
    
                                                                <div class="col form-group mb-0">
                                                                    <label>Postal Code/ZIP*</label>
                                                                    <input type="text" formControlName="zip" class="form-control " style="text-transform: uppercase;" placeholder="Postal Code/ZIP" [ngClass]="{ 'is-invalid': submitted && f.zip.errors }">
                                                                    <div *ngIf="creditApplicationForm.get('zip').invalid && (creditApplicationForm.get('zip').dirty || creditApplicationForm.get('zip').touched)" [ngClass]="'error'">
                                                                        <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('zip').errors.required">Postal Code/ZIP must be entered</div> -->
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('zip').errors.pattern">Zip code is invalid</div>
                                                                    </div>
                                                                    <div *ngIf="submitted && f.zip.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.zip.errors.required">Postal Code/ZIP must be entered</div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                            <div class="col row p-0 m-0">
                                                                
                                                                <div class="col form-group mb-0">
                                                                    <label>Country*</label>
                                                                    <select class="custom-select" #mySelect
                                                                    (change)='onCountrySelect(mySelect.value)' formControlName="country" [ngClass]="{ 'is-invalid': submitted && f.country.errors }">
                                                                        <option *ngFor="let countryOfOrigin of countryListData; let i = index" [value]="countryOfOrigin.countryCode" [selected]="i == 0">{{countryOfOrigin.countryName}}</option>
                                                                    </select>
                                                                    <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.country.errors.required">Country must be entered</div>
                                                                    </div>
                                                                </div>
                                                                <div class="col form-group mb-0">
                                                                    <label>Province/State*</label>
                                                                   
                                                                    <div>
                                                                        <select class="custom-select" formControlName="state" [ngClass]="{ 'is-invalid': submitted && f.state.errors }">
                                                                            <option *ngFor="let obj of countryStateListObj; let i = index" [value]="obj.code" [selected]="i == 0">{{obj.name}}</option>
                                                                        </select>
                                                                    </div>
                                                                    <div *ngIf="submitted && f.state.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.state.errors.required">State must be entered</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col row p-0 m-0">
                                                                <div class="col form-group mb-0">
                                                                    <label>Telephone Number*</label>
                                                                    <input type="text" formControlName="telePhone" class="form-control" placeholder="Telephone Number" [ngClass]="{ 'is-invalid': submitted && f.telePhone.errors }" maxlength="13" >
                                                                    <div *ngIf="creditApplicationForm.get('telePhone').invalid && (creditApplicationForm.get('telePhone').dirty || creditApplicationForm.get('telePhone').touched)" [ngClass]="'error'">
                                                                        <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('telePhone').errors.required">TelePhone must be entered</div> -->
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('telePhone').errors.pattern">Telephone is invalid</div>
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('telePhone').errors.max">must be 10 DIGIT.</div>
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('telePhone').errors.min">must be 10 DIGIT.<small>(excluding country code.)</small></div>
                                                                    </div>
                                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.telePhone.errors.required">TelePhone must be entered</div>
                                                                      </div>
                                                                </div>
                                                                <div class="col form-group mb-0">
                                                                    <label for="email">Email*</label>
                                                                    <input formControlName="email" type="email" style="width: 98% !important;" class="form-control" id="email" placeholder="Email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                                                                    <div *ngIf="creditApplicationForm.get('email').invalid && (creditApplicationForm.get('email').dirty || creditApplicationForm.get('email').touched)" [ngClass]="'error'">
                                                                        
                                                                        <div *ngIf="creditApplicationForm.get('email').errors.pattern">
                                                                            Enter correct Email format.
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.email.errors.required">Email must be entered</div>
                                                                    </div>  
                                                                </div>
                                                            </div>
                                                            <div class="col row p-0 m-0">
                                                                <div class="col form-group mb-0">
                                                                    <label>Fax</label>
                                                                    <input type="text" formControlName="fax" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fax.errors }" placeholder="Fax" maxlength="15">
                                                                    <div *ngIf="creditApplicationForm.get('fax').invalid && (creditApplicationForm.get('fax').dirty || creditApplicationForm.get('fax').touched)" [ngClass]="'error'">
                                                                        <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('fax').errors.required">TelePhone must be entered</div> -->
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('fax').errors.pattern">fax must be numaric.</div>
                                                                    </div>
                                                                </div>
                                                                <!-- <div class="col form-group">
                                                                    <label>Email</label>
                                                                    <input type="text" formControlName="email" class="form-control" placeholder="Email">
                                                                </div> -->
                                                                <div class="col form-group">
                                                                    
                                                                    <label>Phone Number</label>
                                                                    <input type="text" formControlName="cellphone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.cellphone.errors }"  maxlength="15" placeholder="Phone Number">
                                                                    <div *ngIf="creditApplicationForm.get('cellphone').invalid && (creditApplicationForm.get('cellphone').dirty || creditApplicationForm.get('cellphone').touched)" [ngClass]="'error'">
                                                                        <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('cellphone').errors.required">TelePhone must be entered</div> -->
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('cellphone').errors.pattern">Phone Number must be numaric.</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col row p-0 m-0">
                                                                <div class="col form-group">
                                                                    <label>Bussiness Identification Number:</label>
                                                                    <input type="text" formControlName="businessIdentification" class="form-control" placeholder="Bussiness Identification Number">
                                                                </div>
                                                                <div class="col form-group">
                                                                    <label>Year Established:</label>
                                                                    <input type="number" formControlName="yearEstablished" class="form-control" placeholder="Year Established">
                                                                </div>
                                                            </div>
                                                        
                                                    </div>
                                                </div>
                                                <div class="card col-8 mt-2 p-0">
                                                    <div class="card-header p-2">
                                                        <h4 class="mb-0">Billing Address</h4>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="col form-group mb-0 mt-2">
                                                            <label class="">
                                                                <input type="checkbox" #creditapps (change)="assignBillingAddress(creditapps.checked)">
                                                                <span class="checkmark"></span><span class="checkmark-lable1">&nbsp; Same address for billing</span>
                                                            </label>
                                                        </div>
                                                        <div class="col form-group">
                                                            <label>Legal Business Name*</label>
                                                            <input type="text" class="form-control" formControlName="bilingName" [ngClass]="{ 'is-invalid': submitted && f.bilingName.errors }" placeholder="Enter Legal Bussiness Name">
                                                            <div *ngIf="submitted && f.bilingName.errors" class="invalid-feedback">
                                                                <div class="red_alert_msg" *ngIf="f.bilingName.errors.required">Business name must be entered</div>
                                                            </div>
                                                        </div>
                                                        <div class="col row p-0 m-0">
                                                            <div class="col form-group mb-0">
                                                                <label>First Name</label>
                                                                <input type="text" class="form-control" formControlName="bilingFirstName" placeholder="First Name">
                                                            </div>
                                                            <div class="col form-group mb-0">
                                                                <label>Last Name</label>
                                                                <input type="text" class="form-control" formControlName="bilingLastName" placeholder="Last Name">
                                                            </div>
                                                        </div>
                                                        <div class="col row p-0 m-0">
                                                            <div class="col form-group mb-0">
                                                                <label>Address</label>
                                                                <input type="text"  class="form-control" formControlName="bilingAddress" placeholder="Address">
                                                            </div>
                                                            <div class="col form-group mb-0">
                                                                <label>Address 2 (Optional)</label>
                                                                <input type="text" class="form-control" formControlName="bilingAddress2" placeholder="Address 2">
                                                            </div>
                                                        </div>
                                                        <div class="col row p-0 m-0">
                                                            <div class="col form-group mb-0">
                                                                <label>City*</label>
                                                                <input type="text" formControlName="billingCity" [ngClass]="{ 'is-invalid': submitted && f.billingCity.errors }" class="form-control" placeholder="City">
                                                                <div *ngIf="submitted && f.billingCity.errors" class="invalid-feedback">
                                                                    <div class="red_alert_msg" *ngIf="f.billingCity.errors.required">City must be entered</div>
                                                                </div>
                                                                <div *ngIf="creditApplicationForm.get('billingCity').invalid && (creditApplicationForm.get('billingCity').dirty || creditApplicationForm.get('billingCity').touched)" [ngClass]="'error'">
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('billingCity').errors.pattern">City only contain character.</div>
                                                                </div>
                                                            </div>
    
                                                            <div class="col form-group mb-0">
                                                                <label>Postal Code/ZIP*</label>
                                                                <input type="text" formControlName="billingZip" style="text-transform: uppercase;" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.billingZip.errors }" placeholder="Postal Code/ZIP">
                                                                <div *ngIf="creditApplicationForm.get('billingZip').invalid && (creditApplicationForm.get('billingZip').dirty || creditApplicationForm.get('billingZip').touched)" [ngClass]="'error'">
                                                                    <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('billingZip').errors.required">Postal Code/ZIP must be entered</div> -->
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('billingZip').errors.pattern">Zip code is invalid</div>
                                                                </div>
                                                                <div *ngIf="submitted && f.billingZip.errors" class="invalid-feedback">
                                                                    <div class="red_alert_msg" *ngIf="f.billingZip.errors.required">Postal Code/ZIP must be entered</div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                        <div class="col row p-0 m-0">
                                                            <div class="col form-group mb-0">
                                                                <label>Country</label>
                                                                <select class="custom-select" formControlName="bilingCountry" [ngClass]="{ 'is-invalid': submitted && f.bilingCountry.errors }" #mySelected (change)='onBillingCountrySelect(mySelected.value)'>
                                                                    <option *ngFor="let countryOfOrigin of countryListData" [value]="countryOfOrigin.countryCode">{{countryOfOrigin.countryName}}</option>
                                                                </select>
                                                                <div *ngIf="submitted && f.bilingCountry.errors" class="invalid-feedback">
                                                                    <div class="red_alert_msg" *ngIf="f.bilingCountry.errors.required">Country must be entered</div>
                                                                </div>
                                                            </div>
    
                                                            <div class="col form-group mb-0">
                                                                <label>Province/State*</label>
                                                                
                                                                <select class="custom-select"  [ngClass]="{ 'is-invalid': submitted && f.billingState.errors }" formControlName="billingState" >
                                                                    <option *ngFor="let obj of countryStateListObj" [value]="obj['code']">{{obj['name']}}</option>
                                                                </select>
                                                                <div *ngIf="submitted && f.billingState.errors" class="invalid-feedback">
                                                                    <div class="red_alert_msg" *ngIf="f.billingState.errors.required">State must be entered</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col row p-0 m-0">
                                                            <div class="col form-group mb-0">
                                                                <label>Telephone Number</label>
                                                                <input type="text" class="form-control" formControlName="bilingTelephone" placeholder="Telephone Number" [ngClass]="{ 'is-invalid': submitted && f.bilingTelephone.errors }" maxlength="13">
                                                                <div *ngIf="creditApplicationForm.get('bilingTelephone').invalid && (creditApplicationForm.get('bilingTelephone').dirty || creditApplicationForm.get('bilingTelephone').touched)" [ngClass]="'error'">
                                                                    <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingTelephone').errors.required">TelePhone must be entered</div> -->
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingTelephone').errors.pattern">Telephone is invalid</div>
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingTelephone').errors.max">must be 10 DIGIT.</div>
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingTelephone').errors.min">must be 10 DIGIT.<small>(excluding country code.)</small></div>
                                                                </div>
                                                            </div>
                                                            <div class="col form-group mb-0">
                                                                <label>Cell-Phone Number</label>
                                                                <input type="text" class="form-control" formControlName="bilingCellPhone" placeholder="Cell-Phone Number"  [ngClass]="{ 'is-invalid': submitted && f.bilingCellPhone.errors }" maxlength="13">
                                                                <div *ngIf="creditApplicationForm.get('bilingCellPhone').invalid && (creditApplicationForm.get('bilingCellPhone').dirty || creditApplicationForm.get('bilingCellPhone').touched)" [ngClass]="'error'">
                                                                    <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingCellPhone').errors.required">TelePhone must be entered</div> -->
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingCellPhone').errors.pattern">Cell-Phone Number is invalid</div>
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingCellPhone').errors.max">must be 10 DIGIT.</div>
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingCellPhone').errors.min">must be 10 DIGIT.<small>(excluding country code.)</small></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col row p-0 m-0">
                                                            <div class="col form-group">
                                                                <label>Fax</label>
                                                                <input type="text" class="form-control" formControlName="bilingFax" placeholder="Fax" [ngClass]="{ 'is-invalid': submitted && f.bilingFax.errors }" placeholder="Fax" maxlength="15">
                                                                <div *ngIf="creditApplicationForm.get('bilingFax').invalid && (creditApplicationForm.get('bilingFax').dirty || creditApplicationForm.get('bilingFax').touched)" [ngClass]="'error'">
                                                                    <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingFax').errors.required">TelePhone must be entered</div> -->
                                                                    <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bilingFax').errors.pattern">fax must be numaric.</div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col form-group">
                                                                <label>Email</label>
                                                                <input type="text" class="form-control" formControlName="bilingEmail" placeholder="Email">
                                                            </div> -->
                                                            <div class="col form-group">
                                                                <label for="email">Email</label>
                                                                <input formControlName="bilingEmail" type="email" style="width: 98% !important;" class="form-control" id="email" placeholder="Email">
                                                                <div *ngIf="creditApplicationForm.get('bilingEmail').invalid && (creditApplicationForm.get('bilingEmail').dirty || creditApplicationForm.get('bilingEmail').touched)" [ngClass]="'error'">
                                                                    
                                                                    <div *ngIf="creditApplicationForm.get('bilingEmail').errors.pattern">
                                                                        Enter correct Email format.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                </div>
                                                </div>
                                                <div class="col-8 mt-2 p-0 text-right">                                    
                                                    <button class="btn submit_btn white black-text" (click)="submitbilling()" type="button">Submit</button>
                                                </div>
                                            </mat-tab>
                                            <mat-tab label="Business Entity Structure">
                                                <div class="card col-8 p-0">
                                                    <div class="card-header p-3">
                                                        <h4 class="mb-0 p-1">Business Entity Information</h4>
                                                    </div>
                                                    <div class="card-body p-2" formArrayName="businessEntityItems">
                                                        <div *ngFor="let businessitem of creditApplicationForm.get('businessEntityItems')['controls']; let i = index" [formGroupName]="i">  
                                                        
                                                        <div class="form-group">
                                                            <label>Select Entity Type*</label>
                                                            <select #companyEntityType class="custom-select" formControlName="entityType" (change)="onEntitySelect(companyEntityType.value)" [ngClass]="((businessitem.get('entityType').touched || businessitem.get('entityType').dirty) && businessitem.get('entityType').invalid) ? 'is-invalid' : '' "
                                                            required >
                                                                <option value="" disabled></option>
                                                                <option *ngFor="let obj of entityTypeArray" [value]="obj.companyTypeId">{{obj.companyType}}</option>
                                                            </select>
    
                                                           
                                                            <div *ngIf="((businessitem.get('entityType').dirty || businessitem.get('entityType').touched) && businessitem.get('entityType').invalid) ? 'is-invalid1' : ''">
                                                                <label [ngClass]="businessitem.get('entityType').hasError('required')   ? 'text-danger1' : 'd-none'">
                                                                  <small class="red_alert_msg">Entity type must be select</small>
                                                                </label>
                                                              </div>  
                                                            
    
                                                        </div>
                                                        <div class="row col p-0 m-0">
                                                                <div class="col-5 pl-0">
                                                                    <div class="form-group">
                                                                        <label>First Name*</label>
                                                                        <input type="text" class="form-control" name="firstName1" formControlName="firstName1" [ngClass]="{ 'is-invalid': isSubmitted &&  businessitem.get('firstName1').errors }"
                                                                        required >
                                                                        <div *ngIf="businessitem.get('firstName1').invalid && (businessitem.get('firstName1').dirty || businessitem.get('firstName1').touched)" [ngClass]="'error'">
                                                                            <!-- <div class="red_alert_msg" *ngIf="businessitem.get('firstName1').errors.required">First name must be entered</div> -->
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('firstName1').errors.pattern">First name must be characters</div>
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('firstName1').errors.minlength">First Name min length is 2 characters</div>
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('firstName1').errors.maxlength">First Name max length is 32 characters</div>
                                                                        </div>
                                                                        <div *ngIf="isSubmitted && businessitem.get('firstName1').errors" class="invalid-feedback">
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('firstName1').errors.required">First name must be entered</div>
                                                                        </div>

                                                                        <!-- <div *ngIf="((businessitem.get('firstName1').dirty || businessitem.get('firstName1').touched) || businessitem.get('firstName1').invalid) ? 'is-invalid' : ''">

                                                                        </div>   -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-3">
                                                                    <div class="form-group">
                                                                        <label>Middle Initial</label>
                                                                        <input type="text" class="form-control"  name="middleInitial1" formControlName="middleInitial1" 
                                                                        />
                                                                        <div *ngIf="businessitem.get('middleInitial1').invalid && (businessitem.get('middleInitial1').dirty || businessitem.get('middleInitial1').touched)" [ngClass]="'error'">
                                                                            <!-- <div class="red_alert_msg" *ngIf="businessitem.get('middleInitial1').errors.required">First name must be entered</div> -->
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('middleInitial1').errors.pattern">Middle name must be characters</div>
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('middleInitial1').errors.minlength">Middle Name min length is 2 characters</div>
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('middleInitial1').errors.maxlength">Middle Name max length is 32 characters</div>
                                                                        </div>
                                                                        <!-- <div *ngIf="isSubmitted && businessitem.get('middleInitial1').errors" class="invalid-feedback">
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('middleInitial1').errors.required">Middle name must be entered</div>
                                                                        </div> -->
                                                                    </div>
                                                                </div>
                                                                <div class="col-4">
                                                                    <div class="form-group">
                                                                        <label>Last Name*</label>
                                                                        <input type="text" class="form-control"  name="lastName1" formControlName="lastName1" [ngClass]="{ 'is-invalid': isSubmitted &&  businessitem.get('lastName1').errors }"
                                                                        required >
                                                                        <div *ngIf="businessitem.get('lastName1').invalid && (businessitem.get('lastName1').dirty || businessitem.get('lastName1').touched)" [ngClass]="'error'">
                                                                            <!-- <div class="red_alert_msg" *ngIf="businessitem.get('lastName1').errors.required">First name must be entered</div> -->
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('lastName1').errors.pattern">Last name must be characters</div>
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('lastName1').errors.minlength">Last Name min length is 2 characters</div>
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('lastName1').errors.maxlength">Last Name max length is 32 characters</div>
                                                                        </div>
                                                                        <div *ngIf="isSubmitted && businessitem.get('lastName1').errors" class="invalid-feedback">
                                                                            <div class="red_alert_msg" *ngIf="businessitem.get('lastName1').errors.required">Last name must be entered</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        </div>
                                                        <div class="row col p-0 m-0">
                                                        <div class="col pl-0">
                                                            <label>Title*</label>
                                                            <select class="custom-select" name="title" formControlName="title" (change)="changePrefix($event)" [ngClass]="((businessitem.get('title').touched || businessitem.get('title').dirty) && businessitem.get('title').invalid) ? 'is-invalid' : ''"
                                                            required >
                                                                <option>Select One</option>
                                                                <option>President</option>
                                                                <option>Vice President</option>
                                                                <option>Secretory</option>
                                                                <option>Treasure</option>
                                                                <option>Other</option>
                                                            </select>
                                                           
                                                            <div *ngIf="((businessitem.get('title').dirty || businessitem.get('title').touched) && businessitem.get('title').invalid) ? 'is-invalid1' : ''">
                                                                <label>
                                                                  <small class="red_alert_msg">Title must be entered</small>
                                                                </label>
                                                            </div>
                                                            
                                                        </div>
                                                        <div class="col">
                                                            <div class="form-group" *ngIf="hideOther">
                                                                <label>Other*</label>
                                                                <input type="text" class="form-control" name="other" formControlName="other">
                                                                
                                                            </div>
                                                        </div>                                                
                                                        </div>
                                                        <div class="col float-right p-0">
                                                            <button type="button"  (click)="submitBusinessEntityInfo(i)" class="btn orange white-text add-ref col-1 m-1">Save</button>
                                                            <button *ngIf="i!=0" style="padding-right: 2px;padding-left: 2px;" type="button" class="btn orange white-text add-ref col-1 m-1" (click)="removeOrClearBusinessEntity(i)">Remove</button>
                                                        </div>
                                                    </div>
                                                   
                                                        <!-- <button class="btn orange text-white add-more" type="button" (click)="addBusinessEntityItem()">+ Add More</button>                                                    -->
                                                    </div>
                                                </div>
                                                <!-- <div class="col-8">   
                                                    <button type="button" class="btn orange white-text add-ref" (click)="nextTab()">Next</button>
                                                </div> -->
                                            </mat-tab>
                                            <mat-tab label="Bank Information"> 
                                                <div class="card col-8 p-0">
                                                    <div class="card-header p-3">
                                                        <h4 class="mb-0 p-1">Bank Information</h4>
                                                    </div>
                                                    <div class="card-body p-2">
                                                        <div class="row mb-0">
                                                            <div class="col">
                                                                <div class="form-group col">
                                                                    <label>Bank Name*</label>
                                                                    <input type="text" class="form-control" placeholder="Bank Name" name="bankName" formControlName="bankName" [ngClass]="{ 'is-invalid': submittedBankForm && f.bankName.errors }">
                                                                    <div *ngIf="submittedBankForm && f.bankName.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.bankName.errors.required">Bank Name must be enter</div>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group col">
                                                                    <label>Account Number*</label>
                                                                    <input type="text" class="form-control" formControlName="accountNumber" [ngClass]="{ 'is-invalid': submittedBankForm && f.accountNumber.errors }" placeholder="Account Number">
                                                                    <div *ngIf="submittedBankForm && f.accountNumber.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.accountNumber.errors.required">Account must be entered</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h4>Branch Address & Contact</h4>
                                                        <div class="row mb-0">
                                                            <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>Address*</label>
                                                                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submittedBankForm && f.bankAddress1.errors }" formControlName="bankAddress1" placeholder="Address">
                                                                    <div *ngIf="submittedBankForm && f.bankAddress1.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.bankAddress1.errors.required">Account must be entered</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>Address 2 (Optional)</label>
                                                                    <input type="text" class="form-control" formControlName="bankAddress2" placeholder="Address 2">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-0">
                                                            <!-- <div class="col form-group mb-0">
                                                                <label>Country</label>
                                                                
                                                                <select class="custom-select" #mySelect
                                                                (change)='onCountrySelect(mySelect.value)' formControlName="country">
                                                                    <option *ngFor="let countryOfOrigin of countryListData; let i = index" [value]="countryOfOrigin.countryCode" [selected]="i == 0">{{countryOfOrigin.countryName}}</option>
                                                                </select>
                                                                
                                                            </div>
                                                            <div class="col form-group mb-0">
                                                                <label>Province/State</label>
                                                               
                                                                <div>
                                                                    <select class="custom-select" formControlName="state" >
                                                                        <option *ngFor="let obj of countryStateListObj; let i = index" [value]="obj.code" [selected]="i == 0">{{obj.name}}</option>
                                                                    </select>
                                                                </div>
                                                            </div> -->
                                                            <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>Country*</label>
                                                                    <select class="custom-select" #mySelect1  [ngClass]="{ 'is-invalid': submittedBankForm && f.bankCountry.errors }" (change)='onBankCountrySelect(mySelect1.value)' formControlName="bankCountry">
                                                                    <option *ngFor="let countryOfOrigin of countryListData; let i = index" [value]="countryOfOrigin.countryCode" [selected]="i == 0">{{countryOfOrigin.countryName}}</option>
                                                                </select>
                                                                <div *ngIf="submittedBankForm && f.bankCountry.errors" class="invalid-feedback">
                                                                    <div class="red_alert_msg" *ngIf="f.bankCountry.errors.required">Country must be entered</div>
                                                                </div>
                                                                    <!-- <input type="text" class="form-control" formControlName="bankCity" placeholder="City"> -->
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>Province/State*</label>
                                                                    <select class="custom-select" [ngClass]="{ 'is-invalid': submittedBankForm && f.bankState.errors }" formControlName="bankState" >
                                                                        <option *ngFor="let obj of countryStateListObj; let i = index" [value]="obj.code" [selected]="i == 0">{{obj.name}}</option>
                                                                    </select>
                                                                    <div *ngIf="submittedBankForm && f.bankState.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.bankState.errors.required">State must be entered</div>
                                                                    </div>
                                                                    <!-- <input type="text" class="form-control" formControlName="bankState" placeholder="Province/State"> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-0">
                                                            <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>Postal Code/ZIP*</label>
                                                                    <input type="text" class="form-control" style="text-transform: uppercase;" formControlName="bankZip" [ngClass]="{ 'is-invalid': submittedBankForm && f.bankZip.errors }" placeholder="Postal Code/ZIP">
                                                                    <div *ngIf="creditApplicationForm.get('bankZip').invalid && (creditApplicationForm.get('bankZip').dirty || creditApplicationForm.get('bankZip').touched)" [ngClass]="'error'">
                                                                        <!-- <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bankZip').errors.required">Postal Code/ZIP must be entered</div> -->
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bankZip').errors.pattern">Zip code is invalid</div>
                                                                    </div>
                                                                    <div *ngIf="submittedBankForm && f.bankZip.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.bankZip.errors.required">Postal Code/ZIP must be entered</div>
                                                                        <!-- <div class="red_alert_msg" *ngIf="f.bankZip.errors.pattern">Zip code is invalid</div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>City*</label>
                                                                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submittedBankForm && f.bankCity.errors }" formControlName="bankCity" placeholder="City">
                                                                    <div *ngIf="creditApplicationForm.get('bankCity').invalid && (creditApplicationForm.get('bankCity').dirty || creditApplicationForm.get('bankCity').touched)" [ngClass]="'error'">
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bankCity').errors.pattern">City only contain character</div>
                                                                    </div>
                                                                    <div *ngIf="submittedBankForm && f.bankCity.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.bankCity.errors.required">City must be entered</div>
                                                                        <!-- <div class="red_alert_msg" *ngIf="f.bankCity.errors.pattern">City only contain character</div> -->
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>Country</label>
                                                                    <input type="text" class="form-control" formControlName="bankCountry" placeholder="Country">
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                        <div class="row mb-0">
                                                            <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>Telephone*</label>
                                                                    <input type="text" class="form-control" [ngClass]="{ 'is-invalid': submittedBankForm && f.bankTelephone.errors }" formControlName="bankTelephone" placeholder="Telephone" maxlength="13">
                                                                    <div *ngIf="creditApplicationForm.get('bankTelephone').invalid && (creditApplicationForm.get('bankTelephone').dirty || creditApplicationForm.get('bankTelephone').touched)" [ngClass]="'error'">
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bankTelephone').errors.pattern">Telephone is invalid</div>
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bankTelephone').errors.max">must be 10 DIGIT.</div>
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('bankTelephone').errors.min">must be 10 DIGIT.<small>(excluding country code.)</small></div>
                                                                    </div>
                                                                    <div *ngIf="submittedBankForm && f.bankTelephone.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.bankTelephone.errors.required">TelePhone must be entered</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="form-group col mb-0">
                                                                    <label>Contact Name*</label>
                                                                    <input type="text" class="form-control" placeholder="Contact" name="contact" formControlName="contact" [ngClass]="{ 'is-invalid': submittedBankForm && f.contact.errors }" maxlength="13">
                                                                    <!-- <div *ngIf="creditApplicationForm.get('contact').invalid && (creditApplicationForm.get('contact').dirty || creditApplicationForm.get('contact').touched)" [ngClass]="'error'">
                                                                        <div class="red_alert_msg" *ngIf="creditApplicationForm.get('contact').errors.pattern">Contact must be 10 DIGIT.</div>
                                                                    </div> -->
                                                                    <div *ngIf="submittedBankForm && f.contact.errors" class="invalid-feedback">
                                                                        <div class="red_alert_msg" *ngIf="f.contact.errors.required">Contact must be enter</div>
                                                                    </div>
                                                                    <!-- <div class="red_alert_msg" *ngIf="f.contact.errors.invalid">Contact must be enter</div> -->
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-8 mt-2 p-0 text-right">                                      
                                                    <button class="btn submit_btn white black-text m-1" (click)="submitbankInfo()" type="button">Submit </button>
                                                </div>
                                             </mat-tab>
                                             <mat-tab label="Trade References"> 
                                                <div class="card col-8 p-0">
                                                    <div class="card-header p-3">
                                                        <h4 class="mb-0 p-1">Trade References <span class="grey-text">(Minimum of 3 references needed)</span></h4>
                                                    </div>
                                                    <div class="card-body p-2" formArrayName="tradeItems">
                                                        <div *ngFor="let tradeitem of creditApplicationForm.get('tradeItems')['controls']; let i = index" [formGroupName]="i"> 
                                                            
                                                            <div class="row mb-0">
                                                                <div class="col">
                                                                    <div class="col form-group mb-0">
                                                                        <label>Company Name*</label>
                                                                        
                                                                        <input type="text" class="form-control" placeholder="Enter Company Name" name="companyName1" formControlName="companyName1" [ngClass]="{ 'is-invalid': submittedTradeForm[i] && tradeitem.get('companyName1').errors }">
                                                                        <div *ngIf="submittedTradeForm[i] && tradeitem.get('companyName1').errors" class="invalid-feedback">
                                                                            <div class="red_alert_msg" *ngIf="tradeitem.get('companyName1').errors.required">Company Name must be enter</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="col form-group mb-0">
                                                                        <label>Contact*</label>
                                                                        <input type="text" class="form-control" placeholder="Enter Contact" name="contact1" formControlName="contact1" [ngClass]="{ 'is-invalid': submittedTradeForm[i] && tradeitem.get('contact1').errors }">
                                                                        <div *ngIf="submittedTradeForm[i] && tradeitem.get('contact1').errors" class="invalid-feedback">
                                                                            <div class="red_alert_msg" *ngIf="tradeitem.get('contact1').errors.required">Contact must be enter</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row ">
                                                                <div class="col">
                                                                    <div class="col form-group mb-0">
                                                                        <label>Phone*</label>
                                                                        <input type="text" class="form-control" placeholder="Enter Phone" name="phone1" formControlName="phone1" [ngClass]="{ 'is-invalid': submittedTradeForm[i] && tradeitem.get('phone1').errors }" maxlength="13">
                                                                        <div *ngIf="tradeitem.get('phone1').invalid && (tradeitem.get('phone1').dirty || tradeitem.get('phone1').touched)" [ngClass]="'error'">
                                                                            <div class="red_alert_msg" *ngIf="tradeitem.get('phone1').errors.pattern">Telephone is invalid</div>
                                                                            <div class="red_alert_msg" *ngIf="tradeitem.get('phone1').errors.max">must be 10 DIGIT.</div>
                                                                            <div class="red_alert_msg" *ngIf="tradeitem.get('phone1').errors.min">must be 10 DIGIT.<small>(excluding country code.)</small></div>
                                                                        </div>
                                                                        <div *ngIf="submittedTradeForm[i] && tradeitem.get('phone1').errors" class="invalid-feedback">
                                                                            <div class="red_alert_msg" *ngIf="tradeitem.get('phone1').errors.required">Phone must be enter</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col">
                                                                    <!-- <div class="col form-group mb-0">
                                                                        <label>Email*</label>
                                                                       
                                                                        <input type="text" class="form-control" placeholder="Enter Email" name="email1" formControlName="email1" [ngClass]="{ 'is-invalid': submittedTradeForm && tradeitem.get('email1').errors }">
                                                                        <div *ngIf="submittedTradeForm && tradeitem.get('email1').errors" class="invalid-feedback">
                                                                            <div class="red_alert_msg" *ngIf="tradeitem.get('email1').errors.required">Email must be enter</div>
                                                                        </div> 
                                                                    </div> -->

                                                                    <div class="col form-group mb-0">
                                                                        <label for="email">Email*</label>
                                                                        <input formControlName="email1" type="email" style="width: 98% !important;" class="form-control" [ngClass]="{ 'is-invalid': submittedTradeForm[i] && tradeitem.get('email1').errors }" id="email" placeholder="Email">
                                                                        <div *ngIf="tradeitem.get('email1').invalid && (tradeitem.get('email1').dirty || tradeitem.get('email1').touched)" [ngClass]="'error'">
                                                                            
                                                                            <div *ngIf="tradeitem.get('email1').errors.pattern">
                                                                                Enter correct Email format.
                                                                            </div>
                                                                        </div>
                                                                        <div *ngIf="submittedTradeForm[i] && tradeitem.get('email1').errors" class="invalid-feedback">
                                                                            <div class="red_alert_msg" *ngIf="tradeitem.get('email1').errors.required">Email must be enter</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col float-right p-0">
                                                                <button type="button"  (click)="submitTradeInfo(i)" class="btn orange white-text add-ref col-1 m-1">Save</button>
                                                                <button *ngIf="i!=0" style="padding-right: 2px;padding-left: 2px;" type="button" class="btn orange white-text add-ref col-1 m-1" (click)="removeOrClearEmail(i)">Remove</button>
                                                            </div>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                                
                                                <div class="col-8 mt-2 p-0 text-right">   
                                                    <button type="button" class="btn orange white-text add-ref" [disabled]="updateSubmitButton? true: false" (click)="addItem()">+ Add another reference</button>
                                                </div>
                                                <div class="col-8">   
                                                    <button type="button" class="btn orange white-text add-ref" [disabled]="updateSubmitButton? false: true" (click)="tradeSubmit()">Submit</button>
                                                </div>
                                            </mat-tab>
                                            <mat-tab label="Review & Sign" > 
                                                <div class="col-8" style="font-size: 10px !important;font-weight: 500;font-family: 'Poppins';">
                                                    <div>
                                                        <div class="row mb-0">
                                                            <h4 class="mb-0 p-1 d-inline col-10">Business Structure </h4> 
                                                            <div class="col-2 editbtn" (click)="setActiveTab(0)">
                                                                <img src="assets/img/edit.svg">&nbsp;Edit
                                                            </div>
                                                            <div class="grey-devider"></div>    
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Company
                                                                </div>
                                                                <div>{{reviewAndSignData && reviewAndSignData.businessStructure?.legalBusinessName}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Address
                                                                </div>
                                                                <div> {{ reviewAndSignData && reviewAndSignData.businessStructure.address?.addrLine1}} </div>
                                                                <div> {{ reviewAndSignData && reviewAndSignData.businessStructure.address?.addrLine2}} </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    First Name
                                                                </div>
                                                                <div> {{ missingData && missingData?.firstname}} </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Last Name
                                                                </div>
                                                                <div> {{ missingData && missingData?.lastname}} </div>

                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Telephone Number
                                                                </div>
                                                                <div>{{ reviewAndSignData && reviewAndSignData.businessStructure?.phoneNumber}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Cell-Phone Number
                                                                </div>
                                                                <!-- <div>{{reviewAndSignData && reviewAndSignData.businessStructure.address?.phoneNumber}}</div> -->
                                                                <div>{{missingData && missingData?.cellNumber}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Fax
                                                                </div>
                                                                <div> {{ missingData && missingData?.Fax}} </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Email
                                                                </div>
                                                                <div>{{ reviewAndSignData && reviewAndSignData.businessStructure.address?.email}} </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Business Identification Number
                                                                </div>
                                                                <div>{{ missingData && missingData?.BIN}}</div>
                                                            </div>
                                                            <div class="col" style="display: none;">
                                                                <div class="grey-text" >
                                                                    Year Established
                                                                </div>
                                                                <div>{{ reviewAndSignData && reviewAndSignData.businessStructure?.incorporationDate}}</div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="row mb-0">
                                                            <h4 class="mb-0 p-1 d-inline col-10">Bussiness Entity Information</h4> 
                                                            <div class="col-2 editbtn" (click)="setActiveTab(1)">
                                                                <img src="assets/img/edit.svg">&nbsp;Edit
                                                            </div>
                                                            <div class="grey-devider"></div>    
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Bussiness Entity Type
                                                                </div>
                                                                <!-- <div>{{ reviewAndSignData && reviewAndSignData.businessStructure?.businessType}}</div> -->
                                                                <div>{{missingData && missingData?.BusinessEntityType}}</div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Owners Name
                                                                </div>
                                                                <div>{{ missingData && missingData.BusinessEntityInformation[0]?.firstName +" "+ missingData.BusinessEntityInformation[0]?.lastName}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="row mb-0">
                                                            <h4 class="mb-0 p-1 d-inline col-10">Bank Information</h4> 
                                                            <div class="col-2 editbtn" (click)="setActiveTab(2)">
                                                                <img src="assets/img/edit.svg">&nbsp;Edit
                                                            </div>
                                                            <div class="grey-devider"></div>    
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Bussiness Identification Number
                                                                </div>
                                                                <div>{{ reviewAndSignData && reviewAndSignData.businessStructure?.businessIdentificationNumber}}</div>
                                                            </div>
                                                            <div class="col" style="display: none;">
                                                                <div class="grey-text" >
                                                                    Year established
                                                                </div>
                                                                <div>{{ reviewAndSignData && reviewAndSignData.businessStructure?.incorporationDate}}</div>

                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Address
                                                                </div>
                                                                <!-- <div> {{ reviewAndSignData && reviewAndSignData.bankInfo.address?.addrLine1}} </div>
                                                                <div> {{ reviewAndSignData && reviewAndSignData.bankInfo.address?.addrLine2}} </div> -->
                                                                <div>{{missingData && missingData?.BankAddress}}</div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Telephone Number
                                                                </div>
                                                                <div> {{ reviewAndSignData && reviewAndSignData.bankInfo.address?.phoneNumber}} </div>
                                                            </div>
                                                            <div class="col">
                                                                <div class="grey-text">
                                                                    Contact
                                                                </div>
                                                                <div> {{ reviewAndSignData && reviewAndSignData.bankInfo?.contact}} </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div class="row mb-0">
                                                            <h4 class="mb-0 p-1 d-inline col-10">Trade Reference</h4> 
                                                            <div class="col-2 editbtn" (click)="setActiveTab(3)">
                                                                <img src="assets/img/edit.svg">&nbsp;Edit
                                                            </div>
                                                            <div class="grey-devider"></div>    
                                                        </div>
                                                        <div *ngFor="let key of referencesOfreviewAndSignData">
                                                            <div class="row mb-0">
                                                                <div class="col">
                                                                    <div class="grey-text">
                                                                        Company Name
                                                                    </div>
                                                                    <div>{{key.company}}</div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="grey-text">
                                                                        Contact
                                                                    </div>
                                                                    <div>{{key.contactName}}</div>
                                                                </div>
                                                            </div>
                                                        
                                                            <div class="row">
                                                                <div class="col">
                                                                    <div class="grey-text">
                                                                        Phone
                                                                    </div>
                                                                    <div>{{key.referenceAddress.phoneNumber}}</div>
                                                                </div>
                                                                <div class="col">
                                                                    <div class="grey-text">
                                                                        Email
                                                                    </div>
                                                                    <div>{{key.referenceAddress.email}}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                       
                                                        <div class="row">
                                                            <h4>Terms: Our Terms Are Net 30 Days</h4>
                                                            <p>
                                                                By signing this application and submitting it to ShipCarte Inc., I agree that this information
                                                                is true to the best of my knowledge. I agree that all interest and finance charges incurred will be 
                                                                charged back to applicant.
                                                            </p>
                                                            <p class="mt-1">Todays Date: {{todaysDate}}</p>
                                                            
                                                            
                                                            <div class="row">
                                                                    <div class="col-6 ml-2" style="border: #ccc 1px dashed;padding: 20px;
                                                                    font-size: 40px;font-family: 'WindSong', cursive; font-weight: normal; color:#080808; text-align: center;">
                                                                            {{signBy}}
                                                                    </div>
                                                                    <div class="col-5 text-left blue-text m-auto editbtn" (click)="open_modal()">
                                                                        <img src="assets/img/commercial/edit.svg" > Edit
                                                                    </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 text-right">
                                        
                                                    <button class="btn submit_btn white black-text m-1"  type="submit">Submit for Review </button>
                                                </div>
                                            </mat-tab>
                                          </mat-tab-group>
                                          
                                    </div>
                                    <div class="row mt-1 mb-5 p-0" style="display: none;">
                                            <div class="footer-align">
                                            <p class="dark-grey-text mt-2 pl-2 d-inline"> &copy; {{cYear}} ShipCarte. All Rights Reserved </p>
                    
                                            <span class="ml-1 mt-1 mb-5 d-inline">
                                                <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                                                    <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
                                                </a>
                                                <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                                                    <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
                                                </a>
                                                <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                                    <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                                                </a>
                                                <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                                                    <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
                                                </a>
                                                <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                                                    <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                   
                </div>
            </form>
                <div class="row pl-3 m-0 BOL fixmebottom ">
                    <div class=" col-12  pt-1 ">
                        <div class="row m-0">
                            <div class="col-6 ">
                                <button type="button" class="back_btn btn warning m-1 col-2" (click)="open('userPreferences')">Back</button>
                            </div>
                        
                        </div>
                    </div>
            
                
                </div>
        </div>
    <!-- </div>
</div> -->
<app-spinner></app-spinner>