import { Component, OnInit,Input } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-credit-application-signature',
  templateUrl: './credit-application-signature.component.html',
  styleUrls: ['./credit-application-signature.component.css']
})
export class CreditApplicationSignatureComponent implements OnInit {
  sign:any;
  @Input() public signBy;
  @Input() public action;
  creditApplicationForm:FormGroup;
  constructor(public activeModal: NgbActiveModal,public fb:FormBuilder) { }

  
  ngOnInit(): void {
    
    this.creditApplicationForm = this.fb.group({
      signByName:[null,[Validators.required]],
      commercial:[true,[Validators.required]]
    })
    
    this.creditApplicationForm.controls["signByName"].setValue(this.signBy ? this.signBy : "Jhon Doe");
    
  }

  submit(formData){
    this.activeModal.close(this.creditApplicationForm.controls['signByName'].value);
  }

  closeModal() { 
    this.activeModal.close(false); 
  }

  updateSignPreview(sign){
    this.sign = sign;
  }

  resetForm(){
     this.creditApplicationForm.reset();
  }
}
