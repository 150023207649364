import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShipmentListingService } from './../../../services/shipment-listing.service';

@Component({
  selector: 'app-add-favorite-confirm',
  templateUrl: './add-favorite-confirm.component.html',
  styleUrls: ['./add-favorite-confirm.component.css']
})
export class AddFavoriteConfirmComponent implements OnInit {
  @Input() public orderId;
  @Input() public favName;

  constructor(public activeModal: NgbActiveModal,public shipmentListingService:ShipmentListingService) { }

  ngOnInit(): void {
  }

  closeModal() { this.activeModal.close({success : false}); }


  saveToFav(){
    let inputData = {   
          "orderId": this.orderId,
          "isFavourite": true,
          "favouriteOrderName":this.favName
        }

        this.shipmentListingService.updateItems(inputData).subscribe(result =>{
              this.activeModal.close({success : true});
            })
  }
}
