import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SignupService } from '../../../services/sign-up/signup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as jQuery from 'jquery';
import * as $ from 'jquery';
import {ViewChild, ElementRef} from '@angular/core';

@Component({
  selector: 'app-verify-mobile',
  templateUrl: './verify-mobile.component.html',
  styleUrls: ['./verify-mobile.component.css']
})
export class VerifyMobileComponent implements OnInit {
  varifyMobile: FormGroup;
  submitted = true;
  errorMsg: any;
  otpsubmit= false ;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @Input() public phoneNumber;
  constructor(public formBuilder:FormBuilder,private signupService:SignupService,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.errorMsg = false;
    this.varifyMobile = this.formBuilder.group({
      otpMobile:['', Validators.required]
    });
    
  }
  get f() { return this.varifyMobile.controls; }
  closeModal() { this.activeModal.close(); }
  onSubmit(){

    this.otpsubmit=true;
    console.log(this.varifyMobile.value)
    this.submitted = false;
    if (this.varifyMobile.invalid) {
      return;
    }

    this.errorMsg = "Processing, Please wait...";
    let inputData = 
    {
      "loggedInUserId": localStorage.getItem('loggedInUserId'),
      "actorType": localStorage.getItem('actorType'),
      "email": "",
      "otpCode": this.varifyMobile.value.otpMobile,
      "mobile": this.phoneNumber
    }
    this.signupService.validateEmailOtp(inputData).subscribe(result =>{

      if(result['success'] == "true"){
        this.errorMsg = false;
        this.activeModal.close({success:true})
      }
      this.otpsubmit=false;
    }, (error) =>{
      console.log(error["error"]["Message"]);
      console.log(error["error"]["message"]);
      this.errorMsg = error["error"]["Message"] ? error["error"]["Message"] : error["error"]["message"];
      this.otpsubmit=false;
    });
    //this.activeModal.close();
    //this.modalRef.close();
  }
  
}
