import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { CommercialInvoiceService } from 'src/app/services/commercial-invoice.service';
import { LoadingService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-view-commercial-invoice',
  templateUrl: './view-commercial-invoice.component.html',
  styleUrls: ['./view-commercial-invoice.component.css']
})
export class ViewCommercialInvoiceComponent implements OnInit {

  BOLd: any;
  resultData: any;
  dynamicDimension: any;
  declaredValue: number;
  purchaser: any;
  totalItem: number;
  shipperData: any;
  commercialPdfData: any;
  consigneeData: any;
  shipmentItems:any;
  totalUnit:number = 0;
  totalWeight:number = 0;
  totalWeightUnit: string;
  totalOfShpmtItemValue:number = 0;
  itemCurrency: string;
  totalTaxPercentage: any;
  totalDue: any;

  constructor(public common: CommonService, public activeModal: NgbActiveModal, private commercialInvoice: CommercialInvoiceService,private  _loaderService: LoadingService) { }

  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    this.commercialPdfData = this.commercialInvoice?.commercialInvoiceData ? this.commercialInvoice?.commercialInvoiceData : '--';
    this.shipmentItems = this.commercialPdfData?.shipmentItems ? JSON.parse(JSON.stringify(this.commercialPdfData?.shipmentItems)) : '--'
    this.consigneeData = this.commercialPdfData?.consignee ? this.commercialPdfData?.consignee : '--';
    this.shipperData = this.commercialPdfData?.shipper ? this.commercialPdfData?.shipper : '--';
    this.purchaser = this.commercialPdfData?.purchaser?this.commercialPdfData?.purchaser:'--';
    // console.log(this.shipmentItems,'sdksk')

    // this.shipmentItems.forEach(element => {
    //   console.log(element)
    // });
    for(let perItem of this.shipmentItems){
      this.totalUnit += perItem.qty;
      this.totalWeight += perItem.totalWeight;
      this.totalOfShpmtItemValue += (perItem.qty * perItem.shipmentItemValue);
      // this.totalOfShpmtItemValue += (perItem.shipmentItemValue);
    }
    this.totalWeightUnit = this.shipmentItems[0].wghtUnit;
    this.itemCurrency = this.shipmentItems[0].shipmentItemValueCurrency;
    
    let salesTaxAmout = this.commercialPdfData.salesTaxAmout;
    if(this.commercialPdfData.salesTaxIsPercent){
      salesTaxAmout = (this.totalOfShpmtItemValue * salesTaxAmout)/100
    }

    this.totalDue = this.totalOfShpmtItemValue + salesTaxAmout + this.commercialPdfData.freightCost;
    setTimeout(() => {
      this._loaderService.stringSubject.next(false);
    }, 2000);
    // this.totalTaxPercentage = ((this.commercialPdfData?.salesTaxAmout/this.totalOfShpmtItemValue)*100).toFixed(2);
  }

  multiple(unit, shpmtVal){
    unit = JSON.parse(unit);
    shpmtVal = JSON.parse(shpmtVal);

    let total = unit*shpmtVal
    debugger
    return total;
  }

  closeModal() { this.activeModal.close(); }

}
