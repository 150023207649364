import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { environment  } from './../../../environments/environment.prod';
import { environment  } from './../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PriceBrackupService {
  baseUrl:any;
  constructor(private http:HttpClient) { }

  getPRicebrackup(quoteId){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath+ "/api/quote/priceComponent/get?quoteId="+quoteId ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));  
  }
}