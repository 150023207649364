import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from './guard/role.guard';
import { Role } from './models/Role';

const routes: Routes = [
  // { path: '', redirectTo: 'signup', pathMatch:'full'},
  // { path: 'signup',loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  { path: '', redirectTo: 'signin', pathMatch: 'full' },
  { path: 'signin', loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule) },
  { path: 'forget-password', loadChildren: () => import('./signup/forget-password/forget-password-routing.module').then(m => m.ForgetPasswordRoutingModule) },
  { path: 'checkout', loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutModule) },
  {
    path: 'get-quote', loadChildren: () => import('./get-quote/get-quote.module').then(m => m.GetQuoteModule),
    canActivate: [RoleGuard],
    data: { roles: [Role.Shipper, Role.Admin] }
  },
  {
    path: 'get-quote/:quoteId', loadChildren: () => import('./get-quote/get-quote.module').then(m => m.GetQuoteModule), canActivate: [RoleGuard],
    data: { roles: [Role.Shipper, Role.Admin] }
  },
  {
    path: 'get-quote/edit/:quoteId', loadChildren: () => import('./get-quote/get-quote.module').then(m => m.GetQuoteModule), canActivate: [RoleGuard],
    data: { roles: [Role.Shipper, Role.Admin] }
  },
  {
    path: 'reset-password/:token', loadChildren: () => import('./signup/reset-password/reset-password-routing.module').then(m => m.ResetPasswordRoutingModule),
    canActivate: [RoleGuard],
    data: { roles: [Role.Shipper, Role.Admin] }
  },
  { path: 'dashboard', loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule), },
  { path: 'manage-carrier', loadChildren: () => import('./Admin/manage-carrier/manage-carrier.module').then(m => m.ManageCarrierModule) },
  // {
  //   path: 'shipment/document/:quoteId', loadChildren: () => import('../app/shipment/document/document.module').then(m => m.DocumentModule), canActivate: [RoleGuard],
  //   data: { roles: [Role.Shipper, Role.Admin] }
  // },
  {
    path: 'address-book', loadChildren: () => import('./address-book/address-book.module').then(m => m.AddressBookModule), canActivate: [RoleGuard],
    data: { roles: [Role.Shipper, Role.Admin] }
  },
  // { path: 'commercial-invoice/:quoteId', loadChildren: () => import('../app/commercial-invoice/commercial-invoice.module').then(m => m.CommercialInvoiceModule) },
  {
    path: 'shipment', loadChildren: () => import('../app/shipment/shipment.module').then(m => m.ShipmentModule), canActivate: [RoleGuard],
    data: { roles: [Role.Shipper, Role.Admin] }
  },
  { 
    path:'commercialInvoice/generateCommercialInvoice',loadChildren:()=>import('../app/generate-commercial-invoice/generate-commercial-invoice.module').then(m=>m.GenerateCommercialInvoiceModule)
  },
  {
    path:'quickBOL',loadChildren:()=>import('../app/quick-action/quick-bol/quick-bol.module').then(m=>m.QuickBolModule)
  },
  {
    path:'commercialInvoice/createCommercialInvoice', loadChildren:()=>import('../app/quick-action/quick-action.module').then(m=>m.QuickActionModule)
  },
  {
    path:'CommercialInvoice/list',loadChildren:()=>import('../app/quick-action/quick-action.module').then(m=>m.QuickActionModule)
  },
  {
    path:'ViewingCommercial/Invoice',loadChildren:()=>import('../app/quick-action/quick-action.module').then(m=>m.QuickActionModule)
  },
  {
    path: 'shipcartepro',
    loadChildren: () => import('../app/new-shipcarte-pro/new-shipcarte-pro/new-shipcarte-pro.module').then(m => m.NewShipcarteProModule),
    canActivate: [RoleGuard],
    data: { roles: [Role.Shipper, Role.Admin] }
  },
  {
    path: 'market-place', loadChildren: () => import('../app/marketPlaceIntegration/market-place.module').then(m => m.MarketPlaceModule), canActivate: [RoleGuard],
    data: { roles: [Role.Shipper, Role.Admin] }
  },
  { path: 'user-preferences', loadChildren: () => import('../app/user-preferences/user-preferences.module').then(m => m.UserPreferencesModule) },
  { path: 'help', loadChildren: () => import('../app/help/help.module').then(m => m.HelpModule) },
  { path: 'favoriteOld', loadChildren: () => import('./favorite/favorite.module').then(m => m.FavoriteModule) },
  { path: 'favorite', loadChildren: () => import('./favorite-new/favorite-new.module').then(m =>m.FavoriteNewModule ) },
  {
    path: 'invoices', loadChildren: () => import('./invoices/invoices.module').then(m => m.InvoicesModule), canActivate: [RoleGuard],
    data: { roles: [Role.Admin,Role.Accounting] }
  },
  { path: 'reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: 'settings', loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule) },
  {path:'user-profile',loadChildren:()=>import('./new-user-profile/new-user-profile.module').then(m=>m.NewUserProfileModule)}

];


@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
