import { Component, Input, OnInit } from '@angular/core';
import { NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import{Router} from "@angular/router";
 

import { ToastrService } from 'ngx-toastr';
import { MergePDFService } from 'src/app/services/merge-pdf.service';
import { HttpClient } from '@angular/common/http';
 
@Component({
  selector: 'app-download-label',
  templateUrl: './download-label.component.html',
  styleUrls: ['./download-label.component.css']
})
export class DownloadLabelComponent implements OnInit {
  @Input() data;
  pdfLinksArray:any=[]
  mergedPdf: Uint8Array | null = null;
  constructor(private ser:NgbModal, private rt:Router ,private toastr: ToastrService,private mergePDF:MergePDFService,private http:HttpClient) { }

  ngOnInit(): void {
    this.pdfLinksArray=this.data.link;
  }
  getShipmentLabelUrl(i){
        // this.isLabelUrl=this.sanitizer.bypassSecurityTrustResourceUrl(result['link'])
        window.open(this.data['link'][i],'_blank').focus(); 
     
  }
  close(){
    this.ser.dismissAll();
    }

  // navigate(){
  //   this.rt.navigateByUrl("user-preferences");
  //   this.ser.dismissAll();
  // }
  async mergeAndDownloadPDFs() {
    // console.log(this.data)
    // if (this.pdfLinksArray.length === 0) {
    //   alert('Please provide at least one PDF link.');
    //   return;
    // }

    // this.mergedPdf = await this.mergePDF.mergePDFs(this.pdfLinksArray);

    // // Create a Blob from the merged PDF and trigger a download
    // const blob = new Blob([this.mergedPdf], { type: 'application/pdf' });
    // const url = window.URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'merged.pdf';
    // a.click();
  }
 

   
}



