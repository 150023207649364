import { Component, OnInit } from '@angular/core';
import { AnonymousSubject } from 'rxjs/internal/Subject';
import { AdminService } from 'src/app/services/AdminService/admin.service';

@Component({
  selector: 'app-manage-carrier',
  templateUrl: './manage-carrier.component.html',
  styleUrls: ['./manage-carrier.component.css']
})
export class ManageCarrierComponent{
  commission:any;
  page=4;
  displayCourier=false;

  carriers:any;
  constructor(private car:AdminService) { }

  ngOnInit(): void {
    this.car.getAllCarriers().subscribe(c=>{
      this.carriers=c["data"];
    })

  }

  addCommission(carrierID,commission){

  }

}
