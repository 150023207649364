<div class="container" style="position: absolute; width: 100%;">
    <div class="frightClassStyle" style="height: 100%;">
    <div class="row mt-3" style="margin-bottom:0px!important;">
        <div style="display: flex; justify-content: space-between !important; align-items: center;">
            <div style="font-size: 29px !important; color:#354052 !important; font-weight:500 !important;line-height: 32px;">Dimensional
                Weight Calculator </div>
            <div style="font-size: 40px !important; font-weight: 300; color: #354052 !important; cursor: pointer;"
                (click)="closeModal()">&#215;</div>
        </div>
        <div style="padding-top:28px;padding-bottom:26px; display: flex !important;">
            <label style="margin-right: 20px !important;color: #354052 !important;" class="container1">Domestic
                <input type="radio" [checked]="domestic" (click)="getValue($event.target.checked, 'Domestic')"
                    name="test">
                <span style="padding-left:0px !important;" class="checkmark"></span>
            </label>
            <label style="color: #354052 !important;" class="container1">International
                <input type="radio" [checked]="international" (click)="getValue($event.target.checked, 'International')"
                    name="test">
                <span style="padding-left:0px !important;" class="checkmark"></span>
            </label>
        </div>
        <div style=" display: flex !important;">
            <label style="margin-right: 20px !important;color: #354052 !important;" class="container1">Metric
                <input type="radio" [checked]="matrixUnit" (click)="getValue($event.target.checked, 'Metric')"
                    name="unitMap">
                <span style="padding-left:0px !important;" class="checkmark"></span>
            </label>
            <label style="color: #354052 !important;" class="container1">Imperial
                <input type="radio" [checked]="imperialUnit" (click)="getValue($event.target.checked, 'Imperial')"
                    name="unitMap">
                <span style="padding-left:0px !important;" class="checkmark"></span>
            </label>
        </div>
        <form [formGroup]="dimentionForm" (ngSubmit)="submit()" style="margin-top:0px !important;" >
            <div class="row mt-2" style="margin-bottom:0px !important;padding-top:25px !important;">
                <div class="col">
                    <div class="form-group" style="display: flex !important;margin-bottom: 0.5rem !important;">
                        <div>
                            <label style="font-size: 21px !important;color:#354052 !important;">Length&nbsp;<span
                                    style="font-size:13px !important;background: #CED0DA !important;padding:1px 5px;border-radius: 4px;">{{imperialUnit
                                    == true ? 'inch':'cm'}}</span> </label>
                            <input formControlName="Length" type="number" class="form-control" placeholder="0"
                                style="padding: 2px !important; height:44px !important; padding: 2px 16px !important;
                                width: calc(100% - 28px);">
                            <div *ngIf="dimentionForm.get('Length').invalid && (dimentionForm.get('Length').dirty || dimentionForm.get('Length').touched)"
                                [ngClass]="'error'">
                                <div *ngIf="dimentionForm.get('Length').errors.required">
                                    Length is required.
                                </div>
                            </div>
                        </div>
                        <div class="styleButtons">
                            <span style="height:25px !important;" (click)="incrementlength()" class="spanPlus">+</span>
                            <span style="height: 25px !important;" (click)="decrementlength()" class="spanMinus">-</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group" style="display: flex !important;margin-bottom: 0.5rem !important;">
                        <div>
                            <label style="font-size: 21px !important;color:#354052 !important;">Width&nbsp;<span
                                    style="font-size:13px !important;background: #CED0DA !important;padding:1px 5px;border-radius: 4px;">{{imperialUnit
                                    == true ? 'inch':'cm'}}</span> </label>
                            <input formControlName="Width" type="number" class="form-control" placeholder="0"
                                style="padding: 2px !important; height:44px !important; padding: 2px 16px !important;
                                width: calc(100% - 28px);">
                            <div *ngIf="dimentionForm.get('Width').invalid && (dimentionForm.get('Width').dirty || dimentionForm.get('Width').touched)"
                                [ngClass]="'error'">
                                <div *ngIf="dimentionForm.get('Width').errors.required">
                                    Width is required.
                                </div>
                            </div>
                        </div>
                        <div class="styleButtons">
                            <span style="height:25px !important;" (click)="incrementWidth()" class="spanPlus">+</span>
                            <span style="height: 25px !important;" (click)="decrementWidth()" class="spanMinus">-</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group" style="display: flex;margin-bottom: 0.5rem !important;">
                        <div>
                            <label style="font-size: 21px !important;color:#354052 !important;">Height&nbsp;<span
                                    style="font-size:13px !important;background: #CED0DA !important;padding:1px 5px;border-radius: 4px;">{{imperialUnit
                                    == true ? 'inch':'cm'}}</span></label>
                            <input formControlName="Height" type="number" class="form-control" placeholder="0"
                                style="padding: 2px !important; height:44px !important; padding: 2px 16px !important;
                                width: calc(100% - 28px);">
                            <div *ngIf="dimentionForm.get('Height').invalid && (dimentionForm.get('Height').dirty || dimentionForm.get('Height').touched)"
                                [ngClass]="'error'">
                                <div *ngIf="dimentionForm.get('Height').errors.required">
                                    Height is required.
                                </div>
                            </div>
                        </div>
                        <div class="styleButtons">
                            <span style="height:25px !important;" (click)="incrementHeight()" class="spanPlus">+</span>
                            <span style="height: 25px !important;" (click)="decrementHeight()"
                                class="spanMinus">-</span>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <button class="btn orange text-white mt-3"
                    style="text-transform: capitalize;height:44px !important; width:100%; box-shadow: none !important; font-size: 17px !important; font-weight: 600 !important;"
                    type="submit" [disabled]="!dimentionForm.valid">Calculate</button>
            </div>
        </form>
    </div>
    <div>
    <div style="border: 1px solid #CED0DA !important; height:187px; background: #F5F6FA !important;margin-top: 10px !important;padding: 0px 35px 0px 35px;">
        <div class="mt-3" style="font-size:24px !important;padding-top:17px; font-weight: 500; color:#354052 !important;">Result</div>
        <div class="row mb-0 mb-3" style="padding-top: 55px !important;">
            <div class="col" style="font-size: 19px;color:#354052 !important;">
                Dimensional Weight:
            </div>
            <div class="col text-right" style="font-size: 19px;color:#354052 !important;">
                {{ weightInLBS ? (weightInLBS | number : '1.2-2') : '--' }} {{imperialUnit == true ? 'lbs':'kg'}}
            </div>
        </div>
        <div style="border: 1px solid #DDDFE1; margin-top:29px;"></div>
    </div>
    <div class="buttonRes" style="display: flex !important; justify-content: space-between !important;">
        <div style="color: #2877C6 !important; font-size:19px !important;padding-top: 8 !important; display: flex;align-items: center; font-weight:500; cursor: pointer;" (click)="reset()">
            <img class="resetIcon" src="assets/img/fi-rr-undo-alt.svg" /> Reset Fields 
         </div>
        <div class="text-right">
            <button type="button" class="btn faint-grey"
                style="box-shadow: none;text-transform: capitalize; background: none !important; font-size:19px !important;"
                (click)="closeModal()">Cancel</button>
            &nbsp;&nbsp; <button type="submit" class="btn orange text-white"
                style="box-shadow: none;text-transform: capitalize; width: 115px; height:44px !important; font-size:19px!important;"
                (click)="closeModal()">Done</button>
          </div>
       </div>
     </div>
  </div>
</div>