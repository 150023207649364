<!-- <div class="wrapper">
  <div class="row">
    <div class="col-1" [ngClass]="sidebarToggle ? 'sidebarCollapse' :'sidebarNotCollapsed'">
      <app-leftsidebar></app-leftsidebar>
    </div>

    <div class="col-11"> -->
        <div class="productListSection">
            <div class="row m-0 pt-3 " style="border: 1px solid #c4cdd5;opacity: 1;background-color: #f5f6fa;">
              <div class="col-12 p-0 pb-2 marginLeftHeadings paddingLeftHeaderSection">
                <div class="flexHeadingAndButton">
                  <div class="main-heading d-inline">
                  <a style="color: #354052; text-decoration: none; cursor: pointer;"
                    [routerLink]="['/shipcartepro/intellibox/productList']">
                    <img style="margin-right: 5px;width:17px" src="assets/img/shipment/fi-rr-arrow-small-left.svg" alt="">
                    <span style="margin-left: 5px;">{{ isEdit ? 'Editing Product' : 'Creating New Product'}} </span> </a>
                </div>
                  <div class="uploadButtonBox">
                    <button type="button" style="padding: 0px 7px;" class="btn btn-outline-warning" (click)="open(content)">
                      <span style="margin-right: 6px;">  <i class="fa fa-upload upload m-1" aria-hidden="true" style="font-size: 16px;color:white"></i></span> <span>Bulk Upload</span></button>
                  </div>
                </div>
    
              </div>
            </div>
    
            <div class="ml-4 mr-3 mt-4 ">
              <div>
                <label class="settingLabel paddingLeft12pxMobileView">Adding Product Details</label>
              </div>
              <form [formGroup]="productForm">
                <div class="row">
                  <div class="col-sm-12 col-lg-3 paddingAllV-8H-10 paddingLeft20pxMobileView">
                    <div *ngIf="!uploadImageShow" class="imageTemplateSection">
                      <i class="fa fa-camera cam-icon defaultCameraIcon" (click)="fileInput.click()"></i>
                      <!-- <img *ngIf="!uploadImageShow" class="staticImageSize" src="assets/img/shipment/fi-rr-arrow-small-left.svg" alt=""> -->
                    </div>
                    <!-- <div *ngIf="uploadImageShow" class="imageSection"
                      [ngStyle]="{ 'background-image': 'url(' + uploadImageShow + ')'}">
                      <img *ngIf="uploadImageShow" class="imageSection" [src]="uploadImageShow" alt="">
                    </div> -->
                    <div *ngIf="uploadImageShow" class="imageSection">
                      <img style="width: 100%;border-radius: 14px;" class="height100PerMobileView imageAspectFix" [src]="uploadImageShow" alt="">
                    </div>
    
                    <!-- <div style="width:290px;height: 290px;" [ngStyle]="{ 'background-image': 'url(' + uploadImageShow + ')'}"></div> -->
                    <div class="uploadRemoveButtonStyle mt-3">
                      <button class="uploadImageButton" type="button" (click)="fileInput.click()">
                        <span style="font-size: 26px;font-weight: 400;">+</span> <span style="margin-left: 7px;">Upload
                          Image</span>
                        <input #fileInput type="file" accept="image/*" name="uploadImage" id="uploadImage"
                          (change)="uploadImage($event)" style="display:none;" />
                      </button>
                    </div>
                    <div *ngIf="uploadImageShow" class="uploadRemoveButtonStyle mt-3">
                      <button class="removeImageButton" type="button" (click)="removeImage()">
                        Remove Current Image
                      </button>
                    </div>
                  </div>
                  <div class="col-sm-12 col-lg-9 paddingAllV-8H-10">
                    <div class="row mb-0">
                      <div class="col-sm-12 col-lg-4 paddingAllV-8H-10">
                        <div class="paddingAllV-8H-10">
                          <div class="form-group" [ngClass]="isEdit ? 'disable' : ''">
                            <label class="control-label" for="productSku">Product SKU #<span
                                style="color:#F85359">*</span></label>
                            <div class="productInput">
                              <input id="productSku"
                              (blur)="logValidationErrors()" formControlName="productSku" type="text"
                                class="form-control" placeholder="Enter Product SKU #">
                              <span style="color:#FF0000;" *ngIf="formErrors?.productSku">
                                {{formErrors.productSku}}
                              </span>
                            </div>
                          </div>
                          <!-- <span *ngIf="productForm.get('productSku').errors.required">
                            yes*
                        </span> -->
                        </div>
                        <div class="paddingAllV-8H-10">
                          <div class="form-group">
                            <label class="control-label" for="productName">Product Name<span
                                style="color:#F85359">*</span></label>
                            <div class="productInput">
                              <input id="productName" (blur)="logValidationErrors()" formControlName="productName"
                                type="text" class="form-control" placeholder="Enter Product Name" >
                              <span style="color: #FF0000;" *ngIf="formErrors?.productName" >
                                {{formErrors.productName}}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-4 paddingAllV-8H-10">
                        <div class="shipSoloStyle paddingAllV-8H-10">
                          <div class="d-flex form-group">
                            <label class="switch">
                              <input type="checkbox" formControlName="shipSolo" id="shipSolo">
                              <span class="slider round"></span>
                            </label>
                            <label class="control-label">
                              Ship Solo
                            </label>
                          </div>
                        </div>
                        <div [ngClass]="formErrors?.productSku ? 'marginTopError28px' : ''" class="paddingAllV-8H-10 pt-0">
                          <div class="form-group">
                            <label class="control-label" for="productDesc">Product Description<span
                                style="color:#F85359">*</span></label>
                            <div class="productInput">
                              <input id="productDesc" (blur)="logValidationErrors()" formControlName="productDesc"
                                type="text" class="form-control" placeholder="Enter Package Description">
                              <span style="color: #FF0000;" *ngIf="formErrors?.productDesc">
                                {{formErrors.productDesc}}
                              </span>
                            </div>
                          </div>
                          <!-- <span *ngIf="productForm.get('productSku').errors.required">
                            yes*
                        </span> -->
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-8 paddingHorizontal10px">
                        <div class="additionalPeciesStyle paddingHorizontal10px">
                          <div class="d-flex form-group mb-0">
                            <label class="switch">
                              <input type="checkbox" [value]="addAdditionalToggleValue" [checked]="addAdditionalToggle"
                                (click)="addOrRemoveMeasureMent($event.target.value)">
                              <span class="slider round"></span>
                            </label>
                            <label class="control-label">
                              Additional Pieces
                            </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-12 widthsetForMeasureMentSection paddingAllV-8H-10">
                        <div class="form-group paddingHorizontal10px">
                          <input type="radio" value="metric" formControlName="dimentionUnit"> <label class="radioDimention">
                            Metric </label>
                          <input type="radio" value="imperial" formControlName="dimentionUnit"> <label
                            class="radioDimention"> Imperial </label>
                        </div>
                        <div class="paddingHorizontal10px" formArrayName="measureMents"
                          *ngFor="let measurement of productForm.get('measureMents')?.controls; let i=index">
                          <div class="measureMentSection" [formGroupName]="i">
                            <div class="row form-group">
                              <div class="col-sm-12 col-lg-2">
                                <label class="control-label" [attr.for]="'length'+i">Length<span
                                    style="color:#F85359">*</span></label>
                                <div>
                                  <div class="measureMentInput">
                                    <input [id]="'length'+i" class="form-control" formControlName="length" type="number"
                                      placeholder="  0.00">
                                    <div class="styleButtons">
                                      <span class="spanPlus" (click)="increamentByOne(i,'length')">
                                        +
                                      </span>
                                      <span class="spanMinus" (click)="decreamentByOne(i,'length')">
                                        -
                                      </span>
                                    </div>
                                  </div>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                    *ngIf="measurement.get('length').errors?.required && (measurement.get('length')?.touched || submitClicked)">
                                    * length is required
                                  </span>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                    *ngIf="(measurement.get('length').errors?.min || measurement.get('length').errors?.max)  && (measurement.get('length')?.touched || submitClicked)">
                                    * invalid length
                                  </span>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                  *ngIf="measurement.get('length').errors?.pattern && (measurement.get('length')?.touched || submitClicked) && !measurement.get('length').errors?.min && !measurement.get('length').errors?.max ">
                                  * only 2 decimal places allowed
                                </span>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-2">
                                <label class="control-label" [attr.for]="'width'+i">Width<span
    
                                    style="color:#F85359">*</span></label>
                                <div>
                                  <div class="measureMentInput">
                                    <input [id]="'width'+i" class="form-control" formControlName="width" type="number"
                                      min="0" placeholder="  0.00">
                                    <div class="styleButtons">
                                      <span class="spanPlus" (click)="increamentByOne(i,'width')">
                                        +
                                      </span>
                                      <span class="spanMinus" (click)="decreamentByOne(i,'width')">
                                        -
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                  *ngIf="measurement.get('width').errors?.required && (measurement.get('width')?.touched || submitClicked)">
                                  * width is required
                                </span>
                                <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                  *ngIf="(measurement.get('width').errors?.min || measurement.get('width').errors?.max)  && (measurement.get('width')?.touched || submitClicked)">
                                  * invalid width
                                </span>
                                <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                  *ngIf="measurement.get('width').errors?.pattern && (measurement.get('width')?.touched || submitClicked) && !measurement.get('width').errors?.min && !measurement.get('width').errors?.max">
                                  * only 2 decimal places allowed
                                </span>
    
                              </div>
                              <div class="col-sm-12 col-lg-2">
                                <label class="control-label" [attr.for]="'height'+i">Height<span
                                    style="color:#F85359">*</span></label>
                                <div>
                                  <div class="measureMentInput">
                                    <input [id]="'height'+i" class="form-control" formControlName="height" type="number"
                                      min="0" placeholder="  0.00">
                                    <div class="styleButtons">
                                      <span class="spanPlus" (click)="increamentByOne(i,'height')">
                                        +
                                      </span>
                                      <span class="spanMinus" (click)="decreamentByOne(i,'height')">
                                        -
                                      </span>
                                    </div>
                                  </div>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                    *ngIf="measurement.get('height').errors?.required && (measurement.get('height')?.touched || submitClicked)">
                                    * height is required
                                  </span>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                    *ngIf="(measurement.get('height').errors?.min || measurement.get('height').errors?.max) && (measurement.get('height')?.touched || submitClicked)">
                                    * invalid height
                                  </span>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                  *ngIf="measurement.get('height').errors?.pattern && (measurement.get('height')?.touched || submitClicked) && !measurement.get('height').errors?.min && !measurement.get('height').errors?.max">
                                  * only 2 decimal places allowed
                                </span>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-3">
    
                                <label class="control-label" [attr.for]="'weight'+i">Weight<span
    
                                    style="color:#F85359">*</span></label>
                                <div>
                                  <div class="measureMentInput">
                                    <input [id]="'weight'+i" class="form-control" formControlName="weight" type="number"
                                      min="0" placeholder="  0.00">
                                    <div class="styleButtons">
                                      <span class="spanPlus" (click)="increamentByOne(i,'weight')">
                                        +
                                      </span>
                                      <span class="spanMinus" (click)="decreamentByOne(i,'weight')">
                                        -
                                      </span>
                                    </div>
                                  </div>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                    *ngIf="measurement.get('weight').errors?.required && (measurement.get('weight')?.touched || submitClicked)">
                                    * weight is required
                                  </span>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                    *ngIf="(measurement.get('weight').errors?.min || measurement.get('weight').errors?.max) && (measurement.get('weight')?.touched || submitClicked)">
                                    * invalid weight
                                  </span>
                                  <span class="marginBottom12pxMobileView" style="display: block;color: #FF0000;"
                                  *ngIf="measurement.get('weight').errors?.pattern && (measurement.get('weight')?.touched || submitClicked) && !measurement.get('weight').errors?.min && !measurement.get('weight').errors?.max">
                                  * only 2 decimal places allowed
                                </span>
                                </div>
                              </div>
                              <div class="col-sm-12 col-lg-1 d-flex align-items-center">
                                <div *ngIf="productForm.get('measureMents').length > 1" style="margin-top: 15px;">
                                  <i class="fa fa-trash trash m-1" aria-hidden="true"
                                    style="cursor: pointer;font-size: 16px;color:#EC4144;"
                                    (click)="removeMoreFeildMeasureMent(i,measurement.get('uniqueIdForEdit')?.value)"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
    
                      <div class="col-sm-12 col-lg-12 paddingAllV-8H-10">
                        <div class="col-sm-12 col-lg-8 mb-2">
                          <div class="marketPlace d-flex justify-content-between">
                            <label class="marketPlaceLabel">Activate In Marketplace</label>
                            <button type="button" class="btn btn-outline-warning" *ngIf="addAdditionalToggle"
                              (click)="addMoreFeildMeasureMent()">Add Pieces</button>
                          </div>
                        </div>
                        <div class="row mb-0">
                          <div class="col-sm-12 col-lg-8 paddingMarketPlaceWidthsetResponsive">
                          <div class="row mb-0">
                          <div *ngFor="let marketPlace of marketPlacesArray;let i=index" class="col-sm-12 col-lg-6">
                          <div class="paddingHorizontal10px">
                          <div class="d-flex form-group mb-0 align-items-center" style="min-height: 60px;">
                          <label class="switch mb-0">
                          <input type="checkbox" [value]="marketPlace.market_place_id"
                          [checked]="marketPlace.is_active=='true'? true:false"
                          [id]="marketPlace.market_place_id" (click)="actionMarketSwitchClicked($event,i)">
                          <span class="slider round"></span>
                          </label>
                          <img *ngIf="marketPlace.name=='Shopify'" [src]="marketPlace.market_place_logo_url"
                          style="width: 160px;height: 42px;margin-right: 15px;">
                          <img *ngIf="marketPlace.name=='Magento 2'" [src]="marketPlace.market_place_logo_url"
                          style="width: 160px;height: 42px;margin-right: 15px;">
                          <img *ngIf="marketPlace.name=='Etsy' || marketPlace.name=='ebay'" [src]="marketPlace.market_place_logo_url"
                          style="width: 76px;height: 38px;margin-right: 15px;">
                          <img *ngIf="marketPlace.name=='Woo Commerce'" [src]="marketPlace.market_place_logo_url"
                          style="width: 160px;height: 42px;margin-right: 15px;">
                          <img *ngIf="marketPlace.name=='Amazon'" [src]="marketPlace.market_place_logo_url"
                          style="width: 110px;height: 33px;margin-right: 15px; margin-top: 15px;">
                          <img *ngIf="marketPlace.name!=='Amazon' && marketPlace.name!=='Shopify' && marketPlace.name!=='Magento 2' && marketPlace.name!=='Etsy' && marketPlace.name!=='ebay' && marketPlace.name!=='Woo Commerce'" [src]="marketPlace.market_place_logo_url"
                          style="width: 30px;height: 30px;margin-right: 15px;">
                          <!-- <label class="control-label">
                          {{marketPlace.name}}
                          </label> -->
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <!-- <table border="1">
                <tr>
                  <th style="padding:10px">FormGroup</th>
                  <th style="padding:10px">Form control (name)</th>
                </tr>
                <tr>
                  <td style="padding: 10px;">
                    touched: {{productForm.touched}}
                    <br />
                    valid :{{productForm.valid}}
                    <br />
                    dirty :{{productForm.dirty}}
                    <br />
                    FormValues :{{productForm.value | json}}
                    <br />
                  </td>
                  <td style="padding: 10px;">
                    touched: {{productForm.get('productName').touched}}
                    <br />
                    valid :{{productForm.get('productName').valid}}
                    <br />
                    dirty :{{productForm.get('productName').dirty}}
                    <br />
                    name value :{{productForm.get('productName').value}}
    
                  </td>
                </tr>
              </table> -->
            </div>
            <div class="ml-4 mr-3 paddingLeftshippingSetting">
              <form [formGroup]="productForm">
                <div style="border:1px solid #CED0DA;" class="row">
                  <div class="col-sm-12 shippingSettingHeaderStyle">
                    <label class="settingLabel">Shipping settings</label>
                  </div>
                  <div class="col-sm-12" style="margin-top:2rem">
                    <label class="labelInsideShippingSetting">Free Shipping</label>
                    <p class="para14px">Choose wether the product qualifies for free shipping</p>
                  </div>
    
                  <div style="margin-top:12px">
                    <div style="margin-top:10px">
                      <input type="radio" value="QFS" formControlName="freeShipping" > <label class="radioDimention">
                        Qualifies for free shipping </label>
                    </div>
                    <div style="margin-top:10px">
                      <input type="radio" value="EFS" formControlName="freeShipping" > <label class="radioDimention">
                        Excludes from free shipping </label>
                    </div>
                    <div style="margin-top:10px">
                      <input type="radio" value="ASF" formControlName="freeShipping" > <label class="radioDimention">
                        Always ship for free </label>
                    </div>
    
                  </div>
                  <div class="col-sm-12 doNotShipSectionStyle">
                    <label class="labelInsideShippingSetting">Do not ship to regions</label>
                    <p class="para14px">Choose which regions are excluded from shipping</p>
                    <div class="excludedRegionDivStyle">
                      <div *ngFor="let shipregionArray of doNotShipRegionArray;let i=index" class="marginBottom5px">
                        <input  type="checkbox" [id]="shipregionArray.zone_id"
                          [checked]="shipregionArray.is_shippable=='false'? true : false" [name]="shipregionArray.zone_id"
                          [value]="shipregionArray.zone_id" (click)="actionDoNotShipRegionClicked($event,i)">
                        <label class="labelInsideShippingSetting paddLeft25px">{{shipregionArray.zone_name}}</label>
                      </div>
                    </div>
                  </div>
                  <div style="margin-top: 2rem;margin-bottom: 2rem; opacity: 0.5;">
                    <label class="labelInsideShippingSetting">Custom Shipping (coming soon)</label>
                    <p class="para14px">Assign Custom Shipping charges for this product</p>
                    <div class="customShippingFormDiv" style="overflow:hidden;">
                      <div class="new" style="overflow-x:scroll;">
                        <div class="customShippingrowStyle" style="background-color: #E2E7EE; border: 1px solid #CED0DA;">
                          <div style="width: 200px;">
                            <label class="labelInsideShippingSetting" style="padding-left: 20px;">Zone</label>
                          </div>
                          <div class="width400px">
                            <label class="labelInsideShippingSetting">Cost of first quantity</label>
                          </div>
                          <div class="width400px">
                            <label class="labelInsideShippingSetting">Cost of each additional quantity</label>
                          </div>
                        </div>
                        <div *ngFor="let customShipping of customShippingArray;let i=index" class="customShippingrowStyle"
                          [ngStyle]="{'border-bottom': (customShippingArray.length-1) == i ? '1px solid #CED0DA' : 'none' }">
                          <div style="width: 200px;">
                            <label class="labelInsideShippingSetting"
                              style="padding-left: 20px;">{{customShipping.zone_name}}</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="First Quantity" min="0"
                              [value]="customShipping.first_qty_ship_cost"
                              disabled="true"
                              (input)="actionCustomValueChange($event,'firstQuantity',i)">
                            <label class="labelInsideShippingSetting"
                              style="margin-left: -4rem;">{{customShipping.currency}}</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="Additional Quantity"
                              [value]="customShipping.addl_qty_ship_cost" min="0"
                              disabled="true"
                              (input)="actionCustomValueChange($event,'secondQuantity',i)">
                            <label class="labelInsideShippingSetting"
                              style="margin-left: -4rem;">{{customShipping.currency}}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div style="width: 100%;height: 60px;">
          </div>
          <div class="bottomFooter" [ngClass]="sidebarToggle ? 'width99Per' :'width92Per'" style="margin-bottom:30px;" >
            <div class="buttonsFooter" style="margin-right:75px;">
              <div class="buttonDiv">
                <button type="button" [routerLink]="['/shipcartepro/tabs/intelliBox']"
                  class="btn btn-cancel">Cancel</button>
              </div>
              <div class="buttonDiv">
                <button *ngIf="!isEdit" type="button" class="btn btn-outline-warning btn-color"
                  (click)="createProduct()">Create Product</button>
                <button *ngIf="isEdit" type="button" class="btn btn-outline-warning btn-color"
                  (click)="updateProduct()">Save Edits</button>
              </div>
            </div>
    
          </div>
        <!-- </div>
      </div> -->
    
    
    
    
    
    
    
      <!-- while Api Call ***************************************************************************************************************************************  -->
      <app-spinner></app-spinner>
    
      <!-- ***************************************************************************************************************************************  -->
    
        <!-- modal for bulk upload *************************************************************************************************************************************** -->
    
        <ng-template #content let-modal>
          <div class="save_fab scrollView">
            <div class="row" style="margin-top: 20px;">
              <div class="col-8">
                <h5 style="font-size: 24px !important;font-weight: 500 !important;font-family: poppins,sans-serif !important;color: #354052 !important;">Bulk Upload Product</h5>
              </div>
              <div class="close_btn">
                <img src="assets/img/shipment/fi-rr-cross-small.svg" alt="" (click)="modal.dismiss('Cross click')">
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="form-group browseFile">
                  <input type="text" disabled="true"
                    [value]="bulkUploadData.name"
                    style="height: 30px !important;width: 100%; border: 1px solid #CED0DA; padding-left: 5px !important;font-weight: 400;font-family: poppins,sans-serif; ">
                  <button (click)="fileInput1.click()" class="dialogButtonColored" style="height: 32px;">Browse</button>
                  <input type="file"
                  accept=".csv,.zip"
                  (change)="selectExcelFile($event)"
                  #fileInput1
                  style="display: none;">
                </div>
              </div>
              <div class="col-12 text-end">
                <button
                  style="border: none !important; background-color: white !important;font-size: 14px; font-weight: 500;font-family: poppins,sans-serif;color: #354052; "
                  (click)="modal.dismiss('Cross click')">Cancel</button>
                <button
                [ngClass]="(bulkUploadData.file && getFileType()=='csv') ? 'cursorPointer' : 'cursorNotAllowed' "
                [disabled]="(bulkUploadData.file && getFileType()=='csv') ? false : true"
                class="dialogButtonColored"
                (click)="modal.close('Save click');">Upload CSV</button>
                <button
                [ngClass]="(bulkUploadData.file && getFileType()=='zip')? 'cursorPointer' : 'cursorNotAllowed' "
                [disabled]="(bulkUploadData.file && getFileType()=='zip')  ? false : true"
                class="dialogButtonColored"
                (click)="modal.close('Save click');">Upload ZIP</button>
              </div>
            </div>
            <div class="row" style="margin-top: 20px;">
              <div class="col-12">
                <h4 style="font-size: 16px !important;font-family: poppins,sans-serif  !important;color: #354052;"> GuideLines for bulk upload (image zip) :-</h4>
                <ul style="font-family: poppins,sans-serif  !important;color: #354052;">
                  <li> Firstly, upload the product (csv) file w.r.t which you want to add images & for uploading product please refer to
                    <b style="font-size: 14px;font-weight: 600;">"GuideLines for bulk upload (csv)"</b> </li>
                    <li> Secondly, browse zip file and click on upload zip button to upload the images.</li>
                    <li> <b style="font-size: 14px;font-weight: 600;">Important:</b> Image name should be exactly same as the SKU ID of the product for which that image is being uploaded.</li>
                </ul>
                <h4 style="font-size: 16px !important;font-family: poppins,sans-serif  !important;color: #354052;"> GuideLines for bulk upload (csv) :-</h4>
                <ul style="font-family: poppins,sans-serif  !important;color: #354052;" >
                  <li>Sample file for reference <i class="fa fa-download download m-1" aria-hidden="true"
                      style="font-size: 14px;color:black;cursor: pointer;color: #2877C6" (click)="downLoadSampleData()" > </i></li>
                  <li>All feilds are mandatory </li>
                  <li>Do not interchange the columns </li>
                  <li> <span class="fontWeight600">SKU ID : </span> It's a unique identifier w.r.t the product</li>
                  <li> <span class="fontWeight600">Product Name :</span> Name of the product </li>
                  <li> <span class="fontWeight600">Description :</span> Description of the product</li>
                  <li> <span class="fontWeight600">Ship Solo :</span> Choose whether the product is ship solo or not (Y/N) </li>
                  <li> <span class="fontWeight600">Unit Of Measurement :</span> System of units (metric/imperial) </li>
                  <li> <span class="fontWeight600">Sell in market place:</span> Market place where the product is available (Y/N) </li>
                  <li class="innerLi"> <span class="fontWeight600">Free Shipping Mode :</span> Option to be choosen w.r.t free shipping for the product
                    <ul>
                      <li>QFS (Qualifies for free shipping)</li>
                      <li>EFS (Excludes from free shipping)</li>
                      <li>ASF (Always ship for free)</li>
                    </ul>
                  </li>
                  <li> <span class="fontWeight600">Do not ship to region:</span> Choose the region which are excluded from shipping (Y/N) </li>
                  <li class="innerLi"> <span class="fontWeight600">Measurements :</span> Measurement unit of product(Length,Width,Height,Weight),
                    if there are additional pieces w.r.t product you can add additional column for these measurement as shown in the reference file given above
                    <ul>
                      <li> Length should be greater than 0  </li>
                      <li> Width should be greater than 0 </li>
                      <li> Height should be greater than 0 </li>
                      <li> Weight should be greater than 0 </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </ng-template>
    
        <!-- ********************************************************************************************************************************************************************* -->
    
    
    
      <!-- <div class="marginBottom5px">
        <input type="checkbox" formControlName="doNotShipDomestic" id="doNotShipDomestic"
          name="doNotShipDomestic">
        <label class="labelInsideShippingSetting paddLeft25px">Domestic</label>
      </div>
      <div class="marginBottom5px">
        <input type="checkbox" formControlName="doNotShipManitoba" id="doNotShipManitoba"
          name="doNotShipManitoba">
        <label class="labelInsideShippingSetting paddLeft25px">Manitoba</label>
      </div>
      <div class="marginBottom5px">
        <input type="checkbox" formControlName="doNotShipOntario" id="doNotShipOntario"
          name="doNotShipOntario">
        <label class="labelInsideShippingSetting paddLeft25px">Ontario</label>
      </div>
      <div class="marginBottom5px">
        <input type="checkbox" formControlName="doNotShipUscrossborder" id="doNotShipUscrossborder"
          name="doNotShipUscrossborder">
        <label class="labelInsideShippingSetting paddLeft25px">US-Cross-border</label>
      </div>
      <div>
        <input type="checkbox" formControlName="doNotShipRestworld" id="doNotShipRestworld"
          name="doNotShipRestworld">
        <label class="labelInsideShippingSetting paddLeft25px">Rest of world</label>
      </div> -->
    
      <!-- <div class="customShippingrowStyle">
                          <div style="width: 200px;">
                            <label class="labelInsideShippingSetting" style="padding-left: 20px;">California</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="First Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="Additional Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                        </div>
                        <div class="customShippingrowStyle">
                          <div style="width: 200px;">
                            <label class="labelInsideShippingSetting" style="padding-left: 20px;">Domestic</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="First Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="Additional Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                        </div>
                        <div class="customShippingrowStyle">
                          <div style="width: 200px;">
                            <label class="labelInsideShippingSetting" style="padding-left: 20px;">Manitoba</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="First Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="Additional Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                        </div>
                        <div class="customShippingrowStyle">
                          <div style="width: 200px;">
                            <label class="labelInsideShippingSetting" style="padding-left: 20px;">Ontario</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="First Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="Additional Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                        </div>
                        <div class="customShippingrowStyle">
                          <div style="width: 200px;">
                            <label class="labelInsideShippingSetting" style="padding-left: 20px;">US-Cross-border</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="First Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="Additional Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                        </div>
                        <div class="customShippingrowStyle" style="border-bottom: 1px solid #CED0DA;">
                          <div style="width: 200px;">
                            <label class="labelInsideShippingSetting" style="padding-left: 20px;">Rest of world</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="First Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                          <div class="customSettingInput">
                            <input type="number" class="form-control" placeholder="Additional Quantity" min="0">
                            <label class="labelInsideShippingSetting" style="margin-left: -4rem;">CAD</label>
                          </div>
                        </div> -->
    
    
    