import { Component, OnInit,Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ShipmentListingService } from './../../../services/shipment-listing.service';
import {AddFavoriteConfirmComponent} from '../add-favorite-confirm/add-favorite-confirm.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-favorite',
  templateUrl: './add-favorite.component.html',
  styleUrls: ['./add-favorite.component.css']
})
export class AddFavoriteComponent implements OnInit {
  @Input() public orderId;
  @Input() public quoteId;

  displayLoader:boolean;
  favName="";
  constructor(public activeModal: NgbActiveModal,private modalService: NgbModal,public shipmentListingService:ShipmentListingService) { }

  ngOnInit(): void {
    //console.log('order id here',this.orderId)
  }
  // notshowagain(chk){
  //   if(chk){
  //     localStorage.setItem("fav_"+this.quoteId, "true");
  //   }
  // }
  closeModal() { this.activeModal.close({success : false}); }

  // addModal(){
  //   //this.displayLoader = true;
  //   let inputData = {   
  //     "orderId": this.orderId,
  //     "isFavourite": true
  //   }
  //   this.shipmentListingService.updateItems(inputData).subscribe(result =>{
  //     //this.displayLoader = false;
  //     this.activeModal.close({success : true});
  //   })
  // }
  update(value){
console.log(value);
this.favName=value;
  }

  saveToFavorite() {

    const modalRef = this.modalService.open(AddFavoriteConfirmComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass:"my-custom-modal8"  });
    modalRef.componentInstance.favName = this.favName;
    modalRef.componentInstance.orderId=this.orderId
    modalRef.result.then((result) => { 
      if(result && result.success){
        // this.toast.success('Order marked as Favorite Successfully.', 'Success', {
        //   timeOut: 10000,
        // });
        this.activeModal.close({success : true});
      }
      else{
        this.activeModal.close({success : false});
      }
    });
    
    
  }
}
