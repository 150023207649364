import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InvoicesService } from './../services/invoices.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { ViewInvoiceComponent } from './../../app/_helpers/popup-dialog/invoices/view-invoice/view-invoice.component';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from './../../environments/environment';
import { LoadingService } from '../services/loader.service';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  filterCourier: any;
  perPage: number;
  currentPage: any;
  pageNo = 0;
  displayLoader: boolean;
  totalElements: any;
  totalPages: any;
  paginationSize: number;
  invoiceItems: any;
  page = 0;
  queryStringParam: any;
  queryParam: any;
  searchInvoice = '';
  searchWorkOrder = '';
  invoiceDetails: any;
  noteText: any;
  startDate: any;
  endDate: any
  dateFilterForm: FormGroup;
  cYear: any;
  status: String;
  dateParams: String;
  constructor(private formBuilder: FormBuilder, private modalService: NgbModal, public config: NgbPaginationConfig, private calendar: NgbCalendar, public formatter: NgbDateParserFormatter, public invoicesService: InvoicesService, private toast: ToastrService, private router: Router,  private  _loaderService: LoadingService) {
    this.config.size = 'sm';
    this.config.ellipses = true;
  }

  ngOnInit(): void {
    this.cYear = environment.Cyear;
    this.dateFilterForm = this.formBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', Validators.required],
    })
    this.noteText = '';
    this.queryStringParam = '';
    this.filterCourier = "";
    this.status = '';
    this.dateParams = '';
    this.currentPage = 0;
    this.perPage = 10;
    this._loaderService.stringSubject.next(true);
    this.getInvoiceListing(this.pageNo, this.filterCourier, this.perPage);
  }

  notes(event) {
    this.noteText = event.target.innerHtml;
  }
  getInvoiceListing(page, filterPrams, items) {
    this.displayLoader = true;
   
    this.invoicesService.getInvoiceListing(page, filterPrams, this.status ).subscribe(result => {
      if (result) {
        this._loaderService.stringSubject.next(false);
      }
      // console.log('getInvoiceListing here',result)
      if (result['totalPages'] <= 0) {
        this.invoiceItems = []
        this.displayLoader = false;
        // this.toast.error('No data found.', '', {
        //   timeOut: 10000,
        // });
        return;
      } else {
        this.displayLoader = true;
        this.totalElements = result['totalEntries'];
        this.invoiceItems = result['response'];
        this.totalPages = result['totalPages'];
        this.paginationSize = result['totalPages'];
        this.displayLoader = false;
      }

    }, error => {
      this._loaderService.stringSubject.next(false);
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.displayLoader = false;
      this.toast.error('Something went wrong.', '', {
        timeOut: 10000,
      });
    });
  }

  perPageItem(event) {
    this.displayLoader = true;
    const items = event.target.value;
    if (items != '') {
      this.perPage = items;
      this.getInvoiceListing(this.page, this.queryStringParam, this.perPage);
    }
  }



  selectPage(i) {
    this.displayLoader = true;
    this.currentPage = i.page - 1;
    this.invoicesService.getInvoiceListing(i.page - 1, this.queryStringParam, this.status ).subscribe(a => {
      // console.log('hi',a)
      if (a["response"].empty) {
        this.invoiceItems = [];
        this.displayLoader = false;
        // this.toast.error('No data found.', '', {
        //   timeOut: 10000,
        // });
        return;
      }
      this.totalPages = a['totalPages'];
      this.totalElements = a['totalEntries'];
      this.invoiceItems = a['response'];
      this.displayLoader = false;
    }, error => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.displayLoader = false;
      this.toast.error('Something went wrong.', '', {
        timeOut: 10000,
      });
    })

  }

  private dateToString = (date) => `${date.year}-${date.month}-${date.day}`;


  filterByValue(event) {
    this.queryParam = event.target.value;
  }

  viewDetails(invoiceData) {
    console.log(invoiceData)
    // this.router.navigate(['/invoices/invoice-details/'+invoiceData['orderId']])
  }

  pdfPreview(invoiceData) {
    console.log('pdf preview here', invoiceData)
    // this.displayLoader = true;
    // this.invoicesService.getInvoiceDetails(invoiceData.orderId).subscribe(result => {
    //   this.invoiceDetails = result;
    //   this.displayLoader = false;
    //   const modalRef = this.modalService.open(ViewInvoiceComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal0' });
    //   modalRef.componentInstance.data = this.invoiceDetails;
    //   modalRef.result.then((result) => {
    //   });
    // }, error => {
    //   if (error['status'] === 401) {
    //     localStorage.clear();
    //     this.router.navigate(['/signin'])
    //   }
    //   this.displayLoader = false;
    //   this.toast.error('Something went wrong.', '', {
    //     timeOut: 10000,
    //   });
    // });
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  applyFilter() {
    this.startDate = this.dateFilterForm['value'].startDate;
    this.endDate = this.dateFilterForm['value'].endDate;
    let d1 = Date.parse(this.dateFilterForm['value'].startDate);
    let d2 = Date.parse(this.dateFilterForm['value'].endDate);
    // console.log(this.startDate,this.endDate)

    if ((this.startDate && this.endDate) && (d1 > d2)) {
      this.startDate = null;
      this.endDate = null;
      this.dateFilterForm.reset();
      this.toast.error('Please provide valid Dates.', '', {
        timeOut: 10000,
      });
      return;
    }
    let querStringParam = 'startDate=' + this.startDate + "&endDate=" + this.endDate + (this.searchInvoice ? '&searchKey=' + this.searchInvoice : '') + (this.searchWorkOrder ? '&' + this.searchWorkOrder : '') + (this.queryParam ? '&status=' + this.queryParam : '');
    this.getInvoiceListing(0, querStringParam, 10);
  }


  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

}
