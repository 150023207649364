import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class QuickBolService {

  baseUrl: string = environment.apiPath;
  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };
  
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  // Start - Create/Edit/View Quick BOL APIs
  fetchQuickBolFormData(extBolId?){
    let url: any;
    if(extBolId){
      url = this.baseUrl + "/api/sc/admin/ext_bol/getById?bolId=" + extBolId; //to fetch the complete data of quick bol
    }else{
      url = this.baseUrl + "/api/sc/admin/ext_bol/getById"; //to fetch only freightChargeTermList
    }
    return this.http.get(url, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }, error=> {
      return error;
    }));
  }

  saveUpdateFn(dataObj, type){
    let url: any;

    // Below is the case, when there is only 1 url
    url = this.baseUrl + '/api/sc/admin/ext_bol/save';

    // Below is the case, when there are 2 diff urls according to the type create/update
    // if(type == 'create'){
    //   url = this.baseUrl + '/sc/save';
    // }
    // else if(type == 'update'){
    //   url = this.baseUrl + '/sc/edit';
    // }

    return this.http.post(url, dataObj, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }, error=> {
      return error;
    }));
  }
  // End - Create/Edit/View Quick BOL APIs


  // Start - List of BOLs APIs
  getBolListWithFilter(page,search,startDate,endDate,items):any{
    // let dateParams = 'startDate=' + startDate + '&endDate=' + endDate;
    const queryParamsObj:any={};
    if((startDate && !endDate)){
      // console.log('End Date is invalid.');
      this.toastr.error('End Date is invalid in search.','',{timeOut:3000})
    } else if (!startDate && endDate){
      // console.log('Start Date is invalid.');
      this.toastr.error('Start Date is invalid in search.','',{timeOut:3000})
    } else {
      if(search && startDate && endDate){
        queryParamsObj.searchKey = search,
        queryParamsObj.startDate = startDate,
        queryParamsObj.endDate = endDate
      } else if (search && !startDate && !endDate){
        queryParamsObj.searchKey = search
      } else if (!search && startDate && endDate){
        queryParamsObj.startDate = startDate,
        queryParamsObj.endDate = endDate
      }

      // console.log(queryParamsObj);
      const params = new URLSearchParams();
      for (const key in queryParamsObj) {
        if (queryParamsObj.hasOwnProperty(key)) {
          params.set(key, queryParamsObj[key]);
        }
      }

      const queryString = params.toString();

      return this.http.get(
        // this.baseUrl+"/api/sc/admin/ext_bol/getAll/"+page+"/"+items +(search ? '?searchKey='+search :"")+(startDate ? "?startDate="+startDate :"")+(endDate ? "&endDate="+endDate :""), this.jsonHTTPOptions
          this.baseUrl+"/api/sc/admin/ext_bol/getAll/"+page+"/"+items +(queryString != '' ? '?'+queryString : ''), this.jsonHTTPOptions
        ).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));
    }
  }
  // End - List of BOLs APIs


  // Start - Documents of Quick BOL

  getInvoiceDetails(bolId){  
        return this.http.get(environment.apiPath + "/api/sc/admin/ext_bol/getById?bolId="+bolId, { withCredentials: true }).pipe(map(response=>{
          return response;
        }));  
      }
  // End - Documents of Quick BOL

// Start - Documents of Commercial Invoice
  getCommercialInvoiceData(extCommercialInvoiceId,extension){   //598d1f08-c39e-4a4c-b3dc-75ee3ddaedd9
    return this.http.get(environment.apiPath+'/api/invoices/commercial/getById?extCommercialInvoiceId='+extCommercialInvoiceId+'&actionType='+extension,{ withCredentials:true}).pipe(map(response=>{
      return response;
    }))
  }

  // End - Documents of Commercial Invoice
}
