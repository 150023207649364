<!-- <div class="container">
    <div class="row">
        <div class="col1">
            <app-leftsidebar></app-leftsidebar>
        </div>

        <div class="col11"> -->
            <div class="page_heading_fixed_strip">
                <h3 class="page_heading col-8"><img width="18" src="assets/img/reports/reports.svg" /> Reports</h3>
                
            </div>
            <br>
            <div class="row mt-5 pt-2 pl-5 pr-5 ml-1 mr-2 mb-0">
                <div class="card  mt-5 p-1">
                    <div class="card-body p-0">
                        <div class="row mb-0 p-2">
                            <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                            <span class="col-8 overview">
                                Recent Quotes  <a class="view-more" class="blue-text" >View More</a>
                            <br>
                            <small class="overview-sub">Table list of current recent quotes</small>
                            </span>
                            <span class="col-1 text-right show">Show</span>
                            <div class="selectdiv  col-1">
                                <select class="form-control classic" width="70%" > 
                                    <option value="5" selected>5 Items</option>
                                    <option value="15">15 Items</option>
                                    <option value="25">25 Items</option>
                             </select>
                                
                            </div>
                
                            <div class="btn get-quote-btn ml-5">Get Quote</div>
                            <img src="assets/img/dashboard/Dotthree.svg" style="right: -25px;" class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                
                               
                
                            </div>
                        </div>
                        <div class="row shipment_history_table mb-0">
                            <div class="row mt-3 mr-3 pl-4 pr-1">
                               
                                <table id="Shipments" class="table table-responsive">
                                    <thead>
                
                                        <tr>
                                                            <th scope="col-2">
                                                                <span class="down">Order No.</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">PO No.</span>
                                                            </th>
                                                            
                                                            <th scope="col-2" >
                                                                <span class="down">Pickup Date </span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Shipper</span>
                                                            </th>
                                                            <th scope="col-2">
                                                                <span class="down">Origin </span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Consignee</span>
                                                            </th>
                                                            <th scope="col-2">
                                                                <span class="down">Destination</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Quote</span>
                                                            </th>
                                                            
                
                                                            <th scope="col-2">
                                                                <span >Actions</span>
                                                            </th>
                                                            
                                                        </tr>
                 
                                       
                                    </thead>
                                    <tbody class="pt-3">
                
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td>PO#</td>
                                            <td>07/29/2020</td>
                                            <td>Contentiental Carbon Group</td>
                                            <td>Toronto, Ontorio</td>
                                            <td>TCS - Jocobs</td>
                                            <td>
                                                Johnson City, Tenesso
                                            </td>
                                            <td>
                                               $108.60
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;"   />&nbsp;
                                                <span  class="badge badge-success">Quoted</span>
                                            </td>
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td>PO#</td>
                                            <td>07/29/2020</td>
                                            <td>Contentiental Carbon Group</td>
                                            <td>Toronto, Ontorio</td>
                                            <td>TCS - Jocobs</td>
                                            <td>
                                                Johnson City, Tenesso
                                            </td>
                                            <td>
                                               $108.60
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;"   />&nbsp;
                                                <span  class="badge badge-success">Quoted</span>
                                            </td>
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                          <tr >
                                                <td class="order_no" >5545452</td>
                                                <td>PO#</td>
                                                <td>07/29/2020</td>
                                                <td>Contentiental Carbon Group</td>
                                                <td>Toronto, Ontorio</td>
                                                <td>TCS - Jocobs</td>
                                                <td>
                                                    Johnson City, Tenesso
                                                </td>
                                                <td>
                                                   $108.60
                                                    <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;"   />&nbsp;
                                                    <span  class="badge badge-success">Quoted</span>
                                                </td>
                                                <td class="action_td ">
                                                    <div class="dropdown-toggle" data-toggle="dropdown">
                                                        <img src="assets/img/quotes/hor_3_dots.png" />
                                                    </div>
                                                    <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                        
                                           

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1 pt-1 pl-4 pr-3 ml-4  mb-0">
                <div class="card  col-4 m-1 p-2" >
                    <div class="card-body ">
                        <div class="row">
                            <img src="assets/img/dashboard/Dotsix.svg" class="dotSix" >
                            <span class="col overview">
                                Quotations
                                <br>
                                <small class="overview-sub" >How
                                    many quotes created per week</small>
                            </span>
                            <img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                <a class="dropdown-item" style="cursor: pointer" >Export
                                    Excel</a>
                                
                            </div>
                        </div>
                        <img src="assets/img/dashboard/envelop.svg" style="width: 50px;" class="ml-5">
                        <span class="card-price">100</span>

                        <small style="font-weight: 500;color: rgb(167, 166, 166);">
                            <span
                                class=""><img
                                    src="assets/img/dashboard/up.svg"
                                    style="width: 10px;">
                                &nbsp;
                            </span>
                            <span>1.2% Than last week</span>
                        </small>
                    </div>
                </div>

                <div class="card  col-4 m-1 p-2" >
                    <div class="card-body ">
                        <div class="row">
                            <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                            <span class="col overview">
                                Shipment Per Week
                                <br>
                                <small class="overview-sub">How
                                    many shipments were created per week</small>
                            </span>
                            <img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                <a class="dropdown-item" style="cursor: pointer" >Export
                                    Excel</a>
                               
                            </div>
                        </div>
                        <img src="assets/img/dashboard/shipment-per-week.svg" style="width: 50px;" class="ml-5">
                        <span class="card-price">1000</span>

                        <small style="font-weight: 500;color: rgb(167, 166, 166);">
                            <span
                                class=""><img
                                    src="assets/img/dashboard/up.svg"
                                    style="width: 10px;" class="">
                                &nbsp;
                            </span>
                            <span>1.2% Than last week</span>
                        </small>
                    </div>
                </div>

                <div class="card  col-4 m-1 p-2">

                    <div class="card-body ">
                        <div class="row">
                            <img src="assets/img/dashboard/Dotsix.svg"  class="dotSix">
                            <span class="col overview">
                                Invoice Created
                                <br>
                                <small class="overview-sub">How
                                    many
                                    invoices created per week</small>
                            </span>
                            <img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                <!-- <a class="dropdown-item" style="cursor: pointer" >Export Excel</a> -->
                                
                            </div>

                        </div>
                        <img src="assets/img/dashboard/invoice-created.svg" style="width: 50px;" class="ml-5">
                        <span class="card-price">100</span>

                        <small style="font-weight: 500;color: rgb(167, 166, 166);">
                            <span
                                class="">
                                <img src="assets/img/dashboard/up.svg"
                                    style="width: 10px;" class=""> &nbsp;</span>
                            <span>1.2 Than last week</span></small>
                    </div>

                </div>
            </div>
            <div class="row pl-5 pr-5 ml-1 mr-2 mb-0">
                <div class="card  mt-3 p-1">
                    <div class="card-body p-0">
                        <div class="row mb-0 p-2">
                            <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                            <span class="col-8 overview">
                                Recent Customer Invoices  <a class="blue-text view-more" >View More</a>
                            <br>
                            <small class="overview-sub">Table list of current customer invoices</small>
                            </span>
                            <span class="col-1 text-right show" >Show</span>
                            <div class="selectdiv  col-1">
                                <select class="form-control classic" width="70%" > 
                                    <option value="5" selected>5 Items</option>
                                    <option value="15">15 Items</option>
                                    <option value="25">25 Items</option>
                             </select>
                                
                            </div>
                
                            <div class="btn pay-now-btn ml-5">Pay Now</div>
                            <img src="assets/img/dashboard/Dotthree.svg" style="width: 27px;
                                top: -5px;
                                right: -25px;
                                position: relative;
                                cursor: pointer;" class="dropdown-toggle col-1" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                
                               
                
                            </div>
                        </div>
                        <div class="row shipment_history_table mb-0">
                            <div class="row mt-3 mr-3 pl-4 pr-1">
                               
                                <table id="Shipments" class="table table-responsive">
                                    <thead>
                
                                        <tr>
                                                            <th scope="col-2">
                                                                <span class="down">Invoice No.</span>
                                                            </th>
                                                            <th scope="col-2">
                                                                <span class="down">Order No.</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Date</span>
                                                            </th>
                                                            
                                                            <th scope="col-2" >
                                                                <span class="down">Currency</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Status</span>
                                                            </th>
                                                            <th scope="col-2">
                                                                <span class="down">Original Amount </span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Paid Amount</span>
                                                            </th>
                                                            <th scope="col-2">
                                                                <span class="down">Balance Due</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Notes</span>
                                                            </th>
                                                            
                
                                                            <th scope="col-2">
                                                                <span >Actions</span>
                                                            </th>
                                                            
                                                        </tr>
                 
                                       
                                    </thead>
                                    <tbody class="pt-3">
                
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td>3 Orders</td>
                                            
                                            <td>07/29/2020</td>
                                            <td>CAD</td>
                                            <td><span  class="badge badge-success">Paid</span></td>
                                            <td>$ 87.73</td>
                                            <td>$ 87.73</td>
                                            <td>
                                                $00.0
                                            </td>
                                            <td>
                                              --
                                            </td>
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td>3 Orders</td>
                                            
                                            <td>07/29/2020</td>
                                            <td>CAD</td>
                                            <td><span  class="badge badge-warning">Partial Paid</span></td>
                                            <td>$ 87.73</td>
                                            <td>$ 87.73</td>
                                            <td>
                                                $00.0
                                            </td>
                                            <td>
                                                <img src="assets/img/reports/notes.svg" />
                                            </td>
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td>3 Orders</td>
                                            
                                            <td>07/29/2020</td>
                                            <td>CAD</td>
                                            <td><span  class="badge badge-danger">Unpaid</span></td>
                                            <td>$ 87.73</td>
                                            <td>$ 87.73</td>
                                            <td>
                                                $00.0
                                            </td>
                                            <td>
                                              --
                                            </td>
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pl-5 pr-5 ml-1 mr-2 mb-0">
                <div class="card  mt-3 p-1">
                    <div class="card-body p-0">
                        <div class="row mb-0 p-2">
                            <img src="assets/img/dashboard/Dotsix.svg"  class="dotSix">
                            <span class="col-8 overview" style="margin-top: -3px;">
                                Shipments  <a style="font-size: 11px; cursor: pointer;" class="blue-text" >View More</a>
                            <br>
                            <small class="overview-sub">Table list of current shipment in-transit or delivered</small>
                            </span>
                            <span class="col-1 text-right show" >Show</span>
                            <div class="selectdiv  col-1">
                                <select class="form-control classic" width="70%" > 
                                    <option value="5" selected>5 Items</option>
                                    <option value="15">15 Items</option>
                                    <option value="25">25 Items</option>
                             </select>
                                
                            </div>
                
                            <div class="btn get-quote-btn ml-5">Get Quote</div>
                            <img src="assets/img/dashboard/Dotthree.svg" class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                
                               
                
                            </div>
                        </div>
                        <div class="row shipment_history_table mb-0">
                            <div class="row mt-3 mr-3 pl-4 pr-1">
                               
                                <table id="Shipments" class="table table-responsive">
                                    <thead>
                
                                        <tr>
                                                            <th scope="col-2">
                                                                <span class="down">Order No.</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Pro No.</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">PO No.</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Service </span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Pickup Date </span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Shipper</span>
                                                            </th>
                                                           
                                                            <th scope="col-2" >
                                                                <span class="down">Consignee</span>
                                                            </th>
                                                            
                                                            <th scope="col-2" >
                                                                <span class="down">Quote</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Status</span>
                                                            </th>
                
                                                            <th scope="col-2">
                                                                <span >Actions</span>
                                                            </th>
                                                            
                                                        </tr>
                 
                                       
                                    </thead>
                                    <tbody class="pt-3">
                
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td  >5545452</td>
                                            <td>PO#</td>
                                            <td>07/29/2020</td>
                                            <td>Contentiental Carbon Group</td>
                                            <td>Toronto, Ontorio</td>
                                            <td>TCS - Jocobs</td>
                                            <td>
                                                $108.60
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;"   />
                                            </td>
                                            <td>
                                              &nbsp;
                                                <span  class="badge badge-success">Delivered</span>
                                            </td>
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td  >5545452</td>
                                            <td>PO#</td>
                                            <td>07/29/2020</td>
                                            <td>Contentiental Carbon Group</td>
                                            <td>Toronto, Ontorio</td>
                                            <td>TCS - Jocobs</td>
                                            <td>
                                                $108.60
                                                <img src="assets/img/quotes/down_icon.svg" style="cursor: pointer;"   />
                                            </td>
                                            <td>
                                              &nbsp;
                                                <span  class="badge badge-success">Delivered</span>
                                            </td>
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                           

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-1 pt-1 pl-5 pr-5 ml-1 mr-2 mb-0">
               
                    <div class=" card col-6 m-2 p-2">
                        <div class="card-body p-1">
                            <div class="row">
                                <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                                <span class="col overview d-block">
                                    All Shipments
                                    
                                    <small class="d-block overview-sub" >All
                                        shipments by countries and trans-border </small>

                                </span><img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                                <div class="dropdown-menu threedot dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                    <a class="dropdown-item" style="cursor: pointer" >Export Excel</a>
                                  

                                </div>
                                <div id="columnchart_material"></div>
                            </div>
                        </div>
                        <div class="card-footer bg-white p-1">
                            <p class="m-0 grey-text" style="font-size: 10px;">Average transit times</p>
                            <h3 class="m-0"></h3>
                        </div>
                    </div>
                <div class="card col-6 m-2 p-2">
                        <div class="card-body p-1">
                            <div class="row">
                                <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                                <span class="col-5 overview d-block">
                                    Your Top Carriers
                                    
                                    <small class="d-block overview-sub" >List of your top
                                        carriers</small>

                                </span>
                                
                                <span class="col-2 text-right show" >Show</span>
                                <div class="selectdiv col-3">
                                    <select class="form-control classic" width="70%" >
                                        <option>last six months</option>
                                        <option>15 Items</option>
                                        <option>25 Items</option>
                                    </select>

                                </div>

                                <img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                                <div class="dropdown-menu threedot dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                    <a class="dropdown-item" style="cursor: pointer" >Export Excel</a>
                                   

                                </div>
                                <div class="col-12">
                                    <div class="donutCell">
                                        <div id="donut_single" class="donutDiv"></div>
                                        <div id="labelOverlay">
                                            <p class="used-size"></p>
                                            <span></span>
                                        </div>
                                        <div class="centerLabel" style="display: none;">1.2k</div>
                                        <div class="centerLabel2" style="display: none;">Shipments</div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer bg-white p-1">
                            <div class="row m-0">
                                <div class="col-1 p-0">
                                    <img src="" width="45" class="m-1">
                                </div>
                                <div class="col-5">
                                    <p class="m-0 grey-text" style="font-size: 10px;">Average Shipment Size and Weight
                                    </p>
                                    <!--<h3 class="m-0">10,000 Shipment 100 LBS</h3>-->
                                    <h3 class="m-0"> Shipment  LBS</h3>
                                </div>
                                <div class="col-6">
                                    <p class="m-0 grey-text">Revenues Saved</p>
                                    <h3 class="m-0">$</h3>
                                </div>
                            </div>

                        </div>
                    </div>
               

            </div>
            <div class="row pl-5 pr-5 ml-1 mr-2 mb-0">
                <div class="card  mt-3 p-1">
                    <div class="card-body p-0">
                        <div class="row mb-0 p-2">
                            <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                            <span class="col-6 overview" >
                                Recent Quick Commercial Invoices  <a  class="blue-text view-more" >View More</a>
                            <br>
                            <small class="overviw-sub">Table list of created quick commercial invoices</small>
                            </span>
                            <span class="col-2 text-right show" >Show</span>
                            <div class="selectdiv  col-1">
                                <select class="form-control classic" width="70%" > 
                                    <option value="5" selected>5 Items</option>
                                    <option value="15">15 Items</option>
                                    <option value="25">25 Items</option>
                             </select>
                                
                            </div>
                
                            <div class="btn commercial-invoice-btn ml-5">Create Comercial Invoice</div>
                            <img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                
                               
                
                            </div>
                        </div>
                        <div class="row shipment_history_table mb-0">
                            <div class="row mt-3 mr-3 pl-4 pr-1">
                                
                                <table id="Shipments" class="table table-responsive">
                                    <thead>
                
                                        <tr>
                                                            <th scope="col-2">
                                                                <span class="down">Shipment Date</span>
                                                            </th>
                                                            <th scope="col-2">
                                                                <span class="down">Shipper</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Consignee</span>
                                                            </th>
                                                            
                                                            <th scope="col-2" >
                                                                <span class="down">Description</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Value</span>
                                                            </th>
                                                            <th scope="col-2">
                                                                <span class="down">Currency</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Country of Origin</span>
                                                            </th>
                                                            <th></th>
                                                            
                                                        </tr>
                 
                                       
                                    </thead>
                                    <tbody class="pt-3">
                
                                        <tr >
                                            <td >29/07/2020</td>
                                            <td>Contentiental Carbon Group</td>
                                           
                                            <td>TCS - Jocobs</td>
                                            <td>Some description goes here</td>
                                            <td>$ 87.73</td>
                                            <td>CAD</td>
                                            <td>
                                                <img src="assets/img/reports/country.svg" /> Canada
                                            </td>
                                           
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td >29/07/2020</td>
                                            <td>Contentiental Carbon Group</td>
                                           
                                            <td>TCS - Jocobs</td>
                                            <td>Some description goes here</td>
                                            <td>$ 87.73</td>
                                            <td>CAD</td>
                                            <td>
                                                <img src="assets/img/reports/country.svg" /> Canada
                                            </td>
                                           
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pl-5 pr-5 ml-1 mr-2 mb-0">
                <div class="card  mt-3 p-1">
                    <div class="card-body p-0">
                        <div class="row mb-0 p-2">
                            <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                            <span class="col-8 overview" >
                                Recent Quick BOL  <a  class="blue-text view-more" >View More</a>
                            <br>
                            <small class="overview-sub">Table list of current Quick BOL's made</small>
                            </span>
                            <span class="col-1 text-right show" >Show</span>
                            <div class="selectdiv  col-1">
                                <select class="form-control classic" width="70%" > 
                                    <option value="5" selected>5 Items</option>
                                    <option value="15">15 Items</option>
                                    <option value="25">25 Items</option>
                             </select>
                                
                            </div>
                
                            <div class="btn get-quote-btn ml-5">New Quick BOL</div>
                            <img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                
                               
                
                            </div>
                        </div>
                        <div class="row shipment_history_table mb-0">
                            <div class="row mt-3 mr-3 pl-4 pr-1">
                                
                                <table id="Shipments" class="table table-responsive">
                                    <thead>
                
                                        <tr>
                                                            <th scope="col-2">
                                                                <span class="down">BOL No.</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Date Created</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Pickup Date</span>
                                                            </th>
                                                          
                                                            <th scope="col-2" >
                                                                <span class="down">Shipper</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Origin</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Consignee</span>
                                                            </th>
                                                            
                                                            <th scope="col-2" >
                                                                <span class="down">Destination</span>
                                                            </th>
                                                            <th scope="col-2" >
                                                                <span class="down">Carrier</span>
                                                            </th>
                
                                                            <th scope="col-2">
                                                                <span >Actions</span>
                                                            </th>
                                                            
                                                        </tr>
                 
                                       
                                    </thead>
                                    <tbody class="pt-3">
                
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td  >07/29/2020</td>
                                            <td>07/29/2020</td>
                                            <td>Contentiental Carbon Group</td>
                                            <td>Toronto, Ontorio</td>
                                            <td>TCS - Jocobs</td>
                                            <td>Johnson City, Tennesse</td>
                                            
                                            
                                            <td>
                                                <img src="assets/img/reports/carrier.svg" /> XPO
                                            </td>
                                            
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <tr >
                                            <td class="order_no" >5545452</td>
                                            <td  >07/29/2020</td>
                                            <td>07/29/2020</td>
                                            <td>Contentiental Carbon Group</td>
                                            <td>Toronto, Ontorio</td>
                                            <td>TCS - Jocobs</td>
                                            <td>Johnson City, Tennesse</td>
                                            
                                            
                                            <td>
                                                <img src="assets/img/reports/carrier.svg" /> XPO
                                            </td>
                                            
                                            <td class="action_td ">
                                                <div class="dropdown-toggle" data-toggle="dropdown">
                                                    <img src="assets/img/quotes/hor_3_dots.png" />
                                                </div>
                                                <div class="dropdown-menu dashboard" aria-labelledby="dropdownMenuButton" multiple>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                           

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1 pt-1 pl-4 pr-5 ml-4  mr-2 mb-0">
                
                    <div class=" card col-6 m-2 p-2">
                        <div class="card-body p-1">
                            <div class="row">
                                <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                                <span class="col overview d-block">
                                    Shipments Per Month
                                    
                                    <small class="d-block overview-sub" >How
                                        many Shipments per Month</small>

                                </span>
                                <img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                                <div class="dropdown-menu threedot gridster-item-content dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                    <!-- <a class="dropdown-item" style="cursor: pointer">Export Excel</a> -->
                                 

                                </div>
                                <div id="chart_div" style="top:0px;
                                position: relative;"></div>
                            </div>
                        </div>
                    </div>
                
                    <div class=" card col-6 m-2 p-2">
                        <div class="card-body p-1">
                            <div class="row">
                                <img src="assets/img/dashboard/Dotsix.svg" style="width: 37px;" class="">
                                <span class="col overview">
                                    Spending Per Month
                                    <br />
                                    <small style="font-weight: 500;color: rgb(167, 166, 166);font-size: 10px;">How
                                        much spending per month and average per shipment</small>
                                </span><img src="assets/img/dashboard/Dotthree.svg" style="width: 27px;
                                top: -3px;
                                right: -5px;
                                position: relative;
                                cursor: pointer;" class="dropdown-toggle col-1" data-toggle="dropdown">
                                <div class="dropdown-menu threedot dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                    <!-- <a class="dropdown-item" style="cursor: pointer" >Export Excel</a> -->
                                   

                                </div>

                            </div>
                            <div class="row mb-0">
                                <div class="col-md-6">
                                    <h3 class=" m-0">$  </h3>
                                    <span class="bullet"></span>
                                    <span>Spending per Month</span>
                                </div>
                                <div class="col-md-6">

                                    <h3 class="m-0">$ 
                                    </h3>
                                    <span class="AvrageBullet"></span>
                                    <span>Average per Shipment</span>
                                </div>
                            </div>
                            <div class="row mb-0">
                                <div class="col-md-12">
                                    <div id="combo_chart_div" style="
                                position: relative;"></div>
                                </div>
                            </div>

                        </div>
                    </div>
                
            </div>

            <div class="row mt-1 pt-1 pl-4 pr-5 ml-4  mr-2 mb-0">
                
                <div class=" card col-6 m-2 p-2">
                    <div class="card-body p-1">
                        <div class="row">
                            <img src="assets/img/dashboard/Dotsix.svg" style="width: 37px;" class="">
                            <span class="col overview d-block">
                                Service Type
                                
                                <small class="d-block" style="font-weight: 500;color: rgb(167, 166, 166);font-size: 10px;">
                                    Shipments shipped by service types</small>

                            </span>
                            <img src="assets/img/dashboard/Dotthree.svg" style="width: 27px; cursor: pointer;" class="dropdown-toggle col-1" data-toggle="dropdown">
                            <div class="dropdown-menu threedot gridster-item-content dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                <a class="dropdown-item" style="cursor: pointer">Export Excel</a>
                                <!--<div class="dropdown-divider"></div>//-->
                                <!--<a class="dropdown-item">Export Report</a>//-->
                                <!--<div class="dropdown-divider"></div>//-->
                                <!--<a class="dropdown-item red-text">Remove Report</a>//-->

                            </div>
                            <div id="chart_div" style="top:0px;
                            position: relative;"></div>
                        </div>
                    </div>
                </div>
            
                <div class=" card col-6 m-2 p-2">
                    <div class="card-body p-1">
                        <div class="row">
                            <img src="assets/img/dashboard/Dotsix.svg" style="width: 37px;" class="">
                            <span class="col overview">
                                Regions of Shipping
                                <br />
                                <small style="font-weight: 500;color: rgb(167, 166, 166);font-size: 10px;">
                                    Shipments by regions
                                </small>
                            </span><img src="assets/img/dashboard/Dotthree.svg" style="width: 27px;
                            top: -3px;
                            right: -5px;
                            position: relative;
                            cursor: pointer;" class="dropdown-toggle col-1" data-toggle="dropdown">
                            <div class="dropdown-menu threedot dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                <a class="dropdown-item" style="cursor: pointer" >Export Excel</a>
                                <!--<div class="dropdown-divider"></div>//-->
                                <!--<a class="dropdown-item">Export Report</a>//-->
                                <!--<div class="dropdown-divider"></div>//-->
                                <!--<a class="dropdown-item red-text">Remove Report</a>//-->

                            </div>

                        </div>
                       
                        <div class="row mb-0">
                            <div class="col-md-12">
                                <div id="combo_chart_div" style="
                            position: relative;"></div>
                            </div>
                        </div>

                    </div>
                </div>
            
            </div>

            <div class="row mt-1 pt-1 pl-4 pr-5 ml-4  mr-2 mb-0">
                
                <div class="card  col-4 m-1 p-2" >
                    <div class="card-body ">
                        <div class="row">
                            <img src="assets/img/dashboard/Dotsix.svg" class="dotSix" >
                            <span class="col overview">
                                Orders Created
                                <br>
                                <small class="overview-sub" >How
                                    many orders were created by week</small>
                            </span>
                            <img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot" aria-labelledby="dropdownMenuButton" multiple>
                                <a class="dropdown-item" style="cursor: pointer" >Export
                                    Excel</a>
                                
                            </div>
                        </div>
                        <img src="assets/img/reports/orders-created.svg" style="width: 50px;" class="ml-5">
                        <span class="card-price">100</span>

                        <small style="font-weight: 500;color: rgb(167, 166, 166);">
                            <span
                                class=""><img
                                    src="assets/img/dashboard/up.svg"
                                    style="width: 10px;">
                                &nbsp;
                            </span>
                            <span>1.2% Than last week</span>
                        </small>
                    </div>
                </div>
            
                <div class=" card col-8 m-2 p-2">
                    <div class="card-body p-1">
                        <div class="row">
                            <img src="assets/img/dashboard/Dotsix.svg" class="dotSix">
                            <span class="col overview">
                                Deliveries
                                <br />
                                <small class="overview-sub">
                                   Delivery accuracy percentage
                                </small>
                            </span><img src="assets/img/dashboard/Dotthree.svg"  class="dropdown-toggle col-1 dotThree" data-toggle="dropdown">
                            <div class="dropdown-menu threedot dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                <!-- <a class="dropdown-item" style="cursor: pointer" >Export Excel</a> -->
                              

                            </div>

                        </div>
                       
                        <div class="row mb-0">
                            <div class="col-md-12">
                                <div id="combo_chart_div" style="
                            position: relative;"></div>
                            </div>
                        </div>

                    </div>
                </div>
            
            </div>
        <!-- </div>
    </div>
</div> -->
