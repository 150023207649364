import { Component, OnInit,Input } from '@angular/core';
import { FormGroup,FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-commercial-invoice-signature',
  templateUrl: './commercial-invoice-signature.component.html',
  styleUrls: ['./commercial-invoice-signature.component.css']
})
export class CommercialInvoiceSignatureComponent implements OnInit {
  sign:any;
  @Input() public data;
  @Input() public action;
  commercialInvoiceForm:FormGroup;
  constructor(public activeModal: NgbActiveModal,public fb:FormBuilder) {
   }

  ngOnInit(): void {
    this.commercialInvoiceForm = this.fb.group({
      signByName:[null,[Validators.required]],
      commercial:[true,[Validators.required]]
    })
    this.commercialInvoiceForm.controls["signByName"].setValue("Jhon Doe");
    this.sign = "Jhon Doe";
    if(this.action ==  'edit'){
      this.commercialInvoiceForm['controls']['signByName'].setValue(this.data.signByName);
      if(this.data.signByRole[0] == 'Owner'){
        this.commercialInvoiceForm['controls']['commercial'].setValue(true);
      }else{
        this.commercialInvoiceForm['controls']['commercial'].setValue(false);
      }
    }

  }

  clearField(){
    this.commercialInvoiceForm['controls']['signByName'].reset()
    

  }

  submit(formData){
    this.activeModal.close({formData})    
  }

  closeModal() { 
    this.activeModal.close(); 
  }

  updateSignPreview(sign){
    this.sign = sign;
  }
}
