import { Component, OnInit } from '@angular/core';
import { NgbModal ,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-commercial-invoice-confirmation',
  templateUrl: './commercial-invoice-confirmation.component.html',
  styleUrls: ['./commercial-invoice-confirmation.component.css']
})
export class CommercialInvoiceConfirmationComponent implements OnInit {

  constructor(private ser:NgbModal,public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  
  close(){
    // this.ser.dismissAll();
    this.activeModal.close({result :false});
  }

  confirmPopup(){
    this.activeModal.close({result :true});
  }
}
