<div class="save_fab save_conf">
    <div class="row" style="margin-top: 30px; ">
        <div class="col-12 text-center">
            <!-- <h5 style="margin-top: 5px !important; font-size: 18px !important; font-weight: 600 !important; font-family: poppins,sans-serif !important;">BOOK image</h5> -->
            <img style="padding-top: 10px;" src="assets/img/shipment/COntract2.svg" alt="">
        </div>

    </div>
    <div class="row">
        <div class="col-12 text-center" style="font-size: 16px; font-weight: 500; font-family: poppins,sans-serif; color: #303447;">
           Confirm you want to save it to your favorites?
        </div>
    </div>
    <div class="row mb-0 mt-5" style="background-color: white;">
        <div class="col-12 text-end save_fab_btn">
        <button style="border: none !important; background-color: white !important;font-weight: 500;font-size: 14px; font-family: poppins,sans-serif;color: #303447; " (click)="closeModal()">Cancel</button>
        <button   style="border: 1px solid #FC9929; background-color: #FC9929; margin-left: 8px; padding: 3px 18px;font-size: 14px; font-weight: 500; font-family: poppins,sans-serif; color: white;" (click)="saveToFav()">Yes</button>
        </div>
    </div>
</div>
