
    <div style="top: 40px;position: absolute;padding: 35px;">
        <img class="d-block m-auto" src="assets/img/infoDoc.svg"  width="15%">
        <h3 class="text-center">Confirm you want to remove this shipments from favorites?</h3>
        <br>
        <div class="text-right">
            <button type="button" class="btn faint-grey col-4" style="box-shadow: none;" (click)="closeModal()">Cancel</button>
            &nbsp;&nbsp; <button type="button" class="btn orange text-white col-3" style="box-shadow: none;" (click)="submitModal()" >Yes</button>
        </div>
    </div> 
     <hr>
  
