<div class="row bg-light m-0 pt-2" style="position: fixed; bottom: 0; z-index: 1000;width: 100%;">
            
    <div class="footer-block">
        <p class="grey-text1 mt-0 d-inline"> &copy; {{cYear}} ShipCarte. All Rights Reserved </p>

        <span class="ml-1 mt-1 mb-5 d-inline">
            <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
            </a>
            <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
            </a>
            <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
            </a>
            <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
            </a>
            <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
            </a>
        </span>
    </div>
</div>
