<div class="container" style="top: 35px;position: absolute;padding: 20px;">
    <span>
         <h4 style="font-size: 16px !important;">Credit Application Form Signature
             <button type="button" class="close float-right " data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons" (click)="closeModal()">close</span>
                </span>
             </button>
         </h4>
    </span>
    <div class="row mb-0">
        <form [formGroup]="creditApplicationForm" (ngSubmit)="submit(creditApplicationForm.value)">
            <div class="form-group">
                <label>Name of Responsible Employee Or Exporter</label>
                <input type="text" #sign class="form-control" placeholder="" (input)="updateSignPreview($event.target.value)" formControlName="signByName" />
                <div class="blue-text text-right" style="cursor: pointer;" (click)="resetForm()">Clear</div>
                <div class="sign-container">{{sign.value}}</div>
                <br>
                <div class="text-right">
                    <button type="button" class="btn faint-grey col-4 btn-cancel" (click)="closeModal()">Cancel</button>
                    &nbsp;&nbsp; 
                    <button type="submit" class="btn orange text-white col-3" style="box-shadow: none;text-transform: capitalize;" >Done</button>
                </div>
            </div>
        </form>
    </div>
</div>


