<div class="container outerWrapper">
    <div class="row" style="border-bottom: 1px solid #E3E7EE; padding: 15px;margin-bottom: 0px;">
        <div class="col-lg-8">
            <h3 class=" p-0 m-0 d-inline" style=" font-size: 18px !important; font-weight: 500 !important;
            font-family: ''Poppins'sans-serif' !important; color: #303447 !important;">View Receipt </h3>
            <span class="blue-text ml-3 cursor-p" (click)="printComponent()">Print</span>
        </div>
        <div class="col-lg-4 text-end">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true"><span class="material-icons">close </span></span>
            </button>
        </div>
    </div>
    <div class="row" style="padding: 15px;">
        <div class="col-lg-12 p-0">
            <div id="component" class=" printable printableInvoice">
                <h3 class="mb-2 text-center" style="font-size: 19px !important; color: #151522 !important;">
                    INVOICE#{{orderData?orderData.invoiceNumber?orderData.invoiceNumber
                    :'--':'--'}}</h3>
                <h4 class="text-center"
                    style="font-size: 18px !important; font-weight: 400 !important; color: #151522 !important;">Receipt#
                </h4>
                <div class="row" style="margin-bottom: 18px !important; margin-top: 30px !important;">
                    <div class="col text-dark p-0">
                        Amount Paid
                    </div>
                    <div class="col text-regular p-0">
                        $ {{amountPaid | number:'1.2-2'}} {{orderData['currency'] ?
                        orderData['currency'] : ''}}
                    </div>
                </div>
                <div class="row" style="margin-bottom: 18px !important;">
                    <div class="col text-dark p-0">
                        Date Paid
                    </div>
                    <div class="col text-regular p-0">
                        {{data && formattedDate ? formattedDate : ''}}
                    </div>
                </div>
                <div class="row" style="margin-bottom: 18px !important;">
                    <div class="col text-dark p-0">
                        Payment Method
                    </div>
                    <div class="col text-regular p-0">
                        <img *ngIf="iscreditLimitApplied" src="https://dev-shipcarte-docs.s3.amazonaws.com/credit-approved-25042023.svg" style="height: 25px; width: 25px;" > <img style="height: 12px;
                        object-fit: contain;" *ngIf=" data[0]['cardType'] == 'VISA' || data[0]['cardType']=='MASTERCARD' || data[0]['cardType']=='AMERICAN_EXPRESS'" class=""
                            src="assets/img/payment/{{data[0]['cardType']=='VISA'?'visa_icon.svg':data[0]['cardType']=='MASTERCARD'?'master.svg':data[0]['cardType']=='AMERICAN_EXPRESS'?'amex_icon.svg':''}}"  >
                        {{paymentMethod}}
                    </div>

                </div>
                <h3 class="text-dark" style="padding: 12px 0 10px 0; line-height: 1 !important;">Summary</h3>
                <div class="row" style="background-color: #F7F9FC; margin-bottom: 0;">
                    <h4 class="text-dark" style="padding-top: 10px;">Order No.</h4>
                    <hr>
                    <div class="row" style="margin: 0; padding: 10px 0px;" *ngFor="let value of data">
                        <div class="col text-regular text-start"> {{value['orderNumber'] ? value['orderNumber'] : ''}}
                        </div>
                        <div class="col text-right text-regular">$ {{value['charged'] ? value['charged'] :
                            ''}} 
                             {{value['currency'] ? value['currency'] : ''}}</div>
                    </div>
                    <hr>
                    <div class="row" style="margin: 0; padding: 10px 0px;">
                        <div class="col text-dark">Total Amount</div>
                        <div class="col text-right text-dark"> 
                            $ {{ invoiceTotal| number:'1.2-2'}}
                             {{orderData['currency'] ?
                            orderData['currency'] : ''}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</div>