import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { environment  } from './../../../environments/environment.prod';
import { environment  } from './../../../environments/environment';


@Injectable({
    providedIn: 'root'
  })

  export class QuoteHistoryService{
    router: any;
    constructor(private http:HttpClient) { }
  
    GETQuoteHistory(page,items):any{
      // console.log(page,items);
      const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/quote/getAll/"+page+"/"+items+"?quoteStatus=all" ,{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));    
    }

    getQuoteHistoryWithStatus(page,items,url):any{
      // console.log(page,items);
      const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/quote/getAll/"+page+"/"+items+url , { withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));    
    }

    GetQuoteHistorybyKeyword(page,key):any{
      const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/sc/quote/ws/get/all/"+page+"/10?searchKey="+key,{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));    
    }
    
    GetAllQuote(startDate, endDate, searchKey, page, itemsPerPage):any{

      const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/quote/getAll/"+page+"/"+itemsPerPage+
        (startDate && endDate ? "?startDate="+startDate+"&endDate="+endDate : "")+
        (searchKey ? (startDate ? '&' : '?') +'searchKey='+searchKey : ''),
        { withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));    
    } 
  }
