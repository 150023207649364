<div class="container" style="top: 15px;position: absolute;padding: 20px;">
    <span>
         <h3 style="font-size: 20px !important;">Adding Contact Information 
             <button type="button" class="close float-right " data-dismiss="modal" aria-label="Close">
                 <span aria-hidden="true">
                    <span class="material-icons">close</span>
                 </span>
             </button>
         </h3>
    </span>
    <hr>
    <div>
        <form>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="firstName">First Name</label>
                    <input type="text" class="form-control" id="firstName" placeholder="Enter first name">
                </div>
                <div class="form-group col-md-6">
                    <label for="lastName">Last Name</label>
                    <input type="text" class="form-control" id="inpulastNametPassword4" placeholder="Enter last name">
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="popup_icon">Position</label>
                    <input type="text" class="form-control" id="position" placeholder="Enter position">
                </div>
                <div class="form-group col-md-4">
                    <label for="phone">Phone</label>
                    <input type="text" class="form-control" id="phone" placeholder="Enter phone">
                </div>
                <div class="form-group col-md-2">
                    <label for="ext">Ext.</label>
                    <input type="text" style="width: 85% !important;" class="form-control" id="ext" placeholder="Enter Ext">
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input type="email" style="width: 98% !important;" class="form-control" id="email" placeholder="Enter email">
            </div>
        </form>
    </div>
    <div class="text-right">
        <button type="button" class="btn faint-grey col-3" style="box-shadow: none;">Cancel</button> &nbsp;&nbsp; <button type="button" class="btn orange text-white col-3" style="box-shadow: none;">Save</button>
    </div>

</div>