import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { environment  } from './../../../environments/environment.prod';
import { environment  } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private http:HttpClient) { }


  getAllCarriers(){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath+ "/sc/integrator/shpmt/carrier/ws/get/all" ,{ withCredentials: true,
      headers: myheader
      }).pipe(map(response => {
      return response;
    }));  
  }
}
