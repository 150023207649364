<div class="container" style="top: 15px;position: absolute;padding: 20px;">
    <span>
         <h4 style="font-size: 16px !important;">Quick Commercial Invoice Signature
             <button type="button" class="close float-right " data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons" (click)="closeModal()">
                    close
                    </span>
                </span>
             </button>
         </h4>
    </span>
    <div class="row mb-0">
        <form [formGroup]="commercialInvoiceForm" (ngSubmit)="submit(commercialInvoiceForm.value)">
            <div class="form-group">
                <label>Name of Responsible Employee Or Exporter</label>
                <input type="text" #sign class="form-control" placeholder="John Doe" (input)="updateSignPreview($event.target.value)" formControlName="signByName" />
                <div class="blue-text text-right" (click)="clearField()">Clear</div>
                
                <div style="border: #ccc 1px dashed;padding: 20px;
                                            font-size: 28px;font-family: 'WindSong', cursive; text-align: center;">
                                                    {{sign.value}}
                </div>
                <br>
                <div class="col-12 ml-2">
                    <label class="mr-5">
                        <input id="Owner" type="radio" class="custom-control-input" checked [value]=true name="commercial" formControlName="commercial">
                        <label class="custom-control-label" for="Owner">Owner</label>
                    </label>
              
                    <label class="mr-3">
                        <input autocomplete="disabled" id="Agent" type="radio" class="custom-control-input" [value]=false name="commercial" formControlName="commercial">
                        <label class="custom-control-label" for="Agent">Agent</label>
                    </label>
                </div>
                <br>
                <div class="text-right">
                    <button type="button" class="btn faint-grey col-4" style="box-shadow: none;" (click)="closeModal()">Cancel</button>
                        &nbsp;&nbsp; <button type="submit" class="btn orange text-white col-3" style="box-shadow: none;" >Complete</button>
                </div>
            </div>
        </form>
    </div>
</div>
<script>
    window.onload=function(){
        function draw(event) {
            var canvas = document.getElementById("myCanvas");
            var ctx = canvas.getContext("2d");
            var text = document.getElementById('sign').value
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.fillStyle = "#3e3e3e";
            ctx.font = "16px Arial";
            ctx.fillText(text, 50, 50);
        }
        window.addEventListener("keyup", draw, true);
    }
</script>
