<!-- <p>dashboard works!</p>
<!DOCTYPE html> -->
<html>

<head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">

    <title>Collapsible sidebar using Bootstrap 4</title>
	

	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta1/dist/css/bootstrap.min.css"/>
	<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/boxicons@latest/css/boxicons.min.css"/>
	 	
<style>

:root {
    --header-height: 3rem;
    --nav-width: 70px;
    --first-color: #4723D9;
    --first-color-light: #AFA5D9;
    --white-color: #F7F6FB;
    --body-font: 'Nunito', sans-serif;
    --normal-font-size: 1rem;
    --z-fixed: 100
}
a {
    text-decoration: none
}

.header {
    width: 100%;
    height: var(--header-height);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
    background-color: var(--white-color);
    z-index: var(--z-fixed);
    transition: .5s
}

.header_toggle {
    color:#8A94A6;
    font-size: 1.5rem;
    cursor: pointer
}

.header_img {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden
}

.header_img img {
    width: 40px
}

.l-navbar {
    position: fixed;
    top: 0;
    left: -30%;
    width: var(--nav-width);
    height: 100vh;
    background-color:#151522;
    padding: .5rem 1rem 0 0;
    transition: .5s;
    z-index: var(--z-fixed)
}

.nav {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
}

.nav_logo,
.nav_link {
    display: grid;
    grid-template-columns: max-content max-content;
    align-items: center;
    column-gap: 1rem;
     padding: 1px 0px 0 20px;
}

.nav_logo {
    margin-bottom: 2rem
}

.nav_logo-icon {
    font-size: 1.25rem;
    color: var(--white-color)
}

.nav_logo-name {
    color: var(--white-color);
    font-weight: 700
}

.nav_link {
    position: relative;
    color:#fff;
    margin-bottom:1px;
    transition: .3s;
    width: 100%;
    float: left;
    padding: 8px 0 5px 30px;
}

.nav_link:hover {
    color: #FF9A00;
}

.nav_icon {
    font-size: 1.25rem
}

.show {
    left: 0
}

.body-pd {
    padding-left: calc(var(--nav-width) + 1rem)
}

.active {
    background-color: #2d2d39;
    color: #FF9A00;
}

.active::before {
    content: '';
    position: absolute;
    left: 0;
    width: 2px;
    height: 32px;
    background-color: var(--white-color);
    display: none;
}

.height-100 {
    height: 100vh
}

@media screen and (min-width: 768px) {
    body {
        margin: calc(var(--header-height) + 1rem) 0 0 0;
        padding-left: calc(var(--nav-width) + 2rem)
    }

    .header {
        /*height: calc(var(--header-height) + 1rem);*/
        height: 60px;
        padding: 0 2rem 0 calc(var(--nav-width) + 2rem);
        border-bottom: 1px solid #DDDFE1;
    }

    .header_img {
        width: 40px;
        height: 40px
    }

    .header_img img {
        width: 45px
    }

    .l-navbar {
        left: 0;
        padding: 1rem 0rem 0 0
    }
    .nav_link .nav_name{
        padding: 0 0 0 7px;
    }


    .show {
        width: calc(var(--nav-width) + 156px)
    }

    .body-pd {
        padding-left: calc(var(--nav-width) + 188px);
        border-bottom: 1px solid #DDDFE1;
    }
}
.nav_link:hover{
    background-color: #2d2d39;
   
}
.nav_link .icon-box{
    width:22px;
    text-align: center;
}



   /*--------------------- header_css ---------------------*/
    .header_search_box{
        width: 43%;
        padding: 0 0 0 10px;
        margin: 0 30px 0 0;
    }
    .header_search_box input {
        padding: 4px 30px;
        background-image: url(dashboard/search-Icon.svg);
        background-position: 4px 5px;
        background-size: 23px;
        background-repeat: no-repeat;
        border: 1px solid #ccc;
        color: #887676;
        width: 100%;
    }
    .header-btn-box{
        padding: 0 10px;
    }

    .header-btn-box .btn{
        border-radius: 0px;
        font-size: 11px;
        background-color: #FF9A00;
        color: #fff !important;
    }
    .available_credit_box{
        color: #000000;
    }
    .available_credit_box .credit_heading{
        color: #000000;
        font-size: 12px;
    }
    .available_credit_box .credit_count{
        color: #000000;
        font-size: 12px;
    }
    .header_search_box input {
        padding: 4px 30px;
        background-image: url(dashboard/search-Icon.svg);
        background-position: 5px 4px;
        background-size: 20px;
        background-repeat: no-repeat;
        border: none;
        color: #333;
        font-size: 12px;
    }
    .header_search_box input ::placeholder{
        color: #8A94A6;
        font-size: 11px;
    }

    .support_ticket_box{
        color: #8A94A6;
        font-size: 12px;
        margin: 0 0px 0 60px;
    }
    .support_ticket_box span{ 
        margin: 0px 0px 0px 12px;
    }


    /*--------------------- header_css ---------------------*/




</style>

</head>

<body>
    <div id="body-pd">

    <div class="header" id="header">
        <div class="header_toggle"> <i class='bx bx-menu' id="header-toggle"></i> </div>
        <!-- <div class="header_toggle"><img id="header-toggle" src="dashboard/toggle.svg" ></div> -->
        
        <div class="header-btn-box"><button type="button" class="btn btn-warning">Quick Actions</button></div>

        <div class="available_credit_box">
            <img src="./../../../assets/img/sidebar/green_doller.svg">&nbsp
            <span class="credit_heading">Available Credit:</span> <span class="credit_count">13,000</span>
        </div>

        <div class="header_search_box"><input placeholder="Search by BOL, Tracking, Order Number, or Customer Number…" 
            type="search" id="gsearch" name="gsearch">
        </div>

        <div class="support_ticket_box">
            <span><img src="./../../../assets/img/sidebar/support_ticket_icon.svg"> &nbsp Support Ticket</span>
            <span><img src="./../../../assets/img/sidebar/question_circle.svg"></span>
            <span><img src="./../../../assets/img/sidebar/notification.svg"></span>
        </div>

        <div class="header_img"> <img src="./../../../assets/img/sidebar/avatar5.svg" alt=""> </div>

    </div>

    <div class="l-navbar" id="nav-bar">
        <nav class="nav">
            <div> <a href="#" class="nav_logo"><img src="./../../../assets/img/sidebar/logo.svg" style="width:50%;"></a>
                <div class="nav_list">
                
                <a href="#" class="nav_link active">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/star.svg"></span>
                    <span class="nav_name">Favorites</span>
                </a>
                <a href="/dashboard" class="nav_link">
                    <span class="icon-box" ><img src="./../../../assets/img/sidebar/dashboard.svg"></span>
                    <span class="nav_name">Dashboard</span>
                </a> 
                <a href="/get-quote" class="nav_link">
                    <span class="icon-box" style="width:22px;"><img src="./../../../assets/img/sidebar/get_quote.svg"></span>
                    <span class="nav_name">Get quote</span>
                </a>
                <a href="/get-quote/quote-history" class="nav_link">
                    <span class="icon-box" style="width:22px;"><img src="./../../../assets/img/sidebar/quote_history.svg"></span>
                    <span class="nav_name">Quote history</span>
                </a>
                <a href="#" class="nav_link">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/shipments.svg"></span>
                    <span class="nav_name">Shipments</span>
                </a>
                <a href="#" class="nav_link">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/address-book.svg"></span>
                    <span class="nav_name">Address book</span>
                </a>
                 <a href="#" class="nav_link">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/invoices.svg"></span>
                    <span class="nav_name">Invoices</span>
                </a>
                <a href="#" class="nav_link">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/shipcartepro.svg"></span>
                    <span class="nav_name">Shipcarte Pro <br><small style="color: #FF9A00;font-size: 12px;">(Coming Soon)</small> </span>
                </a>
                <a href="#" class="nav_link">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/integrations.svg"></span>
                    <span class="nav_name">Integrations</span>
                </a>
                <a href="#" class="nav_link">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/reports.svg"></span>
                    <span class="nav_name">Reports</span>
                </a>
                <a href="#" class="nav_link">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/claims.svg"></span>
                    <span class="nav_name">Claims</span>
                </a>
                <a href="#" class="nav_link">
                    <span class="icon-box"><img src="./../../../assets/img/sidebar/resources.svg"></span>
                    <span class="nav_name">Resources</span>
                </a>
                
                </div>
            </div>
            <a href="#" class="nav_link">
                <span class="icon-box"><img src="./../../../assets/img/sidebar/setting.svg"></span>
                <span class="nav_name">Settings</span>
            </a>
             <a href="#" class="nav_link">
                <span class="icon-box">
                    <img src="./../../../assets/img/sidebar/log_out.svg">
                </span>
                <span class="nav_name">Log Out</span>
             </a>
        </nav>
    </div>
</div>

</body>

</html>