import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from './../../environments/environment';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { CommonService } from './../../app/services/common.service';
import { FreightClassCalculatorComponent } from 'src/app/_helpers/popup-dialog/quick-actions/freight-class-calculator/freight-class-calculator.component';
import { DimensionalWeightCalculatorComponent } from 'src/app/_helpers/popup-dialog/quick-actions/dimensional-weight-calculator/dimensional-weight-calculator.component';
import { CalculateDistanceComponent } from 'src/app/_helpers/popup-dialog/quick-actions/calculate-distance/calculate-distance.component';
import { ZipPostalCodesComponent } from 'src/app/_helpers/popup-dialog/quick-actions/zip-postal-codes/zip-postal-codes.component';
import {Role} from '../models/Role';
import {SignupService} from '../services/sign-up/signup.service';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.css']
})
export class LeftsidebarComponent implements OnInit {
  webVersion;
  userData:any;
  navLeftObject:any;
  notificationObject:any;
  href = window.location.hash;
  creditLimit:any;
  siderbarToggle:any;
  activeTabIndex:any;
  notificationStatus:any;
  userRole:any=''
  commonData: any;
  constructor(private router:Router,public commonService:CommonService,private modalService: NgbModal,private toastr: ToastrService,private signUpService:SignupService) { 
    this.commonService.data.subscribe((val)=>{
      this.commonData = val;
      // console.log(this.commonData)
    })
  }

  ngOnInit(): void {

    this.router.events.subscribe((event)=>{
      if (event instanceof NavigationEnd) {
        // Navigation ended successfully
        // console.log('Navigation ended successfully');
        this.href = window.location.hash;
      }
    })

    this.signUpService.getUser().subscribe((data)=>{
      this.userRole=data;
    });

    this.navLeftObject = [
      {
        "label": "Dashboard",
        "href": "#/dashboard",
        "styleCode": "dashboard",
        "title": "Dashboard",
        "data":[Role.Admin,Role.Accounting,Role.Shipper]
      },
      {
        "label": "Get Quote",
        "href": "#/get-quote",
        "styleCode": "get_quote",
        "title": "Get Quote",
        "data":[Role.Admin,Role.Shipper],
        "child1":[
          {
            "label": "Quote History",
            "href": "#/get-quote/quote-history",
            "styleCode": "quote_history",
            "title": "Quote History",
            "data":[Role.Admin,Role.Shipper]
          }
        ]
      },
      {
        "label": "Orders",
        "href": "#/shipment",
        "styleCode": "shipments",
        "title": "Shipments",
        "data":[Role.Admin,Role.Shipper],
        "child1":[
          {
            "label": "Favorites",
            "href": "#/favorite",
            "styleCode": "star",
            "title": "Favorites",
            "data":[Role.Admin,Role.Accounting,Role.Shipper]
          },
        ]
      },
      {
        "label": "Invoices",
        "href": "#/invoices",
        "styleCode": "invoices",
        "title": "Invoices",
        "data":[Role.Admin,Role.Accounting]
      },
      {
        "label": "Address Book",
        "href": "#/address-book",
        "styleCode": "address-book",
        "title": "Address Book",
        "data":[Role.Admin,Role.Shipper]
      },
      {
        "label": "ShipCarte Pro ",
        "href": "#/shipcartepro",
        "styleCode": "shipcartepro",
        "title": "ShipCarte Pro",
        "data":[Role.Admin,Role.Shipper],
        "child1":[
          {
            "label": "Market Place",
            "href": "#/market-place",
            "styleCode": "integrations",
            "title": "Market Place",
            "data":[Role.Admin,Role.Shipper]
          },
        ]
      },
      {
        "label": "Reports",
        "href": "#/help/resources",
        "styleCode": "reports",
        "title": "Reports",
        "data":[Role.Admin,Role.Accounting,Role.Shipper]
      },
      {
        "label": "Claims",
        "href": "javascript:void(0)",
        "styleCode": "claims",
        "title": "Claims",
        "data":[Role.Admin,Role.Accounting,Role.Shipper]
      },
      {
        "label": "Resources",
        "href": "#/help/resources/0",
        "styleCode": "resources",
        "title": "Resources",
        "data":[Role.Admin,Role.Accounting,Role.Shipper]
      },
    ];

    let x = window.matchMedia("(max-width: 992px)");
    if(x.matches){
      for(let nav of this.navLeftObject){
        if(nav.child1){
          this.showChild(nav.href)
        }
      }
    }

    //{
      // "lable":"Settings",
      // "href":"#/settings",
      // "styleCode":"settings",
      // "tital":"Settings",
      // "data":[Role.Admin,Role.Accounting,Role.Shipper]
    //}

    // new code .....

    if(this.commonService.sidebarToggle==false){
      this.siderbarToggle = '';
    }
    else{
      this.siderbarToggle = 'collapsed';
    }
    // ************************

    // old  code .....
    // this.siderbarToggle='';
     // ************************

    this.notificationObject = [];
    this.getNotifications();


    this.notificationStatus = false;


      this.webVersion =  environment.Uiversion;
      if(!localStorage.getItem('loggedInUserDetails')){
        localStorage.clear();
        this.router.navigate(['/signin'])
      }

      this.userData = JSON.parse(localStorage.getItem('loggedInUserDetails'));
      this.getCreditData(); // first call

      // setInterval(() => {
      //   this.getCreditData();
      // }, 500000);

  }
  public setActiveTab(num) {
    this.activeTabIndex = num;
   console.log(this.activeTabIndex);
  }
  toggleSidebar()  {

    if(this.commonService.sidebarToggle==false){
      this.siderbarToggle = 'collapsed';
      this.commonService.sidebarToggle = true;
    }
    else{
      this.siderbarToggle = '';
    this.commonService.sidebarToggle = false;
    }

    // old method if error recover it and comment upper code

  // if(this.siderbarToggle == ''){
  //   this.siderbarToggle = 'collapsed';
  //   this.commonService.sidebarToggle = true;
  // }else{
  //   this.siderbarToggle = '';
  //   this.commonService.sidebarToggle = false;
  // }
  }
  toggleSidebarMobile()  {
    if(this.siderbarToggle == ''){
      this.siderbarToggle = 'collapsedMob';
    }else{
      this.siderbarToggle = '';
    }
  }

  showQuoteHistory: boolean = false;
  showFvrtOrder: boolean = false;
  showChildArr = [];
  showChild(link){
    // push href into array
    const index = this.showChildArr.indexOf(link);
    if(index > -1){
      this.showChildArr = this.showChildArr.filter(item => item !== link);
    }else{
      this.showChildArr.push(link);
    }
  }

  getCreditData(){

    this.commonService.getCreditData().subscribe(result =>{
      if(result['availableCreditLimit']){
        this.commonData.creditLimit = result;
        // this.creditLimit = result;
        // console.log(this.commonData.creditLimit)
      }
    }, (error) => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin']);
      }
    });

  }
  getNotifications(){
    this.notificationStatus= "Fetching Notifications... ";
    this.commonService.getNotificationData().subscribe(result =>{
      this.notificationObject = result;
      this.notificationStatus = false;
      if(this.notificationObject['unreadCount']){
         for (let index = 0; index <= this.notificationObject.notifications.length; index++) {
           var inputdata={ }
           inputdata["notificationId"]=this.notificationObject.notifications[index]['notificationId']
        this.commonService.markedRead(inputdata).subscribe(result=>{
        })
         }
         this.notificationObject['unreadCount']=0
      }
    }, (error) => {
      if (error['status'] === 404) {
        this.notificationStatus= "No Data Found ";
      }
    });

  }
  logout(){
    localStorage.clear();
    this.router.navigate(['/signin'])
  }

  redirect(path){
    if(path){
      this.router.navigate(['/'+path])
    }
  }

  open_modal(modal) {
    this.modalService.dismissAll();
    if(modal == 'dimensionmodal'){
      const modalRef = this.modalService.open(DimensionalWeightCalculatorComponent, { size: 'lg', centered: false, backdrop: 'static', animation: true, windowClass: 'my-custom-modal2' });
      this.router.events.subscribe((val) => {
        modalRef.close(); 
      });
      modalRef.result.then((result) => {
      });
    }else if(modal == 'freightmodal'){
      const modalRef = this.modalService.open(FreightClassCalculatorComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal4 freightCal' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.result.then((result) => {
     });
    }else if(modal == 'distancemodal'){
      const modalRef = this.modalService.open(CalculateDistanceComponent, { size: 'lg', centered: false, backdrop: 'static',  animation: true, windowClass: 'my-custom-modal3 cssStyle' });
      this.router.events.subscribe((val) => {
        console.log(val,'val')
        modalRef.close();
      });
      modalRef.result.then((result:any) => {
         console.log(result,'dhsd')
     });
    }else if(modal == 'zipmodal'){
      const modalRef = this.modalService.open(ZipPostalCodesComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal5' });
      this.router.events.subscribe((val) => {
        modalRef.close();
      });
      modalRef.result.then((result) => {
     });
    }


  }
  redirectToDhashboard(){
    this.router.navigate(['dashboard']);
  }
  redirectToGetQuote(){
    this.router.navigate(['get-quote']);
  }
  redirectToResources(tabIndex){

    this.router.navigate(['/help/resources', tabIndex]);

  }
  redirectToSettings(){
    this.router.navigateByUrl('/settings')
  }

  redirectToCreateBillOfLading(){
  this.router.navigateByUrl('/quickBOL/create-bol');
  }
  redirectToQuickCommercialInvoice(){
    this.router.navigateByUrl('/commercialInvoice/createCommercialInvoice/invoice');
    }
    profile(){
      this.router.navigateByUrl('/user-profile');
    }
    Setting(){
      this.router.navigateByUrl('/settings');
    }
    Logout(){
      this.router.navigateByUrl('/signin');
    }

    redirectToFavourite(){
      this.router.navigateByUrl('/favorite');
    }
}
