<!-- <div class="container">
	<div class="row">
		<div class="col1">
			<app-leftsidebar></app-leftsidebar>
		</div> -->

		<div class="bg-light">
            <div class="row mt-5">
                <div *ngIf="id==':appendix'">
                    <h3>Appendix</h3>
                </div>
                <div *ngIf="id==':video-tutorials'">
                    <h3>Video-Tutorials</h3>
                </div>
                <div *ngIf="id==':faq'">
                    <h3>FAQ</h3>
                </div>
                <div *ngIf="id==':o-book'">
                    <h3>O-Book</h3>
                </div>
                <div *ngIf="id==':support'">
                    <h3>Support</h3>
                </div>
                <div *ngIf="id==':create-ticket'">
                    <h3>Create-Ticket</h3>
                </div>
                <div *ngIf="id==':leave-feedback'">
                    <h3>Leave-Feedback</h3>
                </div>
            </div>
            
        </div>
    <!-- </div>
</div> -->
