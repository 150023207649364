import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { environment } from './../../../environments/environment.prod';
import { environment } from './../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class getQuoteService {
  baseUrl: any;
  constructor(private http: HttpClient) { }

  saveQuote(dataObj, saveEdit) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    let url = '';
    if(saveEdit == 'save'){
      url = '/sc/quote/request/ws/save';
    }else if(saveEdit == 'edit'){
      url = '/sc/quote/request/ws/edit';
    }
    return this.http.post(environment.apiPath + url, dataObj, {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }

  diliveryDay(quoteId){
    
    return this.http.get(environment.apiPath + "/sc/quote/order/detail/ws/get?id="+quoteId, { withCredentials: true });
    
  }

  getLocationType() {
    return this.http.get(environment.apiPath + "/sc/loc/type/ws/get/all", { withCredentials: true });
  }

  getShipperConsigneeAddress(custId) {
    return this.http.get(environment.apiPath + "/sc/quote/ws/addr/get/default/shipperConsignee?customerId="+custId, {withCredentials: true})
  }
  getHazmatDetails(hazmatUnNumberValue){
    return this.http.get(environment.apiPath + "/api/sc/quote/getHazmatDetails?un_number="+hazmatUnNumberValue, {withCredentials: true})

  }

  getLocationExtraServices(locationId, action) {
    if(action==='')
    {
      return this.http.get(environment.apiPath + "/sc/loc/shpmt/services/get/all?locTypeId=" + locationId , { withCredentials: true });
    }
    else{
      return this.http.get(environment.apiPath + "/sc/loc/shpmt/services/get/all?locTypeId=" + locationId + "&locType=" + action, { withCredentials: true });
    }
    // http://52.5.190.96:9090/shipcarte/sc/loc/shpmt/services/get/all?locTypeId=5e83b7cc-df9a-11e9-b6b0-f07959651ad4&locType=start
   
  }

  // getPlaceZipcode(placeId){
  //   return this.http.get('https://maps.googleapis.com/maps/api/place/details/json?place_id='+placeId+'&fields=address_components&key='+environment.GooglePlacesApiKey);
  // }

  getCarrierListing(carrierid) {
    return this.http.get(environment.apiPath + "/sc/shpmt/carrier/get/all?quote-id=" + carrierid, { withCredentials: true });
  }

  getFreightClass() {
    return this.http.get(environment.apiPath + "/sc/freight/class/type/ws/get/all", { withCredentials: true });
  }

  getShipmentModeData() {
    return this.http.get(environment.apiPath + "/api/getMapping/shipmentPackage", { withCredentials: true });
  }

  getOrderCardDetailsById(id,tz){
    // const myheader = new HttpHeaders().set('time-zone', tz);
    return this.http.get(environment.apiPath + "/api/quote/getOrderMetaDataById?quoteId=" + id, { withCredentials: true,
     });
  }

  getQuoteAddressDetailsById(id){

    return this.http.get(environment.apiPath + "/api/quote/getQuoteAddressDetailsById?quoteId=" + id ,{withCredentials:true})
  }

  getShipmentItems(id){
    return this.http.get(environment.apiPath + "/api/item/getDetailsByQuoteId?quoteId=" + id , {withCredentials:true})
  }
  getCartonizedItems(id){
    return this.http.get(environment.apiPath + "/api/item/getCartonizationDetailsByQuoteId?quoteId=" + id , {withCredentials:true})

  }
 
  getTrackingData(id){

    return this.http.get(environment.apiPath + "/sc/trace/order?orderId=" +id , {withCredentials:true});
  }
 
  changeStatuOfOrder(orderId,key){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.put(environment.apiPath + "/sc/ws/order/updateShipmentStatus" + '?orderId=' + orderId + '&shipmentStatus=' + key, {}, {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }
  getQuoteDetailsById(id) {
    return this.http.get(environment.apiPath + "/api/quote/getDetailsById?quoteId=" + id, { withCredentials: true });
  }

  createCustomPacakge(dataObj){
    return this.http.post(environment.apiPath +"/api/sc/customPackage/createPackage",dataObj, { withCredentials: true })
  }

  getCustomPackageList(pageNo , perPage,modeId ,searchKey){
    return this.http.get(environment.apiPath + "/api/sc/customPackage/getPackageList/" + pageNo +"/"+perPage +"?shipModeId=" + modeId 
    + (searchKey.length >=1 ? `&query=${searchKey}` : ''), {withCredentials:true})
  }

  deleteCustomPackage(cusId){
    return this.http.delete(environment.apiPath + "/api/sc/customPackage/deletePackage?customPackageId="+ cusId , {withCredentials:true})

  }

  editCustomPackage(cusId , dataObj){
    return this.http.put(environment.apiPath + "/api/sc/customPackage/updatePackage?customPackageId="+ cusId , dataObj, {withCredentials:true})
  }
  getInsuranceDocument(quoteId){
    return this.http.get(environment.apiPath+'/api/sc/insurance/getInsuranceDoc?quoteId='+quoteId,{withCredentials:true})
  }
  removeInsurance(quoteId){
    return this.http.put(environment.apiPath+"/api/quote/removeInsurance?quoteId="+quoteId,{withCredentials:true})
  }
}