import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from './services/common.service';
import { concatMap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class CommonInterceptor implements HttpInterceptor {
  constructor(private toastr: ToastrService, private common: CommonService, private router: Router) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if(localStorage.getItem('loggedInUserDetailsFullData')){
    //   let actualUrl: any = request.url.split('?');
    //   actualUrl[0] = actualUrl[0].split('/');
    //   if(actualUrl[0][actualUrl[0].length - 1] !== 'getStatus'){
    //     const observable = this.common.isUserActiveFn().pipe(
    //       concatMap((response: any) => {
    //         if(response.data){ //check if the user is active
    //           return next.handle(request);
    //         }else{
    //           this.toastr.error('User is Inactive', '');
    //           localStorage.clear();
    //           this.router.navigate(['/signin']);
    //         }
    //       })
    //     );
    //     return observable;
    //   }else{
    //     return next.handle(request);
    //   }
    // }else{
    //   return next.handle(request);
    // }

    return next.handle(request);
  }
}
