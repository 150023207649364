import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-user-preferences-terms',
  templateUrl: './user-preferences-terms.component.html',
  styleUrls: ['./user-preferences-terms.component.css']
})
export class UserPreferencesTermsComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  closeModal() { 
    this.activeModal.close(); 
  }

}
