import { Component, OnInit,Input, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { InvoicesService } from 'src/app/services/invoices.service';
import { LoadingService } from 'src/app/services/loader.service';
 

@Component({
  selector: 'app-view-conslidated-invoice',
  templateUrl: './view-conslidated-invoice.component.html',
  styleUrls: ['./view-conslidated-invoice.component.css']
})
export class ViewConslidatedInvoiceComponent implements OnInit {
 
  data: any;
  oddNumber:any=[3,5,7,9,11,13,15,17,19,21];
  isInvoicePrinting:boolean = false;
  consolidatedInvoiceList: any = [];
  commonData: any;
  amountDue:any=0.0;
  amountPaid:any=0.0;
  subTotal:any=0.0;
 
  @Input() listOrderId;
 
  constructor(
  
    private modal:NgbModal,
    private invoiceSrvc:InvoicesService,
    private  _loaderService: LoadingService

  ) { }

  ngOnInit() {
    this._loaderService.stringSubject.next(true);
    this.getInvoiceDetails();
    setTimeout(() => {
      this._loaderService.stringSubject.next(false);
    }, 2500);
  }

  cancel() {
    this.modal.dismissAll();
  }

   

  getInvoiceDetails() {
    // this.loadCotent(this.loadContent);
    // let url = ServerURLS.getConsolidateInvoice.action + '?orderIds=' + this.listOrderId + '&invoiceDate=' + this.date.toISOString();
    // let promise1 = this.scHttpService.postHttpService(url, '');
    // promise1.then((res: any) => {
    //   console.log(res)
    //   this.consolidatedInvoiceLis console.log(this.consolidatedInvoiceList, this.commonData[0])t = res;
    //   this.commonData = res[0];
    // }).catch((error) => {
    //   this.cancel();
    //   this.toastr.error('Something went wrong', '', {
    //     timeOut: 3000
    //   })
    // });
  
    this.listOrderId.forEach((element,index)=>{
      this.invoiceSrvc.getInvoiceDetails(element).subscribe((res:any)=>{
        this.consolidatedInvoiceList.push(res)
        this.amountDue=this.amountDue+parseFloat(res.amountDue?res.amountDue:0);
        this.commonData = this.consolidatedInvoiceList[0]
        
      })
    })
    
    this.subTotal=JSON.parse(sessionStorage.getItem('ordersIdsInInvoiceOrderDetail')).invoiceTotal;
    this.amountPaid = JSON.parse(sessionStorage.getItem('ordersIdsInInvoiceOrderDetail')).amountPaid;
  }
 

}
