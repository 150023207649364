<div class="container" style="top: 0px;padding: 0 20px 0 20px;">
    <span>
        <h3 style="font-size: 18px !important; color: #354052; font-family: poppins,sans-serif; font-weight: 500; margin-bottom: 22px;">Adding Contact Information 
            <button type="button" class="close float-right " (click)='closeModal()' data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons">close</span>
                </span>
            </button>
        </h3>
    </span>
    <div>
        <form [formGroup]="addressForm" (ngSubmit)="submit()">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="firstName">First Name</label>
                    <input formControlName="firstName" type="text" class="form-control" id="firstName" placeholder="Enter first name">
                    <div *ngIf="addressForm.get('firstName').invalid && (addressForm.get('firstName').dirty || addressForm.get('firstName').touched)" [ngClass]="'error'">
                        <div *ngIf="addressForm.get('firstName').errors.required">
                            Name is required.
                        </div>
                        <div *ngIf="addressForm.get('firstName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="addressForm.get('firstName').errors.minLength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="addressForm.get('firstName').errors.maxLength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="lastName">Last Name</label>
                    <input formControlName="lastName" type="text" class="form-control" id="lastName" placeholder="Enter last name">
                    <div *ngIf="addressForm.get('lastName').invalid && (addressForm.get('lastName').dirty || addressForm.get('lastName').touched)" [ngClass]="'error'">
                        <div *ngIf="addressForm.get('lastName').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="addressForm.get('lastName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="addressForm.get('lastName').errors.minLength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="addressForm.get('lastName').errors.maxLength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="popup_icon">Title</label>
                    <input type="text" formControlName="Position" class="form-control" id="position" placeholder="Enter position">
                </div>
                <div class="form-group col-md-6">
                    <label for="email">Email</label>
                    <input formControlName="email" type="email" class="form-control" id="email" placeholder="Enter email">
                    <div *ngIf="addressForm.get('email').invalid && (addressForm.get('email').dirty || addressForm.get('email').touched)" [ngClass]="'error'">
                        <div *ngIf="addressForm.get('email').errors.required">
                            Email is required.
                        </div>
                        <div *ngIf="addressForm.get('email').errors.pattern">
                            Enter correct Email format.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="phone">Phone</label>
                    <div class="row mb-0">
                        <div class="col-4 p-0">
                            <!-- <select class="custom-select custom-select2" style="height: 28.5px !important;border: 1px solid #C4CDD5; width: 88px !important;margin-top: 0px; margin-right: -1px; margin-left: 12px;" >
                                <option value="Candaa" style="background-image: url('assets/img/Currier-Selection/CANADA.svg');"> (+1)</option>   
                                <option value="USA"><img src="assets/img/Currier-Selection/USA.svg" alt=""> (+1)</option>                                                                                           
                            </select> -->
                            <mat-select  [(value)]="shipperSelected" formControlName="flag">
                                <mat-select-trigger>
                                  <img width="16" src="{{ shipperSelected.icon }}" />
                                  {{ shipperSelected.name }}
                                </mat-select-trigger>
                                <mat-option *ngFor="let item of shipperAllItems" [value]="item">
                                  <img width="16" src="{{ item.icon }}" />
                                  {{item.name}}
                                </mat-option>
                              </mat-select>
                        </div>
                        <div class="col-8 p-0">
                            <input formControlName="phone" type="text" class="form-control" id="phone" placeholder="Enter phone">
                        </div>
                    </div>
                    
                    <div *ngIf="addressForm.get('phone').invalid && (addressForm.get('phone').dirty || addressForm.get('phone').touched)" [ngClass]="'error'">
                        <div *ngIf="addressForm.get('phone').errors.required">
                            Mobile is required.
                        </div>
                        <div *ngIf="addressForm.get('phone').errors.pattern">
                            Enter correct format.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label for="ext">Ext.</label>
                    <input type="text" formControlName="Ext" class="form-control" id="ext" placeholder="Enter Ext">
                </div>
            </div>
            
            <div class="text-right" style="margin-top: 15px;">
                <button type="button" class="btn faint-grey col-3" (click)='closeModal()' style="box-shadow: none; text-transform: none !important; background-color: white !important; font-size: 14px !important;">Cancel</button>
                 &nbsp;&nbsp; <button type="submit" [disabled]="!addressForm.valid" class="btn orange text-white col-3" style="box-shadow: none; font-size: 14px !important; text-transform: none !important;">Save</button>
            </div>
        </form>
    </div>
</div>