import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DistanceCalService {
  distanceCalculatorData:any={
    origin:undefined,
    destination:undefined
  };
  constructor() { }
  getDistanceCalculatorData(){
    return this.distanceCalculatorData
  }
  setDistanceCalcualtorData(data,addType){
    if(addType=='origin'){
      this.distanceCalculatorData.origin=data
    }else if(addType=='dest'){
      this.distanceCalculatorData.destination=data
    }
  }
}
