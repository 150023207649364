import { Component, OnInit } from '@angular/core';
import { NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import{Router} from "@angular/router"
@Component({
    selector: 'app-sipperEdit',
    templateUrl: './shipper-address-edit.component.html',
    styleUrls: ['./shipper-address-edit.component.css']
  })
  export class ShipperAddressEditComponent implements OnInit {
    
    constructor(private ser:NgbModal, private rt:Router) { }
  
    ngOnInit(): void {
    }
  close(){
      this.ser.dismissAll();
  }

  navigate(){
      this.rt.navigateByUrl("get-quote");
      this.ser.dismissAll();
  }
  }