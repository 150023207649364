import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
// import { environment  } from './../../../environments/environment.prod';
import { environment } from './../../../environments/environment';
// import 'rxjs/add/operator/catch';
// import 'rxjs/add/operator/map'
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignupService {
  baseUrl:any;
  constructor(public http:HttpClient) { }

  userType = new BehaviorSubject('');

  getUser(){
    let role = JSON.parse(localStorage.getItem('loggedInUserDetailsFullData'))?.userRole ? JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).userRole :'admin';
    this.userType.next(role);
    return this.userType.asObservable();
  }

  signInUser(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');

    return this.http.post(environment.apiPath+ "/sc/user/ws/autz",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }

  signupwithGoogle(data,actor){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+ "/sc/user/ws/autz",{ "email": data['email'],"socialProvider": data['provider'].toLowerCase(),"loggedInUserId": "","actorType": actor,
    "loginName": "","password": "" } ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }

  signUpSubmit(formDataObj,actorType,action){
    var requestData;
    if(action == 'social'){
      requestData = { "email": formDataObj['email'],"socialProvider": formDataObj['provider'].toLowerCase(),"loggedInUserId": "","actorType": actorType,"loginName": "","password": "" }
    }else{
      requestData = { "loggedInUserId": "","actorType": actorType,
      "loginName": formDataObj['userName'],"socialProvider": "","password": formDataObj['password']}
    }
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+ "/api/registerUser",requestData ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }

  checkUserValidation(userName){
    this.baseUrl = environment.apiPath+ "/api/validateLogin?loginName=" + userName;
    return this.http.get(this.baseUrl);
  }

  getCountryList(){
    this.baseUrl = environment.apiPath+ "/api/country";
    return this.http.get(this.baseUrl);
  }

  getServiceList(){
    this.baseUrl = environment.apiPath+ "/api/services";
    return this.http.get(this.baseUrl);
  }

  getRegionServiceList(){
    this.baseUrl = environment.apiPath+ "/api/regionOfService";
    return this.http.get(this.baseUrl);
  }

  completeRegistration(formDataObj){
    const loggedUserId = localStorage.getItem('loggedInUserId');
    const actor = localStorage.getItem('actorType');
    var dataObj;
    if(actor == 'customer'){
      dataObj = { loggedInUserId:loggedUserId,actorType:actor,firstName:formDataObj['firstName'],lastName:formDataObj['lastName'],email:formDataObj['email'],mobile:formDataObj['phoneNumber'],businessName:formDataObj['businessName'],industryType:formDataObj['industry'],primaryAddress:formDataObj['address1'],secondaryAddress:formDataObj['address2'],city:formDataObj['city'],zipCode:formDataObj['zip'],provinceState:formDataObj['state'],country:formDataObj['country'],promotionalCode:formDataObj['promotionCode'],freightBroker:formDataObj['freighter']};
    }else{
      dataObj = { loggedInUserId:loggedUserId,actorType:actor,firstName:formDataObj['firstName'],lastName:formDataObj['lastName'],email:formDataObj['email'],mobile:formDataObj['phoneNumber'],businessName:formDataObj['businessName'],industryType:formDataObj['industry'],primaryAddress:formDataObj['address1'],secondaryAddress:formDataObj['address2'],city:formDataObj['city'],zipCode:formDataObj['zip'],provinceState:formDataObj['state'],country:formDataObj['country'],service:formDataObj['services'],regionOfService:formDataObj['regionofservice']};
    }

    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+ "/api/completeRegistration",dataObj,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }
  signupFormObj:any=[];
  validateOtpObj:any=[];
  resendOtpObj:any=[];
   dataObj:any
   googleMapId:any;
   countryCode:any;
   isSignupCustomerDataSet= new BehaviorSubject(false);
   signupCustomerServiceDataSet={
    services:[],
    regionOfServices:[],
   }

  customerSignUp(){
    return this.http.post(environment.apiPath+"/api/customerSignUp",this.signupFormObj,{
     
      }).pipe(map(response => {
      return response;
    }));
  }
  validateOTP(){
    return this.http.post(environment.apiPath+"/api/validateOTP",this.validateOtpObj,{
     
    }).pipe(map(response => {
    return response;
  }));
  }

  resendVerification(){
    return this.http.post(environment.apiPath+"/api/reSendOTP",this.resendOtpObj,{
     
    }).pipe(map(response => {
    return response;
  }));
  }
 

  verifyEmail(emailData){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+"/api/generateOTP",emailData ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }
  validateEmailOtp(inputData){
    // http://52.5.190.96:9090/shipcarte/api/validateOTP
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+"/api/validateOTP",inputData ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }
  verifyMobile(mobileData){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+"/api/generateOTP",mobileData ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }
  validateMobileOtp(inputData){
    // http://52.5.190.96:9090/shipcarte/api/validateOTP
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+"/api/validateOTP",inputData ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }



  forgetpassword(inputData){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+ "/api/emailResetPassword",inputData ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }


  paymentOrder(paymentOrder){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+ "/sc/quote/payment/ws/save",paymentOrder ,{
      headers: myheader,withCredentials:true,observe: 'response'
      }).pipe(map(response => {
      return response;
    }),
    catchError( error =>{
      return throwError(error);
    })
    );
  }

  resetPassword(password){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+ "/api/resetPassword",password ,{ withCredentials:true,
      headers: myheader
      }).pipe(map(response => {
      return response;
      }),
      catchError( error =>{
        return throwError(error);
      })
      );

  }

  paymentCheckout(detailObject){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath+ "/sc/payment/creditcard/charge",detailObject ,{ withCredentials:true,
      headers: myheader
      }).pipe(map(response => {
      return response;
      }),
      catchError( error =>{
        return throwError(error);
      })
      );
    }

    payConslidateInvoice(obj){
      const myHeader=new HttpHeaders().set("Content-Type",'application/json');
      return this.http.post(environment.apiPath+'/sc/payment/creditcard/chargeByParentInvId',obj,{withCredentials:true,headers:myHeader})
      .pipe(map(response=>{

      }),catchError(error=>{
        return throwError(error);
      }))
    }


  authorizeLogin(){
    return this.http.get(environment.apiPath+ "/api/authorizeLogin",{ withCredentials: true });
  }


}



