import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';



@Injectable({
  providedIn: 'root'
})
export class MergePDFService {

  constructor(private http: HttpClient) {
    // pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }

  // convertToImage(pdfLink: string): Observable<string[]> {
  //   return new Observable<string[]>((observer) => {
  //     pdfjs.getDocument(pdfLink).promise.then((pdf) => {
  //       const images = [];
  //       const promises = [];

  //       for (let pageNumber = 1; pageNumber <= pdf.numPages; pageNumber++) {
  //         promises.push(
  //           pdf.getPage(pageNumber).then((page) => {
  //             const canvas = document.createElement('canvas');
  //             const context = canvas.getContext('2d');

  //             const viewport = page.getViewport({ scale: 1.5 });
  //             canvas.width = viewport.width;
  //             canvas.height = viewport.height;

  //             const renderContext = {
  //               canvasContext: context,
  //               viewport: viewport,
  //             };

  //             const renderTask = page.render(renderContext);

  //             renderTask.promise.then(() => {
  //               const dataUrl = canvas.toDataURL('image/jpeg');
  //               images.push(dataUrl);
  //               if (images.length === pdf.numPages) {
  //                 observer.next(images);
  //                 observer.complete();
  //               }
  //             });
  //           })
  //         );
  //       }

  //       Promise.all(promises).catch((error) => {
  //         observer.error(error);
  //       });
  //     });
  //   });
  // }
  convertPdfToBase64(pdfUrl: any): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.http.get(pdfUrl, { responseType: 'arraybuffer' }).subscribe(
        (data) => {
          // Step 2: Security Check (Optional)
          // You can add your security checks here if needed

          // Step 3: Encode the PDF as Base64
          const pdfBytes = new Uint8Array(data);
          const safeBase64 = this.arrayBufferToBase64(pdfBytes);

          // Resolve the promise with the safe Base64 representation
          resolve(safeBase64);
        },
        (error) => {
          // Reject the promise with the error
          reject(error);
        }
      );
    });
  }
  
  convertImageToBase64(imageUrl: string): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      // Assuming that the server supports CORS
      this.http.get(imageUrl, { responseType: 'arraybuffer' }).subscribe(
        (data) => {
          // Encode the image as Base64
          const imageBytes = new Uint8Array(data);
          const base64 = this.arrayBufferToBase64(imageBytes);
  
          // Resolve the promise with the Base64 representation
          resolve(base64);
        },
        (error) => {
          // Reject the promise with the error
          reject(error);
        }
      );
    });
  }
  private arrayBufferToBase64(buffer: Uint8Array): string {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }
}
