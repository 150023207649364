<div class="container" style="position: static; padding: 0 !important; height: 100%;" [ngStyle]="{'width':calculated?'1080px':'unset'}">
    <div class="row disCal1"  *ngIf="!calculated" style="max-width: 785px;margin:0 !important;   display: flex; align-content:space-between; background: #fff;padding: 20px;max-width: 803px !important; border-radius: 18px !important;">
        <div class="p-0">
            <div style="display: flex; justify-content: space-between !important; align-items: center !important;">
                <div style="font-size: 32px !important; color:#354052 !important; font-weight:500 !important; letter-spacing: 0px; line-height: 35px;">
                    Calculate Distance
                </div>
                <div style="height: 21px; width:21px; color: #354052 !important; cursor: pointer; position: relative;" (click)="closeModal()">
                    <span style="height: 100%; width: 1px; display: block;position: absolute;border: 1px solid #354052;rotate: 45deg;left: 9px;"></span>
                    <span style="height: 100%; width: 1px; display: block;position: absolute;border: 1px solid #354052;rotate:-45deg;left: 9px;"></span>
                </div>
            </div>
            <form class="m-0" [formGroup]="addForm">
                <div class="form-group logistics_section mt-0 mb-0" style="padding: 36px 0 55px 0 !important;">
                    <label for="exampleInputEmail1" class="d-block">
                        <span class="float-left" style="font-size: 23px !important;color: #354052 !important;" >
                            Origin
                            <img style="width: 20px;" src="assets/img/quotes/SwitchVertical1.svg" class="m-2 text-center" width="12%">
                        </span>
                        <span class="float-right logistics_text_icon_box" style="margin-top: 9px;" >
                            <span style="font-size: 16px !important;color:#354052;">Google Maps</span>
                            <img src="assets/img/quotes/google-maps.svg" class="location_icon m-1 text-center"  style="width: 25px;height: 18px">
                            <!-- <span class="d-inline">
                                <div class="form-check form-switch" style="display: inherit;
                                    margin-top: 7px;">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked">
    
                                </div>
                            </span>
                            <span class="grey-text float-right" style="margin-top: 4%;">Manual Look Up</span> -->
                        </span>
                    </label>
                    <input style="padding: 11px 18px !important;
                        width: calc(100% - 38px);
                        height: calc(100% - 22px) !important;
                        color: #354052 !important;" #addressInput class="form-control" placeholder="Search Origin"
                        ngx-google-places-autocomplete #placesRef="ngx-places" formControlName="origin" [value]=""
                        (onAddressChange)="originChange($event)"  />
                    <span class="red_alert_msg" *ngIf="showerror">
                        Please select specific Origin address type!
                    </span>
                </div>
                <div class="form-group logistics_section mt-0 mb-0"> 
                    <label for="exampleInputEmail1" class="d-block">
                        <span class="float-left" style="font-size:23px !important; color: #354052 !important;">
                            Destination
                            <img src="assets/img/quotes/SwitchVertical1.svg" class="m-2 text-center" width="12%">
                        </span>
                        <span class="float-right logistics_text_icon_box" style="margin-top: 9px;">
                            <span style="font-size: 16px !important; color:#354052;">Google Maps</span>
                            <img style="width: 25px;height: 18px " src="assets/img/quotes/google-maps.svg" class="location_icon m-1 text-center">
                            <!-- <span class="d-inline">
                                <div class="form-check form-switch" style="display: inherit;
                                    margin-top: 7px;">
                                    <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked">
    
                                </div>
                            </span>
                            <span class="grey-text float-right" style="margin-top: 4%; ">Manual Look Up</span> -->
                        </span>
                    </label>
                    <input style="padding: 11px 18px !important;
                        width: calc(100% - 38px);
                        height: calc(100% - 22px) !important;
                        color: #354052 !important;"  #addressInput1 class="form-control" formControlName="destination" placeholder="Search Destination"
                        ngx-google-places-autocomplete #placesRef="ngx-places" [value]=""
                        (onAddressChange)="destinationchange($event)" placeholder="Search Destination"
                        name="test" />
                    <div class="red_alert_msg" *ngIf="showShipperWarningAdd">
                        Please select specific Destination address type!
                    </div>
                </div>
            </form>
        </div>
        <div style="display: flex; justify-content: space-between; align-items: center;" class="p-0">
            <div class="d-flex align-items-center">
                <button type="reset" (click)="addressInput.value = ''; addressInput1.value = ''" class="p-0"style="text-transform: capitalize; opacity: 1; color: #2877C6; font-size:21px; font-weight:500; cursor: pointer; background: none; border: none; "  (click)="reset()">  <img class="resetIcon"
                     src="assets/img/fi-rr-undo-alt.svg" /> Reset Fields 
                   
           </button>
            </div>
            <div class="text-right">
                <button class="btn m-0" style="box-shadow: none;text-transform: capitalize; font-size:20px !important;font-weight: 600 !important; color:#354052 !important;min-height: 45px; padding-left: 27px; padding-right: 27px; margin: 0;"(click)="closeModal()">Cancel</button> &nbsp;&nbsp;
                <button type="submit m-0" class="btn orange text-white" style="margin: 0 !important; box-shadow: none;text-transform: capitalize;font-size: 21px !important;font-weight: 600 !important;padding-bottom: 37px !important;" (click)="calculateDistance()">Calculate Distance</button>
            </div>
        </div>
    </div>

    <!-- ############################################################################################################# -->
    <div *ngIf="calculated" style="max-width: 1080px; font-weight:500 !important;">
        <div class="row disCal2 m-0" style="padding: 0 !important;" *ngIf="calculated"> 
            <div class="col-12 col-md-6 shipment-summary-right" style="padding: 26px 31px 29px 36px; overflow-y: scroll; height: 100%;">
                <div style="display: flex; justify-content: space-between !important;padding-bottom: 20px !important; background: #fff !important;">
                    <div style="font-size: 32px !important; color:#354052 !important; font-weight:500 !important; letter-spacing: 0px; line-height: 35px;">Calculate Distance Results</div>
                    <div style="font-size: 40px !important; font-weight: 400; color: #354052 !important; cursor: pointer; padding-top: 10px " (click)="closeModal()" >&#215;</div>
                </div>
                <div class="history-tl-container">
                    <ul class="tl">
                        <li class="tl-item" ng-repeat="item in retailer_history">
                            <div style="padding-bottom: 11px;" class="item-title"><span style="font-size: 23px !important; color: #354052 !important;" class="text-dark">Origin Address:</span></div>
                            <div style="padding-bottom: 95px !important;" class="item-detail">
                                <div class="inner-sec">
                                    <div class="white-bg " style="list-style-type: none;">
                                        <div style="font-size: 18px !important; color: #354052 !important; font-weight:500 !important;" class="text-dark">{{fromorigin}}</div>
                                        <div style="padding-top:85px !important; font-size: 18px !important; color: #7F8FA4 !important; padding-bottom: 5px;" class="text-grey mt-1">Distance:</div>
                                        <div style="font-size: 18px !important; color: #354052 !important" class="text-dark"> {{distanceInMiles | number: '1.2-2' }} Miles /{{distancecal}}</div>
                                        <div style="padding-top:20px !important; font-size: 18px !important; color: #7F8FA4 !important;padding-bottom: 5px;" class="text-grey mt-1">Duration:</div>
                                        <div style="font-size: 18px !important; color: #354052 !important" class="text-dark">{{durationcal}}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="tl-item" ng-repeat="item in retailer_history">
                            <div style="padding-bottom: 11px;" class="item-title">
                                <span style="font-size: 23px !important; color: #354052 !important;" class="text-dark">Destination Address:</span>
                            </div>
                            <div class="item-detail">
                                <div class="inner-sec">
                                    <div class="white-bg" style="list-style-type: none;">
                                        <div style="font-size: 18px !important; color: #354052 !important" class="text-dark">{{todest}}</div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="text-right mt-2" style="padding-top: 15% !important;">
                    <button type="button" class="btn faint-grey " style=" color: #354052 !important; box-shadow: none;text-transform: capitalize;background-color: #fff !important; font-size: 18px !important; font-weight: 600 !important; min-height: 45px;padding-left: 27px; padding-right: 27px; margin: 0;"
                        (click)="back()">Back to Distance</button>
                    &nbsp;&nbsp; <button type="submit" class="btn orange text-white " (click)="closeModal()"
                        style="box-shadow: none;text-transform: capitalize; width: 114px !important; font-size: 18px !important; font-weight: 600 !important; height: 48px; padding: 11px 33px;">Done</button>
                </div>
            </div>
            <div class="col-12 col-md-6 p-0">
                <agm-map [latitude]="latlng[0][0]" [longitude]="latlng[0][1]" [fitBounds]="false" [zoom]="zoom" #AgmMap>
                    <agm-direction [origin]="origin" [destination]="destination"></agm-direction>
                    <agm-marker *ngFor="let coordinate of latlng; let i=index" [latitude]="coordinate[0]"
                        [longitude]="coordinate[1]" [agmFitBounds]="true"></agm-marker>
                    <agm-polyline [visible]="false" [strokeWeight]="3" [strokeColor]="'#07b57a'">
                        <agm-polyline-point *ngFor="let coordinate of latlng; let i=index" [latitude]="coordinate[0]"
                            [longitude]="coordinate[1]">
                        </agm-polyline-point>
                    </agm-polyline>
                </agm-map>
            </div>
        </div>
    </div>
    
</div>