<!DOCTYPE html>
<html>

<body>	
	<form [formGroup]="varifyMobile" (ngSubmit)="onSubmit()"  class="p-0 bg-white verify-mobile-form" >
        <div class="otp_popup">
            <label for="otpMobile" class="float-left">Phone Number OTP</label>
            <input type="text" formControlName="otpMobile" aria-describedby="mobileHelp" placeholder="Enter Phone Number OTP" [ngClass]="varifyMobile.controls['otpMobile'].invalid ? 'is-invalid' : ''" />
            <div *ngIf="( varifyMobile.controls['otpMobile'].invalid) ? 'is-invalid' : ''">
                <label class="col-6 p-0" [ngClass]="varifyMobile.controls['otpMobile'].hasError('required')   ? 'text-danger' : 'text-success'">
                  <i class="material-icons">{{ varifyMobile.controls['otpMobile'].hasError('required')  ? 'clear' :
                    'done' }}</i>
                  <small class="error-align">OTP is required</small>
                </label>
                 
              </div>
            
            <div *ngIf="errorMsg" class="orange-text">{{errorMsg}}</div>
            <div id="emailHelp" class="mb-3" >
                <button type="submit" [disabled]="otpsubmit" class="resend float-right ">submit</button>
                <!-- <a href="#" class="float-left">Resend</a>  -->
            </div>
            
            <div  class="btn orange text-white m-auto col-3 d-block mt-5" #closeBtn data-dismiss="modal" (click)="closeModal()">Close</div>          
        </div>
            
        <br>
    </form>
    </body>
</html>