import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from '../../../../services/common.service';
import { CalculateDistanceComponent } from '../calculate-distance/calculate-distance.component';
import { Router } from '@angular/router';
@Component({
  selector: 'app-zip-postal-codes',
  templateUrl: './zip-postal-codes.component.html',
  styleUrls: ['./zip-postal-codes.component.css']
})
export class ZipPostalCodesComponent implements OnInit {
  addressObject:any;
  addressStatus:any;
  zipcode:any;
  submitted:boolean;
  invalidCode: boolean;
  constructor(public activeModal: NgbActiveModal,public commonService:CommonService,private modalService:NgbModal,private router:Router) { }

  ngOnInit(): void {
    this.invalidCode = false;

  }

  closeModal() { 
    this.activeModal.close(); 
  }
  
  getAddresses(zipcode){
    // console.log(zipcode)
    this.submitted = true;
    if(!this.zipcode){
      this.invalidCode = true;
      return;
    }

    // this.zipcode = zipcode;
    this.invalidCode = false;
    this.commonService.getAddressByZip(this.zipcode).subscribe(result =>{     
      this.addressObject = result;
      this.addressStatus = false;
      this.submitted = false;
    }, (error) => {
      this.submitted = true; //for show error
      this.zipcode = '';
      this.invalidCode = true;
    });

  }
  open_modal(){
    this.closeModal();
  const modalRef = this.modalService.open(CalculateDistanceComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3 cssStyle' });
  this.router.events.subscribe((val) => {
    modalRef.close();
  });
  modalRef.result.then((result) => {
 });
}


resetField(){
  this.zipcode='';
}


}
