import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-address-delete-confirm',
  templateUrl: './address-delete-confirm.component.html',
  styleUrls: ['./address-delete-confirm.component.css']
})
export class AddressDeleteConfirmComponent implements OnInit {
result : number ;
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  closeModal() {
    this.activeModal.close();
  }
  submit(){
    
    this.activeModal.close(true);
  }
}