<div id="body-pd" class="body-pd {{siderbarToggle}}">
    <div class="l-navbar col1" id="nav-bar">
        <nav class="nav">
            <div style="width: 100%;">
                <a (click)="redirectToDhashboard()" class="nav_logo m-0 d-flex align-items-center justify-content-center"
                    style="width: 100%;grid-template-columns: auto; text-align: center; padding: 28px 0;">
                    <img class="imgExtended imgWidth" style="width:75%; height: 100%; margin: 0;"
                        src="assets/img/sidebar/ShipCarte-Logo2.png">
                    <img class="imgCollapsed" src="assets/img/sidebar/BLACK.png" style="width:75%;">
                </a>
                <div class="nav_list">
                    <!-- <div>
                        <a class="nav_link blank-item"></a>
                    </div> -->
                    <div *ngFor="let nav of navLeftObject">
                        <div *ngIf="nav.data.includes(userRole)" style="position: relative;display: flex;
                        flex-wrap: wrap; align-items: center;
                        width: 100%;">
                            <a href="{{nav.href}}" class="nav_link"
                                [ngClass]="href.includes('get-quote') ? (href==nav.href ? 'active': '' ) : (href.includes(nav.href) ? 'active': '' )"
                                title="{{nav.title}}">
                                <span class="icon-box">
                                    <img src="assets/img/sidebar/{{nav.styleCode}}.svg">
                                </span>
                                <span class="nav_name">{{nav.label}}</span>
                            </a>
                            <span *ngIf="nav.child1" (click)="showChild(nav.href)"
                                style="position: absolute; right:0; padding: 15px; line-height: normal;">
                                <img style="pointer-events: fill;filter: invert(59%) sepia(49%) saturate(1921%) hue-rotate(0deg) brightness(102%) contrast(107%); opacity: 1 !important;" src="assets/img/Currier-Selection/dropdown_arrow.svg"
                                    alt="" [ngClass]="showChildArr.includes(nav.href) ? 'close':'open'">
                            </span>
                        </div>
                        <div *ngIf="showChildArr.includes(nav.href)">
                            <div *ngFor="let navChild of nav?.child1">
                                <a *ngIf="navChild.data.includes(userRole)" title="{{navChild.title}}"
                                    href="{{navChild.href}}" class="nav_link" style="padding-left: 32px;"
                                    [ngClass]="href.includes('get-quote') ? (href==navChild.href ? 'active': '' ) : (href.includes(navChild.href) ? 'active': '' )">
                                    <span class="icon-box">
                                        <img src="assets/img/sidebar/{{navChild.styleCode}}.svg">
                                    </span>
                                    <span class="nav_name">{{navChild.label}}</span>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- <ngb-accordion [closeOthers]="true" activeIds="static-0-header">
                        <ngb-panel id="static-{{i}}" title=""
                            *ngFor="let nav of navLeftObject;let i=index "
                            style="box-shadow: 0px 3px 6px #00000029!important;">
                            <ng-template ngbPanelTitle class="m-0 p-0 shadow">
                                <a *ngIf="nav.data.includes(userRole)" title="{{nav.title}}" href="{{nav.href}}" class="nav_link "
                                [ngClass]="href.includes('get-quote') ? (href==nav.href ? 'active': '' ) : (href.includes(nav.href) ? 'active': '' )">
                                    <span class="icon-box">
                                        <img src="assets/img/sidebar/{{nav.styleCode}}.svg">
                                    </span>
                                    <span class="nav_name">{{nav.label}}</span>
                                    <span *ngIf="nav.child1" (click)="showChild(nav.href)">
                                        <img style="padding: 25px 30px; pointer-events: fill;"
                                            src="assets/img/Currier-Selection/dropdown_arrow.svg" alt="">
                                    </span>
                                </a>
                            </ng-template>

                            <ng-template ngbPanelContent class="faint-grey">
                                <div *ngFor="let navChild of nav.child1">
                                    <a *ngIf="navChild.data.includes(userRole)" title="{{navChild.title}}" href="{{navChild.href}}" class="nav_link "
                                    [ngClass]="href.includes('get-quote') ? (href==navChild.href ? 'active': '' ) : (href.includes(navChild.href) ? 'active': '' )">
                                        <span class="icon-box">
                                            <img src="assets/img/sidebar/{{navChild.styleCode}}.svg">
                                        </span>
                                        <span class="nav_name">{{navChild.label}}</span>
                                        <span *ngIf="navChild.child2" (click)="showChild(navChild.href)">
                                            <img style="padding: 25px 30px; pointer-events: fill;"
                                                src="assets/img/Currier-Selection/dropdown_arrow.svg" alt="">
                                        </span>
                                    </a>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion> -->
                </div>
            </div>
            <div style="margin-top: 70px;">
                <hr>
                <span class="version">Version: {{webVersion}}</span>
                <a (click)="redirectToSettings()" href="javascript:void(0)" class="nav_link">
                    <span class="icon-box"><img src="assets/img/sidebar/setting.svg"></span>
                    <span class="nav_name">Settings</span>
                </a>
                <a class="nav_link" style="cursor: pointer;" (click)="logout()">
                    <span class="icon-box">
                        <img src="assets/img/sidebar/log_out.svg">
                    </span>
                    <span class="nav_name">Log Out</span>
                </a>
            </div>
        </nav>
    </div>
    <div class="top_header custom-sidebar d-flex align-items-center justify-content-between"
        style="float:none; height: 65px;">
        <div>
            <div class="header_toggle_desk" (click)="toggleSidebar()">
                <img id="header-toggle" src="assets/img/sidebar/toggle.svg">
            </div>
            <div class="header_toggle_mobile" placement="bottom" popoverClass="popover_sidebar"
                [ngbPopover]="popSidebar">
                <img id="header-toggle" src="assets/img/sidebar/toggle.svg">
            </div>
            <div class="header-btn-box" style="position: static;">
                <button type="button" class="btn btn-warning" style="cursor: pointer;" placement="bottom"
                    popoverClass="popover_quick" [ngbPopover]="popQuickAction">Quick Actions</button>
            </div>
            <div class="header-btn-box plus" style="position: relative;"><button type="button" class="btn btn-warning"
                    style="cursor: pointer;" placement="bottom" popoverClass="popover_quick"
                    [ngbPopover]="popQuickAction">+</button>
            </div>
            <div class="available_credit_box d-flex">
                <img style="height: 20px;" src="assets/img/sidebar/green_doller.svg">
                <span class="credit_heading">Available Credit : &nbsp;</span>
                <span class="credit_count"> $ {{commonData.creditLimit && commonData.creditLimit.availableCreditLimit ?
                    (commonData.creditLimit.availableCreditLimit| number : '1.2-2') : (0 | number : '1.2-2')}} <img src="assets/img/shipment/CANADA.svg" width="20px" height="20px" alt="" style="margin: 0; margin-top: -5px;">
                    &nbsp;</span>
                <span class="credit_count">/&nbsp; $ {{commonData.creditLimit &&
                    commonData.creditLimit.availableCreditLimitUsd ?
                    (commonData.creditLimit.availableCreditLimitUsd| number : '1.2-2') : (0 | number : '1.2-2')}} <img src="assets/img/shipment/USA.svg" width="20px" height="20px" alt="" style="margin: 0; margin-top: -5px;">
                    &nbsp;</span>
            </div>
            <div class="header_search_box" style="display: flex;">
                <input placeholder="Search by BOL, Tracking, Order Number, or Customer Number…" style="height: 1.3rem;"
                    type="search" id="gsearch" name="gsearch">
            </div>
        </div>
        <div>
            <div class="support_ticket_box d-flex align-items-center">
                <span class="support-tick d-flex">
                    <img class="ticket_icon" src="assets/img/sidebar/support_ticket_icon.svg">
                    <span class="m-0">Support Ticket</span>
                </span>
                <span><img src="assets/img/sidebar/question_circle.svg"
                        style="cursor: pointer; margin: 3px 0px 2px 0px;" placement="bottom" popoverClass="popover_help"
                        [ngbPopover]="popHelp"></span>
                <span><img
                        src="assets/img/sidebar/notification{{notificationObject && notificationObject?.unreadCount? '': 'nomsg'}}.svg"
                        (click)="getNotifications()" style="cursor: pointer;margin: 3px 0px 2px 0px;" placement="bottom"
                        popoverClass="popover_notification" [ngbPopover]="popNotification"></span>
                <span class="profile_img">
                    <img style="width: 45px; height: 45px; border: radius 50px;" class="img-circle"
                        src="{{userData && userData.profile_image ? userData.profile_image : 'assets/img/sidebar/avatar5.svg'}}"
                        alt="">
                       
                </span>   
                <div class="nav-item dropdown no-arrow pull-right" style="display: flex; ">
                    <a class="dropdown-toggle nav-link" style="width: 100%; height: 32px !important;"
                        data-toggle="dropdown" aria-expanded="false">
                    </a>
                    <div class="dropdown-menu shadow dropdown-menu-right animated--grow-in pull-right"
                        role="menu">    
                        <a class="dropdown-item mouse-hover" role="presentation" (click)="profile()" style="font-size: 14px;">&nbsp;Profile</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item mouse-hover" role="presentation" (click)="Setting()" style="font-size: 14px;">&nbsp;Setting</a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item mouse-hover logout" role="presentation" (click)="Logout()" style="font-size: 14px;">&nbsp;Logout</a>
                    </div>
                </div> 
            </div>
        </div>
    </div>
</div>
<ng-template #popNotification>
    <div class="bg-light p-3 f-14">
        Notification
        <button type="button" class="close float-right ">
            <span aria-hidden="true">
                <span class="material-icons">
                    close
                </span>
            </span>
        </button>
    </div>
    <div class="p-3 ">
        <div class="card p-2" *ngIf="notificationStatus">
            <div class="row mb-0">

                <div class="col-12">

                    <div class="f-11">
                        {{notificationStatus}}

                    </div>

                </div>
            </div>

        </div>
        <div class="card p-2" *ngFor="let noti of notificationObject['notifications']">
            <div class="row mb-0">
                <div class="col-2">
                    <img src="assets/img/sidebar/note1.svg">
                </div>
                <div class="col-10">
                    <div class="grey-text f-11">
                        <!-- December 12, 2019 -->
                        {{noti.createdDate}}
                    </div>
                    <div class="f-11">
                        {{noti.title}}
                        <br>
                        {{noti.message}}
                    </div>

                </div>
            </div>

        </div>

    </div>
</ng-template>
<ng-template #popHelp>
    <div class="bg-light p-3 f-14">
        Help
        <button type="button" class="close float-right ">
            <span aria-hidden="true">
                <span class="material-icons">
                    close
                </span>
            </span>
        </button>
    </div>
    <div class="p-3">
        <div class="row op-05 cursor-p">
            <div class="col-2">
                <img src="assets/img/sidebar/help1.svg">
            </div>
            <div class="col-10">
                Appendix
            </div>
        </div>
        <div class="row cursor-p" (click)="redirectToResources(0)">
            <div class="col-2">
                <img src="assets/img/sidebar/help2.svg">
            </div>
            <div class="col-10">
                VIDEO TUTORIALS
            </div>
        </div>
        <div class="row cursor-p" (click)="redirectToResources(1)">
            <div class="col-2">
                <img src="assets/img/sidebar/help3.svg">
            </div>
            <div class="col-10">
                FAQ
            </div>
        </div>

        <div class="row cursor-p" (click)="redirectToResources(2)">
            <div class="col-2">
                <img src="assets/img/sidebar/help4.svg">
            </div>
            <div class="col-10">
                O-BOOK
            </div>
        </div>
        <div class="row cursor-p op-05">
            <div class="col-2">
                <img src="assets/img/sidebar/help5.svg">
            </div>
            <div class="col-10">
                Support
            </div>
        </div>
        <div class="row cursor-p op-05">
            <div class="col-2">
                <img src="assets/img/sidebar/help6.svg">
            </div>
            <div class="col-10">
                Create a Ticket
            </div>
        </div>
        <div class="row cursor-p op-05">
            <div class="col-2">
                <img src="assets/img/sidebar/help7.svg">
            </div>
            <div class="col-10">
                Leave a Feedback
            </div>
        </div>
        <a style="position: absolute;
        bottom: 10px;" class="blue-text">Shipcarte.com</a>
    </div>
</ng-template>
<ng-template #popQuickAction>

    <div class="p-3" style="font-family: poppins,sans-serif;">
        <div class="f-14" style="font-size: 22px;">
            Quick Actions
            <button type="button" class="close float-right ">
                <span aria-hidden="true">
                    <span class="material-icons">
                        close
                    </span>
                </span>
            </button>
        </div>
        <div class="row mt-3">
            <div (click)="redirectToGetQuote()" class="orange-text" style="cursor: pointer;color: #2877C6 !important;font-size: 16px;">
                Create A Quote &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <!-- <div>
                Generate a new quote
            </div> -->
        </div>
        <div class="row mt-3">
            <div class="orange-text" (click)="redirectToFavourite()" style="cursor: pointer;color: #2877C6 !important;font-size: 16px;">
                View Favorite Orders &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <!-- <div>
                Generate a new quote
            </div> -->
        </div>
        <div class="row">
            <div (click)="redirectToCreateBillOfLading()" class="orange-text"
                style="cursor: pointer;color: #2877C6 !important;font-size: 16px;">
                Create Bill of Lading &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <div style="font-size:15px; color: #000000;font-weight:400;">
                Save time and use a bill of lading template
            </div>
        </div>
        <div class="row">
            <div (click)="redirectToQuickCommercialInvoice()" class="orange-text"
                style="cursor: pointer;color: #2877C6 !important;font-size: 16px;">
                Create Commercial Invoice &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <div style="font-size:15px; color: #000000;font-weight:400;">
                Save time and use a commercial invoice template
            </div>
        </div>
        <div class="row">
            <div class="orange-text" style="cursor: pointer;color: #2877C6 !important;font-size: 16px;"
                (click)="open_modal('freightmodal')">
                <!-- (click)="open_modal('freightmodal')" -->
                Freight Class Calculator &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <div style="font-size:15px; color: #000000;font-weight:400;">
                Calculate to find your freight class
            </div>
        </div>
        <div class="row">
            <div class="orange-text" style="cursor: pointer;color: #2877C6 !important;font-size: 16px;"
                (click)="open_modal('dimensionmodal')">
                <!-- (click)="open_modal('dimensionmodal')" -->
                Dimensional Weight Calculator &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <div style="font-size:15px; color: #000000;font-weight:400;">
                Calculate your dimensional weight
            </div>
        </div>
        <div class="row">
            <div class="orange-text" style="cursor: pointer;color: #2877C6 !important;font-size: 16px;"
                (click)="open_modal('distancemodal')">
                <!-- (click)="open_modal('distancemodal')" -->
                Distance Calculator &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <div style="font-size:15px; color: #000000;font-weight:400;"> 
                Find the distance of the location you wish to deliver too
            </div>
        </div>
        <div class="row">
            <div class="orange-text" style="cursor: pointer;color: #2877C6 !important;font-size: 16px;"
                (click)="open_modal('zipmodal')">
                <!-- (click)="open_modal('zipmodal')" -->
                Find Zip/Postal Codes &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <div style="font-size:15px; color: #000000;font-weight:400;">
                Find zip/postal codes of locations
            </div>
        </div>
        <div class="row">
            <!-- style="opacity: 0.5; -->
            <div class="orange-text" style="cursor: pointer;color: #2877C6 !important;font-size: 16px;">

                Supplies Request &nbsp; <img src="assets/img/sidebar/left-arr.svg">
            </div>
            <div style="font-size:15px; color: #000000; font-weight:400;">
                Order supplies from our partners
            </div>
        </div>
    </div>
</ng-template>
<ng-template #popSidebar>

    <app-leftsidebar></app-leftsidebar>

</ng-template>
