import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
// import { environment } from './../../../environments/environment.prod';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfirmOrderService {
  baseUrl: any;
  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };
  constructor(private http: HttpClient) { }

  GetShipmentItems(quoteId) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/api/quote/getDetailsById?quoteId=" + quoteId + "", {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }
  getPrimaryContactList(contactType, companyName) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + '/api/getPrimaryContactList?contactType=' + contactType + '&custId=' + JSON.parse(localStorage.getItem('loggedInUserDetailsFullData')).loggedInUserId + '&companyName=' + companyName, { withCredentials: true, headers: myheader }).pipe(map(response => {
      return response
    }))
  }
  savePrimaryContactInfo(data) {
    return this.http.post(environment.apiPath + "/api/savePrimaryContact", data, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  getAllBrokers() {
    // const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    // return this.http.get(environment.apiPath + "/sc/customs/broker/names/get/all", {
    //   withCredentials: true,
    //   headers: myheader
    // }).pipe(map(response => {
    //   return response;
    // }));
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/api/customs/getAllBrokerInfo", {
      withCredentials: true,
      headers: myheader,
      observe: 'response'
    }).pipe(map(response => {
      // console.log(response,response.status)
      return response;
    }));
  }
  getBrokerList(url) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + '/api/customs/getAllBrokerInfo' + url, { withCredentials: true, headers: myheader, observe: 'response' }).pipe(map(response => {
      return response;
    }))
  }
  getFreightClass() {
    // http://shipcarte.pragralms.com:9090/shipcarte/sc/freight/class/type/ws/get/all
    return this.http.get(environment.apiPath + "/sc/freight/class/type/ws/get/all", { withCredentials: true });
  }
  getShippingHours() {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/sc/pickup/time/ws/get/all", {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }

  searchByKeyWord(data) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/api/addressBook/search?key=" + data, {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }

  saveBroker(data) {
    /*
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.post(environment.apiPath + "/sc/quote/ws/shipment/detail/save", data, {
        withCredentials: true,
        headers: myheader
      }).pipe(map((response: Response) => {
        return response;
      }));*/

    // return this.http.put(environment.apiPath + "/sc/quote/ws/shipment/detail/save", data, this.jsonHTTPOptions).pipe(map(response => {
    //   return response;
    // }));
    return this.http.post(environment.apiPath + "/api/customs/saveBrokerInfo", data, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));

  }
  getAllAddress() {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/sc/quote/ws/addr/get/all/0/5", {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }

  saveToAddressBook(data) {
    console.log("data from service", data);
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath + "/api/addressBook/save", data, {
      withCredentials: true,
      headers: myheader
    }).pipe(map((response: Response) => {
      return response;
    }));
  }
  sourceDes(data) {
    return this.http.put(environment.apiPath + "/sc/source/destination/address/ws/update?isEdit=true", data, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  getBOLNum(quoteId) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/api/bol/generateBOLNumber?quoteId=" + quoteId, {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }
  quoteDetails(quoteId) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/sc/quote/ws/get?quoteId=" + quoteId, {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }

  getDeliveryDetails(quoteid) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/sc/quote/delivery/detail/ws/get?quoteId=" + quoteid, {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));

  }
  getAllDropdownValues() {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/api/customs/getAllDropdownValues", {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));

  }
  hsTariffdescription(data) {
    return this.http.put(environment.apiPath + "/api/customs/hsTariff/description/update", data, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  saveCustoms(data) {
    console.log("data from service", data);
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath + "/api/customs/saveData", data, {
      withCredentials: true,
      headers: myheader
    }).pipe(map((response: Response) => {
      return response;
    }));
  }
  saveDataToAddressBook(data) {
    console.log("data from service", data);
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(environment.apiPath + "/api/addressBook/saveByQuoteId", data, {
      withCredentials: true,
      headers: myheader
    }).pipe(map((response: Response) => {
      return response;
    }));
  }
  getUpdateEditOrderPaymentDetails(quoteId) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + "/sc/quote/request/ws/updateEditOrderPayment?quoteId=" + quoteId, {
      withCredentials: true,
      headers: myheader
    }).pipe(map(response => {
      return response;
    }));

  }
  updateMarshInsuranceCarrierName(data,ctNumber){
    const myheader=new HttpHeaders().set('Content-Type','application/json');
    return this.http.post(environment.apiPath+'/api/sc/insurance/updateInsuranceQuote?certificateNumber='+ctNumber,data,{withCredentials:true})
  }
  cancelEdits(quoteId){
    const myheader=new HttpHeaders().set("Content-Type",'application/json');
    return this.http.get(environment.apiPath+'/sc/quote/request/ws/cancelEditOrder?quoteId='+quoteId,{withCredentials:true,headers:myheader}).pipe(map(response=>{
      return response;
    }))
  }
 
}