import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SignupService } from './../../../services/sign-up/signup.service';

@Component({
  selector: 'app-user-preferences-email',
  templateUrl: './user-preferences-email.component.html',
  styleUrls: ['./user-preferences-email.component.css']
})
export class UserPreferencesEmailComponent implements OnInit {
  displayErrorMessage = true;
  varifyOtp:FormGroup;
  submitted = false;
  @Input() public email;
  @Input() public userId;
  @Input() public mobile;
  @Input() public action;
  errorMessage:any
  constructor(private formBuilder:FormBuilder,private signupService:SignupService,public activeModal: NgbActiveModal) {
    this.varifyOtp = this.createOTP();
   }

  ngOnInit(): void {
    this.displayErrorMessage=false;
  }
  closeModal() { 
    this.activeModal.close(); 
  }
  createOTP(): FormGroup {
    return this.formBuilder.group(
      {
        otpEmail: [
          null,
          Validators.compose([ Validators.required])
        ],
      
        
      }
    );
  }
  get f() { return this.varifyOtp.controls; }

  onSubmit(){
    this.submitted = false;
    if (this.varifyOtp.invalid) {
      return;
    }
    let inputData:any;
    if(this.action == 'email'){
      inputData = 
      {
        "loggedInUserId": this.userId,
        "actorType": 'customer',
        "email": this.email,
        "otpCode": this.varifyOtp.value.otpEmail,
        "mobile": this.mobile
      }
    }else{
     inputData = 
      {
        "loggedInUserId": this.userId,
        "actorType": 'customer',
        "email": this.email,
        "otpCode": this.varifyOtp.value.otpEmail,
        "mobile": this.mobile
      }
    }
   
    this.signupService.validateEmailOtp(inputData).subscribe(result =>{
      console.log('validateEmailOtp here',result)
      if(result['success'] == "true"){
        this.activeModal.close({success:true,msg:result['Message']})
      }
    },error=>{
      console.log(error)
      this.displayErrorMessage = false;   
      this.errorMessage = error['error']['Message'];
      this.activeModal.close({success:false,msg:this.errorMessage}) 
    })
  }
}
