// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
 
 production: false,
 apiPath:'https://api.shipcarte.com/shipcarte',
 GooglePlacesApiKey: 'AIzaSyCX954-_oa1DJ-W9W0tI9kmcZKwFraRdn8',
 apiAuthURL:'https://shipcarte2.ebizonstaging.com/shipcarte-backend/shipcarte',
 Uiversion: '2.24',
 Cyear: '2023'
  
  };
 


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
