import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Http2ServerRequest } from 'http2';
import { map } from 'rxjs/operators';
// import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShipmentListingService {

  constructor(private http:HttpClient) { }

  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };

  getShipmentItems(page,perPage,url){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/order/getAll/"+page+"/"+perPage+url,{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));
  }

  getShipmentTransitItems(page,filterParms,perPage,status){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/order/getAll/"+page+"/"+perPage+ "?"+(filterParms ? filterParms +"&":"") + "shipmentStatus="+status,{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{ 
        return error;
      }));
  }
  getPodDoc(orderId,ladingNo){
    const myHeader=new HttpHeaders().set('Content-Type','application/json');
    return this.http.get(environment.apiPath+'/api/sc/saas/getPodDoc?orderId='+orderId+ (ladingNo ? '&ladingNo='+ladingNo : ''),
      {withCredentials:true,headers:myHeader}).pipe(map(response=>{
      return response;
    },error=>{
      return error;
    }))
  }

  getFavouriteListing(page, perPage,url){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/order/getAll/"+page+"/"+perPage+url,{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));
  }


  // updateItems(page,filterParms,perPage){
  //   const myheader = new HttpHeaders().set('Content-Type', 'application/json');
  //   return this.http.put(environment.apiPath+ "/api/order/updateFavouriteStatus",{ withCredentials: true,
  //     headers: myheader
  //       }).pipe(map(response => {
  //       return response;
  //     },error=>{
  //       return error;
  //     }));
  // }

  updateItems(dataObj){
    return this.http.put(environment.apiPath + "/api/order/updateFavouriteStatus", dataObj, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  cancelOrder(orderId){
    return this.http.post(environment.apiPath+`/sc/ws/order/cancel?order-id=`+orderId,'',this.jsonHTTPOptions).pipe(map(response=>{
      return response;
    }))
  }
  
}
