<div id="DownloadPdfData1" class="col-12" style="border: none !important; padding: 10px 20px 0 20px !important;">
    <app-spinner></app-spinner>
    <!-- <iframe [src]="documentURL" width="100%" height="100%" style="width:100% !important; height: 800px !important; background-color: #FFF;"   embedded="true" scrolling="No"  frameborder="0"></iframe> -->
    <div style="display: flex; justify-content: end;"><img style="margin-right: 10px; margin-bottom: 10px; height: 15px;" (click)="closeModal()" src="assets/img/Currier-Selection/close-x.svg" alt=""></div>
    <iframe [src]="documentURL" width="100%" style="height: 80vh !important;" embedded="true" scrolling="No"  frameborder="0"></iframe>
      <div id="parentContainer" style="visibility: hidden;">
            <div style="background: #fff;margin: auto;width: 100%;
            overflow: hidden;">
                <div id="contentInvoice" #contentInvoice class="bol-page_section"
                    style="background: #fff;padding: 3px 5px;height: auto;border: 1px solid #D4D4D4; border-radius: 6px;float: left;">
    
                    <div>
                        <div>
                            <div class="blo_connaissement_left">
                                <img style="width: 200px;padding: 0px 0 0 0;" src="assets/img/shipcarte-logo-350x130.png"
                                    alt="" />
                            </div>
                            
                        </div>
                        <div *ngFor="let data of orderList; let j=index;">
                            <div class="float-right text-right" *ngIf="j==0">
                                <h3 class="mb-0" style="font-size: 15px !important;font-weight: 600 !important;">INVOICE No. {{data?data.parentInvoiceNumber?data.parentInvoiceNumber:data.invoiceNumber:'--'}}
                                </h3>
                                <!-- <h5 class="mb-0" style="font-size: 14px !important;font-weight: 500 !important;">{{data &&
                                    formattedDate ? formattedDate : ''}} </h5> -->
                                <!-- <small class="orange-text">Set Reminder</small> -->
    
                            </div>
                            <div class="blo_connaissement_section">
    
                                <div class="row blo_connaissement_right" *ngIf="j==0">
                                    <div class=" col-6 blo_left text-start" style="margin-left: -24px;">
                                        <div class="mt-3"
                                            style="font-size: 12px;font-family: poppins,sans-serif; font-weight: 600; ">BILL TO:</div>
                                        <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">
                                            <!-- {{data?.billTo.firstName}} {{data?.billTo.lastName}} -->
                                    </div>
                                        <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">
                                            {{data?.billTo.company ? data?.billTo.company : ''}} </div>
                                        <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">
                                            {{data?.billTo.addrLine1 ? data?.billTo.addrLine1 : ''}}
                                            {{data?.billTo.addrLine2 ? data?.billTo.addrLine2 : ''}} </div>
                                        <div style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;">
                                            {{data?.billTo.city ? data?.billTo.city : ''}}
                                            {{data?.billTo.state ? data?.billTo.state : ''}}
                                            {{data?.billTo.country ? data?.billTo.country : ''}}
                                            {{data?.billTo.zipCode ? data?.billTo.zipCode : ''}}</div>
                                    </div>
                                    <div class="col-6 blo_right text-end">
                                        <h5 class="mt-2 mb-0"
                                            style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                            Total : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{subTotal? subTotal.toFixed(2) : 0.00}}</span> </h5>
                                        <!-- <h3 class="mb-0 orange-text d-inline"
                                            style="font-size: 20px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                            ${{data && data?.amountDue ? data && data?.amountDue : 0}} </h3> -->
                                            <h5 class="mt-2 mb-0"
                                            style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                            Paid : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{amountPaid? amountPaid.toFixed(2) : 0.00}}</span> </h5>
                                            <h5 class="mt-2 mb-0"
                                            style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                            Balance Due : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{remainingAmount? remainingAmount.toFixed(2) : 0.00}}</span> </h5>
                                        <!-- <h3 class="mb-0 orange-text d-inline"
                                            style="font-size: 20px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                            ${{data && data?.amountDue ? data && data?.amountDue : 0}} </h3> -->
                                    </div>
                                </div>
                                <div class="order-details_section">
                                    <div class="order-details"
                                        style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                        <div><b>ORDER NO.</b></div>
                                  
                                        <div style="color:#FF8D00;">{{data && data?.orderNumber ? data && data?.orderNumber :
                                            ''}}
                                        </div>
                                    </div>
                                    <div class="order-details"
                                        style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                        <div><b>CUSTOMER REF NO.</b></div>
                                 
                                        <div>{{data?.poNumber!='' ? data?.poNumber : '--'}}</div>
                                    </div>
                                    <div class="order-details"
                                        style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                        <div><b>ACCOUNT NO.</b></div>
                                    
                                        <div>{{data && data?.accountNumber ? data && data?.accountNumber : ''}} </div>
                                    </div>
        
                                    <div class="order-details"
                                        style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;">
                                        <div><b>TERMS</b></div>
                                  
                                        <div>{{data && data?.terms ? data && data?.terms : ''}}</div>
                                    </div>
                                </div>
        
                                <div class="table_start">
                                    <div class="table-data">
                                        <div class="shipper_box mr-1" style="border-right: 1px solid #C4CDD5; width: 49%;">
                                            <div><b>SHIPPED:</b></div>
                                            <div>{{data && data?.orderDate | date: 'MM-dd-yyyy'}}</div>
        
                                        </div>
                                        <div class="consignee_box">
                                            <div><b>RECEIVED:</b></div>
                                            <div *ngIf="data?.deliveryDate!='--'">{{data?.deliveryDate | date: 'MM-dd-yyyy' }}</div>
                                                        <div *ngIf="data?.deliveryDate=='--'">{{data?.deliveryDate}}</div>
                                        </div>
                                    </div>
        
                                    <div class="table_start">
                                        <div class="table-data">
                                            <div class="shipper_box" style="border-right: 1px solid #C4CDD5; width: 49%;">
                                                <div
                                                    style="border-bottom: 1px solid #C4CDD5;padding-top: 8px;padding-bottom: 8px; background-color: #F5F6FA;">
                                                    <b>SHIPPER:</b>
                                                </div>
                                                <div>{{data?.shipper.firstName}} {{data?.shipper.lastName}}</div>
                                                <div>{{data?.shipper.company ? data?.shipper.company : ''}} </div>
                                                <div>{{data?.shipper.addrLine1 ? data?.shipper.addrLine1 : ''}}
                                                    {{data?.shipper.addrLine2 ? data?.shipper.addrLine2 : ''}} </div>
                                                <div>{{data?.shipper.city ? data?.shipper.city : ''}}
                                                    {{data?.shipper.state ? data?.shipper.state : ''}}
                                                    {{data?.shipper.country ? data?.shipper.country : ''}}
                                                    {{data?.shipper.zipCode ? data?.shipper.zipCode : ''}}</div>
                                            </div>
                                            <div class="consignee_box">
                                                <div
                                                    style="width:102%; border-bottom: 1px solid #C4CDD5;padding-top: 8px;padding-bottom: 8px; background-color: #F5F6FA;">
                                                    <b>CONSIGNEE:</b>
                                                </div>
                                                <div>{{data?.consignee.firstName}} {{data?.consignee.lastName}}</div>
                                                <div>{{data?.consignee.company ? data?.consignee.company : ''}} </div>
                                                <div>{{data?.consignee.addrLine1 ? data?.consignee.addrLine1 : ''}}
                                                    {{data?.consignee.addrLine2 ? data?.consignee.addrLine2 : ''}}
                                                </div>
                                                <div>{{data?.consignee.city ? data?.consignee.city : ''}}
                                                    {{data?.consignee.state ? data?.consignee.state : ''}}
                                                    {{data?.consignee.country ? data?.consignee.country : ''}}
                                                    {{data?.consignee.zipCode ? data?.consignee.zipCode : ''}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
        
                                <div class="pro_details_table">
                                    <table style="background-color: #F5F6FA !important;">
                                        <tr class="yellow-header" style="width: 100%;float: left;">
                                            <th style="width: 14%;padding-left:3px">QTY</th>
                                            <th style="width: 35%;">DESCRIPTION</th>
                                            <th style="width: 12%;">WEIGHT</th>
                                            <th style="width: 12%;">CLASS</th>
                                            <th style="width: 12%;">CHARGES</th>
                                            <th style="width: 12%;">ADJUSTMENTS</th>
                                        </tr>
                                        <tr *ngFor="let key of data?.items"
                                            style="padding: 0px 0px;border-bottom: 1px solid #C4CDD5;width: 100%;float: left;">
                                            <td style="width: 14%;padding-left:3px">{{key['qty'] ? key['qty'] : ''}}</td>
                                            <td style="width: 35%;">
                                                {{key['description'] ? key['description'] : ''}}
                                            </td>
                                            <td style="width: 12%;"> {{key['totalWeight'] ? key['totalWeight'] * key['qty'] :
                                                ''}}
                                                {{key['wghtUnit'] ? key['wghtUnit'] : ''}}</td>
                                            <td style="width: 12%;"> {{key['freightClass'] ? key['freightClass'] : ''}}</td>
                                            <td style="width: 12%;">{{data?.totalCharges>0?'$'+data?.totalCharges:'--'}}</td>
                                            <td style="width: 12%; color: red;">
                                                {{data?.adjustment?.total>0 ? '$'+data?.adjustment?.total:'--'}}
                                            </td>
                                        </tr>
                                        <tr *ngFor="let charge of charges; let i = index"
                                            style="padding: 0px 0px;width: 100%;float: left;height: 25px;">
                                            <td style="width: 14%;padding-left:3px"></td>
                                            <td style="width: 36%; text-align: left;">
                                                {{charge[0]}}
                                            </td>
                                            <td style="width: 50%; text-align: right; padding: 0px 30px 0px 0px;">{{charge[1]}}
                                            </td>
                                        </tr>
                                        <tr *ngFor="let key of data?.items" style="padding: 0px 0px;width: 100%;float: left;">
                                            <!-- <td style="width:100%;padding-left:3px"><b>Dimensions :</b> {{BOLd.shipmentItemslength ? BOLd.shipmentItemslength : '0'}} L x {{BOLd.shipmentItemsbreadth ? BOLd.shipmentItemsbreadth : '0'}}W x {{BOLd.shipmentItemsheight ? BOLd.shipmentItemsheight : '0' }} H</td> -->
                                            <td style="width:100%;padding-left:3px">
                                                <b>Dimensions :</b>{{key['length'] ? key['length'] : ''}} L x {{key['breadth'] ?
                                                key['breadth'] : ''}} W x {{key['height'] ? key['height'] : ''}} H<br>
                                                <b>SERVICE:</b>{{data?.shippingMode ? data?.shippingMode :
                                                ''}}<br>
                                                <b>SHIP VIA:</b>{{data?.carrier ? data?.carrier : ''}}<br>
                                                <b>PARTNER TRACKING NO.:</b>{{data?.proNumber}}<br>
                                                <!-- <div style="border-bottom: 1px dashed #C4CDD5;"></div><br>
                                                <b>NOTES:</b><br>
                                                {{data?.notes ? data?.notes : ''}} -->
                                            </td>
                                        </tr>
        
                                    </table>
                                </div>
                            </div>
    
                            <table>
                                <span class="break-page"></span>
            
                                <div class="pro_details_table">
                                    <tr style="padding: 0px 0px;width: 100%;float: left;font-size: 9px;">
                                        <td style="width:29%;
                                                     float:right ;">
                                            <div style="float: left;"><b>SUB-TOTAL</b></div>
                                            <div style="float: right;">${{data?.subTotal | number : '1.2-2'}}</div>
                                            <br>
                                            <div style="float: left;"><b>TAX -{{data?.taxRate!=null?data?.taxRate:0}}% {{data?.taxName}}</b></div>
                                                                <div style="float: right;">${{data?.totalTaxes | number : '1.2-2'}}</div>
                                            <br>
                                            <hr>
                                            <div style="float: left;">
                                                <b>TOTAL </b>
                                            </div>
                                            <div style="float: right;">
                                                <b>
                                                    <span style="font-size: 15px;color: #FF8D00;">${{data?.amountDue}} </span>
                                                    {{data?data?.currency?data?.currency:'':''}}
                                                </b>
                                            </div>
                                        </td>
                                    </tr>
            
                                </div>
                                <div class="html2pdf__page-break" *ngIf="j != orderList.length-1"></div>
                                <tr *ngIf="orderList.length-1==j" style="padding: 4px 0px;width: 100%;float: left;font-size: 8px;">
                                    <td style="width:45%;padding: 0px 0px;">
            
                                        <div class="mt-1 mb-5 ">
                                            <div>
                                                <b>1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA</b><br>
                                                <b>888.424.8878 EXT. 5<span style="font-size: 8px;color: #FF8D00;">
                                                        accounting@shipcarte.com</span></b><br>
                                            </div>
                                            <a style="margin-right: 8px;" href="https://www.linkedin.com/company/shipcarte-inc/"
                                                target="_blank">
                                                <img src="assets/img/shipment/Black-Linkedin.svg" width="17px"
                                                    class="m-1 text-center">
                                            </a>
                                            <a style="margin-right: 8px;" href="https://www.instagram.com/shipcarte/?hl=en%C2%A0"
                                                target="_blank">
                                                <img src="assets/img/shipment/Black-Insta.svg" width="17px" class="m-1 text-center">
                                            </a>
                                            <!-- <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                                <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                                            </a> -->
                                            <a style="margin-right: 8px;" href="https://twitter.com/shipcarte?lang=en%C2%A0"
                                                target="_blank">
                                                <img src="assets/img/shipment/Black-Twitter.svg" width="17px"
                                                    class="m-1 text-center">
                                            </a>
                                            <a style="margin-right: 8px;" href="https://www.facebook.com/ShipCarte/"
                                                target="_blank">
                                                <img src="assets/img/shipment/Black-Facebook.svg" width="17px"
                                                    class="m-1 text-center">
                                            </a>
                                        </div>
                                    </td>
                                    <td style="width:9%;"></td>
                                    <td style="width:28%;">
                                        <div>
                                            <b>
                                                <span style="font-size: 20px;color: #FF8D00;">THANK YOU / MERCI</span>
                                            </b>
                                            <!-- <img width="50px" src="assets/img/invoice/scikkids.png" > -->
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    
                </div>
               
    
                <!-- <br> -->
                <div style="margin-left: 43%;">
                    <button type="button" class="btn faint-grey orangeBtn col-3 mt-1" style="box-shadow: none;text-transform: capitalize;"
                        (click)="closeModal()">Close</button>
                </div>
    
            </div>
        </div>
        <!-- <div style="margin-left: 43%;">
            <button type="button" id="printBtn" class="btn faint-grey orangeBtn col-3 mt-1" style="box-shadow: none;text-transform: capitalize;"
                (click)="printFile()">Print</button>
        </div> -->
    </div>
    