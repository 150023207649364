import { Component, OnInit,Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { SignupService } from './../../../services/sign-up/signup.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as jQuery from 'jquery';
import * as $ from 'jquery';
import {ViewChild, ElementRef} from '@angular/core';
import { CustomValidators } from '../../../custom-validators';
@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent implements OnInit {
  varifyEmail: FormGroup;
  submitted = false;
  otpsubmit=false;
  @ViewChild('closeBtn') closeBtn: ElementRef;
  @Input() public email;
  displayErrorMessage:any = false;
  errorMessage:any
  constructor(private formBuilder:FormBuilder,private signupService:SignupService,public activeModal: NgbActiveModal) 
  {
    this.varifyEmail = this.createSignupForm();
   }

  ngOnInit(): void {
    // console.log(this.email);
    // this.varifyEmail = this.formBuilder.group({
    //   otpEmail:['', Validators.required]
    // });
    
  }
  createSignupForm(): FormGroup {
    return this.formBuilder.group(
      {
        otpEmail: [
          null,
          Validators.compose([ Validators.required])
        ],
      
        
      }
    );
  }
  get f() { return this.varifyEmail.controls; }
  closeModal() { this.activeModal.close(); }
  onSubmit(){
    this.otpsubmit=true;
    this.submitted = false;
    if (this.varifyEmail.invalid) {
      return;
    }
    this.errorMessage = "Processing, Please wait...";
    let inputData = 
    {
      "loggedInUserId": localStorage.getItem('loggedInUserId'),
      "actorType": localStorage.getItem('actorType'),
      "email": this.email,
      "otpCode": this.varifyEmail.value.otpEmail,
      "mobile": ""
    }
    this.signupService.validateEmailOtp(inputData).subscribe(result =>{
      console.log('validateEmailOtp here',result)
      if(result['success'] == "true"){
        this.activeModal.close({success:true})
      }
      this.otpsubmit=false;
    },error=>{
      console.log(error)
      this.otpsubmit=false;
      this.displayErrorMessage = false;  
      this.errorMessage = error['error']['Message'];  

    })
    //this.activeModal.close();
    //this.modalRef.close();
  }
  
}
