<div class="container" style="padding: 20px;margin-top: 80px;">
    <div class="row">
        <form class="col-10 m-auto" [formGroup]="varifyOtp" name="varifyOtp" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label>OTP</label>
                <input type="text" class="form-control" formControlName="otpEmail" placeholder="Enter Your OTP" 
                [ngClass]="varifyOtp.controls['otpEmail'].touched || varifyOtp.controls['otpEmail'].dirty ? 'is-invalid' : 'is-valid'"
                required>
                <div class="password-label" *ngIf="(varifyOtp.controls['otpEmail'].invalid) ? 'is-invalid1' : ''">
                    <label class="col-6 p-0" [ngClass]="varifyOtp.controls['otpEmail'].hasError('required')   ? 'text-danger1' : 'd-none'">
                      <i style="float: left;" class="material-icons">{{ varifyOtp.controls['otpEmail'].hasError('required')  ? 'clear' :
                        'done' }}</i>
                      <small class="error-align">OTP is required</small>
                    </label>                   
                  </div> 
            </div>
            <div class="orange-text" *ngIf="!displayErrorMessage">
                {{errorMessage}}
              </div>
            <div class="float-right" style="margin-right: -13px;">
                <button class="btn orange text-white submit m-1" type="submit">Submit</button>
                <button class="btn  cancel m-1" type="button" (click)="closeModal()">Cancel</button>
            </div>
           
        </form>
    </div>
</div>
