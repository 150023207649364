<div class="container main-wrapper" style="position: absolute;">

    <div class="row mt-3 frightClassStyle">
        <div style="display: flex; justify-content: space-between !important;">
            <div style="font-size: 26px !important; color:#354052 !important; font-weight:500 !important;line-height:28px !important;">Freight Class
                Calculator</div>
            <div style="font-size: 40px !important; font-weight: 300; color: #354052 !important; cursor: pointer;"
                (click)="closeModal()">&#215;</div>
        </div>
        <div style="padding-top: 25px; display: flex !important;">
            <label style="margin-right: 20px !important;color: #354052 !important;" class="container1">Domestic
                <input type="radio" [checked]="domestic" (click)="getValue($event.target.checked, 'Domestic')"
                    name="test">
                <span style="padding-left:0px !important;" class="checkmark"></span>
            </label>
            <label style="color: #354052 !important;" class="container1">International
                <input type="radio" [checked]="international" (click)="getValue($event.target.checked, 'International')"
                    name="test">
                <span style="padding-left:0px !important;" class="checkmark"></span>
            </label>
        </div>
        <div style="display: flex !important; padding-top: 20px; padding-bottom: 24px;">
            <label style="margin-right: 20px !important;color: #354052 !important;" class="container1">Metric
                <input type="radio" [checked]="matrixUnit" (click)="getValue($event.target.checked, 'Metric')"
                    name="unitMap">
                <span style="padding-left:0px !important;" class="checkmark"></span>
            </label>
            <label style="color: #354052 !important;" class="container1">Imperial
                <input type="radio" [checked]="imperialUnit" (click)="getValue($event.target.checked, 'Imperial')"
                    name="unitMap">
                <span style="padding-left:0px !important;" class="checkmark"></span>
            </label>
        </div>
        <form [formGroup]="frightClassForm" (ngSubmit)="getDensity()" style="margin: 0px !important;">
            <div class="row">
                <div class="col">
                    <div class="form-group" style="display: flex !important;">
                        <div>
                            <label style="font-size: 19px !important; color:#354052 !important">Length&nbsp;<span
                                    style="font-size:13px !important;background: #CED0DA !important;padding:1px 5px;border-radius: 4px;">{{imperialUnit
                                    == true ? 'inch':'cm'}}</span> </label>
                            <input style="height:39px !important; padding:2px; padding: 2px 16px !important;
                            width: calc(100% - 25px);" formControlName="Length" type="number" placeholder="0"
                                min="1" class="form-control">
                                <!-- <div class="error" *ngIf="frightClassForm.get(Length).invalid && (frightClassForm.get('Length').dirty || frightClassForm.get('Length').touched)">
                                    <div *ngIf="frightClassForm.get('Length').errors.required">
                                        Length is required*
                                    </div>
                                </div> -->
                        </div>
                        <div class="styleButtons">
                            <span style="height:23px !important;" class="spanPlus" (click)="incrementlength()">+</span>
                            <span style="height: 22px !important;" class="spanMinus"
                                (click)="decrementlength()">-</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group" style="display: flex !important;">
                        <div>
                            <label style="font-size: 19px !important; color:#354052 !important">Width&nbsp;<span
                                    style="font-size:13px !important;background: #CED0DA !important;padding:1px 5px;border-radius: 4px;">{{imperialUnit
                                    == true ? 'inch':'cm'}}</span> </label>
                            <input style="height:39px !important; padding:2px; padding: 2px 16px !important;
                            width: calc(100% - 25px);" formControlName="Width" type="number" placeholder="0"
                                min="1" class="form-control">
                        </div>
                        <div class="styleButtons">
                            <span style="height:23px !important;" class="spanPlus" (click)="incrementWidth()">+</span>
                            <span style="height: 22px !important;" class="spanMinus" (click)="decrementWidth()">-</span>
                        </div>
                    </div>
                </div>
                <div class="col">
                    <div class="form-group" style="display: flex !important;">
                        <div>
                            <label style="font-size: 19px !important; color:#354052 !important">Height&nbsp;<span
                                    style="font-size:13px !important;background: #CED0DA !important;padding:1px 5px;border-radius: 4px;">{{imperialUnit
                                    == true ? 'inch':'cm'}}</span> </label>
                            <input style="height:39px !important; padding:2px; padding: 2px 16px !important;
                            width: calc(100% - 25px);" formControlName="Height" type="number" placeholder="0"
                                min="1" class="form-control">
                        </div>
                        <div class="styleButtons">
                            <span style="height:23px !important;" class="spanPlus" (click)="incrementHeight()">+</span>
                            <span style="height: 22px !important;" class="spanMinus"
                                (click)="decrementHeight()">-</span>
                        </div>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-group" style="display: flex !important;">
                        <div style="width:100% !important;">
                            <label style="font-size: 19px !important; color:#354052 !important">Weight&nbsp;<span
                                    style="font-size:13px !important;background: #CED0DA !important;padding:1px 5px;border-radius: 4px;">{{imperialUnit
                                    == true ? 'Lbs':'Kg'}}</span> </label>
                            <input style="height:39px !important; padding:2px; padding: 2px 16px !important;
                            width: calc(100% - 25px);" formControlName="Weight" type="number" placeholder="0"
                                min="1" class="form-control">
                        </div>
                        <div class="styleButtons1">
                            <span style="height:22px !important;" class="spanPlus" (click)="incrementWeight()">+</span>
                            <span style="height: 23px !important;" class="spanMinus"
                                (click)="decrementWeight()">-</span>
                        </div>
                    </div>
            </div>
                <div>
                    <button class="btn orange text-white mt-2"
                        style="text-transform: capitalize;box-shadow: none; width:100% !important; font-size: 17px !important;font-weight:600 !important; " type="submit"
                        [disabled]="!frightClassForm.valid">Calculate</button>
                </div>
            </div>
        </form>
        <div>
        <div style="background: #F5F6FA; border: 1px solid #CED0DA;padding-top: 24px; padding-bottom: 15px;padding-left: 30px; padding-right: 32px; box-shadow: none !important;">
            <div style="font-size: 24px !important;color: #354052 !important; font-weight: 500;">Result</div>
            <div class="row mb-0">
                <div class="col" style="font-size: 16px !important;margin-top: 21px; margin-bottom: 14px;color: #354052 !important;">
                    Volume
                </div>
                <div class="col text-right" style="font-size: 16px !important;display: flex;align-items: center;justify-content: end;color: #354052 !important;">
                    {{ volume ? volume  : '--' }} Cu. Ft.
                </div>
            </div>
            <div style="border: 1px solid #DDDFE1; margin-bottom:10px !important; padding:0px 10px;"></div>
            <div class="row mb-0">

                <div class="col" style="font-size: 16px !important;margin-bottom: 15px;color: #354052 !important;">
                    Density
                </div>
                <div class="col text-right" style="font-size: 16px !important;">
                    {{ density | number:'1.2-2' }} Lb/Cu. Ft.
                </div>
            </div>
            <div style="border: 1px solid #DDDFE1; margin-bottom:10px !important; padding:0px 10px;"></div>
            <div class="row mb-0">
                <div class="col" style="font-size: 16px !important;margin-bottom: 15px;color: #354052 !important;">
                    Freight Class
                </div>
                <div class="col text-right" style="font-size: 16px !important;color: #354052 !important;">
                    {{ frightClass ? frightClass : '--' }}
                </div>
            </div>
        </div>
        <div class="buttonRes" style="display: flex !important; justify-content: space-between !important;padding-top: 8%;">
            <div style="color: #2877C6 !important; font-weight:500; font-size:17px !important;padding-top: 8 !important; display: flex; align-items: center; cursor: pointer;"
                (click)="reset()">
                <img class="resetIcon" src="assets/img/fi-rr-undo-alt.svg" />  Reset Fields 
               </div>
            <div class="text-right">
                <button type="button" class="btn faint-grey"
                    style="box-shadow: none;text-transform: capitalize; background: none !important; font-size:16px !important; color: #354052 !important; font-weight: 600 !important;"
                    (click)="closeModal()">Cancel</button>
                &nbsp;&nbsp; <button type="submit" class="btn orange text-white"
                    style="box-shadow: none;text-transform: capitalize; width: 115px; height:44px !important; font-size:16px!important; font-weight: 600 !important;"
                    (click)="closeModal()">Done</button>
            </div>
        </div>
    </div>
  </div>
</div>