import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
// import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ShipCarteProService {

  constructor(private http: HttpClient) { }

  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };

  // EOrder Section ********************************************************************************************************************************************************

  getEOrders(page, pageSize,url) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + `/api/order/getAll/${page}/${pageSize}?iseOrder=true` +
      url,
      {
        withCredentials: true,
        headers: myheader
      }
    ).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }

  // **********************************************************************************************************************************************************************************************

  // product list section ********************************************************************************************************************************************************

  getProductList(url) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + `/api/sc/product/getProductList/${url}`,
      {
        withCredentials: true,
        headers: myheader
      }
    ).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }

  getLoadCreateProduct() {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + `/api/sc/product/getLoadCreateProduct`,
      {
        withCredentials: true,
        headers: myheader
      }
    ).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }

  getProductDetails(productId) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + `/api/sc/product/getProductDetailsById?productId=${productId}`,
      {
        withCredentials: true,
        headers: myheader
      }
    ).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }

  createProduct(obj, imageData) {
    let formData = new FormData();
    formData.append('createRequest', JSON.stringify(obj));
    if (imageData) {
      formData.append('productImage', imageData);
    }
    return this.http.post(environment.apiPath + "/api/sc/product/createProduct ", formData, {
      withCredentials: true
    }).pipe(map(response => {
      return response;
    }));
  }

  updateProduct(obj, imageData, productId) {
    let formData = new FormData();
    formData.append('editRequest', JSON.stringify(obj));
    if (imageData) {
      formData.append('productImage', imageData);
    }
    return this.http.put(environment.apiPath + `/api/sc/product/editProduct?productId=${productId}`, formData, {
      withCredentials: true
    }).pipe(map(response => {
      return response;
    }));
  }

  chageStatusOfProduct(productId, flag) {
    return this.http.delete(environment.apiPath + `/api/sc/product/deleteProduct?productId=${productId}&isActive=${flag}`, {
      withCredentials: true
    }).pipe(map(response => {
      return response;
    }));;
  }

  bulkUploadProduct(data,fileType) {
    // const myheader = new HttpHeaders().set( 'Content-Type', 'multipart/form-data');
    let formData: FormData = new FormData();
    formData.append('file', data);
    let url='';
    if(fileType=='zip'){
    url='/api/sc/product/bulkProductImageUpload';
    }
    else if(fileType=='csv'){
      url='/api/sc/product/bulkProductUpload';
    }
    else{
      url='/api/sc/product/bulkProductUpload';
    }
    // const myheader = new HttpHeaders().set( 'Content-Type', 'multipart/form-data');

    return this.http.post(environment.apiPath + url, formData, {
      withCredentials: true
    }).pipe(map(response => {
      return response;
    }));
  }

  // **********************************************************************************************************************************************************************************************

  // package list section **********************************************************************************************************************************************************************************************

  getPackageList(page, pageSize,url) {
 
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + `/api/sc/pro/package/getAll/${page}/${pageSize}` + url ,
      {
        withCredentials: true,
        headers: myheader
      }
    ).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }

  chageStatusOfPackage(packageId, flag) {
    return this.http.delete(environment.apiPath + `/api/sc/pro/package/activeStatus?packageId=${packageId}&isActive=${flag}`, {
      withCredentials: true
    }).pipe(map(response => {
      return response;
    }));;
  }

  getAllPackageType() {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + `/api/sc/pro/package/type/getAll`,
      {
        withCredentials: true,
        headers: myheader
      }
    ).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }

  createPackage(data) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    let dataToSend = JSON.stringify(data);
    return this.http.post(environment.apiPath + `/api/sc/pro/package/createPackage`, dataToSend, {
      withCredentials: true
      , headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }

  getPackageById(id) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + `/api/sc/pro/package/getDetailsById?packageId=${id}`,
      {
        withCredentials: true,
        headers: myheader
      }
    ).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }

  updatePackage(data) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    let dataToSend = JSON.stringify(data);
    return this.http.put(environment.apiPath + `/api/sc/pro/package/update`, dataToSend, {
      withCredentials: true
      , headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }

  bulkUploadPackage(data) {
    // const myheader = new HttpHeaders().set( 'Content-Type', 'multipart/form-data');
    let formData: FormData = new FormData();
    formData.append('file', data);
    const userId = JSON.parse(localStorage.getItem('loggedInUserDetails')).uuid;

    return this.http.post(environment.apiPath + `/api/sc/packageupload/${userId}`, formData, {
      withCredentials: true
    }).pipe(map(response => {
      return response;
    }));
  }

  // **********************************************************************************************************************************************************************************************

  // carrier section ******************************************************************************************************************************************************************************


  getCarrierDetails() {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(environment.apiPath + `/api/sc/pro/carrier/cost/markup/getAll`,
      {
        withCredentials: true,
        headers: myheader
      }
    ).pipe(map(response => {
      return response;
    }, error => {
      return error;
    }));
  }

  saveCarrierDetail(data) {
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
    let dataToSend = JSON.stringify(data);
    return this.http.post(environment.apiPath + `/api/sc/pro/carrier/cost/markup/save`, dataToSend, {
      withCredentials: true
      , headers: myheader
    }).pipe(map(response => {
      return response;
    }));
  }



  // **********************************************************************************************************************************************************************************************


  // common service for converting a json file to csv **********************************************************************************************************************


  downloadFile(data, headerRow, filename = 'data') {
    let csvData = this.ConvertToCSV(data, headerRow);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSV(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in headerList) {
      row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
      let line = '';
      for (let index in headerList) {
        let head = headerList[index];
        if(head=='package_name' || head=='package_aabvr'){
          if(array[i][head].includes(',')){
            line += `"${array[i][head]}"` + ',';
          }
          else{
            line += array[i][head]+','
          }
        }
        else{
          line += array[i][head]+','
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

  // **********************************************************************************************************************************************************************************************


  downloadFileProduct(data, headerRow,headerRowMap,marketPlacesNames,doNotShipRegionNames, filename = 'data') {
    let csvData = this.ConvertToCSVProduct(data, headerRow,headerRowMap,marketPlacesNames,doNotShipRegionNames);
    let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {  //if Safari open in new window to save file with random filename.
      dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
  }

  ConvertToCSVProduct(objArray, headerList,headerRowMap,marketPlacesNames,doNotShipRegionNames) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = '';

    for (let index in headerRowMap) {
      row += headerRowMap[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = '';
      let count = 0;
      let doNotShipCount=0;
      for (let index in headerList) {
        if (headerList[index] == 'length' || headerList[index] == 'width' || headerList[index] == 'height' || headerList[index] == 'weight') {
          if (count == 0) {
            count = count + 1
            array[i].product_dimension.map((dimentionData) => {
              line += dimentionData.length + ',';
              line += dimentionData.width + ',';
              line += dimentionData.height + ',';
              line += dimentionData.weight + ',';
            })
          }
        }
       else if (doNotShipRegionNames.includes(headerList[index])) {
            doNotShipCount = doNotShipCount + 1
            array[i].doNotShipData.map((donotShip) => {
               if(headerList[index]==donotShip.zone_name){
                line += `"${donotShip.status}"` + ',';
               }
            })
        }
        else if (marketPlacesNames.includes(headerList[index])) {
            doNotShipCount = doNotShipCount + 1
            array[i].marketPlacesData.map((marketPlace) => {
              if(headerList[index]==marketPlace.name){
               line += `"${marketPlace.status}"` + ',';
              }
           })
        }
        else {
          let head = headerList[index];
          // line += `"${array[i][head]}"` + ',';
          if(head =='product_name' || head=='product_description'){
            line += `"${array[i][head]}"` + ',';
          }
          else{
            line += array[i][head] + ',';
          }
        }
      }
      str += line + '\r\n';
    }
    return str;
  }

}
