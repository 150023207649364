<div class="container" style="top: 15px;position: absolute;padding: 20px;">
    <span>
         <h4 style="font-size: 16px !important;">Edit Dashboard
             <button type="button" class="close float-right " data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons" (click)="closeModal()"> close</span>
                </span>
             </button>
         </h4>
    </span>
    <div class="row">
        <div class="custom-control custom-radio  ml-3 mt-2">
            <input id="shipment" type="checkbox" (change)="updatePref('showQuotationsPerWeek', $event)" [checked]="preferences.showQuotationsPerWeek? 'checked' : '' " class="custom-control-input">
            <label class="custom-control-label" for="shipment">Quotations Per Week</label>
        </div>
         <div class="custom-control custom-radio  ml-3 mt-2">
            <input id="showShipmentsPerWeek" type="checkbox" (click)="updatePref('showShipmentsPerWeek', $event)" [checked]="preferences.showShipmentsPerWeek? 'checked' : '' " class="custom-control-input">
            <label class="custom-control-label" for="showShipmentsPerWeek">Shipments Per Week</label>
        </div>
        <div class="custom-control custom-radio  ml-3 mt-2">
            <input id="showInvoicesPerWeek" type="checkbox" (click)="updatePref('showInvoicesPerWeek', $event)" [checked]="preferences.showInvoicesPerWeek? 'checked' : '' " class="custom-control-input">
            <label class="custom-control-label" for="showInvoicesPerWeek">Invoice Per Week</label>
        </div>
        <div class="custom-control custom-radio  ml-3 mt-2">
            <input id="showRecentShipments" type="checkbox" (click)="updatePref('showRecentShipments', $event)" [checked]="preferences.showRecentShipments? 'checked' : '' " class="custom-control-input">
            <label class="custom-control-label" for="showRecentShipments">Recent Shipments</label>
        </div>
        <div class="custom-control custom-radio  ml-3 mt-2">
            <input id="shipmetPerMonth" type="checkbox" (click)="updatePref('showShipmentsPerMonth', $event)" [checked]="preferences.showShipmentsPerMonth? 'checked' : '' " class="custom-control-input">
            <label class="custom-control-label" for="shipmetPerMonth">Shipment Per Month</label>
        </div>
        <div class="custom-control custom-radio  ml-3 mt-2">
            <input id="spendingPerMonth" type="checkbox" (click)="updatePref('showSpendingPerMonth', $event)" [checked]="preferences.showSpendingPerMonth? 'checked' : '' " class="custom-control-input">
            <label class="custom-control-label" for="spendingPerMonth">Spending Per Month</label>
        </div>
        <div class="custom-control custom-radio  ml-3 mt-2">
            <input id="AllShipments" type="checkbox" (click)="updatePref('showAllShipments', $event)" [checked]="preferences.showAllShipments? 'checked' : '' " class="custom-control-input">
            <label class="custom-control-label" for="AllShipments">All Shipments</label>
        </div>
        <div class="custom-control custom-radio  ml-3 mt-2">
            <input id="yourTopCarrier" type="checkbox" (click)="updatePref('showTopCarriers', $event)" [checked]="preferences.showTopCarriers? 'checked' : '' " class="custom-control-input">
            <label class="custom-control-label" for="yourTopCarrier">Your TOP Carriers</label>
        </div>
        <br>
    </div>
    <div class="text-right mt-3">
        <button type="button" class="btn faint-grey col-4" style="box-shadow: none;" (click)="closeModal()">Cancel</button> &nbsp;&nbsp; <button type="submit" class="btn orange text-white col-3" style="box-shadow: none;" (click)="returnValues()">Continue</button>
    </div>
</div>