import { Component, OnInit } from '@angular/core';
import {CommonService} from 'src/app/services/common.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-view-comm-inv-quick-action',
  templateUrl: './view-comm-inv-quick-action.component.html',
  styleUrls: ['./view-comm-inv-quick-action.component.css']
})

export class ViewCommInvQuickActionComponent implements OnInit {

  BOLd:any;
  resultData:any;
  dynamicDimension:any;
  declaredValue:number;
  totalItem:number;
  constructor(public common:CommonService,public activeModal: NgbActiveModal,  private  _loaderService: LoadingService) { }

  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    this.resultData=this.common.commResultData;
    console.log(this.resultData,'rrsult')
    this.dynamicDimension=this.common.dimensionBOL;
    this.declaredValue=this.common.declaredBol;
    this.totalItem=this.common.totalItemBol;
    setTimeout(() => {
      this._loaderService.stringSubject.next(false);
    }, 2000);
  }

  closeModal() { this.activeModal.close(); }

}
