<div class="container popContainer">
    <span>
         <h4 >Adding Customs Broker Information
             <button type="button" class="close float-right " (click)="closeModal()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons">
                    close
                    </span>
                </span>
             </button>
         </h4>
    </span>
    <hr>
    <div>
        <!-- <div class="row">
            <select class="form-select" aria-label="Default select example" (change)="brokerEmailAndNumber($event.target.value)">
                                <option selected>Setect custom broker</option>
                                <option *ngFor="let br of customDropdownValues" value="{{br.brokerId}}">{{br.brokerName}}</option>
                            </select>
        </div> -->
         <form [formGroup]="customNormalForm" (ngSubmit)="submit()">

            <div class="form-row">
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="brokerCompany">Company Name</label>
                    <input formControlName="brokerCompany" type="text" class="form-control" id="" placeholder="Enter Company Name">
                    <div *ngIf="customNormalForm.get('brokerCompany').invalid && (customNormalForm.get('brokerCompany').dirty || customNormalForm.get('brokerCompany').touched)" [ngClass]="'error'">
                        <div *ngIf="customNormalForm.get('brokerCompany').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="f.brokerCompany.errors.minlength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="f.brokerCompany.errors.maxlength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="brokerName">Broker Full Name</label>
                    <input formControlName="brokerName" type="text" class="form-control" id="" placeholder="Enter Broker Full Name">
                    <div *ngIf="customNormalForm.get('brokerName').invalid && (customNormalForm.get('brokerName').dirty || customNormalForm.get('brokerName').touched)" [ngClass]="'error'">
                        <div *ngIf="customNormalForm.get('brokerName').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="customNormalForm.get('brokerName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="f.brokerName.errors.minlength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="f.brokerName.errors.maxlength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div>
                <!-- <div class="form-group col-md-6">
                    <label for="lastName">Last Name</label>
                    <input formControlName="lastName" type="text" class="form-control" id="" placeholder="Enter last name">
                    <div *ngIf="customNormalForm.get('lastName').invalid && (customNormalForm.get('lastName').dirty || customNormalForm.get('lastName').touched)" [ngClass]="'error'">
                        <div *ngIf="customNormalForm.get('lastName').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="customNormalForm.get('lastName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="f.lastName.errors.minlength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="f.lastName.errors.maxlength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="phone">Phone</label>
                    <input formControlName="brokerPhone" type="text" class="form-control" id="phone" placeholder="Enter phone">
                    <div *ngIf="customNormalForm.get('brokerPhone').invalid && (customNormalForm.get('brokerPhone').dirty || customNormalForm.get('brokerPhone').touched)" [ngClass]="'error'">
                        <div *ngIf="customNormalForm.get('brokerPhone').errors.required">
                            Mobile is required.
                        </div>
                        <div *ngIf="customNormalForm.get('brokerPhone').errors.pattern">
                            Enter correct format.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="brokerEmail">Email</label>
                     <input formControlName="brokerEmail" type="brokerEmail" style="width: 98% !important;" class="form-control" id="brokerEmail" placeholder="Enter Broker Email Address">
                      <div *ngIf="customNormalForm.get('brokerEmail').invalid && (customNormalForm.get('brokerEmail').dirty || customNormalForm.get('brokerEmail').touched)" [ngClass]="'error'">
                          <div *ngIf="customNormalForm.get('brokerEmail').errors.required">
                            Email is required.
                          </div>
                          <div *ngIf="customNormalForm.get('brokerEmail').errors.pattern">
                           Enter correct Email format.
                        </div>
                   </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn faint-grey col-3" (click)="closeModal()">Cancel</button> &nbsp;&nbsp;
                <button type="submit" [disabled]="!customNormalForm.valid" class="btn orange text-white col-3">Save</button>
            </div>
        </form>
    </div>
</div>
