import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})

export class InfoComponent implements OnInit {

  constructor(private modal:NgbModal) { }

  @Input() title:string;
  @Input() message: string;
  @Input() buttons: any;

  ngOnInit(): void {

  }

  close(){
  	this.modal.dismissAll({'dismiss': true});
  }

}
