import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/services/Dashboard/dashboard.service';
@Component({
  selector: 'app-edit-dashboard',
  templateUrl: './edit-dashboard.component.html',
  styleUrls: ['./edit-dashboard.component.css']
})
export class EditDashboardComponent implements OnInit {
  shipmets: any = false;
  shipmetPerMonth: any = false;
  spendingPerMonth: any = false;
  AllShipments: any = false;
  yourTopCarrier: any = false;
  shipmetsType: any = false;
  regionsOfShipping: any = false;
  quotations: any = false;
  shipmentPerWeek: any = false;
  editPreferences: any;
  @Input() public preferences;
  constructor(public activeModal: NgbActiveModal,private toast: ToastrService, public dashboardData: DashboardService,private router:Router) { }

  ngOnInit(): void {
     //@Input() preferences: any;
     //@Input() public preferences;
  }

  closeModal() { 
    this.activeModal.close(this.preferences); 
  }

  updatePref(key, value){
    console.log(key, value.target.checked);
      this.preferences[key] = value.target.checked ? 1 : 0;
  }


  returnValues() :any {
    
    //console.log(this.preferences);
    var tempPreferenes = this.preferences;
    tempPreferenes['defaultCountOfRecentShipments'] = 5;
    this.dashboardData.setUserPreferneces(this.preferences).subscribe(res => {

        this.activeModal.close(this.preferences);

    }, (error) => {
        if (error['status'] === 401) {
            localStorage.clear();
            this.router.navigate(['/signin'])
        }

    })

    
    
    
  }
}
