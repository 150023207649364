import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from './../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserpreferencesService {

  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    })
    , withCredentials: true
  };
  constructor(private http: HttpClient) { }


  updateProfile(data){
    return this.http.put(environment.apiPath + "/sc/cust/ws/update", data, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }

  updateAddress(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/sc/addr/ws/save",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));  
  }

  getData(userId){
    return this.http.get(environment.apiPath + "/sc/cust/ws/get?id="+userId, { withCredentials: true });
  }


  uploadImage(formData){
    return this.http.post(environment.apiPath+ "/sc/cust/ws/save/profile/image",formData,{withCredentials:true
      }).pipe(map(response => {
      return response;
    })); 
  }

  changePassword(dataObj){
    return this.http.put(environment.apiPath + "/sc/autz/ws/info/change/password", dataObj, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  changeUserEmail(dataObj){
    return this.http.post(environment.apiPath + "/sc/autz/ws/info/changeUserEmail", dataObj, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }

  changeEmail(dataObj){
    return this.http.put(environment.apiPath + "/sc/cust/ws/update", dataObj, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }

  emailVerify(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/api/generateOTP",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }
  generateOTP(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/api/generateOTP",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }

  getAddressData(addressId){
    return this.http.get(environment.apiPath + "/sc/addr/ws/get?id="+addressId, { withCredentials: true });
  }


  addBusinessEntity(data){
    return this.http.put(environment.apiPath + "/sc/credit/applicant/company/info/ws/update", data, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }
  
  addBusinessStructure(datObj){
    return this.http.post(environment.apiPath + "/sc/credit/applicant/ws/save", datObj, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }

  

  saveApplicantBilling(dataObj){    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/sc/credit/applicant/billing/info/ws/save",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }

  saveBankingInfo(dataObj){    
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/sc/credit/banking/info/ws/save",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }

  saveApplicantReference(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/sc/credit/applicant/reference/ws/save",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));
  }
  getApplicationViewDetails(applicantId){
    return this.http.get(environment.apiPath + "/api/creditApplication/details/get?applicantId="+applicantId, { withCredentials: true });
  }
  getApplicantData(applicantId){
    return this.http.get(environment.apiPath + "/sc/credit/applicant/document/ws/get/all?applicant-id="+applicantId, { withCredentials: true });
  }

  getTradeRefernceData(applicantId){
    return this.http.get(environment.apiPath + "/sc/credit/applicant/reference/ws/get/all?id="+applicantId, { withCredentials: true });
  }

  deleteReference(refId){
    return this.http.get(environment.apiPath + "/sc/credit/applicant/reference/ws/delete?ref-id="+refId, { withCredentials: true });
  }

  getEntityType(){    
    return this.http.get(environment.apiPath + "/sc/company/type/ws/get/all", { withCredentials: true });
  }


  applicantPaymentInfoSave(data){
    return this.http.put(environment.apiPath + "/sc/credit/applicant/payment/info/ws/save", data, this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));
  }

  statusTracker(applicantId,status){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    

    return this.http.post(environment.apiPath + "/sc/credit/applicant/status/tracker/ws/save?applicantId="+applicantId+'&status='+status,'', { headers:myheader, withCredentials: true });
  }

  getBusinessStructureData(applicantId){   
    return this.http.get(environment.apiPath + "/sc/credit/applicant/ws/get?id="+applicantId, { withCredentials: true });
  }

  getBankingData(applicantId){
    return this.http.get(environment.apiPath + "/sc/credit/banking/info/ws/get/by/applicant/id?applicant-id="+applicantId, { withCredentials: true });
  }
  
  getBillingData(applicantId){
    return this.http.get(environment.apiPath + "/sc/credit/billing/address/ws/get/by/id?applicant-id="+applicantId, { withCredentials: true });
  }

}
