import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getQuoteService } from 'src/app/services/get-quote/get-quote.service';
import { LoadingService } from 'src/app/services/loader.service';



@Component({
  selector: 'app-view-bol',
  templateUrl: './view-bol.component.html',
  styleUrls: ['./view-bol.component.css']
})
export class ViewBolComponent implements OnInit {
  @Input() invoiceData;
  // BOLd:any ={
  //   orderNumber:'',
  //   shipDate:'',
  //   customerNumber:'',
  //   shippercompany:'',
  //   shipperaddrLine1:'',
  //   shippercity:'',
  //   shipperstate:'',
  //   shipperzip:'',
  //   consigneecompany:'',
  //   consigneeaddrLine1:'',
  //   consigneecity:'',
  //   consigneestate:'',
  //   consigneezip:'',
  //   quoteNumber:'',
  //   shipmentItemsqty:'',
  //   shipmentItemslengthType:'',
  //   shipmentItemsdescription:'',
  //   shipmentItemstotalWeight:'',
  //   shipmentItemsfreightClass:'',
  //   shipmentItemsnmfc:'',
  //   shipmentItemslength:0,
  //   shipmentItemsbreadth:0,
  //   shipmentItemsheight:0,
  //   shipperAddressfirstName:'',
  //   shipperAddresslastName:'',
  //   consigneeAddressfirstName:'',
  //   consigneeAddresslastName:'',
  //   brokerName:'',
  //   Phone:'',
  //   brokerEmail:'',
  // };

  @Input() BOLd;
  resultData: any;
  dynamicDimension: any;
  declaredValue: number;
  totalItem: number;
  packagesArray: any = [];
  totalPackageCountArray: any = []
  weightType: any = ''
  carrier:any=false;
  constructor(public common: CommonService, public activeModal: NgbActiveModal, public getQuoteService: getQuoteService,private  _loaderService: LoadingService) { }

  ngOnInit(): void {
    console.log(this.BOLd)
    this._loaderService.stringSubject.next(true);
    this.resultData = this.common.bolResultData;
    if (this.resultData['shipmentItems'][0]['lengthType'] === 'imperial') {
      this.weightType = 'Lbs'
    } else {
      this.weightType = 'Kg'
    }
    this.getQuoteService.getShipmentModeData().subscribe((result: any) => {
      if (result) {
        setTimeout(() => {
          this._loaderService.stringSubject.next(false);
        }, 400);
      }
      result.forEach((element) => {

        if (element.description === 'Courier') {
          element.packagingDetails.map((element1) => {

            this.packagesArray.push({
              'packageName': element1.packagingType,
              'packageId': element1.packagingId
            })
          })
        } else if (element.description != 'Full Truckload (TL) Shipment') {
          element.packagingTypeDetails
            .map((element1) => {
              this.packagesArray.push({
                'packageName': element1.packagingName,
                'packageId': element1.packagingId

              })
            })
        }
      })
      // debugger
      let packageId = [];
      this.resultData.shipmentItems.map((element) => {
        if(this.resultData.shipmentMode==='Courier'){
          packageId.push(element.crsPackageId);

        }else{

          packageId.push(element.packaging);
        }
      })

      let uniquePackageId = [... new Set(packageId)]
      uniquePackageId.map((element) => {
        let count = 0;
        let qnty = this.resultData.shipmentItems.filter(data => data.packaging
          === element);

        qnty.map((element) => {
          count = count + element.qty;
        })
        // packageId.map((element1)=>{
        //   if(element===element1){
        //     count=count+1;
        //   }
        // })
        let packageName = this.packagesArray.filter(data => data.packageId === element);

        this.totalPackageCountArray.push({
          'packageName': packageName[0].packageName,
          'count': count
        })
      })

    },err=>{
      this._loaderService.stringSubject.next(false);
    })
    // this.BOLd = this.common.bolInvoiceDetails;
    // if(this.BOLd['carrierLogo'].includes('iVB') || this.)
    if(this.BOLd?.carrierLogo.substr(0,3)==='iVB' || this.BOLd?.carrierLogo.substr(0,3)==='/9j'){
      this.carrier=true;
    }else{
      this.carrier=false;
      // let carrierImage=atob(this.BOLd?.carrierLogo)
      // document.getElementById('carrierImageContainer').innerHTML=carrierImage
    }
   
    this.dynamicDimension = this.common.dimensionBOL;
    this.declaredValue = this.common.declaredBol;
    this.totalItem = this.common.totalItemBol;
  }

  closeModal() { this.activeModal.close(); }

}
