<div class="container showClass"  *ngIf="showSpinar">
    <div class="overlay-box" style="color: #000;
        position: fixed;
        width: 100%;
        background: #fff;
        height: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 999999;
        opacity: 0.8;">
        <div class="payment_status_popup" style="width: 460px;margin: 0 auto;text-align: center;position: absolute;
        left: 0;right: 0;top: 30%;">
            <img src="assets/img/quotes/loader.svg" alt="">
            <h2 class="mt-3" style="
                font-size: 20px !important;
                color: #151522;
                margin: 0;
                padding: 0;
                font-weight: 600 !important;
                text-align: center;">Loading your options, please wait… </h2>
            <div class="oneMore">
                <div class="noone"  [style.width]="withOFtd+'%'">10</div>
            </div>
        </div>

    </div>
</div>