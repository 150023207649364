import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-insurance',
  templateUrl: './insurance.component.html',
  styleUrls: ['./insurance.component.css']
})
export class InsuranceComponent implements OnInit {
  @Input() public data;
  @Input() public isMarshInsuranceApplicalbe;
  amount = 0;
  currency: any = ''
  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.amount = this.data.currency.split('$')[1].split(' ')[0];
    this.currency = this.data.currency.split('$')[1].split(' ')[1]
    console.log(this.isMarshInsuranceApplicalbe)
  }
  closeModal() { this.activeModal.close({ success: false }); }

  optInsaurance() {
    this.activeModal.close({ success: true });
  }
}
