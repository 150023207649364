import { Component, OnInit, DoCheck, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { CustomValidators } from 'src/app/custom-validators';
 
import { ToastrService } from 'ngx-toastr';
 
import { DomSanitizer } from '@angular/platform-browser';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { element } from 'protractor';
import { ShipCarteProService } from 'src/app/services/shipcartePro.service';
import { CommonService } from 'src/app/services/common.service';
import { ListDataServiceService } from 'src/app/services/ListData/list-data-service.service';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.css']
})
export class AddProductComponent implements OnInit {

  productId = '';
  addAdditionalToggle: Boolean = false;
  addAdditionalToggleValue = 'off';
  apiCall: Boolean = false;
  isEdit: Boolean = false;
  editData: any;
  removeDimentionArray = [];
  bulkUploadData: any = {
    file: null,
    name: ''
  };

  validationMessages = {
    'productSku': {
      'required': 'Product sku is required*',
    },
    'productName': {
      'required': 'Product name is required*',
      'maxlength': 'Product name limit exceded*'
    },
    'productDesc': {
      'required': 'Product description is required*',
      'maxlength': 'Product description limit exceded*'
    },
    'dimentionUnit': {
      'required': 'This Feild is required*'
    }
  }

  formErrors = {
    'productSku': '',
    'productName': '',
    'productDesc': '',
    'dimentionUnit': '',
  }

  productForm: FormGroup;
  uploadImageShow: any = null;
  imageData = null;
  marketPlacesArray = [];
  doNotShipRegionArray = [];
  customShippingArray = [];
  submitClicked: boolean = false;
  sidebarToggle: boolean;
  closeResult: string;
  getLoadCreateProDuctData: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private shipcatepro: ShipCarteProService,
    private toast: ToastrService,
    private changeDetector: ChangeDetectorRef,
    public common: CommonService,
    private domSanitizer: DomSanitizer,
    private modalService: NgbModal,
    private listDataService:ListDataServiceService,
    private  _loaderService: LoadingService
  ) {
    this.changeDetector.detach();
  }


  ngDoCheck() {
    if (this.common.sidebarToggle == true) {
      this.sidebarToggle = true;
    }
    else {
      this.sidebarToggle = false;
    }
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    this.productId = this.activatedRoute.snapshot.paramMap.get('id');
    this.productForm = this.fb.group({
      productSku: ['', [Validators.required, CustomValidators.onlySpaceCheckValidator]],
      productName: ['', [Validators.required, Validators.maxLength(50), CustomValidators.onlySpaceCheckValidator]],
      shipSolo: [false],
      dimentionUnit: ['imperial'],
      productDesc: ['', [Validators.required, Validators.maxLength(198), CustomValidators.onlySpaceCheckValidator]],
      measureMents: this.fb.array([
        this.addMeasureMentFormGroup()
      ]),
      freeShipping: ['']
    })

    // this is used to check changes in our form values

    this.productForm.valueChanges.subscribe((value: any) => {
      this.logValidationErrors(this.productForm)
    })

    this.apiCall = true

    this.shipcatepro.getLoadCreateProduct().subscribe((res: any) => {
      if (res) {
        this._loaderService.stringSubject.next(false);
      }
      this.getLoadCreateProDuctData = res;
      this.marketPlacesArray = res?.market_places ? res.market_places : [];
      this.doNotShipRegionArray = res?.do_not_ship_region ? res.do_not_ship_region : [];
      res?.custom_shipping_charge?.map((data, index) => {
        res.custom_shipping_charge[index]['first_qty_ship_cost'] = '';
        res.custom_shipping_charge[index]['addl_qty_ship_cost'] = '';
        res.custom_shipping_charge[index]['currency'] = 'CAD'
      })
      this.customShippingArray = res.custom_shipping_charge ? res.custom_shipping_charge : [];
      if (this.productId) {
        this.isEdit = true;
        this.getProductInfo();
      }
      else {
        this.apiCall = false
      }
    }, error => {
      this._loaderService.stringSubject.next(false);
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.apiCall = false;
      this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
        timeOut: 10000,
      });
    })
  }

  // fetching product if isEdit **********************************************************************************************************************

  getProductInfo() {
    this.shipcatepro.getProductDetails(this.productId).subscribe((res: any) => {

      this.editData = res;

      this.productForm.patchValue({
        productSku: res.product_sku_id,
        productName: res.product_name,
        shipSolo: res.is_ship_solo,
        productDesc: res.product_description,
        dimentionUnit: res.dimension_unit,
        freeShipping: res.shipping_mode
      })

      if (res.is_additional_pieces) {
        this.addAdditionalToggleValue = 'on';
        this.addAdditionalToggle = true
      }
      this.productForm.setControl('measureMents', this.setExistingMeasureMents(res.product_dimension))
      this.uploadImageShow = res.product_image_download_url
      this.setMarketPlacesAccordingToFetched(this.marketPlacesArray, res.market_places);
      this.setDoNotShipRegions(res.do_not_ship_region);
      this.setCustomShippingCharge(res.custom_shipping_charge)
      this.apiCall = false
    }, error => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.apiCall = false;
      this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
        timeOut: 10000,
      });
    })
  }

  // set market places according to fetched details
  setMarketPlacesAccordingToFetched(orignalMarketFetchedArray, fetchedMarketPlaces) {
    orignalMarketFetchedArray.map((originalArray, index) => {
      let fetchedData = fetchedMarketPlaces.filter((dataFetchMarketPlaces) => { return dataFetchMarketPlaces.name == originalArray.name })
      if (fetchedData.length > 0) {
        this.marketPlacesArray[index].is_active = fetchedData[0].is_active
      }
      else {
        this.marketPlacesArray[index].is_active = "false"
      }
    })
  }

  // set do not ship region according to fetched details
  setDoNotShipRegions(fetchedDoNotShipRegion) {
    this.doNotShipRegionArray.map((originalArray, index) => {
      let fetchedData = fetchedDoNotShipRegion.filter((dataFetchDoNotShipRegion) => { return dataFetchDoNotShipRegion.zone_name == originalArray.zone_name })
      if (fetchedData.length > 0) {
        this.doNotShipRegionArray[index].is_shippable = fetchedData[0].is_shippable
      }
      else {
        this.doNotShipRegionArray[index].is_shippable = "true"
      }
    })
  }

  // set do custom shipping  according to fetched details
  setCustomShippingCharge(fetchedCustomArray) {
    this.customShippingArray.map((originalArray, index) => {
      let fetchedData = fetchedCustomArray.filter((dataFetchCustomShipping) => { return dataFetchCustomShipping.zone_id == originalArray.zone_id })
      if (fetchedData.length > 0) {
        this.customShippingArray[index].first_qty_ship_cost = fetchedData[0].first_qty_ship_cost
        this.customShippingArray[index].addl_qty_ship_cost = fetchedData[0].addl_qty_ship_cost
        this.customShippingArray[index].currency = fetchedData[0].currency
      }
    })
  }

  // this function dynamically creates more measurement rows based on array given
  setExistingMeasureMents(dimentions): FormArray {
    const formArray = new FormArray([]);
    dimentions.forEach((dimention) => {
      formArray.push(this.fb.group({
        length: [dimention.length, [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
        width: [dimention.width, [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
        height: [dimention.height, [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
        weight: [dimention.weight, [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
        uniqueIdForEdit: [dimention.product_dimension_id]
      })
      )
    })
    return formArray
  }


  addMeasureMentFormGroup(): FormGroup {
    return this.fb.group({
      length: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      width: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      height: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      weight: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      uniqueIdForEdit: ['new']
    })
  }

  addMoreFeildMeasureMent(): void {
    (<FormArray>this.productForm.get('measureMents')).push(this.addMeasureMentFormGroup())
  }


  removeMoreFeildMeasureMent(measureMentIndexNo: number, valueOfUniqueId): void {

    if (valueOfUniqueId !== 'new') {
      this.removeDimentionArray.push(valueOfUniqueId)
    }

    (<FormArray>this.productForm.get('measureMents')).removeAt(measureMentIndexNo);

    if (this.productForm.get('measureMents')?.value?.length == 1) {
      this.addAdditionalToggleValue = 'off';
      this.addAdditionalToggle = false
    }
  }


  removeMoreFeildMeasureMentAll(): void {
    if (this.productForm.get('measureMents')?.value?.length > 1) {
      if ((<FormArray>this.productForm.get('measureMents')).value[1].uniqueIdForEdit !== 'new') {
        this.removeDimentionArray.push((<FormArray>this.productForm.get('measureMents')).value[1].uniqueIdForEdit)
      }
      (<FormArray>this.productForm.get('measureMents')).removeAt(1);
      this.removeMoreFeildMeasureMentAll();
    }
    else {
      console.log('clearAllFeilds');
    }
    // (<FormArray>this.productForm.get('measureMents')).removeAt(i);
  }



  addOrRemoveMeasureMent(value): void {
    if (value == 'off') {
      this.addAdditionalToggleValue = 'on';
      this.addAdditionalToggle = true
      this.addMoreFeildMeasureMent()
    }
    else {
      this.addAdditionalToggleValue = 'off';
      this.addAdditionalToggle = false
      this.removeMoreFeildMeasureMentAll()
    }
  }

  // fuction when marketswitch buttonClicked

  actionMarketSwitchClicked(event, index) {
    if (event.target.checked) {
      this.marketPlacesArray[index].is_active = "true"
    }
    else {
      this.marketPlacesArray[index].is_active = "false"
    }
  }

  // fuction when do not ship region buttonClicked

  actionDoNotShipRegionClicked(event, index) {
    if (event.target.checked) {
      this.doNotShipRegionArray[index].is_shippable = "false"
    }
    else {
      this.doNotShipRegionArray[index].is_shippable = "true"
    }
  }


  // fuction when customValue changes

  actionCustomValueChange(event, quantityType, index) {
    if (quantityType == 'firstQuantity') {
      this.customShippingArray[index].first_qty_ship_cost = event.target.value;
    }
    else {
      this.customShippingArray[index].addl_qty_ship_cost = event.target.value;
    }
  }


  uploadImage(event): void {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2 && event.target.files[0]) {
        this.uploadImageShow = this.domSanitizer.bypassSecurityTrustUrl(reader.result as string)
        this.imageData = event.target.files[0];
      }
    }
    if (event.target.files[0]) {
      reader.readAsDataURL(event.target.files[0]);
    }
  }



  removeImage(): void {
    this.uploadImageShow = null;
    this.imageData = null;
  }

  increamentByOne(i, measureMentUnit) {
    if (measureMentUnit == "length") {
      ((this.productForm.get('measureMents') as FormArray).at(i) as FormGroup).get('length').patchValue(parseFloat((((<FormArray>this.productForm.get('measureMents')).value[i].length ? (<FormArray>this.productForm.get('measureMents')).value[i].length : 0) + 1).toFixed(2)));
    }
    else if (measureMentUnit == "width") {
      ((this.productForm.get('measureMents') as FormArray).at(i) as FormGroup).get('width').patchValue(parseFloat((((<FormArray>this.productForm.get('measureMents')).value[i].width ? (<FormArray>this.productForm.get('measureMents')).value[i].width : 0) + 1).toFixed(2)));
    }
    else if (measureMentUnit == "height") {
      ((this.productForm.get('measureMents') as FormArray).at(i) as FormGroup).get('height').patchValue(parseFloat((((<FormArray>this.productForm.get('measureMents')).value[i].height ? (<FormArray>this.productForm.get('measureMents')).value[i].height : 0) + 1).toFixed(2)));
    }
    else if (measureMentUnit == "weight") {
      ((this.productForm.get('measureMents') as FormArray).at(i) as FormGroup).get('weight').patchValue(parseFloat((((<FormArray>this.productForm.get('measureMents')).value[i].weight ? (<FormArray>this.productForm.get('measureMents')).value[i].weight : 0) + 1).toFixed(2)));

    }
    // console.log((<FormArray>this.productForm.get('measureMents')).value[i].length);
  }

  decreamentByOne(i, measureMentUnit) {
    if (measureMentUnit == "length") {
      if ((<FormArray>this.productForm.get('measureMents')).value[i].length && (<FormArray>this.productForm.get('measureMents')).value[i].length > 1) {
        ((this.productForm.get('measureMents') as FormArray).at(i) as FormGroup).get('length').patchValue(parseFloat((((<FormArray>this.productForm.get('measureMents')).value[i].length ? (<FormArray>this.productForm.get('measureMents')).value[i].length : 0) - 1).toFixed(2)));
      }
    }
    else if (measureMentUnit == "width") {
      if ((<FormArray>this.productForm.get('measureMents')).value[i].width && (<FormArray>this.productForm.get('measureMents')).value[i].width > 1) {
        ((this.productForm.get('measureMents') as FormArray).at(i) as FormGroup).get('width').patchValue(parseFloat((((<FormArray>this.productForm.get('measureMents')).value[i].width ? (<FormArray>this.productForm.get('measureMents')).value[i].width : 0) - 1).toFixed(2)));
      }
    }
    else if (measureMentUnit == "height") {
      if ((<FormArray>this.productForm.get('measureMents')).value[i].height && (<FormArray>this.productForm.get('measureMents')).value[i].height > 1) {
        ((this.productForm.get('measureMents') as FormArray).at(i) as FormGroup).get('height').patchValue(parseFloat((((<FormArray>this.productForm.get('measureMents')).value[i].height ? (<FormArray>this.productForm.get('measureMents')).value[i].height : 0) - 1).toFixed(2)));
      }
    }
    else if (measureMentUnit == "weight") {
      if ((<FormArray>this.productForm.get('measureMents')).value[i].weight && (<FormArray>this.productForm.get('measureMents')).value[i].weight > 1) {
        ((this.productForm.get('measureMents') as FormArray).at(i) as FormGroup).get('weight').patchValue(parseFloat((((<FormArray>this.productForm.get('measureMents')).value[i].weight ? (<FormArray>this.productForm.get('measureMents')).value[i].weight : 0) - 1).toFixed(2)));
      }
    }
  }


  // function to log validation errors

  logValidationErrors(group: FormGroup = this.productForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key)
      this.formErrors[key] = ''
      if (abstractControl && !abstractControl.valid && ((abstractControl.touched || abstractControl.dirty)) || this.submitClicked) {
        const messages = this.validationMessages[key];
        for (const errorKey in abstractControl.errors) {
          if (errorKey) {
            this.formErrors[key] += messages[errorKey] + " ";
          }
        }
      }
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl)
      }
      // if (abstractControl instanceof FormArray) {
      //   for (const control of abstractControl.controls) {
      //     if (control instanceof FormGroup) {
      //       this.logValidationErrors(control)
      //     }
      //   }

      // }
    })
  }


  ValidationForCustomShipping() {
    let catchError = 0;
    this.customShippingArray.map((data) => {
      if (data.addl_qty_ship_cost.length > 0 && data.first_qty_ship_cost.length == 0) {
        catchError++;
      }
      else if (data.addl_qty_ship_cost.length == 0 && data.first_qty_ship_cost.length > 0) {
        catchError++;
      }
    })
    if (catchError > 0) {
      // this.toast.error('Provide both values For CustomShipping..', '', {
      //   timeOut: 10000,
      // });
      return "true";
    }
    else {
      return "false";
    }
  }


  createProduct() {
    // this.productForm.value.measureMents.map((data) => {
    //    console.log(data.length.toFixed(3));
    // })

    if (this.productForm.valid) {
      let isError = this.ValidationForCustomShipping();
      if (isError == "true") {
        this.toast.error('Provide both values For CustomShipping..', '', {
          timeOut: 10000,
        });
      }
      else if (isError == "false") {
        let filteredMarketPlaces = this.marketPlacesArray.filter((data) => { return data.is_active == "true" });
        let filteredCustomShippingArray = this.customShippingArray.filter((data) => { return data.addl_qty_ship_cost.length > 0 && data.first_qty_ship_cost.length > 0 })
        let arrayForMeasureMent = [];
        this.productForm.value.measureMents.map((data) => {
          let objDimention = {
            height: data.height,
            length: data.length,
            weight: data.weight,
            width: data.width
          }
          arrayForMeasureMent.push(objDimention)
        })

        let objForCreateProduct = {
          product_sku_id: this.productForm.value.productSku,
          is_ship_solo: this.productForm.value.shipSolo,
          product_name: this.productForm.value.productName,
          product_description: this.productForm.value.productDesc,
          is_additional_pieces: this.addAdditionalToggle,
          dimension_unit: this.productForm.value.dimentionUnit,
          shipping_mode: this.productForm.value.freeShipping,
          product_dimension: arrayForMeasureMent,
          market_places: filteredMarketPlaces,
          do_not_ship_region: this.doNotShipRegionArray,
          custom_shipping_charge: filteredCustomShippingArray
        }

        this.apiCall = true;
        this.shipcatepro.createProduct(objForCreateProduct, this.imageData).subscribe((res) => {
          this.toast.success('Success! Product added to list', '', {
            timeOut: 10000,
          });
          this.apiCall = false;
          this.listDataService.removeAllDataOfShipcarteProProductList();
          this.router.navigateByUrl(`/shipcartepro/tabs/intelliBox`)
        }, error => {
          if (error['status'] === 401) {
            localStorage.clear();
            this.router.navigate(['/signin'])
          }
          this.apiCall = false;
          this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
            timeOut: 10000,
          });
        })
      }
    }
    else {
      this.submitClicked = true
      this.logValidationErrors(this.productForm)
    }
  }


  updateProduct() {
    if (this.productForm.valid) {
      let isError = this.ValidationForCustomShipping();
      if (isError == "true") {
        this.toast.error('Provide both values For CustomShipping..', '', {
          timeOut: 10000,
        });
      }
      else if (isError == "false") {
        let newArrayMarketPlacesNewAndModifiedData = [];
        let newArrayDoNotShipNewAndModifiedData = [];
        let newArrayCustomShippingNewAndModifiedData = [];

        // filter for marketPlaces Array  ************************************************************************************************************************

        this.marketPlacesArray.map((originalArray, index) => {
          let fetchedData = this.editData.market_places.filter((dataFetchMarketPlaces) => { return dataFetchMarketPlaces.name == originalArray.name })
          if (fetchedData.length > 0) {
            let localObj = {
              market_place_map_id: fetchedData[0].market_place_map_id,
              is_active: this.marketPlacesArray[index].is_active
            }
            newArrayMarketPlacesNewAndModifiedData.push(localObj);
          }
          else {
            if (this.marketPlacesArray[index].is_active == "true") {
              newArrayMarketPlacesNewAndModifiedData.push(this.marketPlacesArray[index])
            }
          }
        })

        //  ************************************************************************************************************************

        // filter for do not ship region Array ************************************************************************************************************************

        this.doNotShipRegionArray.map((originalArray, index) => {
          let fetchedData = this.editData.do_not_ship_region.filter((dataFetchMarketPlaces) => { return dataFetchMarketPlaces.zone_name == originalArray.zone_name })
          if (fetchedData.length > 0) {
            let localObj = {
              do_not_ship_region_id: fetchedData[0].do_not_ship_region_id,
              is_shippable: this.doNotShipRegionArray[index].is_shippable
            }
            newArrayDoNotShipNewAndModifiedData.push(localObj);
          }
          else {
            if (this.doNotShipRegionArray[index].is_shippable == "false") {
              newArrayDoNotShipNewAndModifiedData.push(this.doNotShipRegionArray[index])
            }
          }
        })

        //  ************************************************************************************************************************

        // filter for custom Shipping Array  ************************************************************************************************************************

        this.customShippingArray.map((originalArray, index) => {
          let fetchedData = this.editData.custom_shipping_charge.filter((dataFetchCustomShipping) => { return dataFetchCustomShipping.zone_id == originalArray.zone_id })
          if (fetchedData.length > 0) {
            let localObj = {
              ship_zone_cost_map_id: fetchedData[0].ship_zone_cost_map_id,
              first_qty_ship_cost: this.customShippingArray[index].first_qty_ship_cost.length > 0 ? this.customShippingArray[index].first_qty_ship_cost : "0",
              addl_qty_ship_cost: this.customShippingArray[index].addl_qty_ship_cost.length > 0 ? this.customShippingArray[index].addl_qty_ship_cost : "0",
              currency: this.customShippingArray[index].currency
            }
            newArrayCustomShippingNewAndModifiedData.push(localObj);
          }
          else {
            if (this.customShippingArray[index].first_qty_ship_cost.length > 0 && this.customShippingArray[index].addl_qty_ship_cost.length > 0) {
              newArrayCustomShippingNewAndModifiedData.push(this.customShippingArray[index])
            }
          }
        })

        //  ************************************************************************************************************************

        // filter for dimentions array ************************************************************************************************************************

        let dimentionArrayToSend = [];

        this.productForm.value.measureMents.map((data) => {
          if (data.uniqueIdForEdit == "new") {
            let objForNewData = {
              height: data.height,
              length: data.length,
              weight: data.weight,
              width: data.width
            }
            dimentionArrayToSend.push(objForNewData)
          }
          else {
            let objForOldData = {
              product_dimension_id: data.uniqueIdForEdit,
              height: data.height,
              length: data.length,
              weight: data.weight,
              width: data.width
            }
            dimentionArrayToSend.push(objForOldData)
          }
        })

        this.removeDimentionArray.map((data) => {
          let objForRemoveDimention = {
            product_dimension_id: data,
            is_deleted: true
          }
          dimentionArrayToSend.push(objForRemoveDimention)
        })

        //  ************************************************************************************************************************

        let objForUpdateProduct = {
          // product_id: this.editData.product_id,
          // product_sku_id: this.productForm.value.productSku,
          // is_ship_solo: this.productForm.value.shipSolo,
          // product_name: this.productForm.value.productName,
          // product_description: this.productForm.value.productDesc,
          // is_additional_pieces: this.addAdditionalToggle,
          // dimension_unit: this.productForm.value.dimentionUnit,
          // shipping_mode: this.productForm.value.freeShipping,
          product_dimension: dimentionArrayToSend,
          market_places: newArrayMarketPlacesNewAndModifiedData,
          do_not_ship_region: newArrayDoNotShipNewAndModifiedData,
          custom_shipping_charge: newArrayCustomShippingNewAndModifiedData
        }
        if (this.uploadImageShow == null) {
          objForUpdateProduct["product_image_id"] = "NA"
        }
        if (this.productForm.value.productSku !== this.editData.product_sku_id) {
          objForUpdateProduct["product_sku_id"] = this.productForm.value.productSku;
        }
        if (this.productForm.value.shipSolo !== this.editData.is_ship_solo) {
          objForUpdateProduct["is_ship_solo"] = this.productForm.value.shipSolo;
        }
        if (this.productForm.value.productName !== this.editData.product_name) {
          objForUpdateProduct["product_name"] = this.productForm.value.productName;
        }
        if (this.productForm.value.productDesc !== this.editData.product_description) {
          objForUpdateProduct["product_description"] = this.productForm.value.productDesc;
        }
        if (this.addAdditionalToggle !== this.editData.is_additional_pieces) {
          objForUpdateProduct["is_additional_pieces"] = this.addAdditionalToggle;
        }
        if (this.productForm.value.dimentionUnit !== this.editData.dimension_unit) {
          objForUpdateProduct["dimension_unit"] = this.productForm.value.dimentionUnit;
        }
        if (this.productForm.value.freeShipping !== this.editData.shipping_mode) {
          objForUpdateProduct["shipping_mode"] = this.productForm.value.freeShipping;
        }
        this.apiCall = true;
        // console.log(this.doNotShipRegionArray);
        this.shipcatepro.updateProduct(objForUpdateProduct, this.imageData, this.editData.product_id).subscribe((res) => {
          this.toast.success('Success! Product updated successfully', '', {
            timeOut: 10000,
          });
          this.listDataService.removeAllDataOfShipcarteProProductList()
          this.apiCall = false;
        }, error => {
          if (error['status'] === 401) {
            localStorage.clear();
            this.router.navigate(['/signin'])
          }
          this.apiCall = false;
          this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
            timeOut: 10000,
          });
        })
      }
    }
    else {
      this.submitClicked = true
      this.logValidationErrors(this.productForm)
    }
  }


  // section for modal open ****************************************************************************************************

  open(content) {
    this.modalService.open(content, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: "my-custom-modalBulkUpload" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if (result == 'Save click') {
        this.bulkUpload();
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.bulkUploadData.name = '';
      this.bulkUploadData.file = null;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //  ************************************ ************************************ ************************************ ************************************ ************************************

  // bulk upload functions ******************************************************************************************************************************

  selectExcelFile(event) {
    event.preventDefault()
    let files
    if (event.dataTransfer) {
      files = event.dataTransfer.files
    } else if (event.target) {
      files = event.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.bulkUploadData.file = event.target.files[0]
        this.bulkUploadData.name = event.target.files[0].name
      }
    }
    reader.readAsDataURL(files[0])
  }

  getFileType() {
    return this.bulkUploadData.name.split(".").pop()
  }

  bulkUpload() {
    if (this.bulkUploadData.file) {
      this.apiCall = true
      let fileType = this.getFileType();
      this.shipcatepro.bulkUploadProduct(this.bulkUploadData.file, fileType).subscribe((res: any) => {
        this.bulkUploadData.name = '';
        this.bulkUploadData.file = null;
        this.toast.success(`${res.message}`, '', {
          timeOut: 10000,
        });
        this.apiCall = false;
        if (fileType == "zip") {
          this.router.navigateByUrl(`/shipcartepro/tabs/intelliBox`)
        }
      }, error => {
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin'])
        }
        this.bulkUploadData.name = '';
        this.bulkUploadData.file = null;
        this.apiCall = false;
        this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
          timeOut: 10000,
        });
      })
    }
  }

  downLoadSampleData() {
    let sampleData = [
      {
        product_id: "29ec8283-afea-4587-840f-89fe0e71ea9a",
        product_sku_id: "SKU-1234T45",
        is_ship_solo: true,
        product_name: "Sample product 1",
        product_description: "Product description 1",
        status: true,
        product_dimension: [
          {
            length: 20,
            width: 20,
            height: 20,
            weight: 30
          },
          {
            length: 12,
            width: 12,
            height: 12,
            weight: 10
          }
        ],
        is_additional_pieces: true,
        dimension_unit: "imperial",
        shipping_mode: "QFS",
        market_places: [
          {
            market_place_id: "052gsdh-s5es6d-4234-sa334-e46afgg45d",
            name: "Shopify"
          },
          {
            market_place_id: "052gsdh-s5es6d-4234-b45-e46afgg45d",
            name: "Woo Commerce"
          },
          {
            market_place_id: "000gsdh-s5x6d-4744-b45-e46afgg45d",
            name: "Amazon"
          }
        ],
        do_not_ship_region: [
          {
            do_not_ship_region_id: "00062a2f-c75e-4744-bf18-e45af8345d",
            zone_name: "California"
          }
        ],
        custom_shipping_charge: []
      },
      {
        product_id: "98c74046-9fa1-4b99-833f-8d5f3bfb125a",
        product_sku_id: "SKU-1234T46",
        is_ship_solo: false,
        product_name: "Sample product 2",
        product_description: "Product description 1",
        status: true,
        product_dimension: [
          {
            length: 10,
            width: 10,
            height: 10,
            weight: 10
          }
        ],
        is_additional_pieces: false,
        dimension_unit: "imperial",
        shipping_mode: "EFS",
        market_places: [
          {
            market_place_id: "052gsdh-s5es6d-4234-b45-e46afgg45d",
            name: "Woo Commerce"
          },
          {
            market_place_id: "052gsdh-s5es6d-4234-sa334-e46afgg45d",
            name: "Shopify"
          },
          {
            market_place_id: "55fgf-s5es6d-45854-sa334-e46afgg45d",
            name: "ebay"
          },
          {
            market_place_id: "000gsdh-s5x6d-4744-b45-e46afgg45d",
            name: "Amazon"
          },
          {
            market_place_id: "052gsdh-s5es6d-45854-sa334-e46afgg45d",
            name: "Etsy"
          },
          {
            market_place_id: "052gsdh-s5x6d-4744-b45-e46afgg45d",
            name: "Magento 2"
          }
        ],
        do_not_ship_region: [
          {
            do_not_ship_region_id: "000gsdh-c75e-4744-b45-e46afgg45d",
            zone_name: "Us-Cross-border"
          },
          {
            do_not_ship_region_id: "00062a2f-c75e-4744-bf18-e45afgg45d",
            zone_name: "Domestic"
          },
          {
            do_not_ship_region_id: "00062a2f-c75e-4744-bf18-e45af8345d",
            zone_name: "California"
          },
          {
            do_not_ship_region_id: "00062a2f-c75e-4744-bf798-e45afgg45d",
            zone_name: "Manitoba"
          },
          {
            do_not_ship_region_id: "000gsdh-s56d-4744-b45-e46afgg45d",
            zone_name: "Rest of world"
          }
        ],
        custom_shipping_charge: []
      },
      {
        product_id: "29ec8283-afea-4587-840f-89fe0e71ea9a",
        product_sku_id: "SKU-1234T90",
        is_ship_solo: false,
        product_name: "Sample product 3",
        product_description: "Product description 3",
        status: true,
        product_dimension: [
          {
            length: 20,
            width: 20,
            height: 20,
            weight: 30
          },
          {
            length: 12,
            width: 12,
            height: 12,
            weight: 10
          },
          {
            length: 10,
            width: 10,
            height: 10,
            weight: 10
          }
        ],
        is_additional_pieces: true,
        dimension_unit: "metric",
        shipping_mode: "ASF",
        market_places: [
          {
            market_place_id: "052gsdh-s5es6d-4234-sa334-e46afgg45d",
            name: "Shopify"
          },
          {
            market_place_id: "000gsdh-s5x6d-4744-b45-e46afgg45d",
            name: "Amazon"
          }
        ],
        do_not_ship_region: [
          {
            do_not_ship_region_id: "00062a2f-c75e-4744-bf18-e45af8345d",
            zone_name: "California"
          },
          {
            do_not_ship_region_id: "000gsdh-s56d-4744-b45-e46afgg45d",
            zone_name: "Rest of world"
          }
        ],
        custom_shipping_charge: []
      },
    ];

    if (sampleData && sampleData.length > 0) {
      let arrTemp = [];
      let orderMarketPlacesArray = [];
      let orderDNSRArray = [];
      let maxLength = 0;
      let nameMarketPlacesOrder = ['Amazon', 'Magento 2', 'Woo Commerce', 'Shopify', 'Etsy', 'ebay'];
      let nameDoNotShipArrayOrder = ['California', 'Domestic', 'Manitoba', 'Ontario', 'Us-Cross-border', 'Rest of world'];
      sampleData.map((data: any) => {
        let tempArrayForProductDonNotShipData = [];
        orderMarketPlacesArray = [];
        orderDNSRArray = [];

        nameDoNotShipArrayOrder.map((dataFixedDNS) => {
          this.getLoadCreateProDuctData?.do_not_ship_region?.map((element) => {
            if (element.zone_name.toLowerCase() == dataFixedDNS.toLowerCase()) {
              orderDNSRArray.push(element);
            }
          })
        })

        orderDNSRArray?.map((fullData) => {

          let match = data.do_not_ship_region.find((element) =>
            element.zone_name == fullData.zone_name
          )
          if (match) {
            tempArrayForProductDonNotShipData.push({ ...fullData, status: 'N' })
          }
          else {
            tempArrayForProductDonNotShipData.push({ ...fullData, status: 'Y' })
          }
        })

        let tempArrayForProductMarketPlaces = [];

        nameMarketPlacesOrder.map((dataFixedMarket) => {
          this.getLoadCreateProDuctData?.market_places?.map((element) => {
            if (element.name.toLowerCase() == dataFixedMarket.toLowerCase()) {
              orderMarketPlacesArray.push(element);
            }
          })
        })

        orderMarketPlacesArray?.map((fullData) => {
          let match = data.market_places.find((element) =>
            element.name == fullData.name
          )
          if (match) {
            tempArrayForProductMarketPlaces.push({ ...fullData, status: 'Y' })
          }
          else {
            tempArrayForProductMarketPlaces.push({ ...fullData, status: 'N' })
          }
        })


        let obj = {
          product_sku_id: data.product_sku_id,
          is_ship_solo: data.is_ship_solo ? 'Y' : 'N',
          product_name: data.product_name,
          product_description: data.product_description,
          dimension_unit: data.dimension_unit,
          product_dimension: data.product_dimension,
          shipping_mode: data.shipping_mode,
          doNotShipData: tempArrayForProductDonNotShipData,
          marketPlacesData: tempArrayForProductMarketPlaces
        }
        if (data.product_dimension.length > maxLength) {
          maxLength = data.product_dimension.length;
        }
        arrTemp.push(obj);
      })

      let headerRowDisplay = ['SKU ID', 'Product Name', 'Description', 'Ship Solo', 'Unit Of Measurement'];
      let headerRowMap = ['product_sku_id', 'product_name', 'product_description', 'is_ship_solo', 'dimension_unit'];
      let marketPlacesNames = [];
      let doNotShipRegionNames = [];

      orderMarketPlacesArray?.map((mplData) => {
        headerRowDisplay.push((`Sell in ${mplData.name}`));
        headerRowMap.push(`${mplData.name}`)
        marketPlacesNames.push(`${mplData.name}`)
      })

      headerRowDisplay.push((`Free Shipping Mode`));
      headerRowMap.push(`shipping_mode`)

      orderDNSRArray?.map((dnsData) => {
        headerRowDisplay.push((`No Ship To ${dnsData.zone_name}`));
        headerRowMap.push(`${dnsData.zone_name}`)
        doNotShipRegionNames.push(`${dnsData.zone_name}`)
      })

      // console.log(headerRowDisplay,headerRowMap,doNotShipRegionNames,marketPlacesNames)

      for (let i = 0; i < maxLength; i++) {
        headerRowMap.push('length');
        headerRowMap.push('width');
        headerRowMap.push('height');
        headerRowMap.push('weight');
        headerRowDisplay.push('Length');
        headerRowDisplay.push('Width');
        headerRowDisplay.push('Height');
        headerRowDisplay.push('Weight');
      }

      this.shipcatepro.downloadFileProduct(arrTemp, headerRowMap, headerRowDisplay, marketPlacesNames, doNotShipRegionNames, 'Sample_Product');
      this.apiCall = false;
    }
  }

  // ************************************************************************************************************************************************************************************************************************************************************

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }

}
