<div style="border-bottom: 1px solid #C4CDD5; background-color: #F5F6FA; width: 100% !important;" id="pageHeaderCss">
    <div >
        <div >
            <a style="color: #354052; text-decoration: none;cursor: pointer;  ">
                <!-- <img (click)="redirectToPartnerList()" style="margin-right: 15px;margin-bottom: 10px;width: 19px; margin-left: 10px;" src="assets/img/common/fi-rr-arrow-small-left.svg" alt=""> -->
                <span class="screen-title pt-2 " style="font-weight:500;font-size:26px;color:#354052;">Favorite Orders</span>
            </a>
        </div>
    </div>
</div>


<!-- ################################ Orders ################################ -->
<div style="height:64px;"></div>
<div style="color: #354052;" id="pageBodyCssS">
    <div class="customerUserSection">
        <div class="row paddingLeftTableList " >
            <div class="col-12 tablelistInner pr-0 pb-0 pl-0" style="border: 1px solid #C4CDD5;     height: calc(100vh - 205px);
            overflow: scroll;">
                <div style="     width: 100%;
                position: sticky;
                top: 0px;
                background: white;
                z-index: 9;
                min-width: 1920px;" class="row mb-0">
                    <div class="col-12 applay_section ">
                        <div class="row pl-0 mb-0 align-items-start justify-content-between">
                            <div class="row flex-nowrap" style="margin:0px !important;">
                                <div class="col-4 pt-3 mr-2 ml-0 text-start serachbar-box pl-0"
                                    style="width: 22%;color: #354052;">
                                    <label class="ml-0"
                                        style="color:#354052 !important;font-family: poppins,sans-serif;font-size: 16px !important;">
                                        Search Favorite
                                        Orders</label>
                                    <input class="form-control ml-0" (input)="setSearchKey($event.target.value)" [value]="searchKey"
                                        style="background-image: url('assets/img/quotes/search-icon.svg') !important; margin-left: 0px; font-size:16px !important;color: #354052 !important; "
                                        type="text" placeholder="Search Favorite Orders.." />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style="margin-top: 12px; min-width: 1920px; border-top: 1px solid #C4CDD5; border-bottom: 1px solid #C4CDD5; background-color: #F5F6FA; padding: 15px 24px; padding-right: 12px; display: flex; justify-content: space-between;"
                        class="  orderhistory">
                        <div style="width: 200px;">
                            <div (click)="sorting('favOrderName')"
                                style="font-size:14px; font-weight: 500; cursor:pointer; ">
                                Favorite order name <img [ngClass]="sortBy['favOrderName']"
                                    style="height: 13px; width: 13px;" src="assets/img/quotes/down_arrow.svg" />
                            </div>
                        </div>
                        <!-- <div style="width: 170px; padding-left:5px; font-size: 14px;">
                        <div style="width:171px; font-size: 14px; font-weight: 500; cursor:pointer; " class="customer"
                            (click)="sorting('carrierName')">
                           Customer<img [ngClass]="sortBy['carrierName']" style="height: 13px; width: 13px;"
                                src="assets/img/quotes/down_arrow.svg" /> </div>
                    </div> -->
                        <div style="width: 170px; font-size: 14px;">
                            <div style=" font-size: 14px; font-weight: 500; cursor:pointer; " class="customer"
                                (click)="sorting('shipmentMdName')">
                                Service <img [ngClass]="sortBy['shipmentMdName']" style="height: 13px; width: 13px;"
                                    src="assets/img/quotes/down_arrow.svg" />
                            </div>
                        </div>
                        <div style="width: 150px;">
                            <div (click)="sorting('pickupDate')"
                                style="font-size: 14px; font-weight: 500; cursor:pointer; ">
                                Pick up Date <img [ngClass]="sortBy['pickupDate']" style="height: 13px; width: 13px;"
                                    src="assets/img/quotes/down_arrow.svg" /> </div>

                        </div>
                        <div style="width: 300px;">
                            <div (click)="sorting('dimension')"
                                style="font-size: 14px; font-weight: 500; cursor:pointer; ">
                                Dimensions <img [ngClass]="sortBy['dimension']" style="height: 13px; width: 13px;"
                                    src="assets/img/quotes/down_arrow.svg" /></div>

                        </div>
                        <div style="width: 170px;">
                            <div (click)="sorting('totalWeight')"
                                style="font-size: 14px; font-weight: 500; cursor:pointer;">
                                Weight <img [ngClass]="sortBy['totalWeight']" style="height: 13px; width: 13px;"
                                    src="assets/img/quotes/down_arrow.svg" /></div>

                        </div>
                        <div style="width: 200px; " (click)="sorting('shipper')">
                            <div style="font-size:14px; font-weight: 500; cursor:pointer; ">
                                Shipper <img [ngClass]="sortBy['shipper']" style="height: 13px; width: 13px;"
                                    src="assets/img/quotes/down_arrow.svg" /> </div>
                        </div>
                        <div style="width: 200px;" (click)="sorting('origin')">
                            <div style="font-size: 14px ; font-weight: 500; cursor:pointer; ">
                                Origin <img [ngClass]="sortBy['origin']" style="height: 13px; width: 13px;"
                                    src="assets/img/quotes/down_arrow.svg" /> </div>
                        </div>
                        <div style="width: 200px;">
                            <div (click)="sorting('consignee')"
                                style="font-size: 14px; font-weight: 500; cursor:pointer; ">
                                Consignee <img [ngClass]="sortBy['consignee']" style="height: 13px; width: 13px;"
                                    src="assets/img/quotes/down_arrow.svg" /> </div>
                        </div>
                        <div style="width: 160px;" (click)="sorting('destination')">
                            <div style="font-size: 14px; font-weight: 500; cursor:pointer; ">
                                Destination <img [ngClass]="sortBy['destination']" style="height: 13px; width: 13px;"
                                    src="assets/img/quotes/down_arrow.svg" /> </div>

                        </div>
                        <div style="width: 110px;" (click)="sorting('destination')">
                            <div style="font-size: 14px; font-weight: 500; cursor:pointer; ">
                            </div>

                        </div>
                        <div style="width: 53px;">
                            <div style="font-size: 14px; font-weight: 500;">
                                Actions
                            </div>
                        </div>
                    </div>
                </div>
                <!-- HEADER START -->

                <!-- HEADER END -->
                <!-- BODY START -->
                <div *ngIf="isDataLoading!=''" style="font-size: 20px; font-weight: 500; display: flex; justify-content: center; align-items: center;height: calc(100vh - 343px);">
                    {{isDataLoading}}
                </div>
                <div *ngIf="isDataLoading==''">
                    <div *ngFor="let orders of manualOrder, let i=index"
                        style="min-width: 1920px;  border-top: 1px solid #C4CDD5; display: flex; padding:9px; padding-left: 24px; padding-right: 12px; display: flex; justify-content: space-between;">
                        <div style="width: 200px; display: flex; align-items: center; padding-right: 20px;">
                            <div>
                                <img style="cursor: pointer; padding: 0px;height: 10px; width: 13px;"
                                    src="assets/img/quotes/down_icon.svg" placement="bottom"
                                    popoverClass="popover_cust_detail" autoClose="outside" triggers="manual"
                                    [ngbPopover]="popContent" #p="ngbPopover"
                                    (click)="p.open(); popOverIndex(orders['quoteDetails'],orders['quoteDetails']['shipmentModeName'],orders['quoteDetails']['shipmentModeStyleCode'])"
                                    class="dropdown-item">

                                <ng-template #popContent class="test"
                                    style="color: #354052;padding:0px;font: normal normal medium 16px/18px Poppins !important;">
                                    <div
                                        style="color: #303447; font-size:16px !important;font-weight: 500;border-bottom:1px solid #E3E7EE; padding-bottom:10px;">
                                        Favorite order name</div>
                                    <div style="display:flex; justify-content: space-between;padding-top:15px;">
                                        <div style="display: flex;">
                                            <div><img style="height:47px;width:47px;" src="{{partnerLogo}}" alt="">
                                            </div>
                                            <div
                                                style="padding-left:10px; font-weight:500;color: #707070;display: flex;align-items: center;font-size:16px !important;">
                                                {{carrierName}}</div>
                                        </div>
                                        <div style="display:flex;align-items: center;">
                                            <div style="color: #151522;padding-right: 5px;font-size:16px !important;">
                                                {{packageName}}</div>
                                            <div><img style="height:20px;width:20px;"
                                                    src="assets/img/common/shmt/{{packageLogo}}.svg" alt=""></div>
                                        </div>
                                    </div>

                                    <div
                                        style="display: flex; justify-content: space-between;color: #151522; font-weight: 500; padding-top:19px;font-size:16px !important;">
                                        <div style="font-size:16px !important;">Quantity:</div>
                                        <div style="font-size:16px !important;">{{quantity}}</div>
                                    </div>

                                    <div
                                        style="display: flex; justify-content: space-between;color: #151522; font-weight: 500; padding-top:19px;">
                                        <div style="font-size:16px !important;">Weight:</div>
                                        <div style="font-size:16px !important;">{{weight}} <span
                                                style="font-size:16px !important;">lbs</span> </div>
                                    </div>

                                    <div
                                        style="display: flex; justify-content: space-between;color: #151522; font-weight: 500; padding-top:19px">
                                        <div style="font-size:16px !important;">Class:</div>
                                        <div style="font-size:16px !important;">{{class}}</div>
                                    </div>

                                    <div
                                        style="display: flex; justify-content: space-between;color: #151522; font-weight: 500; padding-top:19px">
                                        <div style="font-size:16px !important;">Amount Charged:</div>
                                        <div style="font-size:16px !important;">$ {{amountCharged}}</div>
                                    </div>

                                    <div
                                        style="display: flex; justify-content: space-between;color: #151522; font-weight: 500; padding-top:19px;padding-bottom:10px">
                                        <div style="font-size:16px !important;">Last Shipped:</div>
                                        <div style="font-size:16px !important;">{{shipperDate ? shipperDate :'--'}}
                                        </div>
                                    </div>

                                </ng-template>

                            </div>

                            <div
                                style="font-size:14px; word-break: break-all;cursor: pointer; padding-left:15px !important;font-weight: 500;">
                                {{orders.favOrderName ? orders.favOrderName:'--'}}</div>
                        </div>

                        <!-- <div style="width: 170px; display: flex; align-items: center; padding-right: 15px;">
                            <div style="margin-right: 7px; "></div>
                            <div *ngIf="orders.profilePic!='N/A'"  style="background-color: #C4CDD5; padding: 3px ; border-radius: 50%; margin-right: 7px;">
                                <img src="{{orders.profilePic ? orders.profilePic : '/assets/img/avatar.png'}}"
                                    style="border-radius: 50%; height: 32px; width: 32px;" alt="">
                            </div>
                            <div *ngIf="orders.profilePic=='N/A'"  style="background-color: #C4CDD5; padding: 3px ; border-radius: 50%; margin-right: 7px;">
                                <img src="../../../../../assets/img/avatar.png"
                                    style="border-radius: 50%; height: 32px; width: 32px;" alt="">
                            </div>
                            
                            <div style="font-size: 14px; word-break: break-all; color: #2877C6; cursor: pointer; overflow: hidden;">
                                <div (click)="redirctToGeneralDetails(orders.customerId)" >{{orders.consigneeName ? orders.consigneeName:'--'}}</div>
                            </div>
                        </div> -->

                        <div
                            style="width: 170px; display: flex; align-items: center; padding-left: 0px; padding-right: 20px;">
                            <div style=" padding:6px; margin-right: 7px;">
                                <img src="assets/img/common/shmt/{{orders.quoteDetails?.shipmentModeStyleCode }}.svg"
                                    style=" width:25px !important; height:19px !important ; object-fit: cover;" alt="">
                            </div>
                            <div
                                style="font-size: 14px; word-break: break-all; color:#333F52;font-weight: 500 !important;">
                                <div>{{orders.shipmentMdName ? orders.shipmentMdName:'--'}}</div>
                            </div>
                        </div>

                        <div
                            style="width: 150px; display: flex; align-items: center;overflow: hidden; padding-right: 20px;">
                            <div
                                style="font-size: 14px; word-break: break-all;color: #354052;font-weight: 500 !important;">
                                {{orders.pickupDate ?
                                orders.pickupDate :'--'}}</div>
                        </div>

                        <ng-container *ngIf="orders?.quoteDetails?.items && orders?.quoteDetails?.items.length > 0">
                            <div
                                style="width: 300px; display: flex; align-items: center; padding-left: 0px;min-width:fit-content; padding-right: 20px;">
                                <div
                                    style="font-size: 14px; word-break: break-all; font-weight: 500 !important; display: flex;">
                                    <div style="min-width: 70px;">
                                        <span style="color: #7F8FA4;">L:</span>
                                        &nbsp;{{ getSum(orders?.quoteDetails?.items, 'length') | number : '1.2-2' ||
                                        '--' }}
                                    </div>
                                    &nbsp;&nbsp;
                                    <div style="min-width: 70px;">
                                        <span style="color: #7F8FA4;">W:</span>
                                        &nbsp;{{ getSum(orders?.quoteDetails?.items, 'breadth') | number : '1.2-2' ||
                                        '--' }}
                                    </div>
                                    &nbsp;&nbsp;
                                    <div style="min-width: 70px;">
                                        <span style="color: #7F8FA4;">H:</span>
                                        &nbsp;{{ getSum(orders?.quoteDetails?.items, 'height') | number : '1.2-2' ||
                                        '--' }}
                                    </div>
                                    &nbsp;
                                    <span class="custom-badge">{{orders?.quoteDetails?.items[0].unit}}</span>
                                </div>
                            </div>
                            <div
                                style="width: 170px; display: flex; align-items: center; padding-right: 9px; overflow: hidden;min-width: fit-content; padding-right: 20px;">
                                <div
                                    style="font-size: 14px; padding-right: 0px; font-weight: 500 !important; display: flex;">
                                    <div style="min-width: 100px;">
                                        <span style="color: #7F8FA4;">W:</span>
                                        &nbsp;{{ getSum(orders?.quoteDetails?.items, 'totalWeight') | number : '1.2-2'
                                        || '--' }}&nbsp;
                                    </div>
                                    <span class="custom-badge">{{orders?.quoteDetails?.items[0].wghtUnit}}</span>
                                </div>
                            </div>
                        </ng-container>
                        <!-- <ng-container *ngIf="orders?.quoteDetails?.items && orders?.quoteDetails?.items.length > 0">
                            <div style="width: 203px; display: flex; align-items: center; padding-left: 0px;">
                              <div style="font-size: 14px; word-break: break-all; font-weight: 500 !important;">
                                <span style="color: #7F8FA4;">L:</span> &nbsp;{{ getSum(orders?.quoteDetails?.items, 'length') ? getSum(orders?.quoteDetails?.items, 'length') : '--' }}&nbsp;&nbsp;
                                <span style="color: #7F8FA4;">W:</span> &nbsp;{{ getSum(orders?.quoteDetails?.items, 'breadth') ? getSum(orders?.quoteDetails?.items, 'breadth') : '--' }}&nbsp;&nbsp;
                                <span style="color: #7F8FA4;">H:</span> &nbsp;{{ getSum(orders?.quoteDetails?.items, 'height') ? getSum(orders?.quoteDetails?.items, 'height') : '--' }}&nbsp;
                                <span class="custom-badge">Inch</span>
                              </div>
                            </div>
                          
                            <div style="width: 130px; display: flex; align-items: center; padding-right: 9px; overflow: hidden;">
                              <div style="font-size: 14px; padding-right: 0px; font-weight: 500 !important;">
                                <span style="color: #7F8FA4;">W:</span> &nbsp;{{ getSum(orders?.quoteDetails?.items, 'totalWeight') ? getSum(orders?.quoteDetails?.items, 'totalWeight') : '--' }}&nbsp;
                                <span class="custom-badge">Lbs</span>
                              </div>
                            </div>
                          </ng-container> -->
                        <div
                            style="width: 200px; display: flex; align-items: center; padding-right: 15px;overflow: hidden; padding-right: 20px;">
                            <div
                                style="font-size: 14px; padding-right:5px ; color: #354052;font-weight: 500 !important;">
                                {{orders.shipper ?
                                orders.shipper :'--'}}</div>
                        </div>
                        <div
                            style="width:200px; display: flex; align-items: center;overflow: hidden; padding-right: 20px;">
                            <div
                                style="font-size: 14px; padding-right: 5px; color: #354052;font-weight: 500 !important;">
                                {{orders.origin ? orders.
                                origin:'--' }}</div>
                        </div>
                        <div
                            style="width: 200px; display: flex; align-items: center; overflow: hidden; padding-right: 20px;">
                            <div
                                style="font-size: 14px; padding-right: 5px;color: #354052;font-weight: 500 !important;">
                                {{orders.consignee ? orders.
                                consignee:'--' }}</div>
                        </div>
                        <div
                            style="width: 160px ;display: flex; align-items: center; overflow: hidden; font-weight: 500 !important; padding-right: 20px;">
                            <div style="font-size: 14px; color: #354052;font-weight: 500 !important;">
                                {{orders.destination ? orders.destination:'--' }}
                            </div>
                        </div>
                        <div
                            style="width: 110px ;display: flex; align-items: center; overflow: hidden; font-weight: 500 !important;">
                            <span>
                                <button class="payNow" (click)="getQuote()" style="height: 36px; width: 100px;" [ngClass]="{'hidden': orders.source !== 'Quoted', 'visible': orders.source == 'Quoted'}">Get
                                    Quote
                                </button>
                            </span>
                        </div>


                        <div class="action_td"
                            style="width: 53px; display: flex; align-items: center; justify-content: center;">
                            <div style="width: 79px; padding: 0; text-align: center;margin-left: 20px;" class="action_td">
                                <img src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle" data-toggle="dropdown">
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" multiple>
                                    <a (click)="shipAgain(orders['quoteDetails']['quoteId'],orders['source'], orders['orderId'])" class="dropdown-item">
                                        Ship Again
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" (click)="open(  orders['orderId'],orders['favOrderName'],i)">
                                        Remove
                                    </a>
                              </div>
                            <!-- <img src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle " data-toggle="dropdown"
                                style="margin-right: 0px;">
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" multiple>
                                <a (click)="shipAgain(orders['quoteDetails']['quoteId'],orders['source'], orders['orderId'])"
                                    class="dropdown-item">
                                    Ship Again
                                </a>
                                <a class="dropdown-item"
                                    (click)="open(  orders['orderId'],orders['favOrderName'],i)">
                                    Remove
                                </a>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div *ngIf="isLoading" style="display: flex; justify-content: center; padding: 10px;">
                    Loading...
                </div>
                <!-- BODY END -->

            </div>
        </div>
    </div>
</div>


<!-- ###############################  Remove fev ################################## -->
<ng-template #confirmWarn let-modal>
    <div>
        <div class="row" style="margin-top: 30px; ">
            <div class="col-12 text-center">
                <img style="padding-top: 10px;height: 70px;" src="assets/img/Warning.svg" alt="">
            </div>
        </div>

        <div class="row mb-4 justify-content-center">
            <div class="col-12 text-center">
                <p class="mb-0"
                    style="font-size: 16px !important; font-weight: 400; font-family: poppins; color:#151522; display: flex !important;text-align: center !important;">
                    Confirm you want to remove this shipments from favorites?
                </p>
            </div>
        </div>
        <div class="row mt-3 ml-0 mr-0">
            <div class="modal-footer" style="display:flex !important; justify-content: end;">
                <button type="button"
                    style="color:#354052; border: none; width:50px; margin-right: 14px; font-size:14px !important; font-weight:600 !important; cursor:pointer !important;"
                    class="saveToFavCancelButton" (click)="modal.dismiss('Cancel click')">Cancel</button>
                <button type="button"
                    style="padding: 0px 7px; width: 86px; height: 36px; background-color: #FF9A00; color: white; margin-left:10px; font-size:14px !important; font-weight:600 !important;cursor:pointer !important;text-transform: capitalize !important;"
                    (click)="modal.close('Save click')" class="btn btn-color">Yes</button>
            </div>
            <!-- </div> -->
        </div>
    </div>
</ng-template>


<!-- ##################################### Loading page ############################################## -->

<app-spinner></app-spinner>