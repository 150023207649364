import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as data from '../../src/assets/config.json';
import { CommonService } from './services/common.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Shipcarte';

  closeModal: string;
  cYear:any;
  config: any;
  sidebarToggle: boolean;
  val:boolean;
  sharedData: any;
  constructor(
    private modalService: NgbModal, private httpClient: HttpClient, 
    public common: CommonService, private router: Router
  ) {
    this.router.events.subscribe((event)=>{
      if (event instanceof NavigationEnd) {
        // Navigation ended successfully
        // console.log('Navigation ended successfully');
        if(event.url == '/signin'){
          localStorage.clear();
        }
        this.val = this.common.isLoggedSession();
        // this.common.checkLog();
      }
    })
    this.common.checkLog();
    this.common.currentData.subscribe((data: any) => {
      this.sharedData = data;
    })
  }

  ngDoCheck() {
    if (this.common.sidebarToggle == true) {
      this.sidebarToggle = true;
    }
    else {
      this.sidebarToggle = false;
    }
  }
  ngOnInit() {
    this.cYear =  environment.Cyear;
    // console.log(this.activatedRoute.snapshot.paramMap);
    this.config = data;
    console.log('current', this.config.default.version);
    // checking for update first time after app loads
    const headers = new HttpHeaders()
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache');
    this.httpClient
      .get<{ version: string }>('/assets/config.json', { headers })
      .subscribe((config) => {
        console.log('available', config);
        if (config.version !== this.config.default.version) {
          location.reload();
          console.log('1reloaded new version');
        }
      });
    //checking for update in every 30min
    setInterval(() => {
      const headers = new HttpHeaders()
        .set('Cache-Control', 'no-cache')
        .set('Pragma', 'no-cache');
      this.httpClient
        .get<{ version: string }>('/assets/config.json', { headers })
        .subscribe((config) => {
          console.log(config);
          if (config.version !== this.config.default.version) {
            location.reload();
            console.log('2reloaded new version');
          }
        });
    }, 15 * 60 * 1000);
  }

  triggerModal(content) {
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title' })
      .result.then(
        (res) => {
          this.closeModal = `Closed with: ${res}`;
        },
        (res) => {
          this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
