import { Component, OnInit } from '@angular/core';
import {CommonService} from 'src/app/services/common.service'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { getQuoteService } from 'src/app/services/get-quote/get-quote.service';
import { QuickBolService } from 'src/app/services/quick-bol-v2/quick-bol.service';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-view-bol-doc',
  templateUrl: './view-bol-doc.component.html',
  styleUrls: ['./view-bol-doc.component.css']
})

export class ViewBolDocComponent implements OnInit {

  BOLd:any;
  data:any
  resultData:any;
  dynamicDimension:any;
  declaredValue:number;
  totalItem:number;
  packagesArray:any=[];
  totalPackageCountArray:any=[]
  constructor(public common:CommonService,public activeModal: NgbActiveModal,public quickBolService:QuickBolService,public getQuoteService:getQuoteService,  private  _loaderService: LoadingService) { }

  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    this.resultData=this.common.bolResultData;
    this.getData();
    // this.quickBolService.getInvoiceDetails(this.common.bolId).subscribe(result => {
    //   this.data = result;
    //   console.log(result)
    // })

    // this.getQuoteService.getShipmentModeData().subscribe((result:any)=>{
    //   result.forEach((element) => {

    //         if (element.description === 'Courier') {
    //           element.packagingDetails.map((element1) => {
  
    //             this.packagesArray.push({
    //               'packageName': element1.packagingType,
    //               'packageId': element1.packagingId
    //             })
    //           })
    //         } else if (element.description != 'Full Truckload (TL) Shipment') {
    //           element.packagingTypeDetails
    //             .map((element1) => {
    //               this.packagesArray.push({
    //                 'packageName': element1.packagingName,
    //                 'packageId': element1.packagingId
  
    //               })
    //             })
    //         }
    //       })
      
    //       let packageId = [];
    //       this.resultData.shipmentItems?.map((element) => {
    //         packageId.push(element.packaging);
    //       })
  
    //       let uniquePackageId = [... new Set(packageId)]
    //       uniquePackageId.map((element) => {
    //         let count = 0;
    //         let qnty = this.resultData.shipmentItems.filter(data => data.packaging
    //           === element);
  
    //         qnty.map((element) => {
    //           count = count + element.qty;
    //         })
          
    //         let packageName = this.packagesArray.filter(data => data.packageId === element);
  
    //         this.totalPackageCountArray.push({
    //           'packageName': packageName[0].packageName,
    //           'count': count
    //         })
    //       })
    //       console.log(this.totalPackageCountArray)
    // })
    // this.BOLd=this.common.bolInvoiceDetails;
    // this.dynamicDimension=this.common.dimensionBOL;
    // this.declaredValue=this.common.declaredBol;
    // this.totalItem=this.common.totalItemBol;
  }
shippingLabel: boolean;
  getData() {
    this.quickBolService.getInvoiceDetails(this.common.bolId).subscribe(result => {
      console.log('getDatahere', result)
      this.resultData = result;
      if (result) {
        this._loaderService.stringSubject.next(false);
      }
      // Packages handling logic
      if (this.resultData.description === 'Courier' && this.resultData.packagingDetails) {
        this.resultData.packagingDetails.forEach(element1 => {
          this.packagesArray.push({
            'packageName': element1.packagingType,
            'packageId': element1.packagingId
          });
        });
      } else if (this.resultData.description !== 'Full Truckload (TL) Shipment' && this.resultData.packagingTypeDetails) {
        this.resultData.packagingTypeDetails.forEach(element1 => {
          this.packagesArray.push({
            'packageName': element1.packagingName,
            'packageId': element1.packagingId
          });
        });
      }


        this.common.bolResultData = this.resultData;
  
        this.resultData['items'].sort((a, b) => {
          return a.displayOrder - b.displayOrder
        });
        // console.log(this.resultData['shipmentItems'])

        // this.isCommercialInvoiceGenerated = result['isCommercialInvoiceGenerated'];
        // this.isViewBoL = result['isPdfExist'];
        // this.bolId = result['bolId'];
        // this.documentId = result['documentId'];
        localStorage.setItem('com_documentId_' + this.common.quoteId, result['documentId']);

        if (this.BOLd['shipmentMode'] == "Courier") {
          this.shippingLabel = true;
        }
        else {
          this.shippingLabel = false;

        }

        if (this.BOLd['shipperCountry'] != this.BOLd['consigneeCountry']) {
          // this.showComInv = true;
        } else {
          // this.showComInv = false;
        }
        this.dynamicDimension = {
          length: 0,
          width: 0,
          height: 0,
          weight: 0
        }
        this.totalItem = 0
        for (let i in result['items']) {
          this.totalItem += 1
          this.common.totalItemBol = this.totalItem;
          if (result['items'][i] && result['items'][i].unit == 'cm') {
            result['items'][i].length = result['items'][i].length ;
            result['items'][i].totalWeight = result['items'][i].totalWeight;
            result['items'][i].height = result['items'][i].height;
            result['items'][i].breadth = result['items'][i].breadth ;
            this.declaredValue += result['items'][i]['shipmentItemValue'] * result['items'][i]['qty'];
            this.common.declaredBol = this.declaredValue;
            this.dynamicDimension['length'] += result['items'][i].length;
            this.dynamicDimension['width'] += result['items'][i].breadth;
            this.dynamicDimension['height'] += result['items'][i].height;
            this.dynamicDimension['weight'] += result['items'][i].totalWeight * result['items'][i]['qty'];
            this.common.dimensionBOL = this.dynamicDimension;
          } else {
            this.dynamicDimension['length'] += result['items'][i].length;
            this.dynamicDimension['width'] += result['items'][i].breadth;
            this.dynamicDimension['height'] += result['items'][i].height;
            this.dynamicDimension['weight'] += result['items'][i].totalWeight * result['items'][i]['qty'];
            this.declaredValue += result['items'][i]['shipmentItemValue'];
            this.common.declaredBol = this.declaredValue;

            this.common.dimensionBOL = this.dynamicDimension;

          }
        }
        this.BOLd['shipperAddressfirstName'] = result['shipperAddress']['firstName'];
        this.BOLd['shipperAddresslastName'] = result['shipperAddress']['lastName'];
        this.BOLd['consigneeAddressfirstName'] = result['consigneeAddress']['firstName'];
        this.BOLd['consigneeAddresslastName'] = result['consigneeAddress']['lastName'];
        this.BOLd['brokerName'] = result['brokerName'];
        this.BOLd['Phone'] = result['brokerPhone'];
        this.BOLd['brokerEmail'] = result['brokerEmail'];
        //this.BOLd['shipcarteLogoSmall']= result['shipcarteLogoSmall'];
        this.BOLd['carrierLogo'] = result['carrierLogo'];
        setInterval(() => {
          let shipLogo = sessionStorage.getItem('shipSmallLogo');
          let carrierLogo = sessionStorage.getItem('carrierLogo');
          // if(shipLogo && carrierLogo){
          // this.shipcarteSmallLogo = shipLogo;
          // this.carrierLogo = carrierLogo
          // clearInterval(inter)
          // }
        }, 1000)

        convertImageToBase64(this.BOLd['shipcarteLogoSmall'], function (data) {
          sessionStorage.setItem('shipSmallLogo', data);

        })
        convertImageToBase64(this.BOLd['carrierLogo'], function (data) {
          sessionStorage.setItem('carrierLogo', data);

        })
        function convertImageToBase64(imgUrl, callback) {
          const image = new Image();
          image.crossOrigin = 'anonymous';
          image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();
            callback && callback(dataUrl)
          }
          image.src = imgUrl;
        }
        this.common.bolInvoiceDetails = this.BOLd;
        var self = this;
        setTimeout(function () {
          if (result) {
            //self.savePdf();
            // if (self.isCommercialInvoiceGenerated) {
            //   self.saveCommercialPdf();
            // }
            // else {
            //   self.displayLoader = false;
            // }
          } else {
            // self.displayLoader = false;
          }
        }, 3000);

      }, error => {

        this._loaderService.stringSubject.next(false);

      });
    }

  closeModal() { this.activeModal.close(); }

}
