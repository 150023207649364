 
<div class="container m-0" id="printable">
    <div style="display: flex; justify-content:end;">
        <img src="assets/img/shipment/fi-rr-cross-small.svg" style="cursor: pointer; " (click)="cancel()" alt="">
    </div>
    <div class="row" style="margin-top: 20px;">
        <div id="DownloadPdfData" class="col-12" style="border: none !important;">
            <div>
                <div style="background: #fff;margin: auto;width: 100%;overflow: hidden;">
                    <div id="contentInvoice" #contentInvoice class="bol-page_section printable viewinvoice"
                        style="background: #fff;padding: 3px 24px;height: auto;border: 1px solid #D4D4D4; border-radius: 6px;float: left;">
                        <div class="blo_connaissement_section">
                            <div class="blo_connaissement_left">
                                <img style="width: 268px;padding: 0px 0 0 0;"
                                    src="assets/img/shipcarte-logo-350x130.png" alt="" />
                            </div>
                            <div class="float-right text-right" style="margin-top: 11px;">
                                <h3 class="mb-0"
                                    style="font-size: 15px !important;font-weight: 600 !important;color: black;">INVOICE
                                    <!-- {{commonData && commonData?.parentInvoiceNumber ? commonData &&
                                    commonData?.parentInvoiceNumber : ''}} -->
                                    
                                    {{commonData?commonData.parentInvoiceNumber?commonData.parentInvoiceNumber:commonData.invoiceNumber:"--"}}
                                </h3>
                                <!-- <h5 class="mb-0" style="font-size: 14px !important;font-weight: 500 !important;color: black">{{commonData && formattedDate ? formattedDate : ''}} </h5>                                         -->
                            </div>
                            <div class="row m-0 blo_connaissement_right">
                                <div class="p-0 col-6 blo_left text-start">
                                    <div class="mt-3"
                                        style="font-size: 12px;font-family: poppins,sans-serif; font-weight: 600;color: black ">
                                        BILL TO:</div>
                                    <div
                                        style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;color: black">
                                        <!-- {{commonData?commonData['billTo']?commonData['billTo']['firstName']?commonData['billTo']['firstName']:'':"":''}}
                                        {{commonData?commonData['billTo']?commonData['billTo']['lastName']?commonData['billTo']['lastName']:'':'':""}} -->
                                    </div>
                                    <div
                                        style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;color: black">
                                        <!-- {{commonData['consignee']['company'] ? commonData['consignee']['company'] : ''}} -->
                                        {{commonData?commonData['billTo']?commonData['billTo']['company']?commonData['billTo']['company']:'':'':''}}
                                    </div>
                                    <div
                                        style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;color: black">
                                        <!-- {{commonData['consignee']['addrLine1'] ? commonData['consignee']['addrLine1'] :
                                        ''}} {{commonData['consignee']['addrLine2'] ?
                                        commonData['consignee']['addrLine2'] : ''}}  -->

                                        {{commonData?commonData['billTo']['addrLine1']:''}}
                                        {{commonData?commonData['billTo']['addrLine2']:''}}

                                    </div>
                                    <div
                                        style="font-size: 12px; font-weight: 400; font-family: poppins,sans-serif;color: black">
                                        {{commonData?commonData['billTo']?commonData['billTo']['city']?commonData['billTo']['city']:'':'':""
                                        }}
                                        {{commonData?commonData['billTo']?commonData['billTo']['state']?commonData['billTo']['state']:'':'':''
                                        }}
                                        {{commonData?commonData['billTo']?commonData['billTo']['country']?commonData['billTo']['country']:'':'':''
                                        }}
                                        {{commonData?commonData['billTo']?commonData['billTo']['zipCode']?commonData['billTo']['zipCode']:'':'':''
                                        }}
                                    </div>
                                </div>
                                <div class="col-6 blo_right text-end">
                                    <h5 class="mt-2 mb-0"
                                        style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        Total : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{subTotal? subTotal.toFixed(2) : 0.00}}</span> </h5>
                                    <!-- <h3 class="mb-0 orange-text d-inline"
                                        style="font-size: 20px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        ${{data && data?.amountDue ? data && data?.amountDue : 0}} </h3> -->
                                        <h5 class="mt-2 mb-0"
                                        style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        Paid : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{amountPaid? amountPaid.toFixed(2) : 0.00}}</span> </h5>
                                        <h5 class="mt-2 mb-0"
                                        style="font-size: 12px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        Balance Due : <span style="color: #FF8D00;font-size: 15px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">${{amountDue? amountDue.toFixed(2) : 0.00}}</span> </h5>
                                    <!-- <h3 class="mb-0 orange-text d-inline"
                                        style="font-size: 20px !important;font-weight: 600 !important;font-family: poppins,sans-serif;">
                                        ${{data && data?.amountDue ? data && data?.amountDue : 0}} </h3> -->
                                </div>
                            </div>
                        </div>
                        <div id="{{i}}" [ngClass]="i!=0?'invoiceInside':''"
                            *ngFor="let data of consolidatedInvoiceList; let i=index">
                            <div class="order-details_section mt-4">
                                <div class="order-details"
                                    style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;color: black !important;">
                                    <div><b>ORDER NO.</b></div>
                                    <div style="color:#FF8D00;">{{data && data?.orderNumber ? data && data?.orderNumber
                                        : ''}} </div>
                                </div>
                                <div class="order-details"
                                    style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;color: black !important;">
                                    <div><b>CUSTOMER REF NO.</b></div>

                                    <!-- <div>{{data && data?.referenceNumber ? data && data?.referenceNumber : ''}}</div> -->
                                    <div>{{data?.poNumber ? data?.poNumber : '--'}}</div>
                                </div>
                                <div class="order-details"
                                    style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;color: black !important;">
                                    <div><b>ACCOUNT NO.</b></div>

                                    <div>{{data && data?.accountNumber ? data && data?.accountNumber : ''}} </div>
                                </div>
                                <div class="order-details"
                                    style="width: 24%;float: left;font-size: 10px;padding: 0px;font-family: Poppins;color: black !important;">
                                    <div><b>TERMS</b></div>

                                    <div>{{data && data?.terms ? data && data?.terms : ''}}</div>
                                </div>
                            </div>
                            <div class="table_start" style="margin-top: 10px;">
                                <div class="table-data">
                                    <div class="shipper_box mr-1"
                                        style="border-right: 1px solid #C4CDD5; width: 49%;color: black !important; padding: 7px 6px 5px 8px;">
                                        <div><b>SHIPPED:</b></div>
                                        <div>{{data && data?.orderDate | date: 'MM-dd-yyyy'}}</div>
                                    </div>
                                    <div class="consignee_box" style="padding: 7px 6px 5px 8px;">
                                        <div><b>RECEIVED:</b></div>
                                        <div *ngIf="data?.deliveryDate!='--'">{{data?.deliveryDate | date: 'MM-dd-yyyy' }}</div>
                                                <div *ngIf="data?.deliveryDate=='--'">{{data?.deliveryDate}}</div>
                                    </div>
                                </div>
                                <div class="table_start">
                                    <div class="table-data">
                                        <div class="shipper_box " style="border-right: 1px solid #C4CDD5; width: 49%;">
                                            <div
                                                style="border-bottom: 1px solid #C4CDD5;padding: 8px !important; background-color: #F5F6FA;color: black !important;">
                                                <b>SHIPPER:</b>
                                            </div>
                                            <div style="padding: 8px;">
                                                <div>{{data['shipper']['firstName']}} {{data['shipper']['lastName']}}
                                                </div>
                                                <div>{{data['shipper']['company'] ? data['shipper']['company'] : ''}}
                                                </div>
                                                <div>{{data['shipper']['addrLine1'] ? data['shipper']['addrLine1'] :
                                                    ''}}
                                                    {{data['shipper']['addrLine2'] ? data['shipper']['addrLine2'] : ''}}
                                                </div>
                                                <div>{{data['shipper']['city'] ? data['shipper']['city'] : ''}}
                                                    {{data['shipper']['state'] ? data['shipper']['state'] : ''}}
                                                    {{data['shipper']['country'] ? data['shipper']['country'] : ''}}
                                                    {{data['shipper']['zipCode'] ? data['shipper']['zipCode'] : ''}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="consignee_box">
                                            <div
                                                style="width:102%;border-bottom: 1px solid #C4CDD5;padding: 8px !important; background-color: #F5F6FA;color: black !important;">
                                                <b>CONSIGNEE:</b>
                                            </div>
                                            <div style="padding: 8px;">
                                                <div>{{data['consignee']['firstName']}}
                                                    {{data['consignee']['lastName']}}
                                                </div>
                                                <div>{{data['consignee']['company'] ? data['consignee']['company'] :
                                                    ''}}
                                                </div>
                                                <div>{{data['consignee']['addrLine1'] ? data['consignee']['addrLine1'] :
                                                    ''}} {{data['consignee']['addrLine2'] ?
                                                    data['consignee']['addrLine2'] :
                                                    ''}} </div>
                                                <div>{{data['consignee']['city'] ? data['consignee']['city'] : ''}}
                                                    {{data['consignee']['state'] ? data['consignee']['state'] : ''}}
                                                    {{data['consignee']['country'] ? data['consignee']['country'] : ''}}
                                                    {{data['consignee']['zipCode'] ? data['consignee']['zipCode'] : ''}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="pro_details_table">
                                <table style="background-color: #F5F6FA !important; width: 100%;">
                                    <tr class="yellow-header"
                                        style="width: 100%;float: left;color: black !important; padding: 7px; display: flex; justify-content: space-between;">
                                        <th style="width: 14%;padding-left:3px">QTY</th>
                                        <th style="width: 32%;">DESCRIPTION</th>
                                        <th style="width: 12%;">WEIGHT</th>
                                        <th style="width: 12%;">CLASS</th>
                                        <th style="width: 13%;">CHARGES</th>
                                        <th style="width: 14%; display: flex; justify-content: end;">ADJUSTMENTS
                                        </th>
                                    </tr>
                                    <tr *ngFor="let key of data['items']"
                                        style="padding: 0px 0px;border-bottom: 1px solid #C4CDD5;width: 100%;float: left;color: black !important; padding: 7px; display: flex; justify-content: space-between;">
                                        <td style="width: 14%;padding-left:3px">{{key['qty'] ? key['qty'] : ''}}</td>
                                        <td style="width: 32%;">
                                            {{key['description'] ? key['description'] : ''}}
                                        </td>
                                        <td style="width: 12%;"> {{key['totalWeight'] ? key['totalWeight'] * key['qty']
                                            : ''}} {{key['wghtUnit'] ? key['wghtUnit'] : ''}}</td>
                                        <td style="width: 12%;"> {{key['freightClass'] ? key['freightClass'] : ''}}</td>
                                        <td style="width: 13%;">${{data?.totalCharges>0?data?.totalCharges:'--'}}</td>
                                        <td style="width: 14%; display: flex; justify-content: end; color:red">
                                            {{data?.adjustment?.total>0 ? '$'+data?.adjustment?.total:'--'}}
                                        </td>
                                    </tr>
                                    <tr *ngFor="let charge of charges; let i = index"
                                        style="padding: 0px 0px;width: 100%;float: left;height: 25px;">
                                        <td style="width: 14%;padding-left:3px"></td>
                                        <td style="width: 36%; text-align: left;color: black !important;">
                                            {{charge[0]}}
                                        </td>
                                        <td
                                            style="width: 50%; text-align: right; padding: 0px 30px 0px 0px;color: black !important;">
                                            {{charge[1]}} </td>
                                    </tr>
                                    <tr *ngFor="let key of data['items']"
                                        style="padding: 0px 0px;width: 100%;float: left;color: black !important;">
                                        <td style="width:100%;padding:8px">
                                            <div style="margin-bottom: 0px;">
                                                <b>Dimensions :</b>{{key['length'] ? key['length'] : ''}} L x
                                                {{key['breadth'] ? key['breadth'] : ''}} W x {{key['height'] ?
                                                key['height']
                                                : ''}} H
                                            </div>
                                            <div style="margin-bottom: 15px;">
                                                <b>SERVICE:</b>{{data['shippingMode'] ?
                                                data['shippingMode'] :
                                                ''}}<br>
                                                <b>SHIP VIA:</b>{{data['carrier'] ? data['carrier'] :
                                                ''}}<br>
                                                <b>PARTNER TRACKING NO.
                                                    :</b>{{data?.proNumber}}<br>

                                            </div>
                                            <!-- <div style="border-bottom: 1px dashed #C4CDD5;"></div><br>
                                            <b>NOTES:</b><br>
                                            {{data['notes'] ? data['notes'] : ''}} -->
                                        </td>
                                    </tr>
                                </table>
                                <!-- <hr  [ngStyle]="{'margin-bottom':oddNumber.includes(i) && isInvoicePrinting && i!=1 ?'10rem':'2.5rem'}"> -->
                                <!-- <hr [ngStyle]="{'margin-bottom':i===1 && isInvoicePrinting?'14rem':oddNumber.includes(i) && isInvoicePrinting?'10rem':'2.5rem'}"> -->
                            </div>

                            <table style="width: 99%;">
                                <span class="break-page"></span>
                                <div class="pro_details_table">
                                    <tr
                                        style="padding: 0px 0px;width: 100%;float: left;font-size: 9px;color: black !important;">
                                        <td style="width:33%;float:right;">
                                            <div style="display: flex;
                                           justify-content: space-between;">
                                                <div style="float: left;"><b>SUB-TOTAL</b></div>
                                                <div style="float: right;">${{data?.subTotal | number:'1.2-2'}}</div>
                                            </div>
                                            <div style="display: flex;
                                            justify-content: space-between;">
                                                <div style="float: left;"><b>TAX -{{data?.taxRate!=null?data?.taxRate:0}}% {{data?.taxName}}</b></div>
                                                <div style="float: right;">${{data?.totalTaxes | number : '1.2-2'}}</div>
                                            </div>
    
    
                                            <hr>
                                            <div style="float: left;">
                                                <!-- <b>TOTAL DUE</b> -->
                                                <b>TOTAL</b>
                                            </div>
                                            <div style="float: right;">
                                                <b>
                                                    <span style="font-size: 15px;color: #FF8D00;">${{data?.amountDue |
                                                        number:'1.2-2'}}
                                                    </span> {{commonData?.currency}}
                                                </b>
                                            </div>
                                        </td>
                                    </tr>
                                </div>
                                <tr *ngIf="consolidatedInvoiceList.length-1==i" style="padding: 4px 0px;width: 100%;float: left;font-size: 8px;">
                                    <td style="width:54%;padding: 0px 0px; float: left;color: black !important;">
                                        <div class="mt-1 mb-5 ">
                                            <div>
                                                <b>1033 JAYSON COURT, MISSISSAUGA, ON L4W 2P4, CANADA</b><br>
    
                                                <div>
                                                    <span> <b> 888.424.8878 EXT. 5</b></span>
                                                    <span style="margin-left: 27px;"><span
                                                            style="font-size: 8px;color: #FF8D00;">
                                                            accounting@shipcarte.com</span></span>
                                                </div>
                                            </div>
                                            <a style="margin-right: 8px;"
                                                href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                                                <img src="assets/img/shipment/Black-Linkedin.svg" width="17px"
                                                    class="m-1 text-center">
                                            </a>
                                            <a style="margin-right: 8px;"
                                                href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                                                <img src="assets/img/shipment/Black-Insta.svg" width="17px"
                                                    class="m-1 text-center">
                                            </a>
                                            <!-- <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                                    <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                                                </a> -->
                                            <a style="margin-right: 8px;" href="https://twitter.com/shipcarte?lang=en%C2%A0"
                                                target="_blank">
                                                <img src="assets/img/shipment/Black-Twitter.svg" width="17px"
                                                    class="m-1 text-center">
                                            </a>
                                            <a style="margin-right: 8px;" href="https://www.facebook.com/ShipCarte/"
                                                target="_blank">
                                                <img src="assets/img/shipment/Black-Facebook.svg" width="17px"
                                                    class="m-1 text-center">
                                            </a>
                                        </div>
                                    </td>
                                    <td style="width:32%;float: right;margin-top: 20px;">
                                        <div>
                                            <b>
                                                <span style="font-size: 20px;color: #FF8D00;">THANK YOU / MERCI</span>
                                            </b>
                                        </div>
                                    </td>
                                </tr>
                            </table>




                        </div>
                        

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-spinner></app-spinner>
<ng-template #loadContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title">Loading please wait..</h4>
        <div class="spinner-border text-light ml-1" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</ng-template>