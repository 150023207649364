import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactDetails } from 'src/app/services/AddressBook/Models/contactDetails';
@Component({
  selector: 'app-add-contact-details',
  templateUrl: './add-contact-details.component.html',
  styleUrls: ['./add-contact-details.component.css']
})
export class AddContactDetailsComponent implements OnInit {
  contactForm: FormGroup;

  @Input() public data;

  contact: ContactDetails;
  constructor(private activatedRoute: ActivatedRoute, public activeModal: NgbActiveModal, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.contactForm = this.formBuilder.group({
      'firstName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern("[a-zA-Z][a-zA-Z ]*")
      ]),
      'lastName': new FormControl('', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(30),
        Validators.pattern("[a-zA-Z][a-zA-Z ]*")
      ]),
      'email': new FormControl('', [
        Validators.required,
        Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')
      ]),
      'phoneNumber': new FormControl('', [
        Validators.required,
        Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")
      ]),
      'position': new FormControl(''),
      'Extension': new FormControl('')
    });
    if (this.data) {
      this.setValues();
    }


  }
  closeModal() {
    this.activeModal.close();
  }
  get f(){

    return this.contactForm.controls;

}
  setValues() {
    console.log(this.data);
    this.contactForm.controls['firstName'].setValue(this.data.firstName);
    this.contactForm.controls['lastName'].setValue(this.data.lastName);
    this.contactForm.controls['email'].setValue(this.data.email);
    this.contactForm.controls['phoneNumber'].setValue(this.data.phoneNumber);
    this.contactForm.controls['position'].setValue(this.data.position);
    this.contactForm.controls['Extension'].setValue(this.data.extension);

  }

  submit() {
    this.contact = {
      firstName: this.contactForm.value.firstName,
      lastName: this.contactForm.value.lastName,
      phoneNumber: this.contactForm.value.phoneNumber,
      email: this.contactForm.value.email,
      position: this.contactForm.value.position,
      companyId: null,
      contactId: null,
      extension:this.contactForm.value.Extension
    }
    this.activeModal.close(this.contact);
  }
}
