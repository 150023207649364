import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { env } from 'process';

@Injectable({
  providedIn: 'root'
})
export class CommercialInvoiceService {
  commercialInvoiceData:any;
  private jsonHTTPOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })     
     ,withCredentials: true
  };
  constructor(public http:HttpClient) { }
  saveData(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/api/commercialInvoice/saveData",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));    
  }

  createShipment(carrierQuoteReq,quoteId){
    return this.http.put(environment.apiPath+ "/api/commercialInvoice/savePDF",{params: {}},this.jsonHTTPOptions).pipe(map(response => {
      return response;
    }));  
  }

  sendEmail(dataObj){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');    
    return this.http.post(environment.apiPath+ "/api/commercialInvoice/sendEmail",dataObj ,{
      headers: myheader,withCredentials:true
      }).pipe(map(response => {
      return response;
    }));    
  }
  getCommercialInvoiceData(orderId,extension){
    return this.http.get(environment.apiPath+'/api/invoices/commercial/getById?orderId='+orderId+'&actionType='+extension,{ withCredentials:true}).pipe(map(response=>{
      return response;
    }))
  }

  getComInvData(orderId){
    return this.http.get(environment.apiPath+'/api/invoices/commercial/getById?extCommercialInvoiceId='+orderId+'&actionType=ext',{ withCredentials:true}).pipe(map(response=>{
      return response;
    }))
  }
  saveCommercialInvoice(data,extension){
    // const myheader = new HttpHeaders().set('Content-Type', 'application/json');    

    return this.http.post(environment.apiPath+'/api/invoices/commercial/save?actionType='+extension,data,{withCredentials:true}).pipe(map(response=>{
      return response
    })) 
  }

  getCommercialInvoiclisting(pageNo,pageSize,searchQuery,dateFilter){
   if(searchQuery && dateFilter){
    return this.http.get(environment.apiPath+`/api/invoices/ext_ci/getAll/${pageNo}/${pageSize}`+'?searchKey=' + searchQuery +'&'+dateFilter,{ withCredentials:true}).pipe(map(response=>{
      return response;
    }))
   }else{
    return this.http.get(environment.apiPath+`/api/invoices/ext_ci/getAll/${pageNo}/${pageSize}`+(searchQuery ? '?searchKey=' + searchQuery : "")+(dateFilter ? '?'+dateFilter:''),{ withCredentials:true}).pipe(map(response=>{
      return response;
    }))
   }
    
  }

  viewCommercialInvoiceData(orderId,actionType){
    return this.http.get(environment.apiPath+'/api/invoices/commercial/getById?orderId='+ orderId +'&actionType='+actionType,{withCredentials:true}).pipe(map(response=>{
      return response;
    }))
  }

}
