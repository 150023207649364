import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators, FormControl,FormArray } from '@angular/forms';
import { CreditApplicationSignatureComponent } from 'src/app/_helpers/popup-dialog/credit-application-signature/credit-application-signature.component';
import { UserpreferencesService } from './../../services/userpreferences.service';
import { ToastrService } from 'ngx-toastr';
import { setUncaughtExceptionCaptureCallback } from 'process';
import { SignupService } from './../../services/sign-up/signup.service';
import { environment } from './../../../environments/environment';
import { CreditApplicationBackButtonComponent } from 'src/app/_helpers/popup-dialog/credit-application-back-button/credit-application-back-button.component';
import { LoadingService } from 'src/app/services/loader.service';


@Component({
  selector: 'app-credit-application',
  templateUrl: './credit-application.component.html',
  styleUrls: ['./credit-application.component.css']
})

export class CreditApplicationComponent implements OnInit {

  creditApplicationForm: FormGroup;
  submitted:boolean;
  submittedBankForm:boolean;
  submittedTradeForm:any[];
  tradeRefCount:number;
  loggedUserId:any;
  globalApplicantDataId:any;
  tradeItems: FormArray;
  entityTypeArray:any;
  activeTabIndex:any;
  signBy: any;
  todaysDate:any;
  displayLoader:boolean;
  dynamicRefData:any;
  dynamicBusinessData:any;
  dynamicBusinessStructureData:any;
  dynamicBillingData:any;
  countryListData:any;
  countryStateListObj:any;
  updateSubmitButton:boolean;
  hideOther:boolean;
  isSubmitted:boolean = false;
  establishedDate:any;
  reviewAndSignData:any;
  referencesOfreviewAndSignData:any;
  missingData:any;
  cYear:any;
  traderefarray:any[] = [];
  constructor(private activatedRoute: ActivatedRoute, private router: Router, private modalService: NgbModal,public formBuilder: FormBuilder,public userpreferencesService:UserpreferencesService,private toastr: ToastrService,public signupService:SignupService,  private  _loaderService: LoadingService) { }
  
  ngOnInit(): void {
    this.cYear =  environment.Cyear;
    this.submittedBankForm=false;
    this.submittedTradeForm=[false];
    this.displayLoader = true;
    this._loaderService.stringSubject.next(true);
    this.tradeRefCount=0;
    this.activeTabIndex = 0;
    this.todaysDate = this.getTodaysDate('dd/mm/yyyy');
    // this.globalApplicantDataId = "e2b0f7e2-7071-4660-b2d1-b750ee5f8bf2"; // Temporary set for development
    this.signBy = "";
    this.missingData= {
      "firstname":"",
      "lastname":"",
      "Fax":"",
      "cellNumber":"",
      "BIN":"",
      "BusinessEntityInformation":[],
      "BankAddress":"",
      "BusinessEntityType":""
    
    }
   
    this.creditApplicationForm = this.formBuilder.group({
      legalBusinessName: ['', Validators.required],
      firstName: ['', [Validators.required,Validators.minLength(2),Validators.maxLength(32),Validators.pattern('^[a-zA-Z \-\']+')]],
      lastName:['',[Validators.required,Validators.minLength(2),Validators.maxLength(32),Validators.pattern('^[a-zA-Z \-\']+')]],
      address1:['', Validators.required],
      address2:['', Validators.required],
      city:['',[ Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]],
      zip:['', [Validators.required,Validators.pattern("^([A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]|([a-zA-Z0-9]{5,6}))$")]],
      country:['', Validators.required],
      state:['', Validators.required],
      telePhone:['',  [Validators.required,Validators.pattern("^([+]91|[+]1)?s*([0-9]{10})$"),Validators.max(9999999999999),Validators.min(999999999)]],
      email: new FormControl('', [Validators.required,Validators.email,Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]),
      fax:['',[Validators.maxLength(15),Validators.pattern("^[0-9]+$")]],
      cellphone:['',[Validators.maxLength(15),Validators.pattern("^[0-9]+$")]],
      businessIdentification:[''],
      yearEstablished:[''],
      bilingName:['', Validators.required],
      billingCity:['',[ Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]],
      billingState:['', Validators.required],
      bilingCountry:['', Validators.required],
      billingZip:['', [Validators.required,Validators.pattern("^([A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]|([a-zA-Z0-9]{5,6}))$")]],
      bilingFirstName:[''],
      bilingLastName:[''],
      bilingAddress:[''],
      bilingAddress2:[''],
      bilingTelephone:['',[Validators.pattern("^([+]91|[+]1)?s*([0-9]{10})$"),Validators.max(9999999999999),Validators.min(999999999)]],
      bilingCellPhone:['',[Validators.pattern("^([+]91|[+]1)?s*([0-9]{10})$"),Validators.max(9999999999999),Validators.min(999999999)]],
      bilingFax:['',[Validators.maxLength(15),Validators.pattern("^[0-9]+$")]],
      bilingEmail:new FormControl('', [Validators.required,Validators.email,Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')]),
      businessEntityItems: this.formBuilder.array([this.createBusinessEntityFormGroup()]),
      bankName: ['', Validators.required],     
      contact: [null, [Validators.required]], 
      accountNumber:[null, Validators.required],
      bankTelephone:[null , [Validators.required,Validators.pattern("^([+]91|[+]1)?s*([0-9]{10})$"),Validators.max(9999999999999),Validators.min(999999999)]],
      bankCountry:[null , Validators.required],
      bankZip:[null , [Validators.required,Validators.pattern("^([A-Za-z][0-9][A-Za-z][ -]?[0-9][A-Za-z][0-9]|([a-zA-Z0-9]{5,6}))$")]],
      bankState:[null , Validators.required],
      bankCity:[null , [Validators.required,Validators.pattern('^[a-zA-Z \-\']+')]],
      bankAddress2:[null],
      bankAddress1:[null, Validators.required],
      tradeItems: this.formBuilder.array([this.createEmailFormGroup()])      
    });
    this.loggedUserId = JSON.parse(localStorage.getItem('loggedInUserDetails'));
    this.globalApplicantDataId = localStorage.getItem('crapplicationid_'+this.loggedUserId['uuid']) ? localStorage.getItem('crapplicationid_'+this.loggedUserId['uuid']) : '' ;

    if(this.globalApplicantDataId && parseInt(localStorage.getItem('crapplicationstep_'+this.loggedUserId['uuid']))< 4 ){
      this.setActiveTab(parseInt(localStorage.getItem('crapplicationstep_'+this.loggedUserId['uuid'])));
    }
    this.signupService.getCountryList().subscribe(result =>{
      if (result) {
        this._loaderService.stringSubject.next(false);
      }
      this.displayLoader = false;

      if (result['success'].length) {

        this.countryListData = result['success'];
        this.countryStateListObj = this.countryListData[0]['provinceState'];
        this.countryListData.forEach((item,index) => {
          
        });
      
        // this.creditApplicationForm.controls['country'].setValue(this.countryListData[0].countryCode);
        // this.creditApplicationForm.controls['state'].setValue(this.countryListData[0].provinceState[0].code);

        // this.creditApplicationForm.controls['bilingCountry'].setValue(this.countryListData[0].countryCode);
        // this.creditApplicationForm.controls['billingState'].setValue(this.countryListData[0].provinceState[0].code);
      }

    },error=>{
      this._loaderService.stringSubject.next(false);
    })
  }

  public addItem() {
    const emails = this.creditApplicationForm.get('tradeItems') as FormArray;
    emails.push(this.createEmailFormGroup())
    this.updateSubmitButton = false;
    if(emails.length == 3){
      this.updateSubmitButton = true;
    }else{
      this.updateSubmitButton = false;
    }
  }

  public removeOrClearEmail(i: number) {
    const emails = this.creditApplicationForm.get('tradeItems') as FormArray
    if (emails.length > 1) {
      emails.removeAt(i)
    } else {
      emails.reset()
    }
    if(emails.length >= 3){
      this.updateSubmitButton = true;
    }else{
      this.updateSubmitButton = false;
    }
  }

  changePrefix(event){
    this.hideOther = false;
    if(event.target.value == 'Other'){
      this.hideOther = true;
    }else{
      this.hideOther = false;
    }
  }


  private createEmailFormGroup(): FormGroup {
    return new FormGroup({
      'companyName1': new FormControl('',[Validators.required]),
      'contact1': new FormControl('',[Validators.required]),
      'phone1': new FormControl('',[Validators.required,Validators.pattern("^([+]91|[+]1)?s*([0-9]{10})$"),Validators.max(9999999999999),Validators.min(999999999)]),
      'email1': new FormControl('', [Validators.required,Validators.email,Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$')])
    })
  }

  onCountrySelect(selectedCountryCode:string){
    
    for(let key in this.countryListData){
      if(this.countryListData[key].countryCode == selectedCountryCode){
        this.countryStateListObj = this.countryListData[key].provinceState;
        this.creditApplicationForm.controls['state'].setValue(this.countryStateListObj[0].code);
      }
    }
  }

  onBillingCountrySelect(selectedCountry){
    for(let key in this.countryListData){
      if(this.countryListData[key].countryCode == selectedCountry){
        this.countryStateListObj = this.countryListData[key].provinceState;
      }
    }
  }

  onBankCountrySelect(selectedCountry){
    for(let key in this.countryListData){
      if(this.countryListData[key].countryCode == selectedCountry){
        this.countryStateListObj = this.countryListData[key].provinceState;
      }
    }
  }

  public addBusinessEntityItem() {
    const businessItem = this.creditApplicationForm.get('businessEntityItems') as FormArray
    businessItem.push(this.createBusinessEntityFormGroup())
  }

  public removeOrClearBusinessEntity(i: number) {
    const businessItem = this.creditApplicationForm.get('businessEntityItems') as FormArray
    if (businessItem.length > 1) {
      businessItem.removeAt(i)
    } else {
      businessItem.reset()
    }
  }

  private createBusinessEntityFormGroup(): FormGroup {
    return new FormGroup({
      'entityType': new FormControl('',Validators.required),
      'firstName1': new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(32),Validators.pattern('^[a-zA-Z \-\']+')]),
      'middleInitial1': new FormControl('',[Validators.minLength(2),Validators.maxLength(32),Validators.pattern('^[a-zA-Z \-\']+')]),
      'lastName1': new FormControl('',[Validators.required,Validators.minLength(2),Validators.maxLength(32),Validators.pattern('^[a-zA-Z \-\']+')]),
      'title': new FormControl('',Validators.required),
      'other': new FormControl('')
    })
  }

  assignBillingAddress(event){
    if(event == true){
      this.creditApplicationForm.controls['bilingName'].setValue(this.creditApplicationForm.value['legalBusinessName']);
      this.creditApplicationForm.controls['billingCity'].setValue(this.creditApplicationForm.value['city']);
      
      this.creditApplicationForm.controls['billingZip'].setValue(this.creditApplicationForm.value['zip']);
      this.creditApplicationForm.controls['bilingFirstName'].setValue(this.creditApplicationForm.value['firstName']);
      this.creditApplicationForm.controls['bilingLastName'].setValue(this.creditApplicationForm.value['lastName']);
      this.creditApplicationForm.controls['bilingAddress'].setValue(this.creditApplicationForm.value['address1']);
      this.creditApplicationForm.controls['bilingAddress2'].setValue(this.creditApplicationForm.value['address2']);
      this.creditApplicationForm.controls['bilingTelephone'].setValue(this.creditApplicationForm.value['telePhone']);
      this.creditApplicationForm.controls['bilingCountry'].setValue(this.creditApplicationForm.value['country']);
      this.onBillingCountrySelect(this.creditApplicationForm.value['country']);
      this.creditApplicationForm.controls['billingState'].setValue(this.creditApplicationForm.value['state']);

      this.creditApplicationForm.controls['bilingCellPhone'].setValue(this.creditApplicationForm.value['cellphone']);
      // this.creditApplicationForm.controls['bilingCellPhone'].setValue(this.creditApplicationForm.value['legalBusinessName']);
      this.creditApplicationForm.controls['bilingFax'].setValue(this.creditApplicationForm.value['fax']);
      this.creditApplicationForm.controls['bilingEmail'].setValue(this.creditApplicationForm.value['email']);
    }else{
      this.creditApplicationForm.controls['bilingName'].setValue('');
      this.creditApplicationForm.controls['billingCity'].setValue('');
      this.creditApplicationForm.controls['billingZip'].setValue('');
      this.creditApplicationForm.controls['bilingFirstName'].setValue('');
      this.creditApplicationForm.controls['bilingLastName'].setValue('');
      this.creditApplicationForm.controls['bilingAddress'].setValue('');
      this.creditApplicationForm.controls['bilingAddress2'].setValue('');
      this.creditApplicationForm.controls['bilingTelephone'].setValue('');
      this.creditApplicationForm.controls['bilingCellPhone'].setValue('');
      this.creditApplicationForm.controls['bilingFax'].setValue('');
      this.creditApplicationForm.controls['bilingEmail'].setValue('');
    }
  }

  // nextTab(){
  //   if(this.creditApplicationForm.controls['businessEntityItems']['status'] == 'INVALID'){
  //     this.toastr.error('Please fill the form!','', {
  //       timeOut: 5000,
  //     });
  //     return;
  //   }
  //   this.setActiveTab(2);
  // }

  open_modal() {
    
    const modalRef = this.modalService.open(CreditApplicationSignatureComponent, { 
      size: 'lg', 
      centered: false, 
      backdrop: true, 
      animation: true, 
      windowClass: 'my-custom-modal1' 
    });
    this.router.events.subscribe((val) => {
      modalRef.close();
    }); 
    modalRef.componentInstance.signBy = this.signBy;

    modalRef.result.then((result) => {
      if(result){
        this.signBy = result
      }
    });

  }

  onEntitySelect(inputType){
  }

  tabClick(event){
    // this.displayLoader = true;
    if(event.tab.textLabel == 'Review & Sign'){
        this.displayLoader = true;
        this.userpreferencesService.getApplicationViewDetails(this.globalApplicantDataId).subscribe(data =>{
        this.displayLoader = false;
        this.reviewAndSignData=data
        this.referencesOfreviewAndSignData=data['references']
        this.signBy=data['businessStructure']['address']['firstName'] ? data['businessStructure']['address']['firstName'] : " " + ' '+data['businessStructure']['address']['lastName'] ? data['businessStructure']['address']['lastName'] : ""
      })
      // '4f0a0b9c-463b-43c6-a24b-7db7989e5d0f'
      this.userpreferencesService.getBusinessStructureData(this.globalApplicantDataId).subscribe(result =>{
        if(result){
          this.dynamicBusinessStructureData = {
            "uuid": result['data']['uuid'],
            "legalName": result['data']['legalName'],
            "tradeName": result['data']['tradeName'],
            "telePhone": result['data']['telePhone'],
            "tollFree": result['data']['tollFree'],
            "contactAddrId": result['data']['contactAddrId'],
            "crtdBy": result['data']['crtdBy'],
            "locationType": result['data']['locationType'],
            "dateOfIncorporation": result['data']['dateOfIncorporation'],
            "companyType": result['data']['companyType'],
            "companyTypeName": result['data']['companyTypeName'],
            "locationName": result['data']['locationName']
        }
        }
        // this.dynamicBusinessStructureData = result['data'];
       
      this.establishedDate = this.dynamicBusinessStructureData['dateOfIncorporation'].split('-');
      })
      this.userpreferencesService.getBillingData(this.globalApplicantDataId).subscribe(result =>{
        // this.dynamicBillingData = result['data'];
        if(result){
          this.dynamicBillingData = {
            "name": result['data']['name'],
            "city": result['data']['city'],
            "zipCode": result['data']['zipCode'],
            "country": result['data']['country'],
            "state": result['data']['state'],
            "addrLine1": result['data']['addrLine1'],
            "addrLine2": result['data']['addrLine2'],
            "email": result['data']['email'],
            "addressId": result['data']['addressId'],
            "billingId": result['data']['billingId'],
            "isApplicantAddr": result['data']['isApplicantAddr']
        }
        }
      })
      this.userpreferencesService.getBankingData(this.globalApplicantDataId).subscribe(result =>{
        this.displayLoader = false;
        this.dynamicBusinessData = result['data'];
      })
      this.userpreferencesService.getTradeRefernceData(this.globalApplicantDataId).subscribe(data =>{
        this.displayLoader = false;
        this.dynamicRefData = data['data'];
        // this.dynamicRefData = [{"contactName":"Solace ","refCompany":"Solace technologies","refAddr":"bd1e2039-a4d3-447d-9a21-f8666195b6ad","refId":"54b2effa-b102-4a4f-abe6-5878f9ee9e2c","city":"Nashik","state":"MH","zipCode":"98765","email":"solaceinfo@solace.com","phoneNumber":"999-090-7865"},{"contactName":"Sopan","refCompany":"Sopan Infotech","refAddr":"858c2477-c82d-4f7a-adfe-333605568617","refId":"3b5e9ba3-0424-4dc5-880e-2c243e13a49d","city":"Nashik","state":"MH","zipCode":"98765","email":"sopan.shelar@infotech.com","phoneNumber":"999-090-7878"},{"contactName":"Sagar S","refCompany":"Solace Infotech","refAddr":"ea98bf99-5799-414c-96ed-8d36002d7714","refId":"d5605799-46b2-4269-a93f-fd84e49a1802","city":"Nashik","state":"MH","zipCode":"98765","email":"sagar@solace.com","phoneNumber":"999-898-7654"}]

      })
    }else if(event.tab.textLabel == 'Business Entity Structure'){
      this.displayLoader=true;
      this.userpreferencesService.getEntityType().subscribe(result =>{
        this.displayLoader = false;
        this.entityTypeArray = result['data'];
      }, (error) => { 
        this.displayLoader = false;
        this.toastr.error(error['error']['error'],'', {
          timeOut: 5000,
        });
      })
    }
  }

  public setActiveTab(num) {
    this.activeTabIndex = num;
     if(num==1){
       this.displayLoader = true;
       this.userpreferencesService.getEntityType().subscribe(result =>{
         this.displayLoader = false;
         this.entityTypeArray = result['data'];
      });
     }
  }


  onSubmit() {
    this.submitted = true;
    //if (this.creditApplicationForm.invalid) {
      //return;
    //}
    if(!this.globalApplicantDataId) return;
    // this.globalApplicantDataId = '4f0a0b9c-463b-43c6-a24b-7db7989e5d0f';
    this.displayLoader = true;
    this.userpreferencesService.statusTracker(this.globalApplicantDataId,'Submitted').subscribe(result =>{
      this.displayLoader = false;
      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });
      this.router.navigate(['/dashboard'])
    }, (error) => { 
      this.displayLoader = false;
      this.toastr.error(error['error']['error'],'', {
        timeOut: 5000,
      });
    });
  }

  get f() { return this.creditApplicationForm.controls; }

  submitBusinessEntityInfo(index){
    this.isSubmitted = true;
    if(this.creditApplicationForm.controls['businessEntityItems']['controls'][index]['status'] == 'INVALID'){
      this.toastr.error('Please fill the form!','', {
        timeOut: 5000,
      });
      return;
    }
    // if(this.creditApplicationForm.controls['businessEntityItems'].value[index].entityType == '' || 
    // this.creditApplicationForm.controls['businessEntityItems'].value[index].firstName1 == '' || 
    // this.creditApplicationForm.controls['businessEntityItems'].value[index].middleInitial1 == '' || 
    // this.creditApplicationForm.controls['businessEntityItems'].value[index].lastName1 == '' || 
    // this.creditApplicationForm.controls['businessEntityItems'].value[index].title == '' || this.creditApplicationForm.controls['businessEntityItems'].value[index].other == ''){
    //   this.creditApplicationForm.controls['businessEntityItems']['controls'][index]['controls'].entityType.setErrors({ 'invalid': true });
    //   this.creditApplicationForm.controls['businessEntityItems']['controls'][index]['controls'].firstName1.setErrors({ 'invalid': true });
    //   this.creditApplicationForm.controls['businessEntityItems']['controls'][index]['controls'].middleInitial1.setErrors({ 'invalid': true });
    //   this.creditApplicationForm.controls['businessEntityItems']['controls'][index]['controls'].lastName1.setErrors({ 'invalid': true });
    //   this.creditApplicationForm.controls['businessEntityItems']['controls'][index]['controls'].title.setErrors({ 'invalid': true });
    //   this.creditApplicationForm.controls['businessEntityItems']['controls'][index]['controls'].other.setErrors({ 'invalid': true });
    //   this.toastr.error('Please fill the form!','', {
    //     timeOut: 5000,
    //   });
    //   return;
    // }
    // this.isSubmitted = false;z
    let todaysDate = this.getTodaysDate('YYYY-MM-DD');
    let inputData = {
    "applicantId": this.globalApplicantDataId,
    "dateOfIncorporation": todaysDate,
    "locationType": '6086ab9d-0fa7-11ea-8462-163046d26cd4',
    "contactId": null,
    "firstName": this.creditApplicationForm.controls['businessEntityItems'].value[index].firstName1,
    "middleName": this.creditApplicationForm.controls['businessEntityItems'].value[index].middleInitial1,
    "lastName": this.creditApplicationForm.controls['businessEntityItems'].value[index].lastName1,
    "title":this.creditApplicationForm.controls['businessEntityItems'].value[index].title,
    "customTitle":"Mr."
    }
    this.missingData['BusinessEntityType']=this.entityTypeArray[0].companyType;
    this.missingData["BusinessEntityInformation"].push(inputData);

    this.displayLoader = true;
    this.userpreferencesService.addBusinessEntity(inputData).subscribe(result =>{
      this.displayLoader = false;
      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });
      localStorage.setItem('crapplicationstep_'+this.loggedUserId['uuid'],'2');
      this.setActiveTab(2);
setUncaughtExceptionCaptureCallback    }, (error) => {
      this.displayLoader = false;
      this.toastr.error("Something went wrong, Please contact support.",'Oops', {
        timeOut: 5000,
      });
    });
  }

  removeTrade(i: number) {
    // remove address from the list
    const control = <FormArray>this.creditApplicationForm.controls['tradeItems'];
    control.removeAt(i);
  }

  submitTradeInfo(index){
    
    this.submittedTradeForm[index]=true;
    console.log(this.creditApplicationForm.controls['tradeItems'].status)
    // if( this.creditApplicationForm.controls['tradeItems'].status == 'INVALID' || this.creditApplicationForm.controls['tradeItems'].value[index]['contact1'] == '' || this.creditApplicationForm.controls['tradeItems'].value[index]['phone1'] == '' || this.creditApplicationForm.controls['tradeItems'].value[index]['email1'] == '' ){
    if( this.creditApplicationForm.controls['tradeItems'].status == 'INVALID'){
      this.toastr.error('Please fill the form!','', {
        timeOut: 5000,
      });
      return;
    }
    let inputData = {
      "refCompany": this.creditApplicationForm.controls['tradeItems'].value[index]['companyName1'],
      "contactName": this.creditApplicationForm.controls['tradeItems'].value[index]['contact1'],
      "refId": null,
      "refAddr": {
          "zipCode": this.creditApplicationForm.value.zip,
          "city": this.creditApplicationForm.value.city,
          "state": this.creditApplicationForm.value.state,
          "country": this.creditApplicationForm.value.country,
          "phoneNumber": this.creditApplicationForm.controls['tradeItems'].value[index]['phone1'],
          "email": this.creditApplicationForm.controls['tradeItems'].value[index]['email1'],
          "addressId": null
      },
      "applicantId": this.globalApplicantDataId

  }
  this.displayLoader = true;
  this.traderefarray.push(inputData)
    console.log(this.traderefarray)
    var valueArr = this.traderefarray.map(function(item){ return item.email });
    var isDuplicate = valueArr.some(function(item, idx){ 
    return valueArr.indexOf(item) != idx 
    });
    console.log(isDuplicate);  
    this.userpreferencesService.saveApplicantReference(inputData).subscribe(result =>{
      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });
      localStorage.setItem('crapplicationstep_'+this.loggedUserId['uuid'],'3');
      this.displayLoader = false
      this.tradeRefCount+=1;
      // this.setActiveTab(4)
    }, (error) => {
      this.displayLoader=false;
      this.toastr.error(error['error']['error'],'', {
        timeOut: 5000,
      });
    });

  }

  tradeSubmit(){
    const emails = this.creditApplicationForm.get('tradeItems') as FormArray;
    if(emails.length >= 3 && this.tradeRefCount >=3 ){
      this.setActiveTab(4);
    }else{
      this.toastr.error("Minimum of 3 references needed",'Error', {
        timeOut: 5000,
      });
      return;
    }
  }

  submitbankInfo(){
    this.submittedBankForm=true;
    this.displayLoader = true;
    console.log(this.creditApplicationForm.controls.bankName.status)
    if(this.creditApplicationForm.controls.bankName.status != 'INVALID' &&  this.creditApplicationForm.controls.contact.status != 'INVALID' &&  this.creditApplicationForm.controls.accountNumber.status != 'INVALID' &&  this.creditApplicationForm.controls.bankZip.status != 'INVALID' &&  this.creditApplicationForm.controls.bankCity.status != 'INVALID' && this.creditApplicationForm.controls.bankState.status != 'INVALID' &&  this.creditApplicationForm.controls.bankCountry.status != 'INVALID' && this.creditApplicationForm.controls.bankTelephone.status != 'INVALID' ){
      
    }
    // if(this.creditApplicationForm.controls['businessEntityItems']['controls']['status'] == 'INVALID'){
    //   this.toastr.error('Please fill the form!','', {
    //     timeOut: 5000,
    //   });
    //   return;
    // }
    else{
      this.displayLoader = false;
      // this.creditApplicationForm['controls'].bankName.setErrors({ 'invalid': true });
      // this.creditApplicationForm['controls'].contact.setErrors({ 'invalid': true });
      this.toastr.error('Please fill the form!','', {
        timeOut: 5000,
      });
      return;
    }
    let inputData = {
      "bankName": this.creditApplicationForm.value.bankName,
      "contact": this.creditApplicationForm.value.contact,
      "transit": null,
      "account": this.creditApplicationForm.value.accountNumber,
      "tollFree": null,
      "applicantId": this.globalApplicantDataId,
      "uuid": null,
      "bankAddress": {
          "zipCode": this.creditApplicationForm.value.bankZip,
          "city": this.creditApplicationForm.value.bankCity,
          "state": this.creditApplicationForm.value.bankState,
          "country": this.creditApplicationForm.value.bankCountry,
          "email": "bank@gmail.com",
          "phoneNumber": this.creditApplicationForm.value.bankTelephone ? this.creditApplicationForm.value.bankTelephone : '+18888888888' ,
          "fax": "",
          "addressId": null
      }
  }
  this.missingData['BankAddress']=[this.creditApplicationForm.value.bankAddress1,this.creditApplicationForm.value.bankAddress2];
  this.displayLoader = true;

    this.userpreferencesService.saveBankingInfo(inputData).subscribe(result =>{
      this.displayLoader=false
      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });
      // this.displayLoader = false;
      let applicantData = {
        "gstnNumber":"656576567",
        "bondNumber":"7686868",
        "paymentMethodId":"a74893c9-1eee-11ea-8462-163046d26cd4",
        "creditCardNumber":null,"expDate":null,"cardHolderName":null,"year":null,"month":null,"cardType":null,
        "applicantId": this.globalApplicantDataId
      }
      this.userpreferencesService.applicantPaymentInfoSave(applicantData).subscribe(data =>{
        this.toastr.success(data['message'], '', {
          timeOut: 5000,
        });
        localStorage.setItem('crapplicationstep_'+this.loggedUserId['uuid'],'3');

        this.displayLoader = false;

              this.setActiveTab(3);

      }, (error) => {
        this.displayLoader = false
        this.toastr.error(error['error']['error'],'', {
          timeOut: 5000,
        });
      })
    }, (error) => {
      this.displayLoader = false
      this.toastr.error(error['error']['error'],'', {
        timeOut: 5000,
      });
    });
  }

  // submitbilling1(){
  //   this.displayLoader = true;
  //   this.setActiveTab(1);

  // }

  //First Tab Submission - Business Structure
  submitbilling(){
    this.submitted = true;
    this.displayLoader = true;
    // this.creditApplicationForm.controls.bankName.status != 'INVALID'
    if(this.creditApplicationForm.controls.legalBusinessName.status== 'INVALID' ||
    this.creditApplicationForm.controls.firstName.status== 'INVALID' ||
    this.creditApplicationForm.controls.lastName.status== 'INVALID' ||
    this.creditApplicationForm.controls.address1.status== 'INVALID' ||
    this.creditApplicationForm.controls.address2.status== 'INVALID' ||
    this.creditApplicationForm.controls.city.status== 'INVALID' ||
    this.creditApplicationForm.controls.zip.status== 'INVALID' ||
    this.creditApplicationForm.controls.country.status== 'INVALID' ||
    this.creditApplicationForm.controls.state.status== 'INVALID' ||
    this.creditApplicationForm.controls.telePhone.status== 'INVALID' ||
    this.creditApplicationForm.controls.email.status== 'INVALID' ||
    this.creditApplicationForm.controls.bilingName.status== 'INVALID' ||
    this.creditApplicationForm.controls.billingCity.status== 'INVALID' ||
    this.creditApplicationForm.controls.billingZip.status== 'INVALID' ||
    this.creditApplicationForm.controls.bilingCountry.status== 'INVALID' ||
    this.creditApplicationForm.controls.billingState.status== 'INVALID' ){
      // this.creditApplicationForm['controls'].legalBusinessName.setErrors({ 'invalid': true });
      // this.creditApplicationForm['controls'].firstName.setErrors({ 'invalid': true });
      // this.creditApplicationForm['controls'].lastName.setErrors({ 'invalid': true });
      this.toastr.error('Please fill the form!','', {
        timeOut: 5000,
      });
      this.displayLoader = false;
      return;
    }

    this.submitted = false;
    let inputData = {
      "tradeName": this.creditApplicationForm.value.firstName,
      "legalName": this.creditApplicationForm.value.legalBusinessName,
      "tollFree": null,
      "telePhone": this.creditApplicationForm.value.telePhone,
      "uuid": null,
      "contactAddress": {
          "zipCode":this.creditApplicationForm.value.zip,
          "city": this.creditApplicationForm.value.city,
          "state": this.creditApplicationForm.value.state,
          "email": this.creditApplicationForm.value.email,
          "fax": this.creditApplicationForm.value.fax,
          "country": this.creditApplicationForm.value.country,
          "addrLine1":this.creditApplicationForm.value.address1,
          "addrLine2": this.creditApplicationForm.value.address2,
          "addressId": null
      }
  }
  this.missingData['firstname']=this.creditApplicationForm.value.firstName;
  this.missingData['lastname']=this.creditApplicationForm.value.lastName;
  this.missingData['Fax']=this.creditApplicationForm.value.fax;
  this.missingData['cellNumber']=this.creditApplicationForm.value.telePhone;
  this.missingData['BIN']=this.creditApplicationForm.value.businessIdentification;

  this.signBy = this.creditApplicationForm.value.firstName + ' ' + this.creditApplicationForm.value.lastName;
    this.userpreferencesService.addBusinessStructure(inputData).subscribe(result =>{
      localStorage.setItem('crapplicationstep_'+this.loggedUserId['uuid'],'1');
      this.toastr.success(result['message'], '', {
        timeOut: 5000,
      });
      this.globalApplicantDataId = result['data'];
      localStorage.setItem('crapplicationid_'+this.loggedUserId['uuid'],this.globalApplicantDataId);
      let biilingInputData = {
        "billingAddr":{
          "zipCode":this.creditApplicationForm.value.billingZip,
          "city":this.creditApplicationForm.value.billingCity,
          "state":this.creditApplicationForm.value.billingState,
          "addrLine1":this.creditApplicationForm.value.address1,
          "country": this.creditApplicationForm.value.country
        },
          "applicantId":this.globalApplicantDataId,
          "addrId":this.loggedUserId['defaultAddrId'],
          "name":this.creditApplicationForm.value.bilingName
        }

        this.userpreferencesService.saveApplicantBilling(biilingInputData).subscribe(result =>{
          this.toastr.success(result['message'], '', {
            timeOut: 5000,
          });
          this.displayLoader = false
          this.setActiveTab(1);

        }, (error) => {

          this.displayLoader = false
          this.toastr.error(error['error']['error'],'', {
            timeOut: 5000,
          });
        });

    }, (error) => {

      this.displayLoader = false;
      this.toastr.error(error['error']['error'],'', {
        timeOut: 5000,
      });
    });
    
  }

  getTodaysDate(format){
    var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;
    if( format == 'YYYY-MM-DD'){
      return [year,month,day].join('-');
    }else{
      return [day, month, year].join('/');
    }
  }

  // redirectTo(path){
  //   if(path){
  //     this.router.navigate([path]);
  //   }
  // }
  open(modal) {
    if (modal == 'userPreferences') {
      const modalRef = this.modalService.open(CreditApplicationBackButtonComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
      this.router.events.subscribe((val) => {
        modalRef.close();
    });
    }

  }
}
