import { Injectable } from '@angular/core';
import { element } from 'protractor';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListDataServiceService {
  orderList: any;
  quoteHistory: any;
  invoiceList: any;
  settingUserManagmenetList: any;
  addressBookList: any;
  shipcarteProList:any;
  shipcarteProProductList:any;
  shipcarteProPackageList:any;
  favoriteOrderList:any;
  constructor() {
    this.allOrdersData = {
      allOrderList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchkey: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      intransitList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchkey: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      completedList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchkey: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      scheduledList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchkey: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      
      tabId: 0
    }
    this.orderList = {
      allOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      intransitOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      completedOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      scheduledOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      failedOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      tabId: 0
    }
    this.quoteHistory = {
      allQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      draftQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      executedQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      failedQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        isAscending:false,
        sortParam:''
      },
      tabId: 0
    }
    this.invoiceList = {
      allInvoices: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
      },
      dueInvoices: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
      },
      paidInvoices: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
      },
      tabId: 1
    }
    this.settingUserManagmenetList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: '',
      selectedId: ''
    }
    this.addressBookList = {
      contactList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        selectedId: '',
        isAscending:false,
        sortParam:''
      },
      activeContactList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        selectedId: '',
        isAscending:false,
        sortParam:''
      },
      inActiveContactList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        selectedId: '',
        isAscending:false,
        sortParam:''
      },
      tabId:0
    }
    this.shipcarteProList={
      pageCount:-1,
      pageSize:20,
      dataList:[],
      searchKey:'',
      fromDate:'',
      toDate:''
    }
    this.shipcarteProProductList={
      pageCount:-1,
      pageSize:20,
      dataList:[],
      searchKey:'',
      selectedId:'',
      collectionSize:0
    }
    this.shipcarteProPackageList={
      pageCount:-1,
      pageSize:20,
      dataList:[],
      searchKey:'',
      selectedId:'',
      collectionSize:0
    }
    this.favoriteOrderList={
      pageCount:-1,
      pageSize:20,
      dataList:[],
      searchKey:''
    }
  }
  setAllDataOfOrderList(data: any, tabId: any) {
    if (tabId == 1) {
      this.orderList.allOrders.pageCount = data.pageCount;
      this.orderList.allOrders.pageSize = data.pageSize;
      this.orderList.allOrders.dataList = data.dataList
    } else if (tabId == 2) {
      this.orderList.intransitOrders.pageCount = data.pageCount;
      this.orderList.intransitOrders.pageSize = data.pageSize;
      this.orderList.intransitOrders.dataList = data.dataList
    } else if (tabId == 3) {
      this.orderList.completedOrders.pageCount = data.pageCount;
      this.orderList.completedOrders.pageSize = data.pageSize;
      this.orderList.completedOrders.dataList = data.dataList
    } else if (tabId == 4) {
      this.orderList.scheduledOrders.pageCount = data.pageCount;
      this.orderList.scheduledOrders.pageSize = data.pageSize;
      this.orderList.scheduledOrders.dataList = data.dataList
    }

    else if (tabId == 5) {
      this.orderList.failedOrders.pageCount = data.pageCount;
      this.orderList.failedOrders.pageSize = data.pageSize;
      this.orderList.failedOrders.dataList = data.dataList
    }
    this.orderList.tabId = tabId
  }
  setFilterValueInOrderList(value, tabId) {
    if (tabId == 1) {
      this.orderList.allOrders.fitlerKey = value
    } else if (tabId == 2) {
      this.orderList.intransitOrders.fitlerKey = value
    } else if (tabId == 3) {
      this.orderList.completedOrders.fitlerKey = value
    } else if (tabId == 4) {
      this.orderList.scheduledOrders.fitlerKey = value
    }
    else if (tabId == 5) {
      this.orderList.failedOrders.fitlerKey = value
    }
  }
  setDataOfSortValueInOrderList(isAscending,sortParam,tabId){
    if(tabId==1){
      this.orderList.allOrders.isAscending=isAscending;
      this.orderList.allOrders.sortParam=sortParam;
    }else if(tabId==2){
      this.orderList.intransitOrders.isAscending=isAscending;
      this.orderList.intransitOrders.sortParam=sortParam;
    }else if(tabId==3){
      this.orderList.completedOrders.isAscending=isAscending;
      this.orderList.completedOrders.sortParam=sortParam;
    }else if(tabId==4){
      this.orderList.scheduledOrders.isAscending=isAscending;
      this.orderList.scheduledOrders.sortParam=sortParam;
    }
    else if(tabId==5){
      this.orderList.failedOrders.isAscending=isAscending;
      this.orderList.failedOrders.sortParam=sortParam;
    }
  }
  setSearchKeyOfOrderList(value, tabId) {
    if (tabId == 1) {
      this.orderList.allOrders.searchKey = value
    } else if (tabId == 2) {
      this.orderList.intransitOrders.searchKey = value
    } else if (tabId == 3) {
      this.orderList.completedOrders.searchKey = value
    } else if (tabId == 4) {
      this.orderList.scheduledOrders.searchKey = value
    }
    else if (tabId == 5) {
      this.orderList.failedOrders.searchKey = value
    }
  }
  setSelectedItemIdInOrderList(orderId, tabId) {
    if (tabId == 1) {
      this.orderList.allOrders.selectedId = orderId
    } else if (tabId == 2) {
      this.orderList.intransitOrders.selectedId = orderId
    } else if (tabId == 3) {
      this.orderList.completedOrders.selectedId = orderId
    } else if (tabId == 4) {
      this.orderList.scheduledOrders.selectedId = orderId
    }
    else if (tabId == 5) {
      this.orderList.failedOrders.selectedId = orderId
    }
  }
  setDateFilterOfOrderList(fromDate, endDate, tabId) {
    if (tabId == 1) {
      this.orderList.allOrders.fromDate = fromDate
      this.orderList.allOrders.toDate = endDate
    } else if (tabId == 2) {
      this.orderList.intransitOrders.fromDate = fromDate
      this.orderList.intransitOrders.toDate = endDate
    } else if (tabId == 3) {
      this.orderList.completedOrders.fromDate = fromDate
      this.orderList.completedOrders.toDate = endDate
    } else if (tabId == 4) {
      this.orderList.scheduledOrders.fromDate = fromDate
      this.orderList.scheduledOrders.toDate = endDate
    }
    else if (tabId == 5) {
      this.orderList.failedOrders.fromDate = fromDate
      this.orderList.failedOrders.toDate = endDate
    }
  }
  removeElementFromListByOrderIdAndTabId(orderId, tabId) {
    if (tabId == 2) {
      let indexOfElement = -1;
      this.orderList.intransitOrders.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          indexOfElement = index
        }
      })
      if (indexOfElement > -1) {
        this.orderList.intransitOrders.dataList.splice(indexOfElement, 1)
      }
    } 
    else if (tabId == 4) {
      let indexOfElement = -1;
      this.orderList.scheduledOrders.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          indexOfElement = index
        }
      })
      if (indexOfElement > -1) {
        this.orderList.scheduledOrders.dataList.splice(indexOfElement, 1)
      }
    }

    else if (tabId == 5) {
      let indexOfElement = -1;
      this.orderList.failedOrders.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          indexOfElement = index
        }
      })
      if (indexOfElement > -1) {
        this.orderList.failedOrders.dataList.splice(indexOfElement, 1)
      }
    }
  }
  updateTheStatusOfOrderssList(statusValue, orderId, tabId) {
    if (tabId == 1) {
      this.orderList.allOrders.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.orderList.allOrders.dataList[index]['status'] = statusValue
        }
      })
    } else if (tabId == 2) {
      this.orderList.intransitOrders.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.orderList.intransitOrders.dataList[index]['status'] = statusValue
        }
      })
    } else if (tabId == 3) {
      this.orderList.completedOrders.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.orderList.completedOrders.dataList[index]['status'] = statusValue
        }
      })
    } 
    else if (tabId == 4) {
      this.orderList.scheduledOrders.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.orderList.scheduledOrders.dataList[index]['status'] = statusValue
        }
      })
    }

    else if (tabId == 5) {
      this.orderList.failedOrders.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.orderList.failedOrders.dataList[index]['status'] = statusValue
        }
      })
    }
  }
  removeDataOfListAccordingToTabIdInOrderList(tabId) {
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   searchKey: '',
    //   fitlerKey: '',
    //   selectedId: '',
    //   fromDate: '',
    //   toDate: '',
    //   sortParam: '',
    //   isAscending: false
    // };
    if (tabId == 1) {
      this.orderList.allOrders = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 2) {
      this.orderList.intransitOrders = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 3) {
      this.orderList.completedOrders = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 4) {
      this.orderList.scheduledOrders = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      }
    }
      else if (tabId == 5) {
        this.orderList.failedOrders = {
          pageCount: -1,
          pageSize: 20,
          dataList: [],
          searchKey: '',
          fitlerKey: '',
          selectedId: '',
          fromDate: '',
          toDate: '',
          sortParam: '',
          isAscending: false
        };
    }
  }
  setTabIdOfOrderList(id) {
    this.orderList.tabId = id;
  }
  removeAllDataOfOrderlist() {
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   searchKey: '',
    //   fitlerKey: '',
    //   selectedId: '',
    //   fromDate: '',
    //   toDate: '',
    //   sortParam: '',
    //   isAscending: false
    // };
    this.orderList = {
      allOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      intransitOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      completedOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      scheduledOrders: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      failedOrders:{
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        fitlerKey: '',
        selectedId: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      tabId: 0
    }
  }
  getAllDataOfOrderList() {
    return this.orderList;
  }

  setAllDataOfQuoteHistory(data, tabId) {
    if (tabId == 1) {
      this.quoteHistory.allQuotes.pageSize = data.pageSize;
      this.quoteHistory.allQuotes.pageCount = data.pageCount;
      this.quoteHistory.allQuotes.dataList = data.dataList
    } else if (tabId == 2) {
      this.quoteHistory.draftQuotes.pageSize = data.pageSize;
      this.quoteHistory.draftQuotes.pageCount = data.pageCount;
      this.quoteHistory.draftQuotes.dataList = data.dataList
    } else if (tabId == 3) {
      this.quoteHistory.executedQuotes.pageSize = data.pageSize;
      this.quoteHistory.executedQuotes.pageCount = data.pageCount;
      this.quoteHistory.executedQuotes.dataList = data.dataList
    }
    else if (tabId == 4) {
      this.quoteHistory.failedQuotes.pageSize = data.pageSize;
      this.quoteHistory.failedQuotes.pageCount = data.pageCount;
      this.quoteHistory.failedQuotes.dataList = data.dataList
    }
    this.quoteHistory.tabId = tabId
  }
  setDateFilterOfQuoteHistory(fromDate, toDate, tabId) {
    if (tabId == 1) {
      this.quoteHistory.allQuotes.fromDate = fromDate;
      this.quoteHistory.allQuotes.toDate = toDate
    } else if (tabId == 2) {
      this.quoteHistory.draftQuotes.fromDate = fromDate;
      this.quoteHistory.draftQuotes.toDate = toDate
    } else if (tabId == 3) {
      this.quoteHistory.executedQuotes.fromDate = fromDate;
      this.quoteHistory.executedQuotes.toDate = toDate;
    }
    else if (tabId == 4) {
      this.quoteHistory.failedQuotes.fromDate = fromDate;
      this.quoteHistory.failedQuotes.toDate = toDate;
    }
  }
  setDataofSortValueInQuoteHistory(isAscending,sortParam,tabId){
    if(tabId==1){
      this.quoteHistory.allQuotes.isAscending=isAscending;
      this.quoteHistory.allQuotes.sortParam=sortParam;
    }else if(tabId==2){
      this.quoteHistory.draftQuotes.isAscending=isAscending;
      this.quoteHistory.draftQuotes.sortParam=sortParam;
    }else if(tabId==3){
      this.quoteHistory.executedQuotes.isAscending=isAscending;
      this.quoteHistory.executedQuotes.sortParam=sortParam;
    }
    else if(tabId==4){
      this.quoteHistory.failedQuotes.isAscending=isAscending;
      this.quoteHistory.failedQuotes.sortParam=sortParam;
    }
  }
  setSelectedItemIdOfQuoteHistory(quoteId, tabId) {
    if (tabId == 1) {
      this.quoteHistory.allQuotes.selectedId = quoteId
    } else if (tabId == 2) {
      this.quoteHistory.draftQuotes.selectedId = quoteId
    } else if (tabId == 3) {
      this.quoteHistory.executedQuotes.selectedId = quoteId
    }
    else if (tabId == 4) {
      this.quoteHistory.failedQuotes.selectedId = quoteId
    }
  }
  setSearchKeyOfQuoteHistory(value, tabId) {
    if (tabId == 1) {
      this.quoteHistory.allQuotes.searchKey = value;
    } else if (tabId == 2) {
      this.quoteHistory.draftQuotes.searchKey = value;
    } else if (tabId == 3) {
      this.quoteHistory.executedQuotes.searchKey = value;
    }
    else if (tabId == 4) {
      this.quoteHistory.failedQuotes.searchKey = value;
    }
  }
  removeDataOfQuoteHistoryTab(tabId) {
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   selectedId: '',
    //   searchKey: '',
    //   fromDate: '',
    //   toDate: '',
    //   sortParam: '',
    //   isAscending: false
    // }
    if (tabId == 1) {
      this.quoteHistory.allQuotes = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 2) {
      this.quoteHistory.draftQuotes = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    }
     else if (tabId == 3) {
      this.quoteHistory.executedQuotes = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    }

    else if (tabId == 4) {
      this.quoteHistory.failedQuotes = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    }
  }
  removeAllDataOfQuoteHistory() {
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   selectedId: '',
    //   searchKey: '',
    //   fromDate: '',
    //   toDate: '',
    //   sortParam: '',
    //   isAscending: false
    // }
    this.quoteHistory = {
      allQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      draftQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      executedQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },

      failedQuotes: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      tabId: 0
    }
  }
  allOrdersData: any;
  removeDataOfTabWhenClickOnResetButton(tabId: any) {
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   selectedId: '',
    //   searchkey: '',
    //   fromDate: '',
    //   toDate: '',
    //   sortParam: '',
    //   isAscending: false
    // }
    if (tabId == 1) {
      this.allOrdersData.allOrderList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchkey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 2) {
      this.allOrdersData.intransitList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchkey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 3) {
      this.allOrdersData.completedList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchkey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchkey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    }
  }
  setSelectedOrderId(id: any, tabId: any) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.selectedId = id
    } else if (tabId == 2) {
      this.allOrdersData.intransitList.selectedId = id
    } else if (tabId == 3) {
      this.allOrdersData.completedList.selectedId = id
    } else if (tabId == 4) {
      this.allOrdersData.scheduledList.selectedId = id
    }
  }
  updateDataListOfOrderWhenChangeTheStatus(orderId, statusValue, tabId) {
    if (tabId == 1) {
      this.allOrdersData.allOrderList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.allOrderList.dataList[index]['status'] = statusValue
        }
      })
    }
    if (tabId == 2) {
      this.allOrdersData.intransitList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.intransitList.dataList[index]['status'] = statusValue
        }
      })
    }
    if (tabId == 3) {
      this.allOrdersData.completedList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.completedList.dataList[index]['status'] = statusValue
        }
      })
    }
    if (tabId == 4) {
      this.allOrdersData.scheduledList.dataList.forEach((element, index) => {
        if (element.orderId == orderId) {
          this.allOrdersData.scheduledList.dataList[index]['status'] = statusValue
        }
      })
    }
  }
  setTabIdOfQuoteHistory(tabId) {
    this.quoteHistory.tabId = tabId
  }
  getAllDataOfQuoteHistory() {
    return this.quoteHistory;
  }
  setAllDataOfInvoiceList(data, tabId) {
    if (tabId == 1) {
      this.invoiceList.allInvoices.pageCount = data.pageCount;
      this.invoiceList.allInvoices.pageSize = data.pageSize;
      this.invoiceList.allInvoices.dataList = data.dataList;
    } else if (tabId == 2) {
      this.invoiceList.dueInvoices.pageCount = data.pageCount;
      this.invoiceList.dueInvoices.pageSize = data.pageSize;
      this.invoiceList.dueInvoices.dataList = data.dataList;
    } else if (tabId == 3) {
      this.invoiceList.paidInvoices.pageCount = data.pageCount;
      this.invoiceList.paidInvoices.pageSize = data.pageSize;
      this.invoiceList.paidInvoices.dataList = data.dataList;
    }
    this.invoiceList.tabId = tabId
  }
  setSearchKeyForInvoiceList(value, tabId) {
    if (tabId == 1) {
      this.invoiceList.allInvoices.searchKey = value
    } else if (tabId == 2) {
      this.invoiceList.dueInvoices.searchKey = value
    } else if (tabId == 3) {
      this.invoiceList.paidInvoices.searchKey = value
    }
  }
  setFromDateToDateForInvoiceList(fromDate, toDate, tabId) {
    if (tabId == 1) {
      this.invoiceList.allInvoices.fromDate = fromDate
      this.invoiceList.allInvoices.toDate = toDate
    } else if (tabId == 2) {
      this.invoiceList.dueInvoices.fromDate = fromDate
      this.invoiceList.dueInvoices.toDate = toDate
    } else if (tabId == 3) {
      this.invoiceList.paidInvoices.fromDate = fromDate
      this.invoiceList.paidInvoices.toDate = toDate
    }
  }
  setTabIdOfInvoiceList(id) {
    this.invoiceList.tabId = id
  }
  setSelectedInvoiceIdInInvoiceList(id, tabId) {
    if (tabId == 1) {
      this.invoiceList.allInvoices.selectedId = id

    } else if (tabId == 2) {
      this.invoiceList.dueInvoices.selectedId = id

    } else if (tabId == 3) {

      this.invoiceList.paidInvoices.selectedId = id
    }
  }

  getAllDataOfInvoiceList() {
    return this.invoiceList
  }
  removeDataOfTabOfInvoiceList(tabId) {
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   selectedId: '',
    //   searchKey: '',
    //   fromDate: '',
    //   toDate: '',
    //   sortParam: '',
    //   isAscending: false
    // };
    if (tabId == 1) {
      this.invoiceList.allInvoices = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 2) {
      this.invoiceList.dueInvoices = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    } else if (tabId == 3) {
      this.invoiceList.paidInvoices = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      };
    }
  }
  removeAllDataOfInvoiceList() {
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   selectedId: '',
    //   searchKey: '',
    //   fromDate: '',
    //   toDate: '',
    //   sortParam: '',
    //   isAscending: false
    // }
    this.invoiceList = {
      allInvoices: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      dueInvoices: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      paidInvoices: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        selectedId: '',
        searchKey: '',
        fromDate: '',
        toDate: '',
        sortParam: '',
        isAscending: false
      },
      tabId: 1
    }
  }


  //function for setting user management
  setAllDataOfSettingUserManamgementList(data) {
    this.settingUserManagmenetList.pageCount = data.pageCount;
    this.settingUserManagmenetList.pageSize = data.pageSize;
    this.settingUserManagmenetList.dataList = data.dataList
  }
  setSearchKeyForSettingUserManagmentList(value) {
    this.settingUserManagmenetList.searchKey = value;
  }
  getAllDataOfSettingUserManagementList() {
    return this.settingUserManagmenetList;
  }
  removeAllDataOfSettingUserManagementList() {
    let commonObj = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: '',
      selectedId: '',
      sortParam: '',
      isAscending: false
    };
    this.settingUserManagmenetList = commonObj;
  }

  //fuctions for address book list
  setAllDataOfAddressBookList(data, tabId) {
    if (tabId == 1) {
      this.addressBookList.contactList.pageCount = data.pageCount;
      this.addressBookList.contactList.pageSize = data.pageSize;
      this.addressBookList.contactList.dataList = data.dataList;
    } else if (tabId == 2) {
      this.addressBookList.activeContactList.pageCount = data.pageCount;
      this.addressBookList.activeContactList.pageSize = data.pageSize;
      this.addressBookList.activeContactList.dataList = data.dataList;
    } else if (tabId == 3) {
      this.addressBookList.inActiveContactList.pageCount = data.pageCount;
      this.addressBookList.inActiveContactList.pageSize = data.pageSize;
      this.addressBookList.inActiveContactList.dataList = data.dataList;
    }
  }
  getAllDataOfAddressBookList() {
    return this.addressBookList;
  }
  setDataOfSortValueInAddressBook(isAscending,sortPara,tabId){
    if(tabId==1){
      this.addressBookList.contactList.isAscending=isAscending;
      this.addressBookList.contactList.sortParam=sortPara;
    }else if(tabId==2){
      this.addressBookList.activeContactList.isAscending=isAscending;
      this.addressBookList.activeContactList.sortParam=sortPara;
    }else if(tabId==3){
      this.addressBookList.inActiveContactList.isAscending=isAscending;
      this.addressBookList.inActiveContactList.sortParam=sortPara;
    }
  }
  setSearchKeyForAddressBook(value, tabId) {
    if (tabId == 1) {
      this.addressBookList.contactList.searchKey = value
    } else if (tabId == 2) {
      this.addressBookList.activeContactList.searchKey = value
    } else if (tabId == 3) {
      this.addressBookList.inActiveContactList.searchKey = value
    }
  }
  setSelectedContactIdForAddressBookList(id,tabId){
    if(tabId==1){
      this.addressBookList.contactList.selectedId = id

    }else if(tabId==2){
      this.addressBookList.activeContactList.selectedId = id

    }else if(tabId==3){
      this.addressBookList.inActiveContactList.selectedId = id

    }
  }
 removeElementFromTheAddressBookListAccordingToUnquieId(id,tabId){
    if(tabId==2){
      let indexOfElement=-1;
      this.addressBookList.activeContactList.dataList.forEach((element,index)=>{
        if(element.companyId==id){
          indexOfElement=index
        }
      })
      if(indexOfElement>-1){
        this.addressBookList.activeContactList.dataList.splice(indexOfElement,1)
      }
    }else if(tabId==3){
      let indexOfElement=-1;
      this.addressBookList.inActiveContactList.dataList.forEach((element,index)=>{
        if(element.companyId==id){
          indexOfElement=index
        }
      })
      if(indexOfElement>-1){
        this.addressBookList.inActiveContactList.dataList.splice(indexOfElement,1)
      }
    }else if(tabId==1){
      let indexOfElement=-1;
      this.addressBookList.contactList.dataList.forEach((element,index)=>{
        if(element.companyId==id){
          indexOfElement=id
        }
      })
      if(indexOfElement>-1){
        this.addressBookList.contactList.dataList.splice(indexOfElement,1)
      }
    }
  }
  udpateStatusOfAddressBookItemListAccordingToUniqueId(id,tabId,statusValue){
    if(tabId==1){
      let indexOfElement=-1;
      this.addressBookList.contactList.dataList.forEach((element,index)=>{
        if(element.companyId==id){
          indexOfElement=index
        }
      })
      if(indexOfElement>-1){
        this.addressBookList.contactList.dataList[indexOfElement]['status']=statusValue;
      }
    }else if(tabId==2){
      let indexOfElement=-1;
      this.addressBookList.inActiveContactList.dataList.forEach((element,index)=>{
        if(element.companyId==id){
          indexOfElement=index
        }
      })
      if(indexOfElement>-1){
        this.addressBookList.activeContactList.dataList[indexOfElement]['status']=statusValue
      }
    }else if(tabId==3){
      let indexOfElement=-1;
      this.addressBookList.inActiveContactList.dataList.forEach((element,index)=>{
        if(element.companyId==index){
          indexOfElement=index
        }
      })
      if(indexOfElement>-1){
        this.addressBookList.inActiveContactList.dataList[indexOfElement]['status']=statusValue
      }
    }
  }
  setTabIdOfAddressBook(id){
    this.addressBookList.tabId=id;
  }
  removeDataOfAddressBookAccordingToTabId(tabId){
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   searchKey: '',
    //   selectedId: '',
    //   sortParam: '',
    //   isAscending: false
    // };
    if(tabId==1){
      this.addressBookList.contactList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        selectedId: '',
        sortParam: '',
        isAscending: false
      };
    }else if(tabId==2){
     this.addressBookList.activeContactList = {
      pageCount: -1,
      pageSize: 20,
      dataList: [],
      searchKey: '',
      selectedId: '',
      sortParam: '',
      isAscending: false
    };
    }else if(tabId==3){
      this.addressBookList.inActiveContactList = {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        selectedId: '',
        sortParam: '',
        isAscending: false
      };
    }
  }

  removeAllDataOfAddressBookList(){
    // let commonObj = {
    //   pageCount: -1,
    //   pageSize: 20,
    //   dataList: [],
    //   searchKey: '',
    //   selectedId: '',
    //   sortParam: '',
    //   isAscending: false
    // }
    this.addressBookList = {
      contactList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        selectedId: '',
        sortParam: '',
        isAscending: false
      },
      activeContactList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        selectedId: '',
        sortParam: '',
        isAscending: false
      },
      inActiveContactList: {
        pageCount: -1,
        pageSize: 20,
        dataList: [],
        searchKey: '',
        selectedId: '',
        sortParam: '',
        isAscending: false
      },
      tabId:0
    }
  }
  //shipcarte pro list fucntion

  setAllDataOfShipcarteProList(data){
    this.shipcarteProList.pageCount=data.pageCount;
    this.shipcarteProList.pageSize=data.pageSize;
    this.shipcarteProList.dataList=data.dataList;
  }
  setSearchKeyForShipcarteProList(value){
    this.shipcarteProList.searchKey=value;
  }
  setFromDateToDateForShipcarteProList(fromDate,toDate){
    this.shipcarteProList.fromDate=fromDate;
    this.shipcarteProList.toDate=toDate;
  }
  getAllDataOfShipcarteProList(){
    return this.shipcarteProList;
  }
  removeAllDataOfShipcarteProList(){
    let commonObj = {
      pageCount:-1,
      pageSize:20,
      dataList:[],
      searchKey:'',
      fromDate:'',
      toDate:'',
      sortParam: '',
      isAscending: false
    };
    this.shipcarteProList = commonObj;
  }

  // shipcarte pro product list function
  setAllDataOfShipcarteProductList(data){
    console.log(data)
    this.shipcarteProProductList.pageCount=data.pageCount;
    this.shipcarteProProductList.pageSize=data.pageSize;
    this.shipcarteProProductList.dataList=data.dataList;
    this.shipcarteProProductList.collectionSize=data.collectionSize
  }
  setSearchKeyForShipcarteProProductList(value){
    this.shipcarteProProductList.searchKey=value;
  }
  setSelectedIdForShipcarteProProductList(id){
    this.shipcarteProProductList.selectedId=id
  }
  getAllDataOfShipcarteProProductList(){
    return this.shipcarteProProductList;
  }
  removeAllDataOfShipcarteProProductList(){
    let commonObj = {
      pageCount:-1,
      pageSize:20,
      dataList:[],
      searchKey:'',
      selectedId:'',
      collectionSize:0,
      sortParam: '',
      isAscending: false
    }
    this.shipcarteProProductList = commonObj;
  }

  // shipcarte pro package list function
  setAllDataOfShipcarteProPackageList(data){
    this.shipcarteProPackageList.pageCount=data.pageCount;
    this.shipcarteProPackageList.pageSize=data.pageSize;
    this.shipcarteProPackageList.dataList=data.dataList;
    this.shipcarteProPackageList.collectionSize=data.collectionSize
  }
  setSearchKeyForShipcarteProPackageList(value){
    this.shipcarteProPackageList.searchKey=value;
  }
  setSelectedIdForShipcarteProPackageList(id){
    this.shipcarteProPackageList.selectedId=id;
  }
  getAllDataOfShipcarteProPackageList(){
    return this.shipcarteProPackageList;
  }
  removeAllDataOfShipcarteProPackageList(){
    let commonObj = {
      pageCount:-1,
      pageSize:20,
      dataList:[],
      searchKey:'',
      selectedId:'',
      collectionSize:0,
      sortParam: '',
      isAscending: false
    }
    this.shipcarteProPackageList = commonObj;
  }


  //function for favorite order list
  setAllDataOfFavoriteOrderList(data){
    this.favoriteOrderList.pageCount=data.pageCount;
    this.favoriteOrderList.pageSize=data.pageSize;
    this.favoriteOrderList.dataList=data.dataList;
  }
  setSearchKeyForFavoriteOrderList(value){
    this.favoriteOrderList.searchKey=value;
  }
  getAllDataOfFavoriteOrderList(){
    return this.favoriteOrderList
  }
  removeAllDataOfFavoriteOrderList(){
    this.favoriteOrderList={
      pageCount:-1,
      pageSize:20,
      dataList:[],
      searchKey:''
    }
  }







  removeAllListData() {
    this.removeAllDataOfOrderlist()
    this.removeAllDataOfQuoteHistory();
    this.removeAllDataOfInvoiceList()
    this.removeAllDataOfSettingUserManagementList();
    this.removeAllDataOfAddressBookList();
    this.removeAllDataOfShipcarteProList();
    this.removeAllDataOfShipcarteProProductList();
    this.removeAllDataOfFavoriteOrderList();
  }
}