import { Component, OnInit, DoCheck, ChangeDetectorRef,OnDestroy } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, AbstractControl, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomValidators } from 'src/app/custom-validators';
 
import { ToastrService } from 'ngx-toastr';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/services/common.service';
import { ShipCarteProService } from 'src/app/services/shipcartePro.service';
import { ListDataServiceService } from 'src/app/services/ListData/list-data-service.service';
import { LoadingService } from 'src/app/services/loader.service';

@Component({
  selector: 'app-add-packages',
  templateUrl: './add-packages.component.html',
  styleUrls: ['./add-packages.component.css']
})
export class AddPackagesComponent implements OnInit {
  packageForm: FormGroup;
  submitClicked: boolean = false;
  packageId: string;
  isEdit: boolean = false;
  packageTypeArray: any = []
  sidebarToggle: boolean;
  apiCall: boolean;
  packageData: any;
  closeResult: string;
  bulkUploadData: any = {
    file: null,
    name: ''
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    public common: CommonService,
    private toast: ToastrService,
    private shipcatepro: ShipCarteProService,
    private modalService: NgbModal,
    private listDataService:ListDataServiceService,
    private  _loaderService: LoadingService
  ) {
    this.changeDetector.detach();
  }

  ngDoCheck() {
    if (this.common.sidebarToggle == true) {
      this.sidebarToggle = true;
    }
    else {
      this.sidebarToggle = false;
    }
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    this.packageId = this.activatedRoute.snapshot.paramMap.get('id');
    this.packageForm = this.fb.group({
      packageName: ['', [Validators.required, Validators.maxLength(50), CustomValidators.onlySpaceCheckValidator]],
      packageDesc: ['', [Validators.required, Validators.maxLength(50), CustomValidators.onlySpaceCheckValidator]],
      packageCost: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      dimentionUnit: ['imperial'],
      packageType: ['', Validators.required],
      weight: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      length: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      width: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      height: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      innerHeight: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      innerLength: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      innerWidth: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]],
      emptyWeight: ['', [Validators.required, Validators.min(0.001), Validators.max(9999999999), Validators.pattern(/^[0-9]{1,20}(?:\.[0-9]{1,2})?$/)]]
    })

    if (this.packageId) {
      this.isEdit = true;
      this.setDataForEdit();
    }
    else {
      this.apiCall = true
      // api call for getting all package types **********
      this.shipcatepro.getAllPackageType().subscribe((res: any) => {
        this.packageTypeArray = res;
        this.apiCall = false;
        if (res) {
          this._loaderService.stringSubject.next(false);
        }
      }, error => {
        this._loaderService.stringSubject.next(false);
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin'])
        }
        this.apiCall = false;
        this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
          timeOut: 10000,
        });
      })
    }
  }

  // function to prepopulate data to form for edit ******************************************************************************************************************************

  setDataForEdit() {
    this.apiCall = true
    this.packageTypeArray = [];
    this.shipcatepro.getPackageById(this.packageId).subscribe((res: any) => {
      if (res) {
        this._loaderService.stringSubject.next(false);
      }
      this.packageTypeArray = res.packagingTypeList;
      this.packageForm.patchValue({
        packageName: res.packageName,
        packageDesc: res.packageAbvr,
        packageCost: res.packageUnitCost,
        dimentionUnit: res.unitMode,
        packageType: res?.proPackagingTypeDto?.packagingId,
        weight: res.weight,
        length: res.length,
        width: res.width,
        height: res.height,
        innerHeight: res.innerHeight,
        innerLength: res.innerLength,
        innerWidth: res.innerWidth,
        emptyWeight: res.empWeight
      })
      this.packageData = res;
      this.apiCall = false;
    },err=>{
      this._loaderService.stringSubject.next(false);
    })
  }

  // *********************************************************************************************************************************************************************************

  increamentByOne(unit) {
    this.packageForm.get(`${unit}`).patchValue(parseFloat(((this.packageForm.get(`${unit}`).value ? this.packageForm.get(`${unit}`).value : 0) + 1).toFixed(2)));
  }

  decreamentByOne(unit) {
    if (this.packageForm.get(`${unit}`).value > 1) {
      this.packageForm.get(`${unit}`).patchValue(parseFloat(((this.packageForm.get(`${unit}`).value ? this.packageForm.get(`${unit}`).value : 0) - 1).toFixed(2)));
    }
  }

  createPackage() {
    if (this.packageForm.valid) {
      let objForCreatePackage = {
        packageName: this.packageForm.get('packageName').value,
        packageAbvr: this.packageForm.get('packageDesc').value,
        proPackagingTypeDto: {
          packagingId: this.packageForm.get('packageType').value,
        },
        packageUnitCost: this.packageForm.get('packageCost').value,
        unitMode: this.packageForm.get('dimentionUnit').value,
        length: this.packageForm.get('length').value,
        width: this.packageForm.get('width').value,
        height: this.packageForm.get('height').value,
        weight: this.packageForm.get('weight').value,
        innerLength: this.packageForm.get('innerLength').value,
        innerWidth: this.packageForm.get('innerWidth').value,
        innerHeight: this.packageForm.get('innerHeight').value,
        empWeight: this.packageForm.get('emptyWeight').value
      }
      this.apiCall = true
      this.shipcatepro.createPackage(objForCreatePackage).subscribe((res: any) => {
        this.toast.success('Success! Package added to list', '', {
          timeOut: 10000,
        });
        this.apiCall = false;
        this.listDataService.removeAllDataOfShipcarteProPackageList()
        this.router.navigateByUrl(`/shipcartepro/tabs/intelliBox/intelliTabs/packagingList`)
      }, error => {
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin'])
        }
        this.apiCall = false;
        this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
          timeOut: 10000,
        });
      })
    }
    else {
      this.submitClicked = true;
      // console.log(this.packageForm);
    }
  }

  updatePackage() {
    if (this.packageForm.valid) {
      let objForUpatePackage = {
        packageId: this.packageId,
        packageName: this.packageForm.get('packageName').value,
        packageAbvr: this.packageForm.get('packageDesc').value,
        proPackagingTypeDto: {
          packagingId: this.packageForm.get('packageType').value,
        },
        packageUnitCost: this.packageForm.get('packageCost').value,
        unitMode: this.packageForm.get('dimentionUnit').value,
        length: this.packageForm.get('length').value,
        width: this.packageForm.get('width').value,
        height: this.packageForm.get('height').value,
        weight: this.packageForm.get('weight').value,
        innerLength: this.packageForm.get('innerLength').value,
        innerWidth: this.packageForm.get('innerWidth').value,
        innerHeight: this.packageForm.get('innerHeight').value,
        empWeight: this.packageForm.get('emptyWeight').value
      }
      this.apiCall = true
      this.shipcatepro.updatePackage(objForUpatePackage).subscribe((res: any) => {
        this.toast.success(res.message, '', {
          timeOut: 10000,
        });
        this.listDataService.removeAllDataOfShipcarteProPackageList()
        this.setDataForEdit();

      }, error => {
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin'])
        }
        this.apiCall = false;
        this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
          timeOut: 10000,
        });
      })
      // console.log(objForUpatePackage);
    }
    else {
      this.submitClicked = true;
    }
  }

  // function for active deactive package ******************************************************************************************************************************

  changeStatusOfFlag(flag) {
    this.apiCall = true;
    this.shipcatepro.chageStatusOfPackage(this.packageId, flag).subscribe((res) => {
      this.toast.success('Package updated successfully', '', {
        timeOut: 10000,
      });
      this.setDataForEdit();
    }, error => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.apiCall = false;
      this.toast.error('Something went wrong.', '', {
        timeOut: 1000000,
      });
    })
  }

  // ******************************************************************************************************************************


  // section for modal open ****************************************************************************************************

  open(content) {
    this.modalService.open(content, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: "my-custom-modalBulkUpload" }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      if(result=='Save click'){
        this.bulkUpload();
      }
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      this.bulkUploadData.name = '';
      this.bulkUploadData.file = null;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  //  ************************************ ************************************ ************************************ ************************************ ************************************

  // bulk upload functions ******************************************************************************************************************************

  selectExcelFile(event) {
    event.preventDefault()
    let files
    if (event.dataTransfer) {
      files = event.dataTransfer.files
    } else if (event.target) {
      files = event.target.files
    }
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.readyState === 2) {
        this.bulkUploadData.file = event.target.files[0]
        this.bulkUploadData.name = event.target.files[0].name
      }
    }
    reader.readAsDataURL(files[0])
  }

  bulkUpload() {
    if (this.bulkUploadData.file) {
      this.apiCall = true
      this.shipcatepro.bulkUploadPackage(this.bulkUploadData.file).subscribe((res: any) => {
        this.bulkUploadData.name = '';
        this.bulkUploadData.file = null;
        this.toast.success(`${res.message}`, '', {
          timeOut: 10000,
        });
        this.apiCall = false;
        this.router.navigateByUrl(`/shipcartepro/tabs/intelliBox/intelliTabs/packagingList`)
      }, error => {
        if (error['status'] === 401) {
          localStorage.clear();
          this.router.navigate(['/signin'])
        }
        this.bulkUploadData.name = '';
        this.bulkUploadData.file = null;
        this.apiCall = false;
        this.toast.error(error.error.message ? error.error.message : (error.error.Message ? error.error.Message : 'Something Went Wrong'), '', {
          timeOut: 10000,
        });
      })
    }
  }

  // ************************************************************************************************************************************************************************************************************************************************************


  downLoadSampleData(){
    let arrayForDataCsv=[];
    let sampleData=[
      {
     packageName:'Sample package 1',
     packageAbvr:'Description 1',
     shippingMode:'Courier',
     packagingName:'Boxed',
     packageUnitCost:'100',
     unitMode:'imperial',
     weight:'10',
     empWeight:'5',
     length:'10',
     innerLength:'2',
     width:'10',
     innerWidth:'2',
     height:'10',
     innerHeight:'2'
    },
    {
     packageName:'Sample package 2',
     packageAbvr:'Description 2',
     shippingMode:'Less than Truckload (LTL)',
     packagingName:'Boxed',
     packageUnitCost:'100',
     unitMode:'imperial',
     weight:'10',
     empWeight:'5',
     length:'10',
     innerLength:'2',
     width:'10',
     innerWidth:'2',
     height:'10',
     innerHeight:'2',
    },
    {
      packageName:'Sample package 3',
      packageAbvr:'Description 3',
      shippingMode:'Courier',
      packagingName:'Letter',
      packageUnitCost:'100',
      unitMode:'metric',
      weight:'10',
      empWeight:'5',
      length:'10',
      innerLength:'2',
      width:'10',
      innerWidth:'2',
      height:'10',
      innerHeight:'2',
     }]
    let headerRow=['package_name', 'package_aabvr','shipment_mode','package_type', 'package_cost', 'unit','weight', 'empty_weight', 'length', 'inner_length', 'width','inner_width', 'height','inner_height'];

        sampleData.map((data)=>{
          let obj={};
          obj['package_name']=data.packageName
          obj['package_aabvr']=data.packageAbvr
          obj['shipment_mode']=data.shippingMode
          obj['package_type']=data.packagingName
          obj['package_cost']=data.packageUnitCost
          obj['unit']=data.unitMode
          obj['weight']=data.weight
          obj['empty_weight']=data.empWeight
          obj['length']=data.length
          obj['inner_length']=data.innerLength
          obj['width']=data.width
          obj['inner_width']=data.innerWidth
          obj['height']=data.height
          obj['inner_height']=data.innerHeight
          arrayForDataCsv.push(obj);
        })
        this.shipcatepro.downloadFile(arrayForDataCsv,headerRow,'Sample_Package');
  }


  // below section is to give marginTop in ui when error displays in some sections ************************************ ************************************ ************************************ ************************************ **************************

  giveMarginTopToWidth() {
    if (
      (
        (this.packageForm.get('innerLength').errors?.required || this.packageForm.get('innerLength').errors?.min || this.packageForm.get('innerLength').errors?.pattern) &&
        (this.packageForm.get('innerLength')?.touched || this.submitClicked)
      ) &&
      (
        (this.packageForm.get('length').errors?.required || this.packageForm.get('length').errors?.min || this.packageForm.get('length').errors?.pattern) &&
        (this.packageForm.get('length')?.touched || this.submitClicked))
    ) {
      return false;
    }
    else if (
      (
        (this.packageForm.get('innerLength').errors?.required || this.packageForm.get('innerLength').errors?.min || this.packageForm.get('innerLength').errors?.pattern) &&
        (this.packageForm.get('innerLength')?.touched || this.submitClicked)
      ) &&
      (this.packageForm.get('length').status == 'INVALID' &&
        (!this.packageForm.get('length')?.touched || this.submitClicked)
      )) {
      return true;
    }
    else if (
      (
        (this.packageForm.get('innerLength').errors?.required || this.packageForm.get('innerLength').errors?.min || this.packageForm.get('innerLength').errors?.pattern) &&
        (this.packageForm.get('innerLength')?.touched || this.submitClicked)
      ) &&
      (this.packageForm.get('length').status == 'VALID' &&
        (this.packageForm.get('length')?.touched || this.submitClicked)
      )) {
      return true;
    }
  }

  giveMarginTopToInnerWidth() {
    if (
      (
        (this.packageForm.get('length').errors?.required || this.packageForm.get('length').errors?.min || this.packageForm.get('length').errors?.pattern) &&
        (this.packageForm.get('length')?.touched || this.submitClicked)
      ) &&
      (
        (this.packageForm.get('innerLength').errors?.required || this.packageForm.get('innerLength').errors?.min || this.packageForm.get('innerLength').errors?.pattern)
        && (this.packageForm.get('innerLength')?.touched || this.submitClicked))
    ) {
      return false;
    }
    else if (
      (
        (this.packageForm.get('length').errors?.required || this.packageForm.get('length').errors?.min || this.packageForm.get('length').errors?.pattern) &&
        (this.packageForm.get('length')?.touched || this.submitClicked)) &&
      (this.packageForm.get('innerLength').status == 'INVALID' &&
        (!this.packageForm.get('innerLength')?.touched || this.submitClicked))) {
      return true;
    }
    else if (
      (
        (this.packageForm.get('length').errors?.required || this.packageForm.get('length').errors?.min || this.packageForm.get('length').errors?.pattern) &&
        (this.packageForm.get('length')?.touched || this.submitClicked)) &&
      (this.packageForm.get('innerLength').status == 'VALID' &&
        (this.packageForm.get('innerLength')?.touched || this.submitClicked))) {
      return true;
    }
  }

  giveMarginTopToHeight() {
    if ((
      (this.packageForm.get('innerWidth').errors?.required || this.packageForm.get('innerWidth').errors?.min || this.packageForm.get('innerWidth').errors?.pattern) &&
      (this.packageForm.get('innerWidth')?.touched || this.submitClicked)
    ) &&
      (
        (this.packageForm.get('width').errors?.required || this.packageForm.get('width').errors?.min || this.packageForm.get('width').errors?.pattern) &&
        (this.packageForm.get('width')?.touched || this.submitClicked)
      )) {
      return false;
    }
    else if (
      (
        (this.packageForm.get('innerWidth').errors?.required || this.packageForm.get('innerWidth').errors?.min || this.packageForm.get('innerWidth').errors?.pattern) &&
        (this.packageForm.get('innerWidth')?.touched || this.submitClicked)
      ) &&
      (this.packageForm.get('width').status == 'INVALID' && (!this.packageForm.get('width')?.touched || this.submitClicked))) {
      return true;
    }
    else if (
      (
        (this.packageForm.get('innerWidth').errors?.required || this.packageForm.get('innerWidth').errors?.min || this.packageForm.get('innerWidth').errors?.pattern) &&
        (this.packageForm.get('innerWidth')?.touched || this.submitClicked)
      ) &&
      (this.packageForm.get('width').status == 'VALID' &&
        (this.packageForm.get('width')?.touched || this.submitClicked))) {
      return true;
    }
  }

  giveMarginTopToInnerHeight() {
    if (
      (
        (this.packageForm.get('width').errors?.required || this.packageForm.get('width').errors?.min || this.packageForm.get('width').errors?.pattern) &&
        (this.packageForm.get('width')?.touched || this.submitClicked)
      ) &&
      ((this.packageForm.get('innerWidth').errors?.required || this.packageForm.get('innerWidth').errors?.min || this.packageForm.get('innerWidth').errors?.pattern) &&
        (this.packageForm.get('innerWidth')?.touched || this.submitClicked))) {
      return false;
    }
    else if (
      (
        (this.packageForm.get('width').errors?.required || this.packageForm.get('width').errors?.min || this.packageForm.get('width').errors?.pattern) &&
        (this.packageForm.get('width')?.touched || this.submitClicked)
      ) &&
      (this.packageForm.get('innerWidth').status == 'INVALID' &&
        (!this.packageForm.get('innerWidth')?.touched || this.submitClicked))) {
      return true;
    }
    else if (
      (
        (this.packageForm.get('width').errors?.required || this.packageForm.get('width').errors?.min || this.packageForm.get('width').errors?.pattern) &&
        (this.packageForm.get('width')?.touched || this.submitClicked)
      ) &&
      (this.packageForm.get('innerWidth').status == 'VALID' && (this.packageForm.get('innerWidth')?.touched || this.submitClicked))) {
      return true;
    }
  }

  ngOnDestroy(): void {
    this.modalService.dismissAll();
  }


}
