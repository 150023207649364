import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from './../../../../services/common.service';
import { AgmMap } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { Router } from '@angular/router';
import { DistanceCalService } from 'src/app/services/Quick-action/distance-cal.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-calculate-distance',
  templateUrl: './calculate-distance.component.html',
  styleUrls: ['./calculate-distance.component.css'],
})
export class CalculateDistanceComponent implements OnInit {
  origin: any;
  distanceInMiles: any;
  destination: any;
  distancecal: any;
  durationcal: any;
  fromorigin: any;
  todest: any;
  latlng = [];
  addForm: FormGroup
  calculated: boolean;
  showerror: boolean;
  clrData: any;
  datafromServiceFile: any;
  showShipperWarningAdd: boolean;
  options = {
    types: [],
    componentRestrictions: { country: 'UA' },
  };
  @ViewChild('AgmMap')
  public agmMap: AgmMap
  @ViewChild('addressInput') addressInput: ElementRef;
  @ViewChild('addressInput1') addressInput1: ElementRef;
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    public common: CommonService,
    private router: Router,
    private distanceService: DistanceCalService,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    this.showShipperWarningAdd = false
    // this.getDistance();
    this.calculated = false;
    this.showerror = false;
    this.distancecal = '';
    this.durationcal = '';
    this.fromorigin = '';
    this.todest = '';
    this.addForm = this.fb.group({
      origin: [''],
      destination: ['']
    })
    // this.latlng[0] = [43.849902, -79.393173]; //set to shipcarte office for now.
    // this.origin = { lat: 43.849902, lng: -79.393173 };
    // this.destination = { lat: 43.849902, lng: -79.393173 };
    this.reset();
    this.clearval();
  }
  checkDataIsPresentInServiceFileOrNOt() {
    this.datafromServiceFile = this.distanceService.getDistanceCalculatorData();
    this.clrData = this.datafromServiceFile
    // console.log(this.datafromServiceFile)
    if (this.datafromServiceFile.origin && this.datafromServiceFile.destination) {
      // console.log("true")
      // this.addressInput.nativeElement.value=this.datafromServiceFile.origin.fullAddress;
      this.addForm.patchValue({
        origin: this.datafromServiceFile.origin.fullAddress,
        destination: this.datafromServiceFile.destination.fullAddress
      })
      this.origin = this.datafromServiceFile.origin.cor
      this.destination = this.datafromServiceFile.destination.cor
      this.originChange(this.datafromServiceFile.origin.mainObj, 'main', this.datafromServiceFile.origin.fullAddress);
      this.destinationchange(this.datafromServiceFile.destination.mainObj, 'main', this.datafromServiceFile.destination.fullAddress);
    } else {
      console.log('false')
    }
  }
  originChange(e, mode?, fulladrr?) {
    console.log(e)
    this.showerror = false
    var latitude = e.geometry.location.lat();
    var longitude = e.geometry.location.lng();
    this.origin = { lat: latitude, lng: longitude };
    if (!this.latlng.length) {
      this.latlng.push([latitude, longitude]);
    } else {
      this.latlng[0] = [latitude, longitude];
    }
    //storing data into service to refill
    let fullAddress = ''
    if (mode == 'main') {
      fullAddress = fulladrr
    } else {
      fullAddress = this.addressInput.nativeElement.value;
    }
    console.log(fullAddress)
    let obj = {
      cor: this.origin,
      fullAddress: fullAddress,
      mainObj: e
    }
    this.distanceService.setDistanceCalcualtorData(obj, 'origin')

  }

  destinationchange(e, mode?, fulladdrr?) {
    this.showShipperWarningAdd = false
    var latitude = e.geometry.location.lat();
    var longitude = e.geometry.location.lng();
    this.destination = { lat: latitude, lng: longitude };
    if (!this.latlng.length) {
      this.latlng[0] = [];
      this.latlng[1] = [latitude, longitude];
    } else if (this.latlng.length >= 1) {
      this.latlng[1] = [latitude, longitude];
    }

    // console.log(this.latlng,'sfdshdj');
    var origin1 = new google.maps.LatLng(
      this.origin['lat'],
      this.origin['lng']
    );
    let fulladd = '';
    if (mode == 'main') {
      fulladd = fulladdrr
    } else {
      fulladd = this.addressInput1.nativeElement.value
    }
    let obj = {
      cor: this.destination,
      fullAddress: fulladd,
      mainObj: e
    }
    this.distanceService.setDistanceCalcualtorData(obj, 'dest')
    // console.log(origin1 ,"origin")
    // var origin2 = 'Greenwich, England';
    // var destinationA = 'Stockholm, Sweden';
    var destinationB = new google.maps.LatLng(
      this.destination['lat'],
      this.destination['lng']
    );

    var service = new google.maps.DistanceMatrixService();
    console.log(service, 'sdhs')
    service.getDistanceMatrix(
      {
        origins: [origin1],
        destinations: [destinationB],
        travelMode: google.maps.TravelMode.DRIVING,
        unitSystem: google.maps.UnitSystem.METRIC,
        avoidHighways: false,
        avoidTolls: false,
      },
      this.callback.bind(this)
    );
  }
  callback(response, status) {

    console.log(response)
    var self = this;
    if (status == 'OK') {
      var origins = response.originAddresses;
      var destinations = response.destinationAddresses;
      var element = ''
      var distance = ''
      var duration = ''
      var from = ''
      var to = ''
      for (var i = 0; i < origins.length; i++) {
        var results = response.rows[i].elements;
        for (var j = 0; j < results.length; j++) {
          element = results[j];
          distance = element['distance']['text'];
          duration = element['duration']['text'];
          from = origins[i];
          to = destinations[j];
        }
      }
    }
    self.distancecal = distance
    self.durationcal = duration
    self.fromorigin = from
    self.todest = to

  }
  calculateDistance() {

    if (!this.distancecal && !this.origin && !this.durationcal) {
      this.showerror = true
    }
    if (!this.distancecal && !this.destination && !this.durationcal) {
      this.showShipperWarningAdd = true
    }
    if (this.origin && this.destination) {
      this.calculated = true;

      // parseInt(this.distancecal.replace(/,/g, ''), 10);
      let noCommaString = this.distancecal.replace(/,/g, '');
      this.distanceInMiles = parseInt(noCommaString, 10) * 0.621371;

    }

    this.checkDataIsPresentInServiceFileOrNOt()
  }




  reset() {
    // Reset HTML input element
    if (this.addressInput) {
      this.addressInput.nativeElement.value = '';
    }

    this.origin = '';
    this.destination = '';
    this.distancecal = '';
    this.durationcal = '';
    this.distanceInMiles = '';
  }
  clearval() {
    this.origin = ''
    this.destination = ''
    this.distanceInMiles = '';
    // console.log(this.origin,this.destination)
  }
  back() {
    this.calculated = false;
    this.origin = ''
    this.destination = ''
    this.distancecal = ''
    this.durationcal = ''
    this.distanceInMiles = '';
    this.checkDataIsPresentInServiceFileOrNOt();
  }

  closeModal() {
    this.distanceService.setDistanceCalcualtorData('', 'origin')
    this.activeModal.close();
  }


}
