<!DOCTYPE html>
<html>
<head>
	


</head>
<body>	
	
    <form [formGroup]="varifyEmail" name="varifyEmail" (ngSubmit)="onSubmit()" class="p-0 bg-white verify-email-form" >
        <div class="otp_popup">
            <label for="otpEmail" class="float-left">Email OTP</label>
            <input type="email" formControlName="otpEmail" aria-describedby="emailHelp" placeholder="Enter Your email OTP" 
            [ngClass]="varifyEmail.controls['otpEmail'].touched || varifyEmail.controls['otpEmail'].dirty ? 'is-invalid' : 'is-valid'"
            required />
            <div class="password-label" *ngIf="(varifyEmail.controls['otpEmail'].invalid) ? 'is-invalid1' : ''">
              <label class="col-6 p-0" [ngClass]="varifyEmail.controls['otpEmail'].hasError('required')   ? 'text-danger1' : 'd-none'">
                <i style="float: left;" class="material-icons">{{ varifyEmail.controls['otpEmail'].hasError('required')  ? 'clear' :
                  'done' }}</i>
                <small class="error-align">OTP is required</small>
                <!-- <small *ngIf="f.userName.errors.exists" class="mt-3">Username is already exists</small> -->
              </label>
             
            </div> 
              <div class="orange-text" *ngIf="!displayErrorMessage">
                {{errorMessage}}
              </div>
            <div id="emailHelp" class="mb-3" >
                <button type="submit" [disabled]="otpsubmit" class="resend float-right ">submit</button>
                <!-- <a href="#" class="float-left">Resend</a>  -->
            </div>
            
            
        </div>
            
        <br>
       
      
            
            <!-- <div class="form-text text-center">
                <a href="#" class="">Resend</a> </div>
            </div>
            <div class="form-text text-center">
                <a href="#" class="resend">Close</a>
            </div> -->
      
    </form>

 
    <button type="button" class="btn orange text-white m-auto col-3 d-block mt-3" #closeBtn data-dismiss="modal" (click)="closeModal()">Close</button>          
	 

</body>
</html>