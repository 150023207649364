<div class="modal-header">
    <h4 class="modal-title" style="    font-size: 20px !important;
    font-family: 'Poppins' !important;
    font-weight: 500 !important; margin: 0;">View POD</h4>
    <button type="button" class="close" (click)="closeModal()">
      <span aria-hidden="true"><img src="assets/img/shipment/fi-rr-cross-small.svg" alt=""></span>
    </button>
  </div>
  <div class="modal-body">
    <!-- Display the PDF content here -->
    <iframe [src]="sanitizedPdfContent"  width="100%" height="800px" frameborder="0"></iframe>
  
    <!-- Add a button to trigger the download -->
    <!-- <button type="button" class="btn btn-primary" (click)="downloadFile()">Download</button> -->
  </div>