

        <div class="save_fab">
            <div class="row" style="margin-top: 20px;">
                <div class="col-8">
                    <h5>Saving to Favorites</h5>
                </div>
                <div class="close_btn">
                    <img src="assets/img/shipment/fi-rr-cross-small.svg" alt="" (click)="closeModal()">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <form>
                        <div class="form-group">
                            <label style="color: black !important;; font-size: 16px !important; font-weight: 500 !important; font-family: poppins, sans-serif !important; color: #354052;">Favorite order name</label>
                            <input type="text" (change)="update($event.target.value)" placeholder="Enter Favorite order name" style="height: 30px !important;width: 100%; border: 1px solid #CED0DA; padding-left: 5px !important;font-weight: 400;font-family: poppins,sans-serif; ">
                        </div>
                    </form>
                </div>
            </div>
            <div class="row" style="background-color: white;">
                <div class="col-12 text-end save_fab_btn">
                <button style="border: none !important; background-color: white !important;font-size: 14px; font-weight: 500;font-family: poppins,sans-serif;color: #354052; " (click)="closeModal()">Cancel</button>
                <button   style="border: 1px solid #FC9929; background-color: #FC9929;font-size: 14px; margin-left: 8px; padding: 3px 18px; color: white; font-weight: 500;font-family: poppins,sans-serif;" (click)="saveToFavorite()">Add</button>
                </div>
            </div>
        </div>
