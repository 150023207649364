
    <div style="top: 70px;position: absolute;padding: 20px;">
        <img class="d-block m-auto" src="assets/img/payment/popup_icon.svg"  width="15%">
        <h3 class="text-center mt-3">This is to confirm all information inputed is correct</h3>
        
        <br>
        <div class="text-right">
            <button type="button" class="btn faint-grey col-3" style="box-shadow: none;" (click)="close()">Cancle</button>
            &nbsp;&nbsp; <button type="button" class="btn orange text-white col-3" style="box-shadow: none;" (click)="confirmPopup()">Confirm</button>
        </div>
    </div> 
<hr>
  
