import { Injectable } from '@angular/core';
import { HttpClient,HttpParams,HttpHeaders} from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { environment } from './../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {
  public documentID$ = new BehaviorSubject('');
  constructor(public http:HttpClient) { }

  
  getInvoiceListing(page,perPage,url){
    const myheader = new HttpHeaders().set('Content-Type', 'application/json');
      return this.http.get(environment.apiPath+ "/api/invoices/getAll/"+page+"/"+perPage+url ,{ withCredentials: true,
        headers: myheader
        }).pipe(map(response => {
        return response;
      },error=>{
        return error;
      }));
  }


  getInvoiceDetails(orderId){  
// http://localhost:9090/shipcarte/api/invoices/getById?orderId=c76e3fcd-60f3-411c-ac7c-473f4f83ef7e 
    return this.http.get(environment.apiPath + "/api/invoices/getById?orderId="+orderId, { withCredentials: true });  
  }

  uploadInvoice(data){
    return this.http.post(environment.apiPath + "/api/invoices/upload" ,data ,{withCredentials: true})
  }
  getQuoteDetailofOrder(quoteId){
    return this.http.get(environment.apiPath+'/api/invoices/getQuotedChargeByQuoteId?quoteId='+quoteId,{withCredentials:true})
  }
}


