import { Component, OnInit } from '@angular/core';
import { environment } from './../../environments/environment';
@Component({
  selector: 'app-footer-block',
  templateUrl: './footer-block.component.html',
  styleUrls: ['./footer-block.component.css']
})
export class FooterBlockComponent implements OnInit {
  cYear:any;
  constructor() { }

  ngOnInit(): void {
    this.cYear =  environment.Cyear;
  }

}
