<!-- <div class="wrapper">
  <div class="row">
    <div class="col-1 " [ngClass]="sidebarToggle ? 'sidebarCollapse' :'sidebarNotCollapsed'">
      <app-leftsidebar></app-leftsidebar>
    </div>

    <div class="col-11"> -->
        <div class="packageListSection">
            <div class="row m-0 pt-3 " style="border: 1px solid #c4cdd5;opacity: 1;background-color: #f5f6fa;">
              <div class="col-12  p-0 pb-2 marginLeftHeadings paddingLeftHeaderSection">
                <div class="flexHeadingAndButton">
                  <div class="main-heading d-inline">
                    <a style="color: #354052; text-decoration: none; cursor: pointer;"
                      [routerLink]="['/shipcartepro/intellibox/packageList']">
                      <img style="margin-right: 5px;width:17px" src="assets/img/shipment/fi-rr-arrow-small-left.svg" alt="">
                      <span style="margin-left: 5px;"> {{ isEdit ? 'Editing Package' :'Creating New Package Type' }}</span>
                    </a>
                  </div>
                  <div class="uploadButtonBox">
                    <button type="button" style="padding: 0px 7px;" class="btn btn-outline-warning" (click)="open(content)">
                      <span style="margin-right: 6px;"> <i class="fa fa-upload upload m-1" aria-hidden="true"
                          style="font-size: 16px;color:white;"></i></span> <span>Bulk Upload</span></button>
                  </div>
                </div>
              </div>
            </div>
    
            <div class="ml-4 mr-3 mt-4  ">
              <div class="row">
                <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                  <form [formGroup]="packageForm" class="paddingLeft20">
                    <div class="row">
                      <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                        <div class="form-group feildWidth">
                          <label class="control-label" for="packageName">Package Name<span
                              style="color:#F85359">*</span></label>
                          <div class="packageInput">
                            <input id="packageName" formControlName="packageName" type="text" class="form-control"
                              placeholder="Enter Package Name">
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('packageName').errors?.required && (packageForm.get('packageName')?.touched || submitClicked)">
                              * Package name is required
                            </span>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('packageName').errors?.maxlength && (packageForm.get('packageName')?.touched || submitClicked)">
                              * Package name limit exceded
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                        <div class="form-group feildWidth">
                          <label class="control-label" for="packageDesc">Abbreviation<span
                              style="color:#F85359">*</span></label>
                          <div class="packageInput">
                            <input id="packageDesc" formControlName="packageDesc" type="text" class="form-control"
                              placeholder="Enter Package Description">
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('packageDesc').errors?.required && (packageForm.get('packageDesc')?.touched || submitClicked)">
                              * Package description is required
                            </span>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('packageDesc').errors?.maxlength && (packageForm.get('packageDesc')?.touched || submitClicked)">
                              * Package description limit exceded
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                        <div class="form-group feildWidth">
                          <label style="color:black" class="control-label" for="packageType">Package Type<span
                              style="color:#F85359">*</span></label>
                          <div class="packageInput">
                            <span class="rowSelect">
                              <select class="custom-select" id="packageType" formControlName="packageType">
                                <option class="dropdown-item" value=""> Select one </option>
                                <option class="dropdown-item" value="{{packageType.packagingId}}"
                                  *ngFor="let packageType of packageTypeArray"> {{packageType.packagingName}}
                                  ({{packageType.shippingMode}})</option>
                              </select>
                            </span>
                            <span style="display: block;color: #FF0000;margin-top:8px"
                              *ngIf="packageForm.get('packageType').errors?.required && (packageForm.get('packageType')?.touched || submitClicked)">
                              * Package type is required
                            </span>
                            <!-- <span style="color:#FF0000;" *ngIf="formErrors?.productSku">
                            {{formErrors.productSku}}
                          </span> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                        <div class="form-group feildWidth">
                          <label class="control-label" for="packageCost">Package Cost (Per Unit)<span
                              style="color:#F85359">*</span></label>
                          <div class="packageInput">
                            <input id="packageCost" formControlName="packageCost" type="number" class="form-control">
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('packageCost').errors?.required && (packageForm.get('packageCost')?.touched || submitClicked)">
                              * Package cost is required
                            </span>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="(packageForm.get('packageCost').errors?.min || packageForm.get('packageCost').errors?.max)  && (packageForm.get('packageCost')?.touched || submitClicked)">
                              * invalid cost
                            </span>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('packageCost').errors?.pattern && (packageForm.get('packageCost')?.touched || submitClicked) && !packageForm.get('packageCost').errors?.min && !packageForm.get('packageCost').errors?.max">
                              * only 2 decimal places allowed
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-lg-12 paddingAllV-8H-10">
                        <div class="form-group">
                          <input type="radio" value="metric" formControlName="dimentionUnit"> <label class="radioDimention">
                            Metric </label>
                          <input type="radio" value="imperial" formControlName="dimentionUnit"> <label
                            class="radioDimention">
                            Imperial </label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                        <div class="form-group ">
                          <label class="control-label" for="weight">Weight<span style="color:#F85359">*</span></label>
                          <div>
                            <div class="measureMentInput">
                              <input id="weight" class="form-control" formControlName="weight" type="number"
                                placeholder="  0.00">
                              <div class="styleButtons">
                                <span class="spanPlus" (click)="increamentByOne('weight')">
                                  +
                                </span>
                                <span class="spanMinus" (click)="decreamentByOne('weight')">
                                  -
                                </span>
                              </div>
                            </div>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('weight').errors?.required && (packageForm.get('weight')?.touched || submitClicked)">
                              * Weight is required
                            </span>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="(packageForm.get('weight').errors?.min || packageForm.get('weight').errors?.max) && (packageForm.get('weight')?.touched || submitClicked)">
                              * invalid weight
                            </span>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('weight').errors?.pattern && (packageForm.get('weight')?.touched || submitClicked) && !packageForm.get('weight').errors?.min && !packageForm.get('weight').errors?.max">
                              * only 2 decimal places allowed
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                        <div class="form-group ">
                          <label class="control-label" for="emptyWeight">Empty Weight<span
                              style="color:#F85359">*</span></label>
                          <div>
                            <div class="measureMentInput">
                              <input id="emptyWeight" class="form-control" formControlName="emptyWeight" type="number"
                                placeholder="  0.00">
                              <div class="styleButtons">
                                <span class="spanPlus" (click)="increamentByOne('emptyWeight')">
                                  +
                                </span>
                                <span class="spanMinus" (click)="decreamentByOne('emptyWeight')">
                                  -
                                </span>
                              </div>
                            </div>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('emptyWeight').errors?.required && (packageForm.get('emptyWeight')?.touched || submitClicked)">
                              * Empty weight is required
                            </span>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="(packageForm.get('emptyWeight').errors?.min || packageForm.get('emptyWeight').errors?.max)  && (packageForm.get('emptyWeight')?.touched || submitClicked)">
                              * invalid weight
                            </span>
                            <span style="display: block;color: #FF0000;"
                              *ngIf="packageForm.get('emptyWeight').errors?.pattern && (packageForm.get('emptyWeight')?.touched || submitClicked) && !packageForm.get('emptyWeight').errors?.min && !packageForm.get('emptyWeight').errors?.max">
                              * only 2 decimal places allowed
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                        <div class="row">
                          <div class="form-group">
                            <label class="control-label" for="length">Length<span style="color:#F85359">*</span></label>
                            <div>
                              <div class="measureMentInput">
                                <input id="length" class="form-control" formControlName="length" type="number"
                                  placeholder="  0.00">
                                <div class="styleButtons">
                                  <span class="spanPlus" (click)="increamentByOne('length')">
                                    +
                                  </span>
                                  <span class="spanMinus" (click)="decreamentByOne('length')">
                                    -
                                  </span>
                                </div>
                              </div>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('length').errors?.required && (packageForm.get('length')?.touched || submitClicked)">
                                * Length is required
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="(packageForm.get('length').errors?.min || packageForm.get('length').errors?.max) && (packageForm.get('length')?.touched || submitClicked)">
                                * invalid length
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('length').errors?.pattern && (packageForm.get('length')?.touched || submitClicked) && !packageForm.get('length').errors?.min && !packageForm.get('length').errors?.max">
                                * only 2 decimal places allowed
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row" [ngClass]="giveMarginTopToWidth() ? 'marginTopError40px' : ''">
                          <div class="form-group">
                            <label class="control-label" for="width">Width<span style="color:#F85359">*</span></label>
                            <div>
                              <div class="measureMentInput">
                                <input id="width" class="form-control" formControlName="width" type="number"
                                  placeholder="  0.00">
                                <div class="styleButtons">
                                  <span class="spanPlus" (click)="increamentByOne('width')">
                                    +
                                  </span>
                                  <span class="spanMinus" (click)="decreamentByOne('width')">
                                    -
                                  </span>
                                </div>
                              </div>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('width').errors?.required && (packageForm.get('width')?.touched || submitClicked)">
                                * Width is required
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="(packageForm.get('width').errors?.min || packageForm.get('width').errors?.max) && (packageForm.get('width')?.touched || submitClicked)">
                                * invalid width
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('width').errors?.pattern && (packageForm.get('width')?.touched || submitClicked) && !packageForm.get('width').errors?.min && !packageForm.get('width').errors?.max">
                                * only 2 decimal places allowed
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row" [ngClass]="giveMarginTopToHeight() ? 'marginTopError40px' : ''">
                          <div class="form-group">
                            <label class="control-label" for="height">Height<span style="color:#F85359">*</span></label>
                            <div>
                              <div class="measureMentInput">
                                <input id="height" class="form-control" formControlName="height" type="number"
                                  placeholder="  0.00">
                                <div class="styleButtons">
                                  <span class="spanPlus" (click)="increamentByOne('height')">
                                    +
                                  </span>
                                  <span class="spanMinus" (click)="decreamentByOne('height')">
                                    -
                                  </span>
                                </div>
                              </div>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('height').errors?.required && (packageForm.get('height')?.touched || submitClicked)">
                                * Height is required
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="(packageForm.get('height').errors?.min || packageForm.get('height').errors?.max) && (packageForm.get('height')?.touched || submitClicked)">
                                * invalid height
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('height').errors?.pattern && (packageForm.get('height')?.touched || submitClicked) && !packageForm.get('height').errors?.min && !packageForm.get('height').errors?.max">
                                * only 2 decimal places allowed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 col-lg-6 paddingAllV-8H-10">
                        <div class="row">
                          <div class="form-group">
                            <label class="control-label" for="innerLength">Inner Length<span
                                style="color:#F85359">*</span></label>
                            <div>
                              <div class="measureMentInput">
                                <input id="innerLength" class="form-control" formControlName="innerLength" type="number"
                                  placeholder="  0.00">
                                <div class="styleButtons">
                                  <span class="spanPlus" (click)="increamentByOne('innerLength')">
                                    +
                                  </span>
                                  <span class="spanMinus" (click)="decreamentByOne('innerLength')">
                                    -
                                  </span>
                                </div>
                              </div>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('innerLength').errors?.required && (packageForm.get('innerLength')?.touched || submitClicked)">
                                * Inner length is required
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="(packageForm.get('innerLength').errors?.min || packageForm.get('innerLength').errors?.max) && (packageForm.get('innerLength')?.touched || submitClicked)">
                                * invalid length
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('innerLength').errors?.pattern && (packageForm.get('innerLength')?.touched || submitClicked) && !packageForm.get('innerLength').errors?.max && !packageForm.get('innerLength').errors?.min">
                                * only 2 decimal places allowed
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row" [ngClass]="giveMarginTopToInnerWidth() ? 'marginTopError40px' : ''">
                          <div class="form-group">
                            <label class="control-label" for="innerWidth">Inner Width<span
                                style="color:#F85359">*</span></label>
                            <div>
                              <div class="measureMentInput">
                                <input id="innerWidth" class="form-control" formControlName="innerWidth" type="number"
                                  placeholder="  0.00">
                                <div class="styleButtons">
                                  <span class="spanPlus" (click)="increamentByOne('innerWidth')">
                                    +
                                  </span>
                                  <span class="spanMinus" (click)="decreamentByOne('innerWidth')">
                                    -
                                  </span>
                                </div>
                              </div>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('innerWidth').errors?.required && (packageForm.get('innerWidth')?.touched || submitClicked)">
                                * Inner Width is required
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="(packageForm.get('innerWidth').errors?.min || packageForm.get('innerWidth').errors?.max) && (packageForm.get('innerWidth')?.touched || submitClicked)">
                                * invalid width
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('innerWidth').errors?.pattern && (packageForm.get('innerWidth')?.touched || submitClicked) && !packageForm.get('innerWidth').errors?.min && !packageForm.get('innerWidth').errors?.max">
                                * only 2 decimal places allowed
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="row" [ngClass]="giveMarginTopToInnerHeight() ? 'marginTopError40px' : ''">
                          <div class="form-group">
                            <label class="control-label" for="innerHeight">Inner Height<span
                                style="color:#F85359">*</span></label>
                            <div>
                              <div class="measureMentInput">
                                <input id="innerHeight" class="form-control" formControlName="innerHeight" type="number"
                                  placeholder="  0.00">
                                <div class="styleButtons">
                                  <span class="spanPlus" (click)="increamentByOne('innerHeight')">
                                    +
                                  </span>
                                  <span class="spanMinus" (click)="decreamentByOne('innerHeight')">
                                    -
                                  </span>
                                </div>
                              </div>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('innerHeight').errors?.required && (packageForm.get('innerHeight')?.touched || submitClicked)">
                                * Inner Height is required
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="(packageForm.get('innerHeight').errors?.min || packageForm.get('innerHeight').errors?.max) && (packageForm.get('innerHeight')?.touched || submitClicked)">
                                * invalid height
                              </span>
                              <span style="display: block;color: #FF0000;"
                                *ngIf="packageForm.get('innerHeight').errors?.pattern && (packageForm.get('innerHeight')?.touched || submitClicked) && !packageForm.get('innerHeight').errors?.min && !packageForm.get('innerHeight').errors?.max">
                                * only 2 decimal places allowed
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
    
                  </form>
                </div>
              </div>
              <!-- <table border="1">
                <tr>
                  <th style="padding:10px">FormGroup</th>
                  <th style="padding:10px">Form control (name)</th>
                </tr>
                <tr>
                  <td style="padding: 10px;">
                    touched: {{packageForm.touched}}
                    <br />
                    valid :{{packageForm.valid}}
                    <br />
                    dirty :{{packageForm.dirty}}
                    <br />
                    FormValues :{{packageForm.value | json}}
                    <br />
                  </td>
                  <td style="padding: 10px;">
                    touched: {{packageForm.get('packageName').touched}}
                    <br />
                    valid :{{packageForm.get('packageName').valid}}
                    <br />
                    dirty :{{packageForm.get('packageName').dirty}}
                    <br />
                    name value :{{packageForm.get('packageName').value}}
                  </td>
                </tr>
              </table> -->
            </div>
    
          </div>
          <div *ngIf="!isEdit" class="bottomFooter" [ngClass]="sidebarToggle ? 'width99Per' :'width92Per'">
            <div class="buttonsFooter">
              <div class="buttonDiv">
                <button type="button" [routerLink]="['/shipcartepro/tabs/intelliBox/intelliTabs/packagingList']"
                  class="btn btn-cancel">Cancel</button>
              </div>
              <div class="buttonDiv">
                <button type="button" (click)="createPackage()" class="btn btn-outline-warning btn-color">Create
                  Package</button>
              </div>
            </div>
    
          </div>
          <div *ngIf="isEdit" class="bottomFooterEdit" [ngClass]="sidebarToggle ? 'width99Per' :'width92Per'">
            <div>
              <div class="buttonDiv removeStartInResponsive">
                <button *ngIf="packageData?.active" (click)="changeStatusOfFlag(false)" type="button"
                  class="btn btn-outline-warning btn-Warn">
                  <i class="fa fa-trash trash m-1" aria-hidden="true" style="font-size: 16px;color:white;"></i>
                  Deactivate
                </button>
                <button *ngIf="!packageData?.active" type="button" (click)="changeStatusOfFlag(true)"
                  class="btn btn-outline-warning btn-Success">
                  <i class="fa fa-plus m-1" aria-hidden="true" style="font-size: 14px;color:white;"></i>
                  Activate
                </button>
              </div>
            </div>
            <div class="buttonsFooter">
              <div class="buttonDiv">
                <button type="button" [routerLink]="['/shipcartepro/tabs/intelliBox/intelliTabs/packagingList']"
                  class="btn btn-cancel">Cancel</button>
              </div>
              <div class="buttonDiv addStartInResponsive">
                <button *ngIf="packageData?.active" (click)="changeStatusOfFlag(false)" type="button"
                  class="btn btn-outline-warning btn-Warn">
                  <i class="fa fa-trash trash m-1" aria-hidden="true" style="font-size: 16px;color:white;"></i>
                  Deactivate
                </button>
                <button *ngIf="!packageData?.active" (click)="changeStatusOfFlag(true)" type="button"
                  class="btn btn-outline-warning btn-Success">
                  <i class="fa fa-plus m-1" aria-hidden="true" style="font-size: 14px;color:white;"></i>
                  Activate
                </button>
              </div>
              <div class="buttonDiv">
                <button type="button" class="btn btn-outline-warning btn-color" (click)="updatePackage()">Save
                  Edits</button>
              </div>
            </div>
    
          </div>
        <!-- </div>
      </div> -->
    
      <!-- while Api Call ***************************************************************************************************************************************  -->
    
      <app-spinner></app-spinner>    
      <!-- ***************************************************************************************************************************************  -->
    
      <!-- modal for bulk upload *************************************************************************************************************************************** -->
    
      <ng-template #content let-modal>
        <div class="save_fab scrollView">
          <div class="row" style="margin-top: 20px;">
            <div class="col-8">
              <h5 style="font-size: 24px !important;font-weight: 500 !important;font-family: poppins,sans-serif !important;color: #354052  !important;">Bulk Upload Package</h5>
            </div>
            <div class="close_btn">
              <img src="assets/img/shipment/fi-rr-cross-small.svg" alt="" (click)="modal.dismiss('Cross click')">
            </div>
          </div>
          <div class="row mb-0">
            <div class="col-12">
              <div class="form-group browseFile">
                <input type="text" disabled="true" [value]="bulkUploadData.name"
                  style="height: 30px !important;width: 100%; border: 1px solid #CED0DA; padding-left: 5px !important;font-weight: 400;font-family: poppins,sans-serif; ">
                <button (click)="fileInput1.click()" class="dialogButtonColored" style="height: 32px;">Browse</button>
                <input type="file"
                  accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  (change)="selectExcelFile($event)" #fileInput1 style="display: none;">
              </div>
            </div>
            <div class="col-12 text-end">
              <button
                style="border: none !important; background-color: white !important;font-size: 14px; font-weight: 500;font-family: poppins,sans-serif;color: #354052; "
                (click)="modal.dismiss('Cross click')">Cancel</button>
              <button [ngClass]="bulkUploadData.file ? 'cursorPointer' : 'cursorNotAllowed' "
                [disabled]="bulkUploadData.file ? false : true" class="dialogButtonColored"
                (click)="modal.close('Save click');">Upload</button>
            </div>
          </div>
          <div class="row" style="margin-top: 20px;">
            <div class="col-12">
              <h4 style="font-size: 16px !important;font-family: poppins,sans-serif !important;color: #354052  !important;"> GuideLines for bulk upload :-</h4>
              <ul style="font-family: poppins,sans-serif !important;color: #354052  !important;">
                <li>Sample file for reference <i class="fa fa-download download m-1" aria-hidden="true"
                    style="font-size: 14px;color:black;cursor: pointer;color: #2877C6" (click)="downLoadSampleData()"> </i></li>
                <li>All feilds are mandatory </li>
                <li> <span class="fontWeight600">package_name : </span> Name of package</li>
                <li> <span class="fontWeight600">package_aabvr :</span> Description of package </li>
                <li> <span class="fontWeight600">shipment_mode :</span> Select list of shipment modes
                  <ul>
                    <li>Courier</li>
                    <li>Less than Truckload (LTL)</li>
                  </ul>
                </li>
                <li> <span class="fontWeight600">package_type :</span> Select list of package types
                  <ul >
                    <li class="innerLi">Courier
                      <ul>
                        <li >Letter</li>
                        <li >Parcel</li>
                        <li >Boxed</li>
                      </ul>
                    </li>
                    <li class="innerLi">LTL
                      <ul >
                        <li>Custom Package</li>
                        <li>Pallets</li>
                        <li>Crated</li>
                        <li>Drums or Barrels</li>
                        <li >Boxed</li>
                      </ul>
                    </li>
    
                  </ul>
                </li>
                <li> <span class="fontWeight600">package_cost :</span> Package cost (should be greater than 0) </li>
                <li> <span class="fontWeight600">unit :</span> metric/imperial </li>
                <li> <span class="fontWeight600">weight :</span> should be greater than 0 </li>
                <li> <span class="fontWeight600">empty_weight :</span> should be greater than 0 and less than weight </li>
                <li> <span class="fontWeight600">length :</span> should be greater than 0 </li>
                <li> <span class="fontWeight600">inner_length :</span> should be greater than 0 and less than length </li>
                <li> <span class="fontWeight600">width :</span> should be greater than 0 </li>
                <li> <span class="fontWeight600">inner_width :</span> should be greater than 0 and less than width </li>
                <li> <span class="fontWeight600">height :</span> should be greater than 0 </li>
                <li> <span class="fontWeight600">inner_height :</span> should be greater than 0 and less than height </li>
              </ul>
            </div>
          </div>
          <!-- <div class="row" style="background-color: white;">
            <div class="col-12 text-end save_fab_btn">
              <button
                style="border: none !important; background-color: white !important;font-size: 14px; font-weight: 500;font-family: poppins,sans-serif;color: #354052; "
                (click)="modal.dismiss('Cross click')">Cancel</button>
              <button
              [ngClass]="bulkUploadData.file ? 'cursorPointer' : 'cursorNotAllowed' "
              [disabled]="bulkUploadData.file ? false : true"
              class="dialogButtonColored"
              (click)="modal.close('Save click');">Upload</button>
            </div>
          </div> -->
        </div>
      </ng-template>
    
      <!-- ********************************************************************************************************************************************************************* -->
    