<!-- <div class="container">
    <div class="row">
        <div class="col-1">
            <app-leftsidebar></app-leftsidebar>
        </div>

        <div class="col-11"> -->
            <div class="invoice_section ml-5 mr-5 pl-3">
                
                <div class="row mt-5 pt-3 pl-4">
                    <div class="col-10">
                        <div class="main-heading d-inline"><img src="assets/img/invoice/invoice.svg"> Invoices
                        </div>
                    </div>
                    <div class="col-2  float-right">
                        <label class="d-inline" style="top: 84px;left: 1218px;width: 27px;height: 15px;text-align: left;font: normal">Show
                        </label>
                        <select class="custom-select col-9 d-inline ml-2" (change)=" perPageItem($event)">
                                    <option  class="dropdown-item" value="10">10 items per page</option>
                                    <option  class="dropdown-item" value="25">25 items per page</option>
                                    <option  class="dropdown-item" value="50">50 items per page</option>
                                    <option  class="dropdown-item" value="100">100 items per page</option>
                                </select>
                    </div>
                </div>
                <div class="invoice_listing pl-4">
                    <div class="invoice_listing_inner pl-2 pr-4">
                        <form [formGroup]="dateFilterForm">

                        <div class="col-12 applay_section pt-3">
                            <div class="row">
                               
                                <!-- <div class="form-group hidden">
                                    <div class="input-group">
                                      <input name="datepicker"
                                      type="hidden"
                                             class="form-control "
                                             ngbDatepicker
                                             #datepicker="ngbDatepicker"
                                             (dateSelect)="onDateSelection($event)"
                                             [displayMonths]="1"
                                             [dayTemplate]="t"
                                             outsideDays="hidden"
                                             [startDate]="fromDate!"
                                             tabindex="-1">
                                      <ng-template #t let-date let-focused="focused">
                                        <span class="custom-day"
                                              [class.focused]="focused"
                                              [class.range]="isRange(date)"
                                              [class.faded]="isHovered(date) || isInside(date)"
                                              (mouseenter)="hoveredDate = date"
                                              (mouseleave)="hoveredDate = null">
                                          {{ date.day }}
                                        </span>
                                      </ng-template>
                                    </div>
                                  </div> -->
                                 
                                <div class="col-2 pt-3 start-date-box">

                                   
                                    <!-- <input class="form-control" placeholder="Start" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <div class="input-group col-12">
                                        <input class="form-control" placeholder="Start" onfocus="(this.type='date')" onblur="(this.type='text')"
                                        name="dp"  formControlName="startDate"  >
                                        <!-- <input #dpFromDate
                                               class="form-control" placeholder="Start"
                                               name="dpFromDate"
                                               [value]="formatter.format(fromDate)"
                                               (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
                                        <div class="input-group-append">
                                          <button class="btn  calendar" (click)="datepicker.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                                        </div> -->
                                      </div>
                                </div>
                                <div class="col-2 pt-3 end-date-box">
                                    
                                    <!-- <input class="form-control" class="form-control" placeholder="End" onfocus="(this.type='date')" onblur="(this.type='text')" style="color: #ABAFB3;font-size: 15px;" /> -->
                                    <div class="input-group col-12">
                                       
                                                 <input class="form-control" placeholder="End" onfocus="(this.type='date')" onblur="(this.type='text')"
                                                 name="dp"  formControlName="endDate"  >
                                        <!-- <input #dpToDate
                                               class="form-control" placeholder="End"
                                               name="dpToDate"
                                               [value]="formatter.format(toDate)"
                                               (input)="toDate = validateInput(toDate, dpToDate.value)">
                                        <div class="input-group-append">
                                          <button class="btn calendar" (click)="datepicker.toggle()" type="button"><i class="fa fa-calendar" aria-hidden="true"></i></button>
                                        </div> -->
                                      </div>
                                </div>
                                <div class="col-2 pt-3 serachbar-box">
                                    <input class="form-control"  type="text" [ngModelOptions]="{standalone: true}" [(ngModel)]="searchInvoice" placeholder="Search Invoice #" />
                                </div>
                                <div class="col-2 pt-3 serachbar-box">
                                    <input class="form-control"  type="text" [ngModelOptions]="{standalone: true}"  [(ngModel)]="searchWorkOrder" placeholder="Work Order #:" />
                                </div>
                                <div class="col-2 pt-3 filter">
                                    <select class="custom-select1" (change)="filterByValue($event)">
                                            <option selected disabled>Status</option>
                                            <option value="CRS">Courier</option>
                                            <option value="LTL">LTL</option>
                                            <option value="VLTL">Volume LTL</option>
                                            <option value="FTL">Full Load</option>
                                            <!-- <option>PO No.</option>
                                            <option>In Transist</option>
                                            <option>Delivered</option> -->
                                          
                                        </select>
                                </div>
                                <div class="col-1 pt-3 text-center apply-btn-box">
                                    <button type="button" class="btn btn-outline-warning"  (click)="applyFilter()">Apply</button>
                                </div>

                            </div>
                        </div>
                        </form>

                        <div class="row invoices_history_table mb-2">
                            <div>
                                <!-- <div *ngIf="!displayErrorMessage">
                                        {{errorMessage}} <button class="btn orange text-white" (click)="redirectTo()">"I'LL CREATE ONE"!</button>
                                    </div> -->
                                <table class="table table-responsive">
                                    <thead>
                                        <tr>
                                            <th>
                                                <span class="down">Invoice No.</span>
                                            </th>
                                            <th>
                                                <span class="down">Order QTY</span>
                                            </th>
                                            <th>
                                                <span class="down">Date</span>
                                            </th>
                                            <th>
                                                <span class="down">Status</span>
                                            </th>
                                            <th> 
                                                <span class="down">Invoice Total</span>
                                            </th>
                                            <th><span class="down">Currency</span>
                                            </th>
                                            <th>
                                                <span class="down">Balance Remaining</span>
                                            </th>
                                            <th >
                                                <span class="down">Notes</span>
                                            </th>
                                            <th>
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody class="pt-3">
                                        <tr  style="margin-top: 20px;color: #5d4646;" *ngIf="invoiceItems?.length == 0">
                                            <td colspan="9" class=" text-center">No data found!!!</td>  
                                         </tr>
                                        <tr *ngFor="let key of invoiceItems;let i = index ">
                                            <td class="invoice_no" (click)="viewDetails(key,key['status'])">                                                
                                                <!-- {{invoiceItems && key?.invoiceNumber}}  -->
                                                 <!-- {{key['invoiceNumber']}} -->
                                                 {{key['invoiceNumber'] ? key['invoiceNumber']  : '--'}}
                                            </td>
                                            <td>{{key['orderQuantity'] ? key['orderQuantity']  : '--'}}</td>
                                            <td>{{key['invoiceDate'] ? (key['invoiceDate'] | date: 'dd/MM/yyyy')  : '--'}}</td>
                                            <td>
                                                <span [class]="key['status']=='UNPAID' ? 'badge badge-pill badge-danger' : key['status']=='PAID' ? 'badge badge-pill badge-success': 'badge badge-pill badge-secondary'">{{key['status'] ? key['status']  : '--'}}</span>
                                            </td>
                                            <td>{{key['invoiceTotal'] ? key['invoiceTotal']  : '--'}}</td>
                                            <td>{{key['currency'] ? key['currency']  : '--'}}</td>
                                            <td>{{key['remainingBalance'] ? key['remainingBalance']  : '--'}}</td>
                                            <td ><span  placement="bottom"  popoverClass="popover-invoice" [popoverTitle]="popTitle" ngbPopover="{{key['notes'] ? key['notes']  : '--'}}" >{{key['notes'] ? key['notes']  : '--'}}</span></td>
                                            <td class="text-center">
                                                <img src="assets/img/quotes/hor_3_dots.png" class="dropdown-toggle cursor-p" data-toggle="dropdown">
                                                <div class="dropdown-menu invoice-menu" aria-labelledby="dropdownMenuButton" multiple>
                                                    <a class="dropdown-item" (click)="pdfPreview(key)">Print</a>
                                                    <a class="dropdown-item" (click)="pdfPreview(key)">PDF Preview</a>
                                                    <a class="dropdown-item" (click)="viewDetails(key)">View Invoice Details</a>
                                                 
                                                </div>
                                            </td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="row mb-2 pl-2 pb-0">

                            <div class="col-8" style="margin-top: 20px;color: #7b7b7b;">
                                 Showing {{currentPage==0 ? 1 : (currentPage * perPage)+1}} to {{currentPage==0 ? (totalElements < perPage ? totalElements : perPage) : (totalElements <= ((currentPage+1) * perPage) ? totalElements : ( (currentPage+1) * perPage)) }} of {{totalElements}}

                            </div>

                            <div class="col-4 pagination_right">

                                <ngb-pagination #i [collectionSize]="totalElements" [maxSize]="5" [rotate]="true" style="margin-top: 14px;  margin-left: 219px;" [pageSize]="perPage" [(page)]="page" (click)="selectPage(i)">
                                    <ng-template ngbPaginationPrevious style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Prev</ng-template>
                                    <ng-template ngbPaginationNext style="top: 702px;
                                    left: 1323px;
                                    width: 26px;
                                    height: 17px;
                                    text-align: right;
                                    font: normal normal normal 12px/21px Poppins;
                                    letter-spacing: 0px;
                                    color: #151522;
                                    opacity: 1;">Next</ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <!-- <div class="col-12 pt-3 pl-4 footer">
                    <p class="copyright d-inline">© {{cYear}} ShipCarte. All Rights Reserved.</p>
                    <span class="ml-1 mt-1 mb-5 d-inline">
                            <a href="https://www.linkedin.com/company/shipcarte-inc/" target="_blank">
                                <img src="assets/img/quotes/linked-in.svg" width="13px" class="m-1 text-center">
                            </a>
                            <a href="https://www.instagram.com/shipcarte/?hl=en%C2%A0" target="_blank">
                                <img src="assets/img/quotes/instagram.svg" width="13px" class="m-1 text-center">
                            </a>
                            <a href="https://www.youtube.com/channel/UC28WEGzSkupdg6T7xd7yrTw" target="_blank">
                                <img src="assets/img/quotes/youtube.svg" width="17px" class="m-1 text-center">
                            </a>
                            <a href="https://twitter.com/shipcarte?lang=en%C2%A0" target="_blank">
                                <img src="assets/img/quotes/twitter.svg" width="17px" class="m-1 text-center">
                            </a>
                            <a href="https://www.facebook.com/ShipCarte/" target="_blank">
                                <img src="assets/img/quotes/facebook.svg" width="8px" class="m-1 text-center">
                            </a>
                        </span>
                </div> -->
            </div>
        <!-- </div>
    </div>
</div> -->
<ng-template #popTitle>Credit Notes:</ng-template>

<ng-template #invoiceContent>
    <h4 class="mb-0">Credit Notes:</h4>
    <p>{{noteText}}
    </p>
</ng-template>


<app-spinner></app-spinner>