<div class="container" style="top: 10px;position: absolute;height: 98% !important;padding: 0px;">
    <div class="mainDiv" *ngIf="!addressObject" style="margin:0 !important; height: 100%; background: #fff;max-width: 803px !important; border-radius: 18px !important; display: flex;justify-content:space-between;flex-direction: column;" >
    <div class="row" >
        <div style="display:flex; justify-content: space-between;font: normal normal medium 32px Poppins; padding-bottom: 40px;" class="pl-0 pr-0">
            <div style="font-size:32px; font-weight: 500; color: #354052 !important;line-height: 35px">
                Find Zip/Postal codes</div>
            <div style="cursor: pointer;"><span style="font-weight:600 !important; font-size:26px !important;" class="material-icons" (click)="closeModal()">close</span></div>
        </div>
        <div>
            {{addressStatus}}
        </div>
        <form>
            <div class="form-group col-12 p-0">
                <label style="font-size:23px !important; color: #354052 !important;">Zip/Postal Code </label>
                <input style="box-shadow: none !important; padding: 11px 18px !important; width: calc(100% - 36px);" class="form-control" name="zipcode"
                [(ngModel)]="zipcode" placeholder="Enter Zip/Postal Code">
                <div class="red_alert_msg">{{submitted && (zipcode =='' || invalidCode ) ? 'Please Provide valid Zipcode' : ''}}</div>
            </div>
        </form>
    </div>
    <div class="footBoot" style="display: flex; justify-content: space-between;">
        <div style="display: flex;align-items: center;">
            <button (click)="resetField()"
                style="text-transform:capitalize; opacity: 1; color: #2877C6; background: none; border: none;font-size: 19px !important; font-weight: 500 !important; cursor: pointer;"
                type="button">  <img  class="resetIcon"
                 src="assets/img/fi-rr-undo-alt.svg"/> Reset Fields 
             </button>
        </div>
        <div class="text-right d-flex align-items-center">
            <button class="btn " style="box-shadow: none;text-transform: capitalize; font-size: 20px !important; font-weight: 600 !important; color: #354052 !important"
                (click)="closeModal()">Cancel</button>
            &nbsp;&nbsp; <button type="submit" class="btn orange text-white "
                style="box-shadow: none; font-size: 20px !important; font-weight: 600 !important;width: 193px !important; text-transform: unset;
                height: 54px !important;border: 1px solid #0000001A; margin: 0" (click)="getAddresses($event.target.value)">Let's
                find out!</button>
        </div>
    </div>
  </div>

  <div class="container test" *ngIf="addressObject" style="top: 10px;position: absolute;">
    <div class="row mb-0" style="margin: 0px 20px;" >
        <div style="display:flex; justify-content: space-between;font: normal normal medium 32px Poppins; padding-top: 46px;">
            <div
                style="font-size:32px;font: normal normal medium 32px/35px Poppins;padding-left: 0px !important;color: #354052 !important;font-weight: 500; color: #354052 !important;">
                Find Zip/Postal codes</div>
            <div style="cursor: pointer;"><span  style="font-weight:600 !important; font-size:26px !important;" class="material-icons" (click)="closeModal()">close</span></div>
        </div>
        <div
            style="background: #F5F6FA 0% 0% no-repeat padding-box;border: 1px solid #CED0DA; margin-top:39px; padding: 31px 39px; font-weight: 500;">
            <div style="font-size:26px; padding-bottom: 35px;">Result</div>
            <div class="row m-0">
                <div class="text-left p-0 col-5" style="font-size:23px;color: #354052;">Zip Code:</div>
                <div class="text-right p-0 col-7" style="font-size:23px;color: #354052;">{{zipcode}}</div>
                <!-- <div style="border: 1px solid #DDDFE1; width: 100%;margin-top: 30px"></div> -->
            </div>
            <div style="border: 1px solid #DDDFE1; width: 100%;margin: 30px 0"></div>
            <div class="row m-0">
                <div class="text-left p-0 col-5" style="font-size:23px;color: #354052;">City:</div>
                <div class="text-right p-0 col-7" style="font-size:23px;color: #354052;">{{addressObject &&
                    addressObject['data']?.city }}</div>
                <!-- <div style="border: 1px solid #DDDFE1; width: 100%;margin-top: 30px"></div> -->
            </div>
            <div style="border: 1px solid #DDDFE1; width: 100%;margin: 30px 0"></div>
            <div class="row m-0">
                <div class="text-left p-0 col-5" style="font-size:23px;color: #354052;">State/Province:</div>
                <div class="text-right p-0 col-7" style="font-size:23px;color: #354052;">{{addressObject &&
                    addressObject['data']?.state }}</div>
                <!-- <div style="border: 1px solid #DDDFE1; width: 100%;margin-top: 30px"></div> -->
            </div>
            <div style="border: 1px solid #DDDFE1; width: 100%;margin: 30px 0"></div>
            <div class="row m-0">
                <div class="text-left p-0 col-5" style="font-size:23px;color: #354052;">Country:</div>
                <div class="text-right p-0 col-7" style="font-size:23px;color: #354052;">{{addressObject && addressObject['data']?.country == 'CA' ? 'Canada':'United States' }}
                </div>

            </div>
        </div>
        <div class="text-right p-0 d-flex align-items-center justify-content-end" style="margin-top: 40px !important;">
            <button (click)="closeModal()" type="button" class="btn orange-border col-3"
                style="box-shadow: none;text-transform: capitalize; border: none !important; height: 40px !important; font-size: 20px !important; font-weight: 600 !important; color: #354052 !important;">Done</button>
            &nbsp;&nbsp; <button type="button"  class="btn orange text-white" (click)="open_modal();"
                style="box-shadow: none;text-transform: capitalize;font-size: 20px !important; font-weight: 600; height: unset !important; padding: 12px 19px !important;">Get
                Distance</button>
        </div>
    </div>
</div>
</div>



