<div class="row" style=" padding: 10px 10px 0px 10px;">
    <div class="col-12">
        <div class="row" style="font-size: 18px; font-weight: 500; font-family: poppins, sans-serif;">
            <div class="col-6 text-start">Price Summary</div>
            <div class="col-6 text-end" ><img style="cursor: pointer;" src="assets/img/shipment/fi-rr-cross-small.svg" alt="" (click)="closeModal()"></div>
        </div>
        <hr>
        <div class="row" style="font-size: 16px; font-weight: 500; font-family: poppins, sans-serif;">
            <div class="col-6 text-start">
                <img style="width: 80px; height: 80px;" src="{{ priceSummary ? priceSummary['carrierLogo'] : 'assets/img/quotes/vehicles/Courier-Truck_FEDEX-Express.png'}}"width="70%">

            </div>
            <div class="col-6 text-end"style="font-size: 14px; font-weight: 400; font-family: poppins, sans-serif;">
                <div style="margin-bottom: 3px;"> {{shipmentMode}} <img src="assets/img/quotes/shmt/{{shipmentStyleCode}}.svg" width="15%"></div>
                <div>Weight: {{weight}} {{weightUnit | uppercase}} </div>
            </div>
        </div>

        <div class="row" style="font-size: 16px; font-weight: 400; font-family: poppins, sans-serif;">
            <div class="col-4 text-start">Quote No.</div>
            <div class="col-8 text-end" style="font-weight: 500;">{{quoteId}}</div>
        </div>

        <div class="row  mb-2 " style="font-size: 16px; font-weight: 400; font-family: poppins, sans-serif;" *ngFor="let charge of charges; let i = index">
            <div class="col-6">
                {{charge[0]}}

            </div>
            <div class="col-6 text-right"> $ {{charge[1]}}</div>
        </div>

        <div class="row" style="font-size: 16px; font-weight: 400; font-family: poppins, sans-serif; color: orange;">
            <div class="col-6 text-start">Discount</div>
            <div class="col-6 text-end">$ {{(priceSummary && priceSummary['discount']) ? priceSummary['discount'] :'00.00'}}</div>
        </div>
        <div class="row" style="font-size: 16px; font-weight: 400; font-family: poppins, sans-serif;">
          <div class="col-6 text-start">Shipcarte Insurance</div>
          <div class="col-6 text-end">$ {{(priceSummary && priceSummary['scInsurance']) ? priceSummary['scInsurance'] :'00.00'}}</div>
      </div>
        <hr>

        <!-- <div class="row" style="font-size: 16px; font-weight: 500; font-family: poppins, sans-serif;">
            <div class="col-6 text-start">ShipCarte</div>
            <div class="col-6 text-end">$ {{priceSummary ? priceSummary['scCharges']:'00.00'}}</div>
        </div> -->


        <div class="row" style="font-size: 22px; font-weight: 500; font-family: poppins, sans-serif; margin-bottom: 0 !important;">
            <div class="col-4 text-start">Total</div>
            <div class="col-8 text-end">$ {{priceSummary ? priceSummary['totalAmount']:'--'}} CAD</div>
        </div>
    </div>
</div>
