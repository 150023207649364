import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { ActivatedRoute} from '@angular/router';
@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
id:any;
  constructor(public router: Router,public route: ActivatedRoute) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    console.log(this.id)
    // this.quoteId = this.route.snapshot.paramMap.get('quoteId');
  }

}
