<div class="container popContainer">
    <span>
         <h4 style="margin: 0">Adding Customs Information
             <button type="button" class="close float-right " (click)="closeModal()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons">
                    close
                    </span>
                </span>
             </button>
         </h4>
    </span>
    <hr style="margin: 2px">
    <div class="m-2 ">
         <form [formGroup]="customForm" (ngSubmit)="submit()" >
            <div class="form-row">
                <div class="form-group col-md-6">
                    <div class="broker_name">
                        Select Contents Type*
                    </div>
                    
                    <select formControlName="contentsType" class="custom-select" (change)="changeContentsType()" aria-label="Default select example" >
                        <!-- <option selected="true" value="">select Contents Type</option> -->
                        <option *ngFor="let ct of allDropdownValues?.contentType" value="{{ct}}">{{ct}}</option>
                    </select>
                    <!-- <div *ngIf="contactForm.get('firstName').invalid && (contactForm.get('firstName').dirty || contactForm.get('firstName').touched)" [ngClass]="'error'">
                        <div *ngIf="contactForm.get('firstName').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="contactForm.get('firstName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="f.firstName.errors.minlength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="f.firstName.errors.maxlength">
                            Maximum 30 characters.
                        </div>
                    </div> -->
                </div>
                <div class="form-group col-md-6">
                    <div >
                        <div class="broker_name">
                            Other contents Explanation
                        </div>
                        <textarea formControlName="contentsExplanation" style="resize:none" class="form-control contact-input"  name="" rows="6" cols="100"></textarea>
                    </div>
                    <!-- <div *ngIf="contactForm.get('lastName').invalid && (contactForm.get('lastName').dirty || contactForm.get('lastName').touched)" [ngClass]="'error'">
                        <div *ngIf="contactForm.get('lastName').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="contactForm.get('lastName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="f.lastName.errors.minlength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="f.lastName.errors.maxlength">
                            Maximum 30 characters.
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="form-row pt-1">
                <div class="form-group col-md-6">
                    <div class="broker_name">
                        Customs Certified?*
                    </div>
                                <div class="radio-center">
                                    <div class="custom-control custom-radio pl-4 pr-2">
                                        <input id="yes" type="radio" class="custom-control-input" (change)="changecustomsCertified()" checked [value]="true" name="customsCertified"  formControlName="customsCertified" >
                                        <label class="custom-control-label" for="yes">Yes</label>
                                    </div>
                                    <div class="custom-control custom-radio pl-4">
                                        <input autocomplete="disabled" id="no" type="radio" class="custom-control-input" (change)="changecustomsCertified()" [value]="false" name="customsCertified" formControlName="customsCertified" >
                                        <label class="custom-control-label" for="no">No</label>
                                    </div>
                                </div>
                </div>
                <!-- <div class="form-group col-md-4">
                    <label for="phone">Phone</label>
                    <input formControlName="phoneNumber" type="text" class="form-control" id="phone" placeholder="Enter phone">
                    <div *ngIf="contactForm.get('phoneNumber').invalid && (contactForm.get('phoneNumber').dirty || contactForm.get('phoneNumber').touched)" [ngClass]="'error'">
                        <div *ngIf="contactForm.get('phoneNumber').errors.required">
                            Mobile is required.
                        </div>
                        <div *ngIf="contactForm.get('phoneNumber').errors.pattern">
                            Enter correct format.
                        </div>
                    </div>
                </div> -->
                <div class="form-group col-md-6">
                    <div  style="display: block;">
                         <div class="broker_name">
                            Select Broker Information
                        </div>
                       <!-- <input type="text" formControlName="customsSigner" class="form-control"  placeholder="Broker Full Name"  /> -->
                        <select formControlName="customsSigner" class="custom-select" aria-label="Default select example" (change)="brokerEmailAndNumber($event.target.value)">
                            <!-- <option value=""> custom broker info</option> -->
                            <option *ngFor="let br of customDropdownValues" value="{{br.brokerId}}">{{br.brokerName}}</option>
                        </select>
                    </div>
                    
                </div>
            </div>
            <div class="form-row pt-1">
                <div class="form-group col-md-6">
                    <div class="broker_name">
                        Select Restriction Type*
                    </div>
                    
                    <select formControlName="restrictionType" (change)="changerestrictionType()" aria-placeholder="select Restriction Type" class="custom-select" aria-label="Default select example" >
                        <!-- <option >select Restriction Type</option> -->
                        <option *ngFor="let cr of allDropdownValues?.contentRestriction" value="{{cr}}">{{cr}}</option>
                    </select>
                </div>
                <!-- <div class="form-group col-md-4">
                    <label for="phone">Phone</label>
                    <input formControlName="phoneNumber" type="text" class="form-control" id="phone" placeholder="Enter phone">
                    <div *ngIf="contactForm.get('phoneNumber').invalid && (contactForm.get('phoneNumber').dirty || contactForm.get('phoneNumber').touched)" [ngClass]="'error'">
                        <div *ngIf="contactForm.get('phoneNumber').errors.required">
                            Mobile is required.
                        </div>
                        <div *ngIf="contactForm.get('phoneNumber').errors.pattern">
                            Enter correct format.
                        </div>
                    </div>
                </div> -->
                <div class="form-group col-md-6">
                    <div >
                        <div class="broker_name">
                            Restriction Description
                        </div>
                        <textarea formControlName="restrictionDescription" style="resize:none" class="form-control contact-input"  name="" rows="6" cols="100"></textarea>
                    </div>
                    
                </div>
            </div>
            <div class="form-row pt-2 mb-2">
                <div class="form-group col-md-6"> 
                    <div class="broker_name">
                        Select Non-Delivery Option*
                    </div>
                    
                    <select class="custom-select" aria-label="Default select example" formControlName="nonDeliveryOption"  >
                        <!-- <option selected="true" value="">select Non-Delivery Option</option> -->
                        <option *ngFor="let nd of allDropdownValues?.nonDeliveryOption" value="{{nd}}">{{nd}}</option>
                    </select>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn faint-grey col-3" (click)="closeModal()">Cancel</button> &nbsp;&nbsp;
                <button type="submit" [disabled]="!customForm.valid"  class="btn orange text-white col-3">Save</button>
            </div>
        </form>
    </div>
</div>