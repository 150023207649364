import { Component, OnInit } from '@angular/core';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { RemoveFavoriteComponent } from './../_helpers/popup-dialog/remove-favorite/remove-favorite.component';
import { ShipmentListingService  } from './../services/shipment-listing.service';
import { ToastrService } from 'ngx-toastr';
import { PriceBrackupService } from '../services/PRiceBrackup/price-brackeup.service';
import { environment } from './../../environments/environment';
import { CommonService } from '../services/common.service';
import { LoadingService } from '../services/loader.service';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.css']
})
export class FavoriteComponent implements OnInit {
hoveredDate: NgbDate | null = null;

  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  // constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private modalService: NgbModal) { }
  filterCourier: any;
  perPage :number;  
  currentPage: any;
  pageNo = 0;
  favouriteItems:any;
  page = 0;
  displayLoader: boolean;
  totalElements: any;
  totalPages: any;
  paginationSize:number;
  popOverDataPrice: any;
  searchData = '';
  dateFilterForm: FormGroup;
  searchKey: any;
  filterKey:any;
  queryStringParam: string;
  startDate:any;
  endDate:any
  cYear:any;
  sidebarToggle: boolean;
  constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private modalService: NgbModal,public shipmentListingService:ShipmentListingService,private toast: ToastrService,private router: Router,public config: NgbPaginationConfig,public priceBr:PriceBrackupService,private formBuilder: FormBuilder, public common: CommonService,   private  _loaderService: LoadingService) { 
    // constructor(private calendar: NgbCalendar, public formatter: NgbDateParserFormatter,private modalService: NgbModal,public shipmentListingService:ShipmentListingService,public config: NgbPaginationConfig) { 
      this.config.size = 'sm';
      this.config.ellipses = true;
    }
    ngDoCheck() {
      if(this.common.sidebarToggle==true){
        this.sidebarToggle=true;
      }
      else{
        this.sidebarToggle=false;
      }
    }
    ngOnInit(): void {
    this._loaderService.stringSubject.next(true);
    this.cYear =  environment.Cyear;
    this.searchKey = '';
    this.queryStringParam = "isFavourite=true"
    this.dateFilterForm = this.formBuilder.group({
      startDate: ['', [Validators.required]],
      endDate: ['', Validators.required],
    })
    this.filterCourier = "isFavourite=true";
    this.currentPage = 0;
    this.perPage=10;
    this.getFavouriteListing(this.pageNo, this.filterCourier, this.perPage);
    
  }

  submit(){}

  keyWordSearch(keyword){

    if (keyword.target.value == "") {
      this.searchKey='';
      return;
    }
    this.searchKey = keyword.target.value;
    //this.getFavouriteListing(this.page,this.queryStringParam,this.perPage);

  }

  applySearchFilter(){
    this.startDate = this.dateFilterForm['value'].startDate;
    this.endDate = this.dateFilterForm['value'].endDate;
    let d1 = Date.parse(this.dateFilterForm['value'].startDate);
    let d2 = Date.parse(this.dateFilterForm['value'].endDate);
    // console.log(this.startDate,this.endDate)

    if ((this.startDate && this.endDate) && (d1 > d2)) {
      this.startDate = null;
      this.endDate = null;
      this.dateFilterForm.reset();
      this.toast.error('Please provide valid Dates.', '', {
        timeOut: 10000,
      });
      return;
    }
    this.queryStringParam = "isFavorite=true"

    if(this.searchKey){
      this.queryStringParam +='&searchKey='+this.searchKey;
    }
    
    if(this.startDate && this.endDate){
      this.queryStringParam += '&startDate='+this.startDate+'&endDate='+this.endDate;
    }

    this.getFavouriteListing(this.page, this.queryStringParam, this.perPage);
  }


  perPageItem(event) {
    this.displayLoader = true;
    const items = event.target.value;
    if (items != '') {
      this.perPage = items;
      this.getFavouriteListing(this.page, this.queryStringParam, this.perPage);
    }
  }


  getFavouriteListing(page, filterPrams, items){
    // this.displayLoader = true;
    // this.shipmentListingService.getFavouriteListing(page, filterPrams, items).subscribe(result =>{
    //   console.log(result)
    //   this.displayLoader = false;
    //   this.favouriteItems = result['response'];
    //   this.totalElements =   result['totalPages'] * this.perPage;
    // })

    this.displayLoader = true;
    this.shipmentListingService.getFavouriteListing(page, filterPrams, items).subscribe(ship => {
        if (ship) {
          this._loaderService.stringSubject.next(false);
        }
      if (ship['totalEntries'] <= 0) {
        this.favouriteItems = [];
        this.displayLoader = false;
        // this.toast.error('No data found.', '', {
        //   timeOut: 10000,
        // });
        return;
      } else {
        this.displayLoader = true;
        for (let i = 0; i < ship["response"].length; i++) {

          this.priceBr.getPRicebrackup(ship['response'][i]['quoteDetails']["quoteId"]).subscribe(price => {

            ship['response'][i]["priceBr"] = price ? price : null;
            ship['response'][i]["price"] = price ? price['totalAmount'] : null;
            ship['response'][i]["poNumber"] = ship['response'][i]["quoteDetails"]['poNumber'];
            ship['response'][i]["shipmentModeName"] = ship['response'][i]["quoteDetails"]['shipmentModeName'];
            ship['response'][i]["pickupDate"] = ship['response'][i]["quoteDetails"]['pickupDate'];
            ship['response'][i]["shipperName"] = ship['response'][i]["quoteDetails"]['shipperAddress']['firstName'];
            ship['response'][i]["shipperName"] = ship['response'][i]["quoteDetails"]['shipperAddress']['firstName'];
            ship['response'][i]["consigneeName"] = ship['response'][i]["quoteDetails"]['consigneeAddress']['firstName'];
           
            ship['response'][i]['quoteDetails']['items'].forEach(element => {

              ship['response'][i]["quantity"] = element['qty'];
              ship['response'][i]["weight"] += (element['totalWeight']*element['qty']);
              ship['response'][i]["class"] = element['freightClass']
              ship['response'][i]["amountCharged"] = element['shipmentItemValue']
            });
          });
        }
        
        this.totalElements = ship['totalEntries'];
        this.favouriteItems = ship['response'];
        this.totalPages = ship['totalPages'];
        this.paginationSize=ship['totalPages'];
        this.displayLoader = false;
      }

    },error=>{
      this._loaderService.stringSubject.next(false);
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.displayLoader = false;
      this.toast.error('Something went wrong.', '', {
        timeOut: 10000,
      });
    });
  }

  filterByValue(event) {
    var querStringParam = event.target.value ? 'shippingMode=' + event.target.value + "&isFavorite=true" : "&isFavorite=true";
    this.getFavouriteListing(0, querStringParam, 10);
  }

  selectPage(i){
    this.displayLoader = true;
    this.currentPage = i.page - 1;
    this.shipmentListingService.getFavouriteListing(i.page - 1, this.queryStringParam, this.perPage).subscribe(a => {
      if (a["response"].empty) {
        this.favouriteItems = [];
        this.displayLoader = false;
        // this.toast.error('No data found.', '', {
        //   timeOut: 10000,
        // });
        return;
      }

      for (let i = 0; i < a["response"].length; i++) {
        this.priceBr.getPRicebrackup(a['response'][i]['quoteDetails']["quoteId"]).subscribe(price => {
          a['response'][i]["priceBr"] = price ? price : null;       

        });
      }
         
      this.totalPages = a['response'].totalPages;
      this.totalElements = a['totalPages'] * this.perPage;
      this.favouriteItems = a['response'];

      this.displayLoader = false;
    }, error => {
      if (error['status'] === 401) {
        localStorage.clear();
        this.router.navigate(['/signin'])
      }
      this.displayLoader = false;
      this.toast.error('Something went wrong.', '', {
        timeOut: 10000,
      });
    })

  }


  popOverIndex(i) {
    this.popOverDataPrice=[]
    this.popOverDataPrice = this.favouriteItems[i]['priceBr'];
    this.popOverDataPrice['customId'] = this.favouriteItems[i]['quoteDetails'].customQuoteId;
    this.popOverDataPrice['shpmtModeName'] = this.favouriteItems[i]['quoteDetails'].shipmentModeName;
    this.popOverDataPrice['shipmentModeStyleCode'] = this.favouriteItems[i]['quoteDetails'].shipmentModeStyleCode;
    this.popOverDataPrice['class'] = this.favouriteItems[i]['class'];
    this.popOverDataPrice['amountCharged'] = this.popOverDataPrice['totalAmount'];
    this.popOverDataPrice['lastShipped'] = this.favouriteItems[i]['quoteDetails'].pickupDate;
    var totalWeight = 0;
    var totalQty =0
    this.favouriteItems[i]['quoteDetails']['items'].forEach((item, index)=>{
      totalWeight+= (item.wghtUnit.toLowerCase() == 'kg' ? item.totalWeight/2.20 : item.totalWeight).toFixed(2)*item.qty;
      totalQty += item.qty
    });
    this.popOverDataPrice['weight'] = totalWeight;
    this.popOverDataPrice['quantity'] = totalQty;
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }

  open_modal(key) {

    const modalRef = this.modalService.open(RemoveFavoriteComponent, { size: 'lg', centered: false, backdrop: true, animation: true, windowClass: 'my-custom-modal3' });
    this.router.events.subscribe((val) => {
      modalRef.close();
    });
    modalRef.componentInstance.orderId = key['orderId'];
    modalRef.componentInstance.orderFavoriteName = key['favOrderName'];
    modalRef.result.then((result) => {
      if(result['success'] ==  true){
        this.toast.success('Shipments succesfully removed from favorites.', '', {
          timeOut: 10000,
        });
        this.getFavouriteListing(this.pageNo, this.filterCourier, this.perPage)
      }
    });
  }

  editQuote(quoteid) {
    this.router.navigate(['/get-quote/'+quoteid])
  }

  getQuote(quoteid) {
    this.router.navigate(['/get-quote/details/'+quoteid])
  }

}
