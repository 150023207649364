<div class="container popContainer">
    <span>
         <h4 >Adding Contact Information 
             <button type="button" class="close float-right " (click)="closeModal()" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">
                    <span class="material-icons">
                    close
                    </span>
                </span>
             </button>
         </h4>
    </span>
    <hr>
    <div>
         <form [formGroup]="contactForm" (ngSubmit)="submit()">
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="firstName">First Name</label>
                    <input formControlName="firstName" type="text" class="form-control" id="" placeholder="Enter first name">
                    <div *ngIf="contactForm.get('firstName').invalid && (contactForm.get('firstName').dirty || contactForm.get('firstName').touched)" [ngClass]="'error'">
                        <div *ngIf="contactForm.get('firstName').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="contactForm.get('firstName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="f.firstName.errors.minlength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="f.firstName.errors.maxlength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-6">
                    <label for="lastName">Last Name</label>
                    <input formControlName="lastName" type="text" class="form-control" id="" placeholder="Enter last name">
                    <div *ngIf="contactForm.get('lastName').invalid && (contactForm.get('lastName').dirty || contactForm.get('lastName').touched)" [ngClass]="'error'">
                        <div *ngIf="contactForm.get('lastName').errors.required">
                            Last Name is required.
                        </div>
                        <div *ngIf="contactForm.get('lastName').errors.pattern">
                            Special charactres/numbers not allowed.
                        </div>
                        <div *ngIf="f.lastName.errors.minlength">
                            Minimum 2 characters.
                        </div>
                        <div *ngIf="f.lastName.errors.maxlength">
                            Maximum 30 characters.
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="form-group col-md-6">
                    <label for="popup_icon">Position</label>
                    <input formControlName="position" type="text" class="form-control" id="position" placeholder="Enter position">
                </div>
                <div class="form-group col-md-4">
                    <label for="phone">Phone</label>
                    <input formControlName="phoneNumber" type="text" class="form-control" id="phone" placeholder="Enter phone">
                    <div *ngIf="contactForm.get('phoneNumber').invalid && (contactForm.get('phoneNumber').dirty || contactForm.get('phoneNumber').touched)" [ngClass]="'error'">
                        <div *ngIf="contactForm.get('phoneNumber').errors.required">
                            Mobile is required.
                        </div>
                        <div *ngIf="contactForm.get('phoneNumber').errors.pattern">
                            Enter correct format.
                        </div>
                    </div>
                </div>
                <div class="form-group col-md-2">
                    <label for="ext">Ext.</label>
                    <input formControlName="Extension" type="text" style="width: 85% !important;" class="form-control" id="ext" placeholder="">
                </div>
            </div>
            <div class="form-group">
                <label for="email">Email</label>
                <input formControlName="email" type="email" style="width: 98% !important;" class="form-control" id="email" placeholder="Enter email address">
                <div *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)" [ngClass]="'error'">
                    <div *ngIf="contactForm.get('email').errors.required">
                        Email is required.
                    </div>
                    <div *ngIf="contactForm.get('email').errors.pattern">
                        Enter correct Email format.
                    </div>
                </div>
            </div>
            <div class="text-right">
                <button type="button" class="btn faint-grey col-3" (click)="closeModal()">Cancel</button> &nbsp;&nbsp;
                <button type="submit" [disabled]="!contactForm.valid" class="btn orange text-white col-3">Save</button>
            </div>
        </form>
    </div>
</div>