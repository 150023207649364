import { Component, OnInit } from '@angular/core';
import { NgbModal ,ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import{Router} from "@angular/router"

@Component({
  selector: 'app-credit-application-back-button',
  templateUrl: './credit-application-back-button.component.html',
  styleUrls: ['./credit-application-back-button.component.css']
})
export class CreditApplicationBackButtonComponent implements OnInit {

  constructor(private ser:NgbModal, private rt:Router) { }
  
  ngOnInit(): void {
  }
  close(){
    this.ser.dismissAll();
    }

  navigate(){
    this.rt.navigateByUrl("user-preferences");
    this.ser.dismissAll();
  }
}
