
    <div style="top: 90px;position: absolute;padding: 20px;">
        <img class="d-block m-auto" src="assets/img/payment/popup_icon.svg"  width="10%">
        <h3 class="text-center">If you wish to edit the shipper address, It will take you back step 1, are you sure?</h3>
        <br>
        <div class="text-right">
            <button type="button" class="btn faint-grey col-3" style="box-shadow: none;" (click)="close()">Cancle</button>
            &nbsp;&nbsp; <button type="button" class="btn orange text-white col-3" style="box-shadow: none;" (click)="navigate()">Yes</button>
        </div>
        
    </div> 
    <hr>
  