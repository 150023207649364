import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {SignupService} from '../services/sign-up/signup.service'

@Injectable({
  providedIn: 'root'
})

export class RoleGuard implements CanActivate {

  constructor
  ( private signupService:SignupService,
    private router: Router,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let user='';
    this.signupService.getUser().subscribe((data)=>{
     user=data;
    });
    if (user) {
      if (route.data.roles && route.data.roles.indexOf(user) !== -1) {
        return true;
      }
      else {
        return false;
      }
    }
  }
}
