import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDatepickerConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ShipmentListingService } from '../services/shipment-listing.service';
import { Console, error } from 'console';
import { ListDataServiceService } from '../services/ListData/list-data-service.service';
import { LoadingService } from '../services/loader.service';

@Component({
  selector: 'app-favorite-new',
  templateUrl: './favorite-new.component.html',
  styleUrls: ['./favorite-new.component.css']
})
export class FavoriteNewComponent implements OnInit {

  @ViewChild('confirmWarn') confirmWarn;
  displayLoader: boolean;
  sortBy: any;
  orders: any = [];
  orderId: any;
  temp: any = [];
  status: any = [];
  selectTab: any = 1;
  manualOrder: any = [];
  page: number = 0;
  pageSize: number = 20;
  isDesc: any = false;
  searchKey: any = '';
  tabStatus: any = '';
  savingToFavValue: string = '';

  tabStyle: number = 1;
  popOverDataPrice: any = [];
  selectedQuoteID: any;
  selectedOrderID: any;
  selectedStatus: any;
  trackingData: any;


  quoteId: any;
  quote: any;
  ordersId: any;
  orderStatus: any;
  customOrderId: any;
  displayCourier: true;
  orderid: any;
  shipperZip: any;
  consigneeZip: any;
  trackOrderId: any;
  addFevForm: FormGroup;
  quoteIdArray: any = [];
  todaysDate: any;
  minDate: any;
  quantity: any;
  weight: any;
  class: any;
  amountCharged: any;
  partnerLogo: any;
  carrierName: any;
  packageLogo: any;
  packageName: any;
  shipperDate: any;
  data: any;
  formattedDate: any;


  constructor(
    private modalService: NgbModal,
    private route: Router,
    private urlFunService: ShipmentListingService,
    private toastService: ToastrService,
    private fb: FormBuilder,
    private config: NgbDatepickerConfig,
    private listDataService:ListDataServiceService,
    private  _loaderService: LoadingService
  ) { }

  ngOnInit() {
    this.orders = [
      { id: 1, orderType: 'All Orders' },
      { id: 2, orderType: 'In-Transit Orders' },
      { id: 3, orderType: 'Completed Orders' }
    ]
    this.sortBy = {
      "favOrderName": "downOrder",
      "consigneeName": 'downOrder',
      "source": 'downOrder',
      "pickupDate": 'downOrder',
      "totalWeight": 'downOrder',
      "dimension": 'downOrder',
      "shipper": 'downOrder',
      "origin": 'downOrder',
      "consignee": "downOrder",
      "destination": "downOrder",
      "price": "downOrder",
      "carrierName": "downOrder",
      "shipmentModeName": "downOrder",
      "shipmentMdName": "downOrder"
    }
    this.checkDataIsPresentInServiceFileOrNot();
    this.addFevForm = this.fb.group({
      addFev: ['', [Validators.required]]
    })


    this.config.minDate = { month: 1, day: 1, year: 1980, };
    this.config.outsideDays = 'hidden';

  }

  onClickCreateManualOrder() {
    this.displayLoader = true;
    this._loaderService.stringSubject.next(true);
    this.route.navigateByUrl('/shipcarte/orders/newOrderEntry');
  }
  checkDataIsPresentInServiceFileOrNot(){
    let dataFromServiceFile=this.listDataService.getAllDataOfFavoriteOrderList();
    if(dataFromServiceFile.dataList.length>0){
      this.page=dataFromServiceFile.pageCount;
      this.pageSize=dataFromServiceFile.pageSize;
      this.manualOrder=dataFromServiceFile.dataList;
      if(dataFromServiceFile.searchKey!='')[
        this.searchKey=dataFromServiceFile.searchKey
      ]
    }else{
      this.listDataService.removeAllDataOfFavoriteOrderList();
      this.page=0;
      this.fetchOrderList();
    }
  }
  // all order list Fetch **************************************************

  isDataLoading:any=''
  fetchOrderList() {
    this.isDataLoading=''
    this.displayLoader = true;
    this._loaderService.stringSubject.next(true);
    // isFavourite=true
    let url = '?isFavourite=true';
    if (this.searchKey != '') {
      url = url + '&searchKey=' + this.searchKey
    }
    this.urlFunService.getFavouriteListing(this.page, this.pageSize, url).subscribe(
      (res: any) => {
        this.manualOrder = res.response;
        if (res.response.length > 0) {
          let iter = 0;
          this.quoteIdArray = [];

          for (let i = 0; i < res.response.length; i++) {
            res["response"][i].pickupDate = res["response"][i].quoteDetails.pickupDate ? res["response"][i].quoteDetails.pickupDate : "N/A";
            res["response"][i].shipper = res["response"][i].quoteDetails.shipperAddress.company ? res["response"][i].quoteDetails.shipperAddress.company : 'N/A';
            res["response"][i].origin = `${res["response"][i].quoteDetails.shipperAddress.city}, ${res["response"][i].quoteDetails.shipperAddress.state}`;
            res["response"][i].destination = `${res["response"][i].quoteDetails.consigneeAddress.city}, ${res["response"][i].quoteDetails.consigneeAddress.state}`;
            res["response"][i].consignee = res["response"][i].quoteDetails.consigneeAddress.company ? res["response"][i].quoteDetails.consigneeAddress.company : 'N/A';
            res["response"][i].consigneeName = `${res["response"][i].quoteDetails.customerName}` ? `${res["response"][i].quoteDetails.customerName} ` : 'N/A'
            res["response"][i].carrierName = res["response"][i].quoteDetails.carrierName ? res["response"][i].quoteDetails.carrierName : 'N/A';
            res["response"][i].partnerLogo = res["response"][i].quoteDetails.carrierLogo ? res["response"][i].quoteDetails.carrierLogo : 'N/A';
            res["response"][i].customerId = res["response"][i].quoteDetails.customerId ? res["response"][i].quoteDetails.customerId : 'N/A';
            res["response"][i].profilePic = res["response"][i].quoteDetails.profileImageDownloadUrl ? res["response"][i].quoteDetails.profileImageDownloadUrl : 'N/A';
            res["response"][i].shipmentMdName = res["response"][i].quoteDetails.shipmentModeName ? res["response"][i].quoteDetails.shipmentModeName : 'N/A';
            res["response"][i].shipmentCarrierCode = res["response"][i].quoteDetails.shipmentModeStyleCode ? res["response"][i].quoteDetails.shipmentModeStyleCode : 'N/A';
            iter = iter + 1;
            if (iter === res.response.length) {
              this.manualOrder = res.response;
              let data={
                pageCount:this.page,
                pageSize:this.pageSize,
                dataList:this.manualOrder
              }
              this.listDataService.setAllDataOfFavoriteOrderList(data);
              this.displayLoader = false;
              this._loaderService.stringSubject.next(false);
              this.isDataLoading=''
            }

            this.quoteIdArray.push(res.response[i].quoteDetails.quoteId);
          }
        } else {
          this.isDataLoading='No Data Found!'
          let data={
            pageCount:-1,
            pageSize:20,
            dataList:[]
          }
          this.listDataService.setAllDataOfFavoriteOrderList(data);
          this.manualOrder = [];
          this.displayLoader = false;
          this._loaderService.stringSubject.next(false);
        }
      },
      (error) => {
        this.displayLoader = false;
        this._loaderService.stringSubject.next(false);
        this.toastService.error(error, 'Something Went Wrong')
      }
    );
  }
  getSum(items: any[], property: string): number {
    if (property !== 'totalWeight') {
      return items.reduce((sum, item) => sum + (parseFloat(item[property]) || 0), 0);
    } else if (property === 'totalWeight') {
      return items.reduce((sum, item) => sum + ((parseFloat(item[property]) || 0) * item['qty']), 0);
    }
    return 0;
  }


  popOverIndex(value, packageName, packageLogo, orderSource) {
    this.quantity = 0;
    this.weight = 0;
    this.class = 0;
    this.shipperDate = value.pickupDate ? value.pickupDate : '--'
    this.packageName = packageName ? packageName : '';
    this.amountCharged = value.totalAmount ? value.totalAmount : '--';
    this.carrierName = value.carrierName ? value.carrierName : '--';
    this.partnerLogo = value.carrierLogo ? value.carrierLogo : 'assets/img/carrier/No-Carrier-Image.svg';
    this.packageLogo = packageLogo ? packageLogo : '';
    // console.log(this.packageLogo,'gj')
    value.items.forEach((item) => {
      if (typeof item.qty === 'number') {
        this.quantity += item.qty;
      }

      if (typeof item.totalWeight === 'number') {
        this.weight += item.totalWeight;
      }

      let dataClass = Number(item.freightClass)
      if (typeof dataClass === 'number') {
        this.class += dataClass;
      }

      // this.weight += item.totalWeight;
      // this.class += item.freightClass;   
    });

  }

  setValue(value: any) {
    this.savingToFavValue = value;

  }



  postFevData(orderId, fevName,index) {

    this.displayLoader = true;
    this._loaderService.stringSubject.next(true);

    let inputData = {
      "orderId": orderId,
      "isFavourite": false,
      "favouriteOrderName": fevName
    }
    this.urlFunService.updateItems(inputData).subscribe(result => {
      this.manualOrder.splice(index,1)
      this.toastService.success('Shipment Successfully Removed From Favorites.')
      this.displayLoader = false;
      this._loaderService.stringSubject.next(false);
    }, (error) => {
      this.displayLoader = false;
      this._loaderService.stringSubject.next(false);
      this.toastService.error(error.message ? error.message : 'Something Went Wrong', '',)
    })

  }


  viewQuote(quoteid, customOrId, orderId, orderStatus, customerName, customerid, status, carrierName, carrierLogo, orderSource) {
    ///shipcarte/orders/orderDetail
    const userInforamation = {
      quoteid: quoteid,
      customOrderId: customOrId,
      orderId: orderId,
      orderStatus: orderStatus,
      customerName: customerName,
      customerId: customerid,
      carrierName: carrierName.carrierName,
      carrierLogo: carrierLogo,
      source: orderSource
      // carrierId:
      // currencyUnit:
      // contractId

    }
    // this.commanService.quoteId = quoteid;
    sessionStorage.setItem("userInformation", JSON.stringify(userInforamation))
    this.route.navigate(['/shipcarte/orders/orderDetail']);

    this.orderid = orderId
    this.quote = quoteid


  }

  // Moduls *************************************************************
  open( orderId, fevName,index) {
    this.modalService.open(this.confirmWarn, { size: 'sm', centered: true, windowClass: 'confirmModal' }).result.then((result) => {
      this.postFevData(orderId, fevName,index);
 
    }, (reason) => {
    })
  }
  //end module ***********************************************************

  // sorting(property) {
  //   this.isDesc = !this.isDesc  // change the direction
  //   let direction = this.isDesc ? 1 : -1;
  //   this.sortBy[property] = !this.isDesc ? 'downOrder' : 'upOrder';

  //   if (property === 'price') {
  //     this.manualOrder.sort(function (a, b) {
  //       if (parseFloat(a[property]) < parseFloat(b[property])) {
  //         return -1 * direction;
  //       }
  //       else if (parseFloat(a[property]) > parseFloat(b[property])) {
  //         return 1 * direction;
  //       }
  //       else {
  //         return 0;
  //       }
  //     });
  //   } else {
  //     this.manualOrder.sort(function (a, b) {
  //       if (a[property].toLowerCase() < b[property].toLowerCase()) {
  //         return -1 * direction;
  //       }
  //       else if (a[property].toLowerCase() > b[property].toLowerCase()) {
  //         return 1 * direction;
  //       }
  //       else {
  //         return 0;
  //       }
  //     });
  //   }
  // }
  sorting(property) {
    this.isDesc = !this.isDesc;  // change the direction
    let direction = this.isDesc ? 1 : -1;
    this.sortBy[property] = !this.isDesc ? 'downOrder' : 'upOrder';

    this.manualOrder.sort((a, b) => {
      if (property === 'price') {
        return (parseFloat(a[property]) - parseFloat(b[property])) * direction;
      } else if (property === 'dimension') {
        const dimensionA = this.getDimensionAsArray(a?.quoteDetails?.items);
        const dimensionB = this.getDimensionAsArray(b?.quoteDetails?.items);

        // Compare dimensions numerically
        for (let i = 0; i < dimensionA.length; i++) {
          const diff = (dimensionA[i] - dimensionB[i]) * direction;
          if (diff !== 0) {
            return diff;
          }
        }
        return 0;
      } else if (property === 'totalWeight') {
        const sumA = this.getSum(a?.quoteDetails?.items, property);
        const sumB = this.getSum(b?.quoteDetails?.items, property);
        return (sumA - sumB) * direction;
      } else {
        // For other properties
        return a[property].toLowerCase().localeCompare(b[property].toLowerCase()) * direction;
      }
    });
  }
  getDimensionAsArray(items: any[]): number[] {
    const sumLength = this.getSum(items, 'length');
    const sumBreadth = this.getSum(items, 'breadth');
    const sumHeight = this.getSum(items, 'height');

    return [sumLength, sumBreadth, sumHeight];
  }
 

  //filters*************************************************************
  timer: any;
  setSearchKey(searchParam) {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      if (searchParam.length > 2 && searchParam != null && searchParam != '') {
        this.searchKey = searchParam;
        this.page=0
        this.listDataService.setSearchKeyForFavoriteOrderList(searchParam)
        this.fetchOrderList();
      } else if (searchParam.length === 0) {
        this.searchKey = '';
        this.page=0
        this.listDataService.setSearchKeyForFavoriteOrderList('')
        this.fetchOrderList();
      }
    }, 500);
  }

  
  showToastDateError() {
    this.toastService.error('Please select valid date range', '', {
      timeOut: 3000
    })
  }

  // popup data for delivered ************************ ************* *************************** *****************************

  showViewPodBtn: boolean = false;
  showPopupDeleivered(quoteid, orderId, orderStatus) {
    this.showViewPodBtn = false;
    this.selectedQuoteID = quoteid;
    this.selectedOrderID = orderId;
    this.selectedStatus = orderStatus;
  }

  redirctToGeneralDetails(id) {
    console.log(id)
    // this.commanService.customerId=id
    this.route.navigateByUrl(`shipcarte/customer/list/generalDetail/edit`)
  }

  shipAgain(quoteId, orderSource, orderId) {
    if (orderSource == 'On Call Order') {
      this.toastService.error("You can't ship again the On Call Order", '', {});
    } else {
      this.route.navigateByUrl('/get-quote/' + quoteId);
    }
  }
  getQuote() {
    this.route.navigateByUrl('/get-quote')
  }
  isLoading: boolean = false
  @HostListener("window:scroll", ["$event"])
  private scrollChangeCallback: () => void;
  currentPosition: any;
  startPosition: number;
  showButton: boolean = false
  ngAfterViewInit() {
    this.scrollChangeCallback = () => this.scrollHanlderFavorateList(event);
    window.addEventListener('scroll', this.scrollChangeCallback, true);
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scrollChangeCallback, true);
    console.log(this.scrollChangeCallback)
  }
  allDataLoaded: any = false;
  scrollHanlderFavorateList(e) {
    this.startPosition = e.srcElement.scrollTop;
    let scroll = e.srcElement.scrollTop;

    if (scroll > this.currentPosition) {
      this.showButton = false;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight && !this.isLoading) {
        this.isLoading = true
        if (!this.allDataLoaded) {
          this.fetchListOfDataToAppend();
        } else {
          this.isLoading = false
        }
      }
    } else {

      this.showButton = true;
    }
    this.currentPosition = scroll;
  }
  fetchListOfDataToAppend() {
    // isFavourite=true
    this.page = this.page + 1
    let url = '?isFavourite=true';
    if (this.searchKey != '') {
      url = url + '&searchKey=' + this.searchKey
    }
    this.urlFunService.getFavouriteListing(this.page, this.pageSize, url).subscribe(
      (res: any) => {
        if (res.response.length > 0) {
          let iter = 0;
          this.quoteIdArray = [];

          for (let i = 0; i < res.response.length; i++) {
            res["response"][i].pickupDate = res["response"][i].quoteDetails.pickupDate ? res["response"][i].quoteDetails.pickupDate : "N/A";
            res["response"][i].shipper = res["response"][i].quoteDetails.shipperAddress.company ? res["response"][i].quoteDetails.shipperAddress.company : 'N/A';
            res["response"][i].origin = `${res["response"][i].quoteDetails.shipperAddress.city}, ${res["response"][i].quoteDetails.shipperAddress.state}`;
            res["response"][i].destination = `${res["response"][i].quoteDetails.consigneeAddress.city}, ${res["response"][i].quoteDetails.consigneeAddress.state}`;
            res["response"][i].consignee = res["response"][i].quoteDetails.consigneeAddress.company ? res["response"][i].quoteDetails.consigneeAddress.company : 'N/A';
            res["response"][i].consigneeName = `${res["response"][i].quoteDetails.customerName}` ? `${res["response"][i].quoteDetails.customerName} ` : 'N/A'
            res["response"][i].carrierName = res["response"][i].quoteDetails.carrierName ? res["response"][i].quoteDetails.carrierName : 'N/A';
            res["response"][i].partnerLogo = res["response"][i].quoteDetails.carrierLogo ? res["response"][i].quoteDetails.carrierLogo : 'N/A';
            res["response"][i].customerId = res["response"][i].quoteDetails.customerId ? res["response"][i].quoteDetails.customerId : 'N/A';
            res["response"][i].profilePic = res["response"][i].quoteDetails.profileImageDownloadUrl ? res["response"][i].quoteDetails.profileImageDownloadUrl : 'N/A';
            res["response"][i].shipmentMdName = res["response"][i].quoteDetails.shipmentModeName ? res["response"][i].quoteDetails.shipmentModeName : 'N/A';
            res["response"][i].shipmentCarrierCode = res["response"][i].quoteDetails.shipmentModeStyleCode ? res["response"][i].quoteDetails.shipmentModeStyleCode : 'N/A';
            iter = iter + 1;
            if (iter === res.response.length) {
              this.isLoading = false
              this.manualOrder = this.manualOrder.concat(res.response);
              let data={
                pageCount:this.page,
                pageSize:this.pageSize,
                dataList:this.manualOrder
              }
              this.listDataService.setAllDataOfFavoriteOrderList(data);
            }
            this.quoteIdArray.push(res.response[i].quoteDetails.quoteId);
          }
        } else if(res.response.length==0){
          this.allDataLoaded=true;
          this.isLoading=false;
        }
      },
      (error) => {
        this.isLoading = false
        if (error['status'] == 401) {
          localStorage.clear();
          this.route.navigate(['/signin'])
        } else {
          this.toastService.error("Something Went Wrong!")
        }
      }
    );
  }
}
